import React, { Component, useRef } from "react";
import EvalReport from "./eval-report";
import { TRANSLATIONS } from "../../data-structures/localization";
import { Loading } from "../../common/loading-icon";
import { Validate } from "../../helpers/validation";
import { EvalHelpers } from "../../helpers/eval-helpers.tsx";
import { HelperFunctions } from "../../helpers/helper-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SELECT_ALL_VALUE } from "../../forms/form-elements.tsx";

const evalData = {
	userData: null,
	evalHistory: null,
};

export default class EvalHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedCourse: null,
			selectedLesson: null,
			selectedEvaluation: null,
		};
	}

	render() {
		evalData.userData = this.props.userData;
		evalData.evalHistory = this.props.evalHistory;
		return (
			<div className="eval-history-list">
				<div className="top-spacer sticky-top" />
				<h2 className="no-print">{TRANSLATIONS.HEADERS.EVALUATION_HISTORY}</h2>
				{this.props.courses && this.props.evalHistory ? (
					this.props.evalHistory.length === 0 ? (
						<div>{TRANSLATIONS.DASHBOARD.EVAL_HISTORY.NO_DATA_MESSAGE}</div>
					) : (
						<>
							<div className="no-print">
								<CourseList
									courses={this.props.courses}
									units={this.props.units}
									lessons={this.props.lessons}
									state={this.state}
									setState={(newState) => this.setState(newState)}
								/>
							</div>
							{this.state.selectedEvaluation && (
								<div className="print-only">
									<EvalReport userData={evalData.userData} evaluation={this.state.selectedEvaluation} />
								</div>
							)}
						</>
					)
				) : (
					<Loading />
				)}
			</div>
		);
	}
}

const DataRow = (props = { cellData: [], isHeader: false, expanded: false, nestLayer: 0, onClick: () => {} }) => {
	const nodeRef = useRef();

	const scrollToSelection = () => {
		const elementPosition = nodeRef.current.offsetTop;
		const rowHeights = 32 * (props.nestLayer + 1);
		const spacerOffset = 10;

		const scrollPosition = elementPosition - rowHeights + spacerOffset;

		if (scrollPosition < window.scrollY) {
			window.scrollTo({
				top: scrollPosition,
				left: 0,
				behavior: "instant",
			});
		}
	};

	const toggleExpanded = (e) => {
		e.stopPropagation();
		props.onClick();
		props.expanded && scrollToSelection();
	};

	const cells = props.cellData.map((cd, i) => (
		<div key={i} className={`history-cell${props.isHeader ? " header-cell" : ""}`} onClick={props.isHeader ? () => {} : scrollToSelection}>
			<span className="cell-text">{cd}</span>
		</div>
	));
	return props.isHeader ? (
		<div className="history-row header-row sticky-top">
			<div className="history-cell" />
			{cells}
		</div>
	) : (
		<div className="history-row-container" ref={nodeRef}>
			<button className={`history-row${props.expanded ? " expanded sticky-top" : ""}`}>
				<div className="history-cell action-btns" onClick={scrollToSelection}>
					<span className="cell-text">
						<FontAwesomeIcon
							className={`icon-btn arrow ${props.expanded ? "expanded" : ""}`}
							icon="fa-solid fa-chevron-right"
							onClick={toggleExpanded}
						/>
					</span>
				</div>
				{cells}
			</button>
			{props.expanded && props.children}
		</div>
	);
};

const NoDataRow = () => (
	<div className="history-row">
		<div className="no-data">{TRANSLATIONS.PERFORMANCE.NO_DATA_MESSAGE}</div>
	</div>
);

const CourseList = (props = { courses: [], units: [], lessons: [], state: null, setState: () => {} }) => (
	<div className="courses history-table">
		<DataRow
			cellData={[
				TRANSLATIONS.DASHBOARD.EVAL_HISTORY.COURSE,
				TRANSLATIONS.DASHBOARD.EVAL_HISTORY.PERCENT_COMPLETE,
				TRANSLATIONS.DASHBOARD.EVAL_HISTORY.TIME_SPENT,
				TRANSLATIONS.DASHBOARD.EVAL_HISTORY.TIME_REMAINING,
			]}
			isHeader
			nestLayer={1}
		/>
		{props.courses.length > 0 ? (
			props.courses.map((course) => {
				const completionData = EvalHelpers.getCourseCompletionData(course, evalData.evalHistory);
				return (
					<DataRow
						key={course.id}
						cellData={[
							!Validate.isNullOrEmpty(course.name) ? course.name : "-",
							!Validate.isNullOrEmpty(completionData.percentComplete) && completionData.timeSpent > 0
								? `${Math.round(completionData.percentComplete * 100)}%`
								: "-",
							!Validate.isNullOrEmpty(completionData.timeSpent) ? HelperFunctions.getFriendlyDuration(completionData.timeSpent) : "-",
							!Validate.isNullOrEmpty(completionData.timeRemaining)
								? HelperFunctions.getFriendlyDuration(completionData.timeRemaining)
								: "-",
						]}
						expanded={props.state.selectedCourse?.id === course.id}
						nestLayer={1}
						onClick={() => props.setState({ selectedCourse: props.state.selectedCourse?.id !== course.id ? course : null })}
					>
						<LessonList course={course} units={props.units} lessons={props.lessons} state={props.state} setState={props.setState} />
					</DataRow>
				);
			})
		) : (
			<NoDataRow />
		)}
	</div>
);

const LessonList = (props = { course: null, units: [], lessons: [], state: null, setState: () => {} }) => {
	const flattenedLessonList = props.course.unitList
		.filter((u) => u.active && !u.deleted && props.units.some((su) => su === SELECT_ALL_VALUE || su.id === u.id))
		.flatMap((unit) =>
			unit.lessonList
				.filter((l) => l.active && !l.deleted && props.lessons.some((sl) => sl === SELECT_ALL_VALUE || sl.id === l.id))
				.map((lesson) => ({ unit: unit, ...lesson }))
		);
	return (
		<div className="lessons history-table sub-table">
			<DataRow
				cellData={[
					TRANSLATIONS.DASHBOARD.EVAL_HISTORY.UNIT,
					TRANSLATIONS.DASHBOARD.EVAL_HISTORY.LESSON,
					TRANSLATIONS.DASHBOARD.EVAL_HISTORY.COMPLETED,
					TRANSLATIONS.DASHBOARD.EVAL_HISTORY.BEST_SCORE,
					TRANSLATIONS.DASHBOARD.EVAL_HISTORY.LAST_SCORE,
					TRANSLATIONS.DASHBOARD.EVAL_HISTORY.LAST_ATTEMPT,
					TRANSLATIONS.DASHBOARD.EVAL_HISTORY.NUMBER_OF_ATTEMPTS,
				]}
				isHeader
				nestLayer={2}
			/>
			{flattenedLessonList.length > 0 ? (
				flattenedLessonList.map((lesson) => {
					const lessonEvals = EvalHelpers.getEvalsForLesson(lesson.id, evalData.evalHistory).sort(
						(a, b) => new Date(a.evaluationDate) - new Date(b.evaluationDate)
					);
					const mostRecentEval = lessonEvals.at(-1);

					return (
						<DataRow
							key={lesson.id}
							cellData={[
								!Validate.isNullOrEmpty(lesson.unit.name) ? lesson.unit.name : "-",
								!Validate.isNullOrEmpty(lesson.name) ? lesson.name : "-",
								<FontAwesomeIcon icon={`fa-solid ${EvalHelpers.isLessonComplete(lesson, lessonEvals) ? "fa-check" : "fa-x"}`} />,
								...(lessonEvals.length > 0
									? [
											Math.max(...lessonEvals.map((e) => e.scoring.finalScore)),
											mostRecentEval.scoring.finalScore,
											HelperFunctions.getFriendlyDateTime(mostRecentEval.evaluationDate),
									  ]
									: ["-", "-", "-"]),
								lessonEvals.length,
							]}
							expanded={props.state.selectedLesson?.id === lesson.id}
							nestLayer={2}
							onClick={() =>
								props.setState({
									selectedLesson: props.state.selectedLesson?.id !== lesson.id ? lesson : null,
								})
							}
						>
							<EvalList evals={lessonEvals} state={props.state} setState={props.setState} />
						</DataRow>
					);
				})
			) : (
				<NoDataRow />
			)}
		</div>
	);
};

const EvalList = (props = { evals: null, state: null, setState: () => {} }) => (
	<div className="attempts history-table sub-table">
		<DataRow
			cellData={[
				TRANSLATIONS.DASHBOARD.EVAL_HISTORY.DATE,
				TRANSLATIONS.DASHBOARD.EVAL_HISTORY.ATTEMPT,
				TRANSLATIONS.DASHBOARD.EVAL_HISTORY.COMPLETED,
				TRANSLATIONS.DASHBOARD.EVAL_HISTORY.SCORE,
			]}
			isHeader
			nestLayer={3}
		/>
		{props.evals.length > 0 ? (
			props.evals.flatMap((evaluation, i) => (
				<DataRow
					key={evaluation.id}
					cellData={[
						`${HelperFunctions.getFriendlyDate(evaluation.evaluationDate)} ${HelperFunctions.getFriendlyTime(evaluation.evaluationDate)}`,
						i + 1,
						<FontAwesomeIcon icon={`fa-solid ${evaluation.completed ? "fa-check" : "fa-x"}`} />,
						!Validate.isNullOrEmpty(evaluation.scoring.finalScore) ? evaluation.scoring.finalScore : "-",
					]}
					expanded={props.state.selectedEvaluation?.id === evaluation.id}
					nestLayer={3}
					onClick={() =>
						props.setState({
							selectedEvaluation: props.state.selectedEvaluation?.id !== evaluation.id ? evaluation : null,
						})
					}
				>
					<div className="history-row">
						<EvalReport userData={evalData.userData} evaluation={props.state.selectedEvaluation} />
					</div>
				</DataRow>
			))
		) : (
			<NoDataRow />
		)}
	</div>
);
