import React, { ChangeEventHandler, Component, MouseEventHandler, ReactNode } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "chartjs-adapter-moment";
import { TRANSLATIONS } from "../data-structures/localization";
import { globalState } from "../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISelectOption, MultiSelectOption, SelectOption } from "../data-structures/select-options";
import { GSTooltip } from "../common/tooltip";

//#region Field Types
const InputField = (props: {
	id?: string;
	label?: string;
	tooltipContent?: string;
	columnLayout?: boolean;
	fullWidth?: boolean;
	dataState?: string;
	className?: string;
	containerClassName?: string;
	fieldValue?: string;
	autoCompleteString?: string;
	type?: any;
	min?: any;
	max?: any;
	step?: any;
	formField?: boolean;
	errors?: Array<FormError>;
	handleChange?: ChangeEventHandler;
}) => {
	const applicableErrors = props.errors?.filter((item) => item.errorFields.includes(props.dataState)) || [];
	const getFieldFromType = () => {
		switch (props.type) {
			case "textarea":
				return (
					<textarea
						className={props.className}
						value={props.fieldValue}
						data-state={props.dataState}
						autoComplete={props.autoCompleteString}
						rows={3}
						onChange={props.handleChange}
					/>
				);

			default:
				return (
					<input
						className={props.className}
						type={props.type}
						min={props.min}
						max={props.max}
						step={props.step}
						value={props.fieldValue}
						data-state={props.dataState}
						autoComplete={props.autoCompleteString}
						onChange={props.handleChange}
					/>
				);
		}
	};

	const fieldTemplate = (
		<>
			<div
				className={`${props.formField ? "form-field" : ""}${props.type ? ` input-${props.type}` : ""} ${props.containerClassName || ""} ${
					applicableErrors.length > 0 ? "has-error" : ""
				}`}
			>
				{props.label && !props.columnLayout && <label>{`${props.label}:`}</label>}

				{props.formField ? (
					<div className="input-wrapper">
						{getFieldFromType()}
						{props.tooltipContent && <GSTooltip id={props.id || props.label} content={props.tooltipContent} />}
					</div>
				) : (
					getFieldFromType()
				)}
			</div>
			{applicableErrors.map((e, i) => (
				<ErrorContainer key={i} message={e.message} inlineFormError />
			))}
		</>
	);

	return props.columnLayout ? (
		<div className={`column-field-container${props.fullWidth ? " w100" : ""}`}>
			{props.label && (
				<label>
					{props.label}
					{props.tooltipContent && <GSTooltip id={props.id || props.label} content={props.tooltipContent} />}
				</label>
			)}
			{fieldTemplate}
		</div>
	) : (
		fieldTemplate
	);
};

const InfoField = (props: { label?: string; className?: string; fieldValue?: string; formField?: boolean }) => {
	return (
		<div className={`info-field ${props.formField ? "form-field" : ""}`}>
			<label>{props.label}:</label>
			<div className={`info-value ${props.className || ""}`}>{props.fieldValue}</div>
		</div>
	);
};

const SelectField = (props: {
	label?: string;
	id?: string;
	className?: string;
	dataState?: any;
	fieldValue?: any;
	defaultValue?: any;
	placeholder?: string;
	noOptionsMessage?: string;
	fieldOptions?: Array<ISelectOption<any>>;
	formField?: boolean;
	isDisabled?: boolean;
	errors?: Array<FormError>;
	handleChange?: ChangeEventHandler;
}) => {
	const applicableErrors = props.errors && props.dataState ? props.errors.filter((item) => item.errorFields.includes(props.dataState)) : [];
	return (
		<>
			<div className={`${props.formField ? "form-field" : ""} input-select ${props.className || ""}`}>
				{props.label && <label>{`${props.label}${props.formField ? ":" : ""}`}</label>}
				<Select
					id={props.id}
					className="react-select"
					classNamePrefix="react-select"
					options={props.fieldOptions}
					value={props.fieldValue}
					defaultValue={props.defaultValue}
					isDisabled={props.isDisabled}
					placeholder={props.placeholder || TRANSLATIONS.FORMS.SELECT_PLACEHOLDER}
					noOptionsMessage={() => props.noOptionsMessage || TRANSLATIONS.FORMS.NO_OPTIONS}
					onChange={props.handleChange}
				/>
			</div>
			{applicableErrors.map((e, i) => (
				<ErrorContainer key={i} message={e.message} inlineFormError />
			))}
		</>
	);
};

const MultiSelectField = (props: {
	label: string;
	id?: string;
	className: string;
	dataState?: string;
	fieldValue?: any;
	defaultValue?: any;
	placeholder?: string;
	noOptionsMessage?: string;
	fieldOptions: Array<ISelectOption<any> | MultiSelectOption<ISelectOption<any>>>;
	allOptionsLabel?: string;
	formField?: boolean;
	isDisabled: boolean;
	errors?: Array<FormError>;
	handleChange?: Function;
}) => {
	const nonGroupedOptions = props.fieldOptions.flatMap((o) => (o as MultiSelectOption<any>).options || o);
	const useSelectAll = props.allOptionsLabel && props.fieldOptions.length > 0;
	const options = [...(useSelectAll ? [new SelectAllOption({ label: props.allOptionsLabel })] : []), ...props.fieldOptions];
	const isSelectAllSelected = useSelectAll && props.fieldValue.length === nonGroupedOptions.length;
	const applicableErrors = props.errors && props.dataState ? props.errors.filter((item) => item.errorFields.includes(props.dataState)) : [];

	const isOptionSelected = (option) => isSelectAllSelected || props.fieldValue.some(({ value }) => value === option.value);
	const onChange = (newValue, actionMeta) => {
		let newOptions = [];

		const { action, option, removedValue } = actionMeta;
		if (action === "select-option" && option.value === SELECT_ALL_VALUE) {
			newOptions = nonGroupedOptions;
		} else if (
			(action === "deselect-option" && option.value === SELECT_ALL_VALUE) ||
			(action === "remove-value" && removedValue.value === SELECT_ALL_VALUE)
		) {
			newOptions = [];
		} else if (action === "deselect-option" && isSelectAllSelected) {
			newOptions = nonGroupedOptions.filter(({ value }) => value !== option.value);
		} else {
			newOptions = newValue || [];
		}

		props.handleChange(newOptions);
	};

	return (
		<>
			<div className={`${props.formField ? "form-field" : ""} input-select ${props.className || ""}`}>
				{props.label && <label>{`${props.label}${props.formField ? ":" : ""}`}</label>}
				<Select
					id={props.id}
					className="react-select"
					options={options}
					isMulti
					classNamePrefix="react-select"
					placeholder={props.placeholder || TRANSLATIONS.FORMS.SELECT_PLACEHOLDER}
					noOptionsMessage={() => props.noOptionsMessage || TRANSLATIONS.FORMS.NO_OPTIONS}
					closeMenuOnSelect={false}
					hideSelectedOptions={!useSelectAll}
					value={isSelectAllSelected ? new SelectAllOption({ label: props.allOptionsLabel }) : props.fieldValue}
					defaultValue={props.defaultValue}
					isDisabled={props.isDisabled}
					isOptionSelected={isOptionSelected}
					onChange={onChange}
					menuPlacement="auto"
				/>
			</div>
			{applicableErrors.map((e, i) => (
				<ErrorContainer key={i} message={e.message} inlineFormError />
			))}
		</>
	);
};

const CheckBoxField = (props: {
	label?: string;
	id?: string;
	className?: string;
	dataState?: string;
	fieldValue?: any;
	formField?: boolean;
	labelFirst?: boolean;
	errors?: Array<FormError>;
	handleChange?: MouseEventHandler;
}) => {
	const applicableErrors = props.errors && props.dataState ? props.errors.filter((item) => item.errorFields.includes(props.dataState)) : [];

	return (
		<>
			<div className={props.formField ? "form-field" : ""}>
				{props.labelFirst && <label htmlFor={props.id}>{props.label}:</label>}
				<input
					type="checkbox"
					id={props.id}
					className={`input-checkbox ${props.id || ""}${props.className || ""}`}
					data-state={props.dataState}
					defaultChecked={props.fieldValue}
					onClick={props.handleChange}
				/>
				{!props.labelFirst && <label htmlFor={props.id}>{props.label}</label>}
			</div>
			{applicableErrors.map((e, i) => (
				<ErrorContainer key={i} message={e.message} inlineFormError />
			))}
		</>
	);
};

const SearchBox = (props: {
	label?: string;
	id?: string;
	className?: string;
	dataState?: string;
	fieldValue?: string;
	handleChange?: ChangeEventHandler;
}) => {
	return (
		<InputField
			label={props.label}
			type="search"
			id={props.id}
			className={props.className}
			dataState={props.dataState}
			fieldValue={props.fieldValue}
			handleChange={props.handleChange}
			columnLayout={true}
			formField={false}
		/>
	);
};

const DatePickerField = (props: {
	className?: string;
	id?: string;
	label?: string;
	formField?: boolean;
	fieldValue?: any;
	dataState?: any;
	isClearable?: boolean;
	isDisabled?: boolean;
	errors?: Array<FormError>;
	handleChange?: ChangeEventHandler;
}) => {
	const applicableErrors = props.errors && props.dataState ? props.errors.filter((item) => item.errorFields.includes(props.dataState)) : [];
	return (
		<>
			<div className={`${props.formField ? "form-field" : ""} ${props.className}`}>
				<label htmlFor={props.id}>{props.label}:</label>
				<DatePicker
					id={props.id}
					selected={props.fieldValue ? new Date(props.fieldValue) : null}
					locale={globalState.locale}
					isClearable={props.isClearable}
					placeholderText={TRANSLATIONS.FORMS.SELECT_PLACEHOLDER}
					disabled={props.isDisabled}
					onChange={props.handleChange}
				/>
			</div>
			{applicableErrors.map((e, i) => (
				<ErrorContainer key={i} message={e.message} inlineFormError />
			))}
		</>
	);
};

const DateRangeField = (props: {
	className?: string;
	id?: string;
	label?: string;
	formField?: boolean;
	startDate?: Date;
	endDate?: Date;
	minDate?: Date;
	maxDate?: Date;
	isDisabled?: boolean;
	handleChangeStart?: ChangeEventHandler;
	handleChangeEnd?: ChangeEventHandler;
}) => {
	const clampedMinDate = props.startDate ? Math.max(props.startDate.valueOf(), props.minDate.valueOf()) : props.minDate;
	const clampedMaxDate = props.endDate ? Math.min(props.endDate.valueOf(), props.maxDate.valueOf()) : props.maxDate;

	return (
		<div className={`${props.formField ? "form-field" : ""} ${props.className}`}>
			<label htmlFor={props.id + "-start"}>{props.label}</label>
			<div id={props.id}>
				<DatePicker
					id={props.id + "-start"}
					selected={props.startDate}
					selectsStart
					startDate={props.startDate}
					endDate={props.endDate}
					minDate={props.minDate}
					maxDate={clampedMaxDate}
					locale={globalState.locale}
					disabled={props.isDisabled}
					onChange={props.handleChangeStart}
				/>
				<DatePicker
					id={props.id + "-end"}
					selected={props.endDate}
					selectsEnd
					startDate={props.startDate}
					endDate={props.endDate}
					minDate={clampedMinDate}
					maxDate={props.maxDate}
					locale={globalState.locale}
					disabled={props.isDisabled}
					onChange={props.handleChangeEnd}
				/>
			</div>
		</div>
	);
};

const Button = (params: {
	buttonText?: string;
	className?: string;
	type: "button" | "submit" | "reset";
	onClick?: Function;
	customButton?: ReactNode;
	isDisabled: false;
}) => (
	<div className="btn-container">
		{params.customButton || (
			<button
				className={`btn ${params.className || ""}`}
				type={params.type || "submit"}
				onClick={(e) => {
					e.preventDefault();
					params.onClick();
				}}
				disabled={params.isDisabled}
			>
				{params.buttonText || TRANSLATIONS.FORMS.SUBMIT}
			</button>
		)}
	</div>
);
//#endregion

//#region Misc. Form Components

interface IProps {
	toggleVisible?: Function;
	customMessage?: string;
	includeCheck: boolean;
}
interface IState {
	visible: boolean;
}
class SavedCheck extends Component<IProps, IState> {
	static defaultProps = {
		includeCheck: true,
		toggleVisible: null,
		customMessage: null,
	};

	constructor(props) {
		super(props);
		this.state = {
			visible: true,
		};
	}

	componentDidMount() {
		if (this.props.toggleVisible)
			setTimeout(() => this.setState({ visible: false }, () => setTimeout(() => this.props.toggleVisible(), 200)), 3000);
	}

	render() {
		return (
			<div className={`saved-check${this.state.visible ? " visible" : ""}`}>
				{this.props.customMessage || TRANSLATIONS.SAVED}
				{this.props.includeCheck && <FontAwesomeIcon icon={["fas", "check"]} />}
			</div>
		);
	}
}

const PermanentActionWarning = () => <div className="warning-message">{TRANSLATIONS.FORMS.PERMANENT_ACTION_WARNING}</div>;
//#endregion

//#region Errors
class FormError {
	message: string;
	errorFields: Array<string>;

	constructor(message, errorFields = []) {
		this.message = message;
		this.errorFields = errorFields;
	}
}

const ErrorContainer = ({ message, inlineFormError = false }) =>
	message && (
		<div className="error-container w100">
			{!inlineFormError && <div>{TRANSLATIONS.ERRORS.ERROR}:</div>}
			<div className="message">{message.errorMsg || message}</div>
		</div>
	);

//#endregion

//#region SelectOptions
export const SELECT_ALL_VALUE = "<SELECT_ALL>";
export class SelectAllOption extends SelectOption<string> {
	constructor(params?: { label?: string }) {
		super({ label: params?.label || TRANSLATIONS.FORMS.SELECT_ALL_OPTION, value: SELECT_ALL_VALUE });
	}
}
//#endregion

const GSForms = {
	InputField,
	InfoField,
	SelectField,
	MultiSelectField,
	CheckBoxField,
	SearchBox,
	DatePickerField,
	DateRangeField,
	Button,
	SavedCheck,
	PermanentActionWarning,
	FormError,
	ErrorContainer,
	SelectAllOption,
	SELECT_ALL_VALUE,
};
export default GSForms;
