import React, { Component } from "react";
import { HelperFunctions } from "../../helpers/helper-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TRANSLATIONS } from "../../data-structures/localization";
import { UserData } from "../../data-structures/user-data.tsx";

export default class EvalReport extends Component {
	getEventType = (event) => {
		if (event.type.includes("CollisionEvent"))
			return TRANSLATIONS.DASHBOARD.EVAL_REPORT.COLLISION_OCCURRED_MESSAGE(HelperFunctions.getSeverityLabel(event.severity));
		else return event.type.replace("GSFalconMessages.", "").replace(", GSFalconMessages", "");
	};

	render() {
		const userData = new UserData(this.props.userData);
		const evaluation = this.props.evaluation;
		return (
			<div className="eval-report-container container print">
				<div className="report-header row">
					<span>{TRANSLATIONS.DASHBOARD.EVAL_REPORT.TITLE}</span>
					<button onClick={window.print} className="print-btn no-print">
						{TRANSLATIONS.PRINT}
					</button>
				</div>
				{userData && (
					<div className="row">
						<div className="data-cell col">
							<div className="label">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.FIRST_NAME}</div>
							<div className="data">{userData.firstName}</div>
						</div>
						<div className="data-cell col">
							<div className="label">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.LAST_NAME}</div>
							<div className="data">{userData.lastName}</div>
						</div>
						<div className="data-cell col">
							<div className="label">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.USERNAME}</div>
							<div className="data">{userData.username}</div>
						</div>
					</div>
				)}
				{evaluation && (
					<>
						<div className="row">
							<div className="data-cell col">
								<div className="label">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.COURSE_NAME}</div>
								<div className="data">{evaluation.course.name}</div>
							</div>
							<div className="data-cell col">
								<div className="label">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.UNIT_NAME}</div>
								<div className="data">{evaluation.unit.name}</div>
							</div>
							<div className="data-cell col">
								<div className="label">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.LESSON_NAME}</div>
								<div className="data">{evaluation.lesson.name}</div>
							</div>
						</div>
						<div className="row">
							<div className="data-cell col-xl-3 col-lg-3 col-sm-4">
								<div className="label">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.RUN_DATE}</div>
								<div className="data">{HelperFunctions.getFriendlyDateTime(evaluation.evaluationDate)}</div>
							</div>
							<div className="data-cell col-xl col-lg-3 col-sm-4">
								<div className="label">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.DURATION}</div>
								<div className="data">{HelperFunctions.getFriendlyDuration(evaluation.duration)}</div>
							</div>
							<div className="data-cell col-xl col-lg-3 col-sm-4">
								<div className="label">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.COMPLETED}</div>
								<div className="data">
									<FontAwesomeIcon icon={`fa-solid ${evaluation.completed ? "fa-check" : "fa-x"}`} />
								</div>
							</div>
							<div className="data-cell col-xl col-lg-3 col-sm-4">
								<div className="label">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.STARTING_SCORE}</div>
								<div className="data">{evaluation.scoring.startingScore}</div>
							</div>
							<div className="data-cell col-xl col-lg-3 col-sm-4">
								<div className="label">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.FINAL_SCORE}</div>
								<div className="data">{evaluation.scoring.finalScore}</div>
							</div>
							<div className="data-cell col-xl col-lg-3 col-sm-4">
								<div className="label">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.MIN_SCORE}</div>
								<div className="data">{evaluation.scoring.minimumPassingScore}</div>
							</div>
							<div className="data-cell col-xl-2 col-lg-3 col-sm-4">
								<div className="label">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.MAXIMUM_ATTEMPTS}</div>
								<div className="data">{evaluation.scoring.maxAttempts}</div>
							</div>
						</div>
						<div className="row">
							<div className="row-header">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.REPORT_DATA_TITLE}</div>
							<div className="data-table row">
								<div className="data-row row col-12">
									<div className="data-table-header col">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.REPORT_DATA_NAME}</div>
									<div className="data-table-header col">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.REPORT_DATA_VALUE}</div>
								</div>
								{evaluation.dataList.length > 0 ? (
									evaluation.dataList.map(
										(d, i) =>
											!d.name.includes("ScoreCard") && (
												<div className="data-row row col-12" key={i}>
													<div className="data col-sm-6">{d.name}</div>
													<div className="data col-sm-6">{d.value}</div>
												</div>
											)
									)
								) : (
									<div className="data-row row">
										<div className="data col-sm-6"></div>
										<div className="data col-sm-6"></div>
									</div>
								)}
							</div>
						</div>
						<div className="row">
							<div className="row-header">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.SCORING_FACTORS_TITLE}</div>
							<div className="data-table row">
								<div className="data-row row col-4">
									<div className="data-table-header col">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.SCORING_FACTORS_SIM_TIME}</div>
									<div className="data-table-header col">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.SCORING_FACTORS_NAME}</div>
									<div className="data-table-header col">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.SCORING_FACTORS_OFFSET}</div>
								</div>
								{evaluation.scoring.scoringFactors.length > 0 ? (
									evaluation.scoring.scoringFactors.map((sf, i) => (
										<div className="data-row row" key={i}>
											<div className="data col-sm-4">{HelperFunctions.getFriendlyDuration(sf.simTime)}</div>
											<div className="data col-sm-4">{sf.name}</div>
											<div className="data col-sm-4">{sf.offset}</div>
										</div>
									))
								) : (
									<div className="data-row row">
										<div className="data col-sm-4"></div>
										<div className="data col-sm-4"></div>
										<div className="data col-sm-4"></div>
									</div>
								)}
							</div>
						</div>
						<div className="row">
							<div className="row-header">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.EVENT_HISTORY_TITLE}</div>
							<div className="data-table row">
								<div className="data-row row col-4">
									<div className="data-table-header col">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.EVENT_HISTORY_SIM_TIME}</div>
									<div className="data-table-header col">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.EVENT_HISTORY_NAME}</div>
									<div className="data-table-header col">{TRANSLATIONS.DASHBOARD.EVAL_REPORT.EVENT_HISTORY_DESCRIPTION}</div>
								</div>
								{evaluation.eventList.length > 0 ? (
									evaluation.eventList.map((e, i) => {
										return (
											<div className="data-row row" key={i}>
												<div className="data col-sm-4">{HelperFunctions.getFriendlyDuration(e.simTime)}</div>
												<div className="data col-sm-4">{e.reportData.title}</div>
												<div className="data col-sm-4">{e.reportData.description}</div>
											</div>
										);
									})
								) : (
									<div className="data-row row">
										<div className="data col-sm-4"></div>
										<div className="data col-sm-4"></div>
										<div className="data col-sm-4"></div>
									</div>
								)}
							</div>
						</div>
					</>
				)}
			</div>
		);
	}
}
