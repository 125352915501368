import ParamAssignableObject from "./param-assignable-object.tsx";
import { Validate } from "../helpers/validation.js";

export class AuthHeader extends ParamAssignableObject {
	id;
	username;
	authToken;
	subscriptionExpirationDate;
	tokenExpirationDate;
	rollingToken;
	auditLog;

	constructor(authHeaderData: {
		id: any;
		username: any;
		authToken: any;
		subscriptionExpirationDate: any;
		tokenExpirationDate: any;
		rollingToken: any;
		auditLog: any;
	}) {
		super();
		if (authHeaderData?.subscriptionExpirationDate)
			authHeaderData.subscriptionExpirationDate = Validate.isValidDate(authHeaderData.subscriptionExpirationDate)
				? new Date(authHeaderData.subscriptionExpirationDate)
				: null;

		if (authHeaderData) this.constructFromParams(authHeaderData);
	}

	static constructFromDB = (dbAuthHeader) => {
		return dbAuthHeader
			? new AuthHeader({
					id: dbAuthHeader.Id,
					username: dbAuthHeader.Username,
					authToken: dbAuthHeader.AuthToken,
					subscriptionExpirationDate: Validate.isValidDate(dbAuthHeader.SubscriptionExpirationDate)
						? new Date(dbAuthHeader.SubscriptionExpirationDate)
						: null,
					tokenExpirationDate: dbAuthHeader.TokenExpirationDate,
					rollingToken: dbAuthHeader.RollingToken,
					auditLog: dbAuthHeader.AuditLog,
			  })
			: null;
	};

	static retrieveFromStorage = () => {
		const jsonString = localStorage.getItem("authHeader");
		const json = jsonString && JSON.parse(jsonString);
		return json ? new AuthHeader(json) : null;
	};

	prepareToSend = () => ({
		Id: this.id,
		Username: this.username,
		AuthToken: this.authToken,
		...(this.subscriptionExpirationDate ? { SubscriptionExpirationDate: this.subscriptionExpirationDate } : {}),
		TokenExpirationDate: this.tokenExpirationDate,
		RollingToken: this.rollingToken,
		AuditLog: this.auditLog,
	});
}
