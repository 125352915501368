import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SlidingPane from "react-sliding-pane";
import { TRANSLATIONS } from "../data-structures/localization";
import { IconButton } from "./icon-button";
import "./cards.scss";

export class CardSelectionPanel extends Component {
	render() {
		const cardPanel = this.props.children[0];
		const detailsPanel = this.props.children[1];
		return (
			<div className="card-selection-panel">
				{cardPanel &&
					React.cloneElement(cardPanel, {
						className: `col-md-6 col-sm-12 panel card-panel ${cardPanel.props.className || ""}`,
					})}

				{detailsPanel &&
					(window.innerWidth >= 768 ? (
						React.cloneElement(detailsPanel, {
							className: `d-none d-md-block col-md-6 panel details-panel ${detailsPanel.props.className || ""}`,
						})
					) : (
						<SlidingPane
							isOpen={this.props.panelIsOpen ? true : false}
							overlayClassName="d-md-none d-xs-block sliding-pane-overlay"
							className="sliding-pane"
							closeIcon={
								<>
									<FontAwesomeIcon icon="fa-solid fa-chevron-left" /> {TRANSLATIONS.FORMS.BACK}
								</>
							}
							title={this.props.detailsTitle}
							width="100%"
							onRequestClose={this.props.handleDetailsClose}
						>
							{React.cloneElement(detailsPanel, {
								className: `d-md-none d-xs-block col-xs-12 panel details-panel ${detailsPanel.props.className || ""}`,
							})}
						</SlidingPane>
					))}
			</div>
		);
	}
}

export class CardList extends Component {
	render() {
		return <div className={`card-list ${this.props.className || ""}`}>{this.props.children}</div>;
	}
}

export class Card extends Component {
	static propTypes = {
		className: PropTypes.string,

		title: PropTypes.string.isRequired,
		subtitle: PropTypes.string,
		detailsObj: PropTypes.object,

		viewOnly: PropTypes.bool,
		isDisabled: PropTypes.bool,
		isSelected: PropTypes.bool,
		isActive: PropTypes.bool,
		handleSelect: PropTypes.func,

		displayEditButton: PropTypes.bool,
		inEditMode: PropTypes.bool,
		handleEdit: PropTypes.func,
		cancelEdit: PropTypes.func,

		displayRemoveButton: PropTypes.bool,
		handleRemove: PropTypes.func,
		cancelRemove: PropTypes.func,
		markedForRemoval: PropTypes.bool,
		customCancelIcon: PropTypes.string,
		customRemoveIcon: PropTypes.string,
	};

	static defaultProps = {
		handleSelect: () => {},
		detailsObj: {},
	};

	render() {
		const props = this.props;
		return (
			<button
				className={[
					"card w100",
					props.className || "",
					props.isSelected ? "selected" : "",
					props.isActive ? "active" : "inactive",
					props.isDisabled ? "disabled" : "",
					props.markedForRemoval ? "marked-for-deletion" : "",
				].join(" ")}
				onClick={() => {
					if (props.isDisabled) return;
					props.handleSelect();
				}}
			>
				<div className={`card-info ${this.props.viewOnly ? "col-sm-12" : "col-sm-10"}`}>
					<div className="title-container">
						<h4 className="title">{props.title}</h4>
						<span className="subtitle">{props.subtitle ? `(${props.subtitle})` : ""}</span>
					</div>
					{Object.entries(props.detailsObj).length > 0 && (
						<div className="details-container">
							{Object.entries(props.detailsObj).map((detail, i) => (
								<div className="detail" key={i}>
									<em>{detail[0]}</em>
									<div>{detail[1]}</div>
								</div>
							))}
						</div>
					)}
				</div>

				{!props.viewOnly && (
					<div className="action-btns flexbox col-sm-2 no-print">
						<div className="edit-btn col-6">
							{props.displayEditButton &&
								(props.isSelected && props.inEditMode ? (
									<IconButton
										icon={`fa-solid ${props.customCancelIcon || "fa-arrow-rotate-left"}`}
										onClick={() => {
											if (!props.isDisabled) props.cancelEdit();
										}}
									/>
								) : (
									<IconButton
										icon="fa-solid fa-pencil"
										onClick={() => {
											if (!props.isDisabled) props.handleEdit();
										}}
									/>
								))}
						</div>
						<div className="remove-btn col-6">
							{props.displayRemoveButton &&
								(props.markedForRemoval ? (
									<IconButton
										icon={`fa-solid ${props.customCancelIcon || "fa-x"}`}
										onClick={() => {
											if (!props.isDisabled) props.cancelRemove();
										}}
									/>
								) : (
									<IconButton
										icon={`fa-solid ${props.customRemoveIcon || "fa-trash"}`}
										onClick={() => {
											if (!props.isDisabled) props.handleRemove();
										}}
									/>
								))}
						</div>
					</div>
				)}
			</button>
		);
	}
}
