import React from "react";
import { TRANSLATIONS } from "../../data-structures/localization";
import GroupList from "./group-list";

export const GroupManagement = () => {
	return (
		<div className="group-management-container container">
			<h1 className="no-print">{TRANSLATIONS.HEADERS.GROUP_MANAGEMENT}</h1>
			<GroupList />
		</div>
	);
};
