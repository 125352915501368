import React, { Component } from "react";

export default class ImageCarousel extends Component {
	static defaultProps = {
		id: "",
		imageFolder: require.context("./carousel-images", false, /\.(png|jpe?g|svg)$/, "lazy"),
	};

	constructor(props) {
		super(props);

		this.state = {
			imagePaths: [],
			loadedImageIndexes: [],
		};
	}

	componentDidMount() {
		if (this.state.imagePaths.length === 0) {
			Promise.all(this.props.imageFolder.keys().map((item) => this.props.imageFolder(item))).then((loadedImages) => {
				this.setState({ imagePaths: loadedImages });
			});
		}
	}

	render() {
		return (
			<div id={this.props.id} className="carousel slide" data-bs-ride="carousel">
				<div className="carousel-inner">
					{this.state.imagePaths.map((src, i) => (
						<div
							className={`carousel-item${
								(!this.state.loadedImageIndexes.includes(0) && this.state.loadedImageIndexes[0] === i) || i === 0 ? " active" : ""
							}`}
							key={i}
							style={this.state.loadedImageIndexes.includes(i) ? {} : { display: "none" }}
						>
							<img
								src={src}
								className="w-100"
								alt=""
								onLoad={() => {
									this.setState({ loadedImageIndexes: [...this.state.loadedImageIndexes, i] });
								}}
							/>
						</div>
					))}
				</div>
			</div>
		);
	}
}
