import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Nav from "../../data-structures/navigation";
import { TRANSLATIONS } from "../../data-structures/localization";
import { GSLogo, LOGO_TYPE } from "../../common/logo/logo";
import { HelperFunctions } from "../../helpers/helper-functions";
import { globalState } from "../../store";
import { SocialIcons } from "../../common/social-icons";
import "./footer.scss";

export default class Footer extends Component {
	render() {
		return (
			<>
				{globalState.loggedIn && globalState.userData && (
					<div className="last-login no-print">
						{TRANSLATIONS.FOOTER.LAST_LOGIN}: {HelperFunctions.getFriendlyDateTime(globalState.userData.dateLastLoggedIn)}
					</div>
				)}

				<div className="footer no-print">
					<div className="container">
						<div className="row">
							<div className="footer-section col-lg-3 col-md-6">
								<GSLogo type={LOGO_TYPE.WHITE} linkTo={Nav.PATHS.HOME} />
								<SocialIcons />
								<br />
								<div>
									<div>GlobalSim, Inc.</div>
									<div>9735 South 500 West</div>
									<div>Sandy, UT 84070</div>
									<div>USA</div>
								</div>
							</div>

							<div className="footer-section col-lg-3 col-md-6 order-md-3 order-lg-1 footer-links">
								<a href={`${Nav.MAIN_SITE_PATH}/about/`} target="_blank" rel="noreferrer">
									{TRANSLATIONS.NAVLINKS.ABOUT}
								</a>
								<a href={`${Nav.MAIN_SITE_PATH}/simulators/`} target="_blank" rel="noreferrer">
									{TRANSLATIONS.NAVLINKS.SIMULATORS}
								</a>
								<a href={`${Nav.MAIN_SITE_PATH}/models/`} target="_blank" rel="noreferrer">
									{TRANSLATIONS.NAVLINKS.MODELS}
								</a>
								<a href={`${Nav.MAIN_SITE_PATH}/industries/`} target="_blank" rel="noreferrer">
									{TRANSLATIONS.NAVLINKS.INDUSTRIES}
								</a>
								<a href={`${Nav.MAIN_SITE_PATH}/media/`} target="_blank" rel="noreferrer">
									{TRANSLATIONS.NAVLINKS.MEDIA}
								</a>
								<a href={`${Nav.MAIN_SITE_PATH}/blog/`} target="_blank" rel="noreferrer">
									{TRANSLATIONS.NAVLINKS.BLOG}
								</a>
							</div>

							<div className="footer-section col-lg-3 col-md-6 order-md-2 order-lg-2 footer-links">
								<h3>{TRANSLATIONS.FOOTER.SIMULATORS}</h3>
								<a href={`https://cloudsimulator.globalsim.com/`} target="_blank" rel="noreferrer">
									{TRANSLATIONS.FOOTER.CLOUD}
								</a>
								<a href={`${Nav.MAIN_SITE_PATH}/simulators/essential/`} target="_blank" rel="noreferrer">
									{TRANSLATIONS.FOOTER.ESSENTIAL}
								</a>
								<a href={`${Nav.MAIN_SITE_PATH}/simulators/full-mission/`} target="_blank" rel="noreferrer">
									{TRANSLATIONS.FOOTER.FULL_MISSION}
								</a>
								<a href={`${Nav.MAIN_SITE_PATH}/simulators/advanced/`} target="_blank" rel="noreferrer">
									{TRANSLATIONS.FOOTER.ADVANCED}
								</a>
								<a href={`${Nav.MAIN_SITE_PATH}/simulators/coordination-tester/`} target="_blank" rel="noreferrer">
									{TRANSLATIONS.FOOTER.COORDINATOR_TEST_SYSTEM}
								</a>
								<a href={`${Nav.MAIN_SITE_PATH}/simulators/roro-simulator/`} target="_blank" rel="noreferrer">
									{TRANSLATIONS.FOOTER.RO_RO}
								</a>
								<a href={`${Nav.MAIN_SITE_PATH}/simulators/remote-control/`} target="_blank" rel="noreferrer">
									{TRANSLATIONS.FOOTER.REMOTE_STATION}
								</a>
							</div>

							<div className="footer-section col-lg-3 col-md-6 order-md-last order-lg-3 footer-links">
								<h3>{TRANSLATIONS.FOOTER.HOURS}</h3>
								<div>
									<strong>{TRANSLATIONS.FOOTER.MONDAY_TO_FRIDAY}</strong>
								</div>
								<div>{TRANSLATIONS.FOOTER.WEEKDAY_HOURS}</div>
								<div>{TRANSLATIONS.FOOTER.MOUNTAIN_TIME}</div>
								<br />
								<div>
									<strong>{TRANSLATIONS.FOOTER.SATURDAY_TO_SUNDAY}</strong>
								</div>
								<div>{TRANSLATIONS.FOOTER.CLOSED}</div>
								<br />
								<a href="tel:8015719094">{TRANSLATIONS.FOOTER.PHONE}: +1 (801) 571-9094</a>
							</div>
						</div>

						<div className="row">
							<div className="legal-container footer-section col-md-12 footer-links w100">
								<hr className="w100" />

								<NavLink to={Nav.PATHS.PRIVACY_POLICY}>{TRANSLATIONS.NAVLINKS.PRIVACY_POLICY}</NavLink>
								<NavLink to={Nav.PATHS.TERMS_OF_SERVICE}>{TRANSLATIONS.NAVLINKS.TERMS_OF_SERVICE}</NavLink>
								<NavLink to={Nav.PATHS.DATA_RETENTION}>{TRANSLATIONS.NAVLINKS.DATA_RETENTION_POLICY}</NavLink>
							</div>

							<div className="copyright-container footer-section col-md-12 footer-links w100">
								GlobalSim © {new Date().getFullYear()} {TRANSLATIONS.FOOTER.ALL_RIGHTS_RESERVED}.
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
