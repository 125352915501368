import React, { Component } from "react";
import GSForms from "./form-elements";
import { HelperFunctions } from "../helpers/helper-functions";
import { TRANSLATIONS } from "../data-structures/localization";
import { MEASUREMENT_UNITS, ERROR_CODES, TMS_APP_ROLES } from "../data-structures/gs-constants";
import { Loading } from "../common/loading-icon";
import { globalState, onGlobalStateChange, setGlobalState } from "../store";
import { LANGUAGES } from "../data-structures/lang-codes";
import { Validate } from "../helpers/validation";
import { TMSHelpers } from "../helpers/tms-helpers";
import FormHelpers from "../helpers/form-helpers";

export default class OrgDataForm extends Component {
	//#region Initialization
	constructor(props) {
		super(props);

		this.state = {
			orgName: "",
			language: "",
			units: "",

			installerCollections: [],
			launcherCollection: "",
			simulatorCollection: "",
			scenarioEditorCollection: "",
			iosCollection: "",

			isCloudBased: null,

			subscriptionExpirationDate: null,

			restrictConnectionsByLicense: null,
			instructorLicenses: "",
			studentLicenses: "",

			formErrors: [],
			responseError: null,

			submitting: false,
			saved: false,
		};

		this.initialized = false;
	}

	componentDidMount() {
		this.initialize();

		this.unsubscribe = onGlobalStateChange(() => {
			const orgData = globalState.orgData;
			if (orgData) {
				this.initialize();
				this.setState({
					units: Object.values(MEASUREMENT_UNITS).find((x) => x.value === orgData.defaultUnits),
					...(this.state.formErrors.length > 0 && { formErrors: this.getFormErrors() || [] }),
					...(this.state.responseError && {
						responseError: Object.values(ERROR_CODES).find((x) => x.key === this.state.responseError.key),
					}),
					...this.getInstallerCollectionInitialState(),
				});
			}
		});
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	initialize = async () => {
		const orgData = globalState.orgData;
		if (!this.initialized && orgData) {
			this.initialized = true;

			this.setState({
				orgName: orgData.name,
				language: Object.values(LANGUAGES).find((x) => x.value === orgData.defaultLanguage),
				units: Object.values(MEASUREMENT_UNITS).find((x) => x.value === orgData.defaultUnits),
				isCloudBased: orgData.isCloudBased || false,
				subscriptionExpirationDate: orgData.subscriptionExpirationDate,
				restrictConnectionsByLicense: orgData.restrictConnectionsByLicense || false,
				instructorLicenses: orgData.instructorLicenses,
				studentLicenses: orgData.studentLicenses,
				...this.getInstallerCollectionInitialState(),
			});
		}
	};

	getInstallerCollectionInitialState = () => {
		const orgData = globalState.orgData;
		const installerCollections = [
			{ label: "None", value: null },
			...(globalState.installerCollections?.map((collection) => ({
				label: collection.name,
				value: collection.name,
			})) || []),
		];

		return (
			globalState.installerCollections && {
				installerCollections: installerCollections,
				launcherCollection: Object.values(installerCollections).find((ip) => ip.value === orgData.launcherCollection),
				simulatorCollection: Object.values(installerCollections).find((ip) => ip.value === orgData.simulatorCollection),
				scenarioEditorCollection: Object.values(installerCollections).find((ip) => ip.value === orgData.scenarioEditorCollection),
				iosCollection: Object.values(installerCollections).find((ip) => ip.value === orgData.iosCollection),
			}
		);
	};
	//#endregion

	handleSubmit = async () => {
		const formErrors = this.getFormErrors();
		if (formErrors.length > 0) this.setState({ submitting: false, saved: false, responseError: null, formErrors: formErrors });
		else {
			this.setState({ submitting: true, saved: false, responseError: null, formErrors: [] }, async () => {
				const newSubscriptionDate = new Date(this.state.subscriptionExpirationDate);
				const clearSubscription = !Validate.hasValue(newSubscriptionDate) || !Validate.isValidDate(newSubscriptionDate);
				const utcDate = clearSubscription ? null : new Date(newSubscriptionDate.getTime() - newSubscriptionDate.getTimezoneOffset() * 60000);

				await TMSHelpers.sendRequest("UpdateOrganizationRequest", {
					OrganizationToUpdate: {
						Id: globalState.orgData.id,
						Name: this.state.orgName,
						DefaultUnits: this.state.units.value,
						DefaultLanguageCode: this.state.language.value.toUpperCase(),
						...(!clearSubscription ? { SubscriptionExpiration: utcDate.toISOString() } : {}),
						AssociatedLauncherCollection: this.state.launcherCollection?.value,
						AssociatedSimulatorCollection: this.state.simulatorCollection?.value,
						AssociatedScenarioEditorCollection: this.state.scenarioEditorCollection?.value,
						AssociatedIOSCollection: this.state.iosCollection?.value,
						IsCloudBased: this.state.isCloudBased,
						RestrictConnectionsByLicense: this.state.restrictConnectionsByLicense,
						InstructorLicenses: parseInt(this.state.instructorLicenses),
						StudentLicenses: parseInt(this.state.studentLicenses),
					},
					...(clearSubscription ? { ClearSubscription: true } : {}),
				}).then((response) => {
					if (response.Success) {
						const responseMessage = JSON.parse(response.ResponseMessage);
						setGlobalState({ orgData: TMSHelpers.trimOrgData(responseMessage.UpdatedOrganization, responseMessage.IsCloudStandardOrg) });
						TMSHelpers.refreshGlobalData();
					}

					this.setState({
						submitting: false,
						saved: response.Success,
						...this.getErrorState(response),
					});
				});
			});
		}
	};

	getFormErrors = () => {
		const errors = [];
		const state = this.state;
		if (Validate.isNullOrEmpty(state.orgName)) errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["orgName"]));

		if (Validate.isNullOrEmpty(state.restrictConnectionsByLicense)) {
			errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["restrictConnectionsByLicense"]));
		} else if (state.restrictConnectionsByLicense) {
			if (Validate.isNullOrEmpty(state.instructorLicenses)) {
				errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["instructorLicenses"]));
			} else {
				if (parseFloat(state.instructorLicenses) % 1 !== 0)
					errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.MUST_SELECT_INTEGER, ["instructorLicenses"]));
			}

			if (Validate.isNullOrEmpty(state.studentLicenses)) {
				errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["studentLicenses"]));
			} else {
				if (parseFloat(state.studentLicenses) % 1 !== 0)
					errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.MUST_SELECT_INTEGER, ["studentLicenses"]));
			}
		}

		return errors;
	};

	getErrorState = (response) => {
		if (response && response.errorMsg)
			switch (response.key) {
				default:
					return { responseError: HelperFunctions.getResponseError(response) };
			}
	};

	render() {
		return (
			(HelperFunctions.loggedInAsDeveloper() || (globalState.orgData && !globalState.orgData.isCloudStandardOrg)) && (
				<div className="org-data-form form-container">
					<h2>{TRANSLATIONS.HEADERS.ORG_DATA}</h2>

					{globalState.orgData ? (
						HelperFunctions.loggedInAsAdmin() ? (
							<form autoComplete="on">
								<GSForms.InputField
									dataState="orgName"
									fieldValue={this.state.orgName}
									label={TRANSLATIONS.FORMS.ORGANIZATION_NAME}
									errors={this.state.formErrors}
									formField
									handleChange={(e) => FormHelpers.handleChange(e, this)}
								/>
								<GSForms.SelectField
									label={TRANSLATIONS.FORMS.LANGUAGE_PREFERENCE}
									fieldOptions={HelperFunctions.getSortedLanguages()}
									fieldValue={this.state.language}
									formField
									errors={this.state.formErrors}
									handleChange={(option) => FormHelpers.handleSelectChange(option, "language", this)}
								/>
								<GSForms.SelectField
									label={TRANSLATIONS.FORMS.UNITS_PREFERENCE}
									fieldOptions={Object.values(MEASUREMENT_UNITS)}
									fieldValue={this.state.units}
									formField
									errors={this.state.formErrors}
									handleChange={(option) => FormHelpers.handleSelectChange(option, "units", this)}
								/>
								{HelperFunctions.loggedInAsGlobalAdmin() ? (
									<>
										<GSForms.CheckBoxField
											label={TRANSLATIONS.ORG_DATA.CLOUD_BASED}
											id="cloud-based"
											fieldValue={this.state.isCloudBased}
											dataState="isCloudBased"
											formField
											labelFirst
											errors={this.state.formErrors}
											handleChange={(e) => FormHelpers.handleCheckboxChange(e, this)}
										/>

										<GSForms.DatePickerField
											formField
											dataState={"subscriptionExpirationDate"}
											label={TRANSLATIONS.USER_MANAGEMENT.SUBSCRIPTION_EXPIRATION_DATE}
											fieldValue={this.state.subscriptionExpirationDate}
											isClearable
											errors={this.state.formErrors}
											handleChange={(selectedDate) => {
												this.setState({
													subscriptionExpirationDate: selectedDate ? new Date(selectedDate).setHours(23, 59, 59) : null,
												});
											}}
										/>

										<GSForms.CheckBoxField
											label={TRANSLATIONS.ORG_DATA.RESTRICT_CONNECTIONS_BY_LICENSE}
											id="restrict-by-license"
											fieldValue={this.state.restrictConnectionsByLicense}
											dataState="restrictConnectionsByLicense"
											formField
											labelFirst
											errors={this.state.formErrors}
											handleChange={(e) => FormHelpers.handleCheckboxChange(e, this)}
										/>
										{this.state.restrictConnectionsByLicense && (
											<>
												<GSForms.InputField
													label={TRANSLATIONS.ORG_DATA.STUDENT_LICENSES}
													type="number"
													min="-1"
													step="1"
													dataState="studentLicenses"
													fieldValue={this.state.studentLicenses}
													errors={this.state.formErrors}
													formField
													handleChange={(e) => FormHelpers.handleChange(e, this)}
												/>
												<GSForms.InputField
													label={TRANSLATIONS.ORG_DATA.INSTRUCTOR_LICENSES}
													type="number"
													min="-1"
													step="1"
													dataState="instructorLicenses"
													fieldValue={this.state.instructorLicenses}
													errors={this.state.formErrors}
													formField
													handleChange={(e) => FormHelpers.handleChange(e, this)}
												/>
											</>
										)}

										<br />
										<h5>{TRANSLATIONS.ORG_DATA.ASSOCIATED_INSTALLERS}</h5>
										{globalState.installerCollections ? (
											<>
												<GSForms.SelectField
													label={TRANSLATIONS.ORG_DATA.ASSOCIATED_LAUNCHER_COLLECTION}
													fieldOptions={this.state.installerCollections}
													fieldValue={this.state.launcherCollection}
													formField
													errors={this.state.formErrors}
													handleChange={(option) => FormHelpers.handleSelectChange(option, "launcherCollection", this)}
												/>
												<GSForms.SelectField
													label={TRANSLATIONS.ORG_DATA.ASSOCIATED_SIMULATOR_COLLECTION}
													fieldOptions={this.state.installerCollections}
													fieldValue={this.state.simulatorCollection}
													formField
													errors={this.state.formErrors}
													handleChange={(option) => FormHelpers.handleSelectChange(option, "simulatorCollection", this)}
												/>
												<GSForms.SelectField
													label={TRANSLATIONS.ORG_DATA.ASSOCIATED_SCENARIO_EDITOR_COLLECTION}
													fieldOptions={this.state.installerCollections}
													fieldValue={this.state.scenarioEditorCollection}
													formField
													errors={this.state.formErrors}
													handleChange={(option) =>
														FormHelpers.handleSelectChange(option, "scenarioEditorCollection", this)
													}
												/>
												<GSForms.SelectField
													label={TRANSLATIONS.ORG_DATA.ASSOCIATED_IOS_COLLECTION}
													fieldOptions={this.state.installerCollections}
													fieldValue={this.state.iosCollection}
													formField
													errors={this.state.formErrors}
													handleChange={(option) =>
														FormHelpers.handleSelectChange(option, "iosCollection", this)
													}
												/>
											</>
										) : (
											<Loading />
										)}
									</>
								) : (
									HelperFunctions.loggedInAsAdmin() &&
									this.state.subscriptionExpirationDate && (
										<GSForms.InfoField
											label={TRANSLATIONS.USER_MANAGEMENT.SUBSCRIPTION_EXPIRATION_DATE}
											fieldValue={new Date(this.state.subscriptionExpirationDate).toLocaleString(globalState.locale, {
												dateStyle: "short",
												timeStyle: "medium",
											})}
											formField
										/>
									)
								)}

								<GSForms.ErrorContainer message={this.state.responseError} />
								{this.state.submitting && <Loading />}
								{this.state.saved && <GSForms.SavedCheck toggleVisible={() => this.setState({ saved: false })} />}

								<div className="flexbox">
									<GSForms.Button
										buttonText={TRANSLATIONS.FORMS.RESET}
										onClick={() => {
											this.initialized = false;
											this.initialize();
										}}
										type="button"
									/>
									<GSForms.Button onClick={this.handleSubmit} />
								</div>
							</form>
						) : (
							<GSForms.InfoField label={TRANSLATIONS.FORMS.ORGANIZATION_NAME} fieldValue={globalState.orgData.name} formField />
						)
					) : (
						<Loading />
					)}
				</div>
			)
		);
	}
}
