import React, { Component } from "react";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import GSForms from "./form-elements";
import { USER_IMPORT_HEADERS } from "../data-structures/gs-constants";
import { TRANSLATIONS } from "../data-structures/localization";
import { FileImporter, ImportHelpers } from "../helpers/import-helpers";
import { globalState } from "../store";
import { Loading } from "../common/loading-icon";
import { TMSHelpers } from "../helpers/tms-helpers";
import { HelperFunctions } from "../helpers/helper-functions";
import FormHelpers from "../helpers/form-helpers";

export class UserImportExport extends Component {
	static propTypes = {
		exportFileName: PropTypes.string,
		groupsToEnrollUsersIn: PropTypes.array,
		toggleVisibility: PropTypes.func,
	};

	static defaultProps = {
		groupsToEnrollUsersIn: [],
		toggleVisibility: () => {},
	};

	constructor(props) {
		super(props);
		this.state = {
			importUserFile: null,
			importErrors: [],
			selectedGroups: props.groupsToEnrollUsersIn || [],

			submitting: false,
			saved: false,
			importedUserCount: 0,
			updatedUserCount: 0,
			newUserCount: 0,
			addedUserCount: 0,
		};
	}

	updateGroupList = async (usersToAdd, importErrors, updatedUserCount, newUserCount) => {
		TMSHelpers.refreshGlobalData();
		let addedUserIds = [];
		for await (const groupData of this.state.selectedGroups.map((g) => globalState.groupList.find((gd) => gd.id === g.id))) {
			const usersNotInGroup = (
				Object.values(groupData.userList).length === 0 ? usersToAdd : usersToAdd.filter((u) => !groupData.userList[u.id])
			).map((x) => x.id);

			await TMSHelpers.updateGroupUserList(groupData.id, [...Object.keys(groupData.userList), ...usersNotInGroup]);
			addedUserIds.push(...usersNotInGroup);
		}

		this.setState({
			importErrors: importErrors,
			importUserFile: null,
			submitting: false,
			saved: true,
			importedUserCount: usersToAdd.length,
			updatedUserCount: updatedUserCount,
			newUserCount: newUserCount,
			addedUserCount: HelperFunctions.getUniqueValues(addedUserIds).length,
		});
	};

	handleImportUsers = () => {
		this.setState(
			{
				submitting: true,
				importErrors: [],
				saved: false,
				importedUserCount: 0,
				updatedUserCount: 0,
				newUserCount: 0,
				addedUserCount: 0,
			},
			() => {
				if (this.state.importUserFile) {
					const context = this;
					FileImporter.onFileImport(async (e) => {
						const [parsedUsers, importErrors, updatedUserCount, newUserCount] = await ImportHelpers.parseUserFile(e);
						if (this.state.selectedGroups.length > 0) {
							await context.updateGroupList(parsedUsers, importErrors, updatedUserCount, newUserCount);
						} else {
							TMSHelpers.refreshGlobalData();
							this.setState({
								importErrors: importErrors,
								importUserFile: null,
								submitting: false,
								saved: true,
								selectedGroups: [],
								importedUserCount: parsedUsers.length,
								updatedUserCount: updatedUserCount,
								newUserCount: newUserCount,
								addedUserCount: 0,
							});
						}
					});
					FileImporter.importFile(this.state.importUserFile);
				} else {
					this.setState({
						submitting: false,
						saved: false,
						importErrors: [new GSForms.FormError(TRANSLATIONS.ERRORS.NO_FILE_SELECTED, ["importUserFile"])],
					});
				}
			}
		);
	};

	render() {
		const applicableErrors = this.state.importErrors ? this.state.importErrors.filter((item) => item.errorFields.includes("importUserFile")) : [];
		return (
			<div className="column-field-container w100">
				<div className={`form-field input-file ${applicableErrors.length > 0 ? "has-error" : ""}`}>
					<div className="flexbox column">
						<h2>{TRANSLATIONS.USER_IMPORT.IMPORT_USERS}</h2>
						<div className="flexbox">
							<div className="flexbox">
								<CSVLink
									className="btn btn-container"
									data={[]}
									headers={USER_IMPORT_HEADERS.slice(0, 4)}
									target="_blank"
									filename={"user-list-template.csv"}
								>
									{TRANSLATIONS.USER_IMPORT.DOWNLOAD_TEMPLATE}
								</CSVLink>

								<label htmlFor="import-users-field" className="btn-container">
									<div className="btn">{TRANSLATIONS.FORMS.SELECT_FILE}</div>
								</label>
							</div>
							<input
								id="import-users-field"
								type={"file"}
								data-state={"importUserFile"}
								accept={".csv"}
								ref={FileImporter.fileRef}
								style={{ display: "none" }}
								onChange={(e) => FormHelpers.handleChange(e, this, e.target.files[0])}
								onClick={(e) => {
									e.target.value = null;
									this.setState({ saved: false, importErrors: [], importUserFile: null });
								}}
							/>
						</div>
						<strong>{this.state.importUserFile?.name || "-"}</strong>
						{this.props.groupsToEnrollUsersIn?.length === 0 && (
							<GSForms.MultiSelectField
								label={TRANSLATIONS.USER_IMPORT.ADD_USERS_TO_GROUPS}
								dataState="selectedGroups"
								fieldOptions={globalState.groupList.filter((x) => x.active).map((g) => ({ ...g, label: g.name, value: g.id }))}
								fieldValue={this.state.selectedGroups}
								handleChange={(e) => {
									this.setState({
										selectedGroups: e,
										saved: false,
										importErrors: [],
									});
								}}
							/>
						)}
						<div className="flexbox">
							<GSForms.Button type="button" buttonText={TRANSLATIONS.FORMS.CANCEL} onClick={this.props.toggleVisibility} />
							<GSForms.Button type="button" onClick={this.handleImportUsers} />
						</div>
					</div>
				</div>

				{this.state.saved && (
					<GSForms.SavedCheck
						includeCheck={false}
						customMessage={TRANSLATIONS.USER_IMPORT.IMPORT_SUCCESSFUL(
							this.state.importedUserCount,
							this.state.updatedUserCount,
							this.state.newUserCount,
							this.state.addedUserCount,
							this.state.selectedGroups
						)}
					/>
				)}
				{applicableErrors.map((e, i) => (
					<GSForms.ErrorContainer key={i} message={e.message} inlineFormError />
				))}
				{this.state.submitting && <Loading />}
			</div>
		);
	}
}
