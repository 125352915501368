import { Validate } from "../helpers/validation";

export default class ParamAssignableObject {
	constructFromParams = (params) => {
		if (params) {
			Object.entries(params)
				.map((param) => ({ key: param[0], value: param[1] }))
				.forEach((param) => (this[param.key] = param.value));
		}
	};

	static exists = (data) => Validate.hasValue(data) && Object.values(data).some((d) => Validate.hasValue(d));
}
