import { af, ar, da, de, el, enUS, es, fi, fr, he, hi, id, it, ja, ko, nl, nn, pt, ru, sv, th, vi, zhCN } from "date-fns/locale";
import { registerLocale } from "react-datepicker";

export const LANGUAGE_CODES = {
	AFRIKANS: "af",
	ARABIC: "ar",
	DANISH: "da",
	GERMAN: "de",
	GREEK: "el",
	ENGLISH: "en",
	ENGLISH_US: "en-US",
	SPANISH: "es",
	FINNISH: "fi",
	FRENCH: "fr",
	HEBREW: "he",
	HINDI: "hi",
	INDONESIAN: "id",
	ITALIAN: "it",
	JAPANESE: "ja",
	KOREAN: "ko",
	DUTCH: "nl",
	NORWEIGAN: "no",
	PORTUGUESE: "pt",
	RUSSIAN: "ru",
	SWEDISH: "sv",
	THAI: "th",
	VIETNAMESE: "vi",
	CHINESE: "zh",
};

//#region registering locales
registerLocale(LANGUAGE_CODES.AFRIKANS, af);
registerLocale(LANGUAGE_CODES.ARABIC, ar);
registerLocale(LANGUAGE_CODES.DANISH, da);
registerLocale(LANGUAGE_CODES.GERMAN, de);
registerLocale(LANGUAGE_CODES.GREEK, el);
registerLocale(LANGUAGE_CODES.ENGLISH, enUS);
registerLocale(LANGUAGE_CODES.SPANISH, es);
registerLocale(LANGUAGE_CODES.FINNISH, fi);
registerLocale(LANGUAGE_CODES.FRENCH, fr);
registerLocale(LANGUAGE_CODES.HEBREW, he);
registerLocale(LANGUAGE_CODES.HINDI, hi);
registerLocale(LANGUAGE_CODES.INDONESIAN, id);
registerLocale(LANGUAGE_CODES.ITALIAN, it);
registerLocale(LANGUAGE_CODES.JAPANESE, ja);
registerLocale(LANGUAGE_CODES.KOREAN, ko);
registerLocale(LANGUAGE_CODES.DUTCH, nl);
registerLocale(LANGUAGE_CODES.NORWEIGAN, nn);
registerLocale(LANGUAGE_CODES.PORTUGUESE, pt);
registerLocale(LANGUAGE_CODES.RUSSIAN, ru);
registerLocale(LANGUAGE_CODES.SWEDISH, sv);
registerLocale(LANGUAGE_CODES.THAI, th);
registerLocale(LANGUAGE_CODES.VIETNAMESE, vi);
registerLocale(LANGUAGE_CODES.CHINESE, zhCN);
//#endregion

//#region Languages
function Languages() {
	this[LANGUAGE_CODES.AFRIKANS] = {
		active: false,
		value: LANGUAGE_CODES.AFRIKANS,
		label: "Afrikaans",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.ARABIC] = {
		active: false,
		value: LANGUAGE_CODES.ARABIC,
		label: "عربى",
		RTL: true,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.DANISH] = {
		active: false,
		value: LANGUAGE_CODES.DANISH,
		label: "dansk",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.GERMAN] = {
		active: false,
		value: LANGUAGE_CODES.GERMAN,
		label: "Deutsche",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.GREEK] = {
		active: false,
		value: LANGUAGE_CODES.GREEK,
		label: "Ελληνικά",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.ENGLISH] = {
		active: true,
		value: LANGUAGE_CODES.ENGLISH,
		label: "English",
		RTL: false,
		defaultUnit: "IMPERIAL",
	};
	this[LANGUAGE_CODES.ENGLISH_US] = this[LANGUAGE_CODES.ENGLISH];
	this[LANGUAGE_CODES.SPANISH] = {
		active: true,
		value: LANGUAGE_CODES.SPANISH,
		label: "Español",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.FINNISH] = {
		active: false,
		value: LANGUAGE_CODES.FINNISH,
		label: "Suomalainen",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.FRENCH] = {
		active: false,
		value: LANGUAGE_CODES.FRENCH,
		label: "Français",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.HEBREW] = {
		active: false,
		value: LANGUAGE_CODES.HEBREW,
		label: "עִברִית",
		RTL: true,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.HINDI] = {
		active: false,
		value: LANGUAGE_CODES.HINDI,
		label: "हिंदी",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.INDONESIAN] = {
		active: false,
		value: LANGUAGE_CODES.INDONESIAN,
		label: "bahasa Indonesia",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.ITALIAN] = {
		active: false,
		value: LANGUAGE_CODES.ITALIAN,
		label: "Italiano",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.JAPANESE] = {
		active: false,
		value: LANGUAGE_CODES.JAPANESE,
		label: "日本語",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.KOREAN] = {
		active: false,
		value: LANGUAGE_CODES.KOREAN,
		label: "한국어",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.DUTCH] = {
		active: false,
		value: LANGUAGE_CODES.DUTCH,
		label: "Nederlands",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.NORWEIGAN] = {
		active: false,
		value: LANGUAGE_CODES.NORWEIGAN,
		label: "norsk",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.PORTUGUESE] = {
		active: false,
		value: LANGUAGE_CODES.PORTUGUESE,
		label: "Português",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.RUSSIAN] = {
		active: false,
		value: LANGUAGE_CODES.RUSSIAN,
		label: "Русский",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.SWEDISH] = {
		active: false,
		value: LANGUAGE_CODES.SWEDISH,
		label: "svenska",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.THAI] = {
		active: false,
		value: LANGUAGE_CODES.THAI,
		label: "ไทย",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.VIETNAMESE] = {
		active: false,
		value: LANGUAGE_CODES.VIETNAMESE,
		label: "Tiếng Việt",
		RTL: false,
		defaultUnit: "METRIC",
	};
	this[LANGUAGE_CODES.CHINESE] = {
		active: false,
		value: LANGUAGE_CODES.CHINESE,
		label: "中文",
		RTL: false,
		defaultUnit: "METRIC",
	};
}

export const LANGUAGES = new Languages();
//#endregion
