import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./social-icons.scss";

export const SocialIcons = () => {
	return (
		<div className="social-container">
			<a href="https://www.facebook.com/globalsimsimulation/" target="_blank" rel="noreferrer">
				<FontAwesomeIcon className="icon-btn facebook" icon="fa-brands fa-facebook" />
			</a>
			<a href="https://x.com/GlobalSimInc" target="_blank" rel="noreferrer">
				<FontAwesomeIcon className="icon-btn x-twitter" icon="fa-brands fa-x-twitter" />
			</a>
			<a href="https://www.linkedin.com/company/globalsim-inc.?trk=biz-companies-cym" target="_blank" rel="noreferrer">
				<FontAwesomeIcon className="icon-btn linkedin" icon="fa-brands fa-linkedin" />
			</a>
			<a href="https://www.youtube.com/globalsiminc" target="_blank" rel="noreferrer">
				<FontAwesomeIcon className="icon-btn youtube" icon="fa-brands fa-youtube" />
			</a>
		</div>
	);
};
