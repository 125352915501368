import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import Footer from "./footer";
import { Header } from "./header";
import Nav from "../../data-structures/navigation";
import { LocalePicker } from "./locale-picker";
import { PageNotFound } from "../page-not-found";
import { globalState, onGlobalStateChange } from "../../store";
import { connect } from "react-redux";
import { TMSHelpers } from "../../helpers/tms-helpers";
import { Loading } from "../../common/loading-icon";
import "bootstrap/dist/js/bootstrap";
import "./app.scss";

library.add(fab, fas, far);

let prevRoute = null;
let stashedRoute = null;
export const getStashedRoute = () => stashedRoute;
export const getCurrentRoute = () => window.location.pathname;

const App = () => {
	const [routes, setRoutes] = useState([]);
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		if (globalState.loggedIn) {
			TMSHelpers.refreshGlobalData(localStorage.getItem("userId"), true, true);
		}
		setupRoutes();

		onGlobalStateChange(() => {
			setupRoutes();
		});

		return () => TMSHelpers.cancelAsyncTasks();
	}, []);

	const location = useLocation();
	useEffect(() => {
		TMSHelpers.checkSiteVersion();
		TMSHelpers.checkAuthExpired();

		window.scrollTo(0, 0);

		stashedRoute = window.location.pathname;
		if (!stashedRoute.includes(prevRoute)) TMSHelpers.cancelAsyncTasks();
		prevRoute = stashedRoute;
	}, [location]);

	const setupRoutes = () => {
		if (!globalState.loggedIn || globalState.userData) {
			setRoutes(Nav.generateRoutes());
			setInitialized(true);
		}
	};

	return (
		<div className="app">
			<Header />
			<div className="content">
				{initialized ? (
					<Routes>
						<Route path="*" element={<PageNotFound />} />
						{routes}
					</Routes>
				) : (
					<Loading />
				)}
			</div>
			<Footer />
			<LocalePicker />
		</div>
	);
};

export default connect(() => ({ rendering: { lastRender: new Date(Date.now()).toLocaleString() } }))(App);
