import ParamAssignableObject from "./param-assignable-object.tsx";

export class FileShareDirectory extends ParamAssignableObject {
	name;
	subdirectories;

	constructor(directoryData: { name: any; subdirectories: any }) {
		super();
		if (directoryData) this.constructFromParams(directoryData);
	}

	static constructFromDB = (dbDir) => {
		return dbDir
			? new FileShareDirectory({
					name: dbDir.Name,
					subdirectories: dbDir.Subdirectories.$values.map((subDir) => this.constructFromDB(subDir)),
			  })
			: null;
	};
}
