//#region Imports and locale registration
import { Validate } from "../helpers/validation";
import { globalState, onGlobalStateChange } from "../store";
import { LANGUAGE_CODES } from "./lang-codes";
//#endregion

//#region Translations
class Translation {
	constructor({
		MISSING_TRANSLATION = "____MISSING_TRANSLATION____",
		MEASUREMENT_UNITS = {},
		FORMS = {},
		ERRORS = {},
		HEADERS = {},
		NAVLINKS = {},
		FOOTER = {},
		DASHBOARD = {
			STATS: {},
			EVAL_HISTORY: {},
			EVAL_REPORT: {},
		},
		SEVERITY = {},
		DOWNLOADS = {},
		PERFORMANCE = {},
		DATA_SOURCES = {},
		CHART_TYPES = {},
		TIME_UNITS = {},
		AXIS_UNITS = {},
		SPEED_UNITS = {},
		AGG_DATA_OPTIONS = {},
		PRINT,
		SAVED,
		COLLAPSE,
		CLICK_HERE,
		USER_MANAGEMENT = {},
		GROUP_MANAGEMENT = {},
		ORG_DATA = {},
		USER_IMPORT = {
			FILE_HEADERS: {},
		},
		ROLES = {},
		EMAIL = {},
		NOT_APPLICABLE,
	} = {}) {
		this.MISSING_TRANSLATION = MISSING_TRANSLATION;
		this.MEASUREMENT_UNITS = {
			METRIC: MEASUREMENT_UNITS.METRIC || MISSING_TRANSLATION,
			IMPERIAL: MEASUREMENT_UNITS.IMPERIAL || MISSING_TRANSLATION,
		};
		this.FORMS = {
			SELECT_PLACEHOLDER: FORMS.SELECT_PLACEHOLDER || MISSING_TRANSLATION,
			SELECT_ALL_OPTION: FORMS.SELECT_ALL_OPTION || MISSING_TRANSLATION,
			NO_OPTIONS: FORMS.NO_OPTIONS || MISSING_TRANSLATION,
			ORGANIZATION_NAME: FORMS.ORGANIZATION_NAME || MISSING_TRANSLATION,
			FIRST_NAME: FORMS.FIRST_NAME || MISSING_TRANSLATION,
			LAST_NAME: FORMS.LAST_NAME || MISSING_TRANSLATION,
			EMAIL_ADDRESS: FORMS.EMAIL_ADDRESS || MISSING_TRANSLATION,
			EMAIL_VERIFIED: FORMS.EMAIL_VERIFIED || MISSING_TRANSLATION,
			USERNAME: FORMS.USERNAME || MISSING_TRANSLATION,
			LANGUAGE_PREFERENCE: FORMS.LANGUAGE_PREFERENCE || MISSING_TRANSLATION,
			UNITS_PREFERENCE: FORMS.UNITS_PREFERENCE || MISSING_TRANSLATION,
			PASSWORD: FORMS.PASSWORD || MISSING_TRANSLATION,
			CONFIRM_PASSWORD: FORMS.CONFIRM_PASSWORD || MISSING_TRANSLATION,
			CURRENT_PASSWORD: FORMS.CURRENT_PASSWORD || MISSING_TRANSLATION,
			NEW_PASSWORD: FORMS.NEW_PASSWORD || MISSING_TRANSLATION,
			CONFIRM_NEW_PASSWORD: FORMS.CONFIRM_NEW_PASSWORD || MISSING_TRANSLATION,
			USERNAME_REQUIREMENTS: FORMS.USERNAME_REQUIREMENTS || MISSING_TRANSLATION,
			PASSWORD_REQUIREMENTS: FORMS.PASSWORD_REQUIREMENTS || MISSING_TRANSLATION,
			SUBMIT: FORMS.SUBMIT || MISSING_TRANSLATION,
			CANCEL: FORMS.CANCEL || MISSING_TRANSLATION,
			RESET: FORMS.RESET || MISSING_TRANSLATION,
			SAVE_CHANGES: FORMS.SAVE_CHANGES || MISSING_TRANSLATION,
			DISCARD_CHANGES: FORMS.DISCARD_CHANGES || MISSING_TRANSLATION,
			SELECT_FILE: FORMS.SELECT_FILE || MISSING_TRANSLATION,
			FORGOT_PASSWORD: FORMS.FORGOT_PASSWORD || MISSING_TRANSLATION,
			FORGOT_USERNAME_OR_PASSWORD: FORMS.FORGOT_USERNAME_OR_PASSWORD || MISSING_TRANSLATION,
			BACK_TO_LOGIN: FORMS.BACK_TO_LOGIN || MISSING_TRANSLATION,
			BACK_TO_DASHBOARD: FORMS.BACK_TO_DASHBOARD || MISSING_TRANSLATION,
			ALREADY_HAVE_AN_ACCOUNT: FORMS.ALREADY_HAVE_AN_ACCOUNT || MISSING_TRANSLATION,
			LOG_IN_INSTEAD: FORMS.LOG_IN_INSTEAD || MISSING_TRANSLATION,
			DONT_HAVE_AN_ACCOUNT: FORMS.DONT_HAVE_AN_ACCOUNT || MISSING_TRANSLATION,
			SIGN_UP_INSTEAD: FORMS.SIGN_UP_INSTEAD || MISSING_TRANSLATION,
			PERMANENT_ACTION_WARNING: FORMS.PERMANENT_ACTION_WARNING,
			BACK: FORMS.BACK || MISSING_TRANSLATION,
		};
		this.ERRORS = {
			ERROR: ERRORS.ERROR || MISSING_TRANSLATION,
			LINE_ERROR: ERRORS.LINE_ERROR || (() => MISSING_TRANSLATION),
			CANNOT_CREATE_USER: ERRORS.CANNOT_CREATE_USER || (() => MISSING_TRANSLATION),
			FIELD_EMPTY: ERRORS.FIELD_EMPTY || (() => MISSING_TRANSLATION),
			NO_FILE_SELECTED: ERRORS.NO_FILE_SELECTED || MISSING_TRANSLATION,
			NO_FILE_TO_DOWNLOAD: ERRORS.NO_FILE_TO_DOWNLOAD || MISSING_TRANSLATION,
			DOWNLOADS_COMING_SOON: ERRORS.DOWNLOADS_COMING_SOON || MISSING_TRANSLATION,
			DUPLICATE_USER: ERRORS.DUPLICATE_USER || MISSING_TRANSLATION,
			PASSWORD_MATCH: ERRORS.PASSWORD_MATCH || MISSING_TRANSLATION,
			CONFIRM_PASSWORD: ERRORS.CONFIRM_PASSWORD || MISSING_TRANSLATION,
			EMAIL_INVALID: ERRORS.EMAIL_INVALID || MISSING_TRANSLATION,
			UNAUTHORIZED: ERRORS.UNAUTHORIZED || MISSING_TRANSLATION,
			NOT_AUTHORIZED: ERRORS.NOT_AUTHORIZED || MISSING_TRANSLATION,
			USERNAME_TAKEN: ERRORS.USERNAME_TAKEN || MISSING_TRANSLATION,
			PASSWORD_INVALID: ERRORS.PASSWORD_INVALID || MISSING_TRANSLATION,
			TIMEOUT: ERRORS.TIMEOUT || MISSING_TRANSLATION,
			USER_ABORT: ERRORS.USER_ABORT || MISSING_TRANSLATION,
			INVALID_CREDS: ERRORS.INVALID_CREDS || MISSING_TRANSLATION,
			SUBSCRIPTION_EXPIRED: ERRORS.SUBSCRIPTION_EXPIRED || MISSING_TRANSLATION,
			TYPE_ERROR: ERRORS.TYPE_ERROR || MISSING_TRANSLATION,
			CANNOT_DELETE_SELF: ERRORS.CANNOT_DELETE_SELF || MISSING_TRANSLATION,
			COLLECTION_ALREADY_EXISTS: ERRORS.COLLECTION_ALREADY_EXISTS || MISSING_TRANSLATION,
			COLLECTION_PATCHED: ERRORS.COLLECTION_PATCHED || MISSING_TRANSLATION,
			INVALID_USERNAME_LENGTH: ERRORS.INVALID_USERNAME_LENGTH || MISSING_TRANSLATION,
			INVALID_USERNAME_START: ERRORS.INVALID_USERNAME_START || MISSING_TRANSLATION,
			INVALID_USERNAME_CHARS: ERRORS.INVALID_USERNAME_CHARS || MISSING_TRANSLATION,
			INVALID_USERNAME_BLACKLIST: ERRORS.INVALID_USERNAME_BLACKLIST || MISSING_TRANSLATION,
			INVALID_PASSWORD_LENGTH: ERRORS.INVALID_PASSWORD_LENGTH || MISSING_TRANSLATION,
			INVALID_PASSWORD_BLACKLIST: ERRORS.INVALID_PASSWORD_BLACKLIST || MISSING_TRANSLATION,
			INVALID_DATE_RANGE: ERRORS.INVALID_DATE_RANGE || MISSING_TRANSLATION,
			MUST_SELECT_INTEGER: ERRORS.MUST_SELECT_INTEGER || MISSING_TRANSLATION,
			EMAIL_ALREADY_VERIFIED: ERRORS.EMAIL_ALREADY_VERIFIED || MISSING_TRANSLATION,
			INVALID_TOKEN: ERRORS.INVALID_TOKEN || MISSING_TRANSLATION,
			TOKEN_EXPIRED: ERRORS.TOKEN_EXPIRED || MISSING_TRANSLATION,
		};
		this.HEADERS = {
			PAGE_NOT_FOUND: HEADERS.PAGE_NOT_FOUND || MISSING_TRANSLATION,
			DOWNLOADS: HEADERS.DOWNLOADS || MISSING_TRANSLATION,
			DASHBOARD: HEADERS.DASHBOARD || MISSING_TRANSLATION,
			STATISTICS: HEADERS.STATISTICS || MISSING_TRANSLATION,
			GROUPS: HEADERS.GROUPS || MISSING_TRANSLATION,
			PAYMENTS: HEADERS.PAYMENTS || MISSING_TRANSLATION,
			EVALUATION_HISTORY: HEADERS.EVALUATION_HISTORY || MISSING_TRANSLATION,
			PERFORMANCE: HEADERS.PERFORMANCE || MISSING_TRANSLATION,
			PERFORMANCE_PRINTOUT: HEADERS.PERFORMANCE_PRINTOUT || (() => MISSING_TRANSLATION),
			PROFILE: HEADERS.PROFILE || MISSING_TRANSLATION,
			COMPLETE_PROFILE: HEADERS.COMPLETE_PROFILE || MISSING_TRANSLATION,
			VERIFY_EMAIL: HEADERS.VERIFY_EMAIL || MISSING_TRANSLATION,
			ORG_DATA: HEADERS.ORG_DATA || MISSING_TRANSLATION,
			USER_DATA: HEADERS.USER_DATA || MISSING_TRANSLATION,
			RESET_PASSWORD: HEADERS.RESET_PASSWORD || MISSING_TRANSLATION,
			REQUEST_USERNAME: HEADERS.REQUEST_USERNAME || MISSING_TRANSLATION,
			SIGN_UP: HEADERS.SIGN_UP || MISSING_TRANSLATION,
			HELP: HEADERS.HELP || MISSING_TRANSLATION,
			SIDE_NAV_INDEX: HEADERS.SIDE_NAV_INDEX || MISSING_TRANSLATION,
			GETTING_STARTED: HEADERS.GETTING_STARTED || MISSING_TRANSLATION,
			DEVICE_SETUP: HEADERS.DEVICE_SETUP || MISSING_TRANSLATION,
			RUNNING_A_CRANE: HEADERS.RUNNING_A_CRANE || MISSING_TRANSLATION,
			AAR_AND_DRONE_VIEW: HEADERS.AAR_AND_DRONE_VIEW || MISSING_TRANSLATION,
			WHATS_NEW: HEADERS.WHATS_NEW || MISSING_TRANSLATION,
			GROUP_MANAGEMENT: HEADERS.GROUP_MANAGEMENT || MISSING_TRANSLATION,
			EDIT_GROUP: HEADERS.EDIT_GROUP || MISSING_TRANSLATION,
			ADD_GROUP: HEADERS.ADD_GROUP || MISSING_TRANSLATION,
			GROUP_DETAILS: HEADERS.GROUP_DETAILS || MISSING_TRANSLATION,
			USER_MANAGEMENT: HEADERS.USER_MANAGEMENT || MISSING_TRANSLATION,
			EDIT_USER: HEADERS.EDIT_USER || MISSING_TRANSLATION,
			USER_DETAILS: HEADERS.USER_DETAILS || MISSING_TRANSLATION,
			ADD_USER: HEADERS.ADD_USER || MISSING_TRANSLATION,
			PRIVACY_POLICY: HEADERS.PRIVACY_POLICY || MISSING_TRANSLATION,
			TERMS_OF_SERVICE: HEADERS.TERMS_OF_SERVICE || MISSING_TRANSLATION,
			DATA_RETENTION_POLICY: HEADERS.DATA_RETENTION_POLICY || MISSING_TRANSLATION,
		};
		this.NAVLINKS = {
			DOWNLOADS: NAVLINKS.DOWNLOADS || MISSING_TRANSLATION,
			DASHBOARD: NAVLINKS.DASHBOARD || MISSING_TRANSLATION,
			PERFORMANCE: NAVLINKS.PERFORMANCE || MISSING_TRANSLATION,
			PROFILE: NAVLINKS.PROFILE || MISSING_TRANSLATION,
			HELP: NAVLINKS.HELP || MISSING_TRANSLATION,
			WHATS_NEW: NAVLINKS.WHATS_NEW || MISSING_TRANSLATION,
			GROUP_MANAGEMENT: NAVLINKS.GROUP_MANAGEMENT || MISSING_TRANSLATION,
			USER_MANAGEMENT: NAVLINKS.USER_MANAGEMENT || MISSING_TRANSLATION,
			DEVELOPER: NAVLINKS.DEVELOPER || MISSING_TRANSLATION,
			LOG_IN: NAVLINKS.LOG_IN || MISSING_TRANSLATION,
			SIGN_UP: NAVLINKS.SIGN_UP || MISSING_TRANSLATION,
			LOG_OUT: NAVLINKS.LOG_OUT || MISSING_TRANSLATION,
			COMPLETE_PROFILE: NAVLINKS.COMPLETE_PROFILE || MISSING_TRANSLATION,
			VERIFY_EMAIL: NAVLINKS.VERIFY_EMAIL || MISSING_TRANSLATION,
			PASSWORD_RESET: NAVLINKS.PASSWORD_RESET || MISSING_TRANSLATION,
			CONTACT: NAVLINKS.CONTACT || MISSING_TRANSLATION,
			ABOUT: NAVLINKS.ABOUT || MISSING_TRANSLATION,
			SIMULATORS: NAVLINKS.SIMULATORS || MISSING_TRANSLATION,
			MODELS: NAVLINKS.MODELS || MISSING_TRANSLATION,
			INDUSTRIES: NAVLINKS.INDUSTRIES || MISSING_TRANSLATION,
			MEDIA: NAVLINKS.MEDIA || MISSING_TRANSLATION,
			BLOG: NAVLINKS.BLOG || MISSING_TRANSLATION,
			PRIVACY_POLICY: NAVLINKS.PRIVACY_POLICY || MISSING_TRANSLATION,
			TERMS_OF_SERVICE: NAVLINKS.TERMS_OF_SERVICE || MISSING_TRANSLATION,
			DATA_RETENTION_POLICY: NAVLINKS.DATA_RETENTION_POLICY || MISSING_TRANSLATION,
		};
		this.FOOTER = {
			LAST_LOGIN: FOOTER.LAST_LOGIN || MISSING_TRANSLATION,
			SIMULATORS: FOOTER.SIMULATORS || MISSING_TRANSLATION,
			CLOUD: FOOTER.CLOUD || MISSING_TRANSLATION,
			ESSENTIAL: FOOTER.ESSENTIAL || MISSING_TRANSLATION,
			FULL_MISSION: FOOTER.FULL_MISSION || MISSING_TRANSLATION,
			ADVANCED: FOOTER.ADVANCED || MISSING_TRANSLATION,
			COORDINATOR_TEST_SYSTEM: FOOTER.COORDINATOR_TEST_SYSTEM || MISSING_TRANSLATION,
			RO_RO: FOOTER.RO_RO || MISSING_TRANSLATION,
			REMOTE_STATION: FOOTER.REMOTE_STATION || MISSING_TRANSLATION,
			HOURS: FOOTER.HOURS || MISSING_TRANSLATION,
			MONDAY_TO_FRIDAY: FOOTER.MONDAY_TO_FRIDAY || MISSING_TRANSLATION,
			WEEKDAY_HOURS: FOOTER.WEEKDAY_HOURS || MISSING_TRANSLATION,
			MOUNTAIN_TIME: FOOTER.MOUNTAIN_TIME || MISSING_TRANSLATION,
			SATURDAY_TO_SUNDAY: FOOTER.SATURDAY_TO_SUNDAY || MISSING_TRANSLATION,
			CLOSED: FOOTER.CLOSED || MISSING_TRANSLATION,
			PHONE: FOOTER.PHONE || MISSING_TRANSLATION,
			ALL_RIGHTS_RESERVED: FOOTER.ALL_RIGHTS_RESERVED || MISSING_TRANSLATION,
		};
		this.DASHBOARD = {
			STATS: {
				ORGANIZATION_NAME: DASHBOARD.STATS.ORGANIZATION_NAME || MISSING_TRANSLATION,
				COURSES_ENROLLED_IN: DASHBOARD.STATS.COURSES_ENROLLED_IN || MISSING_TRANSLATION,
				ALL_COURSES: DASHBOARD.STATS.ALL_COURSES || MISSING_TRANSLATION,
				COURSE_TOTALS: DASHBOARD.STATS.COURSE_TOTALS || MISSING_TRANSLATION,
				GROUP_STATS: DASHBOARD.STATS.GROUP_STATS || MISSING_TRANSLATION,
				NEXT_LESSON: DASHBOARD.STATS.NEXT_LESSON || MISSING_TRANSLATION,
				RANK: DASHBOARD.STATS.RANK || MISSING_TRANSLATION,
			},
			EVAL_HISTORY: {
				COMPLETED: DASHBOARD.EVAL_HISTORY.COMPLETED || MISSING_TRANSLATION,
				COURSES: DASHBOARD.EVAL_HISTORY.COURSES || MISSING_TRANSLATION,
				COURSE: DASHBOARD.EVAL_HISTORY.COURSE || MISSING_TRANSLATION,
				PERCENT_COMPLETE: DASHBOARD.EVAL_HISTORY.PERCENT_COMPLETE || MISSING_TRANSLATION,
				TIME_SPENT: DASHBOARD.EVAL_HISTORY.TIME_SPENT || MISSING_TRANSLATION,
				TIME_REMAINING: DASHBOARD.EVAL_HISTORY.TIME_REMAINING || MISSING_TRANSLATION,
				UNIT: DASHBOARD.EVAL_HISTORY.UNIT || MISSING_TRANSLATION,
				LESSONS: DASHBOARD.EVAL_HISTORY.LESSONS || MISSING_TRANSLATION,
				LESSON: DASHBOARD.EVAL_HISTORY.LESSON || MISSING_TRANSLATION,
				BEST_SCORE: DASHBOARD.EVAL_HISTORY.BEST_SCORE || MISSING_TRANSLATION,
				LAST_SCORE: DASHBOARD.EVAL_HISTORY.LAST_SCORE || MISSING_TRANSLATION,
				LAST_ATTEMPT: DASHBOARD.EVAL_HISTORY.LAST_ATTEMPT || MISSING_TRANSLATION,
				NUMBER_OF_ATTEMPTS: DASHBOARD.EVAL_HISTORY.NUMBER_OF_ATTEMPTS || MISSING_TRANSLATION,
				EVALUATION_HISTORY: DASHBOARD.EVAL_HISTORY.EVALUATION_HISTORY || MISSING_TRANSLATION,
				DATE: DASHBOARD.EVAL_HISTORY.DATE || MISSING_TRANSLATION,
				TIME: DASHBOARD.EVAL_HISTORY.TIME || MISSING_TRANSLATION,
				ATTEMPT: DASHBOARD.EVAL_HISTORY.ATTEMPT || MISSING_TRANSLATION,
				SCORE: DASHBOARD.EVAL_HISTORY.SCORE || MISSING_TRANSLATION,
				NO_DATA_MESSAGE: DASHBOARD.EVAL_HISTORY.NO_DATA_MESSAGE || MISSING_TRANSLATION,
				RANK: DASHBOARD.EVAL_HISTORY.RANK || MISSING_TRANSLATION,
			},
			EVAL_REPORT: {
				TITLE: DASHBOARD.EVAL_REPORT.TITLE || MISSING_TRANSLATION,
				FIRST_NAME: DASHBOARD.EVAL_REPORT.FIRST_NAME || MISSING_TRANSLATION,
				LAST_NAME: DASHBOARD.EVAL_REPORT.LAST_NAME || MISSING_TRANSLATION,
				USERNAME: DASHBOARD.EVAL_REPORT.USERNAME || MISSING_TRANSLATION,
				COURSE_NAME: DASHBOARD.EVAL_REPORT.COURSE_NAME || MISSING_TRANSLATION,
				UNIT_NAME: DASHBOARD.EVAL_REPORT.UNIT_NAME || MISSING_TRANSLATION,
				LESSON_NAME: DASHBOARD.EVAL_REPORT.LESSON_NAME || MISSING_TRANSLATION,
				RUN_DATE: DASHBOARD.EVAL_REPORT.RUN_DATE || MISSING_TRANSLATION,
				DURATION: DASHBOARD.EVAL_REPORT.DURATION || MISSING_TRANSLATION,
				COMPLETED: DASHBOARD.EVAL_REPORT.COMPLETED || MISSING_TRANSLATION,
				STARTING_SCORE: DASHBOARD.EVAL_REPORT.STARTING_SCORE || MISSING_TRANSLATION,
				FINAL_SCORE: DASHBOARD.EVAL_REPORT.FINAL_SCORE || MISSING_TRANSLATION,
				MIN_SCORE: DASHBOARD.EVAL_REPORT.MIN_SCORE || MISSING_TRANSLATION,
				MAXIMUM_ATTEMPTS: DASHBOARD.EVAL_REPORT.MAXIMUM_ATTEMPTS || MISSING_TRANSLATION,
				REPORT_DATA_TITLE: DASHBOARD.EVAL_REPORT.REPORT_DATA_TITLE || MISSING_TRANSLATION,
				REPORT_DATA_NAME: DASHBOARD.EVAL_REPORT.REPORT_DATA_NAME || MISSING_TRANSLATION,
				REPORT_DATA_VALUE: DASHBOARD.EVAL_REPORT.REPORT_DATA_VALUE || MISSING_TRANSLATION,
				SCORING_FACTORS_TITLE: DASHBOARD.EVAL_REPORT.SCORING_FACTORS_TITLE || MISSING_TRANSLATION,
				SCORING_FACTORS_SIM_TIME: DASHBOARD.EVAL_REPORT.SCORING_FACTORS_SIM_TIME || MISSING_TRANSLATION,
				SCORING_FACTORS_NAME: DASHBOARD.EVAL_REPORT.SCORING_FACTORS_NAME || MISSING_TRANSLATION,
				SCORING_FACTORS_OFFSET: DASHBOARD.EVAL_REPORT.SCORING_FACTORS_OFFSET || MISSING_TRANSLATION,
				EVENT_HISTORY_TITLE: DASHBOARD.EVAL_REPORT.EVENT_HISTORY_TITLE || MISSING_TRANSLATION,
				EVENT_HISTORY_SIM_TIME: DASHBOARD.EVAL_REPORT.EVENT_HISTORY_SIM_TIME || MISSING_TRANSLATION,
				EVENT_HISTORY_NAME: DASHBOARD.EVAL_REPORT.EVENT_HISTORY_NAME || MISSING_TRANSLATION,
				EVENT_HISTORY_DESCRIPTION: DASHBOARD.EVAL_REPORT.EVENT_HISTORY_DESCRIPTION || MISSING_TRANSLATION,
				COLLISION_OCCURRED_MESSAGE: DASHBOARD.EVAL_REPORT.COLLISION_OCCURRED_MESSAGE || (() => MISSING_TRANSLATION),
			},
		};
		this.SEVERITY = {
			LOW: SEVERITY.LOW,
			MEDIUM: SEVERITY.MEDIUM,
			HIGH: SEVERITY.HIGH,
			FATAL: SEVERITY.FATAL,
			VIOLATION: SEVERITY.VIOLATION,
		};
		this.DOWNLOADS = {
			DOWNLOAD_LAUNCHER: DOWNLOADS.DOWNLOAD_LAUNCHER,
			INSTRUCTIONS: DOWNLOADS.INSTRUCTIONS,
		};
		this.PERFORMANCE = {
			STUDENTS: PERFORMANCE.STUDENTS || MISSING_TRANSLATION,
			DATA_SOURCE: PERFORMANCE.DATA_SOURCE || MISSING_TRANSLATION,
			DATA_SOURCE_NOT_SUPPORTED: PERFORMANCE.DATA_SOURCE_NOT_SUPPORTED || (() => MISSING_TRANSLATION),
			CHART_TYPE: PERFORMANCE.CHART_TYPE || MISSING_TRANSLATION,
			X_AXIS_UNIT: PERFORMANCE.X_AXIS_UNIT || MISSING_TRANSLATION,
			SPEED_UNIT: PERFORMANCE.SPEED_UNIT || MISSING_TRANSLATION,
			DATE_RANGE: PERFORMANCE.DATE_RANGE || MISSING_TRANSLATION,
			AGGREGATED_DATA_OPTIONS: PERFORMANCE.AGGREGATED_DATA_OPTIONS || MISSING_TRANSLATION,
			COURSE: PERFORMANCE.COURSE || MISSING_TRANSLATION,
			COURSES: PERFORMANCE.COURSES || MISSING_TRANSLATION,
			UNIT: PERFORMANCE.UNIT || MISSING_TRANSLATION,
			UNITS: PERFORMANCE.UNITS || MISSING_TRANSLATION,
			LESSON: PERFORMANCE.LESSON || MISSING_TRANSLATION,
			LESSONS: PERFORMANCE.LESSONS || MISSING_TRANSLATION,
			UNIT_SELECT_PLACEHOLDER: PERFORMANCE.UNIT_SELECT_PLACEHOLDER || MISSING_TRANSLATION,
			LESSON_SELECT_PLACEHOLDER: PERFORMANCE.LESSON_SELECT_PLACEHOLDER || MISSING_TRANSLATION,
			SELECT_ALL_STUDENTS: PERFORMANCE.SELECT_ALL_STUDENTS || MISSING_TRANSLATION,
			SELECT_ALL_COURSES: PERFORMANCE.SELECT_ALL_COURSES || MISSING_TRANSLATION,
			SELECT_ALL_UNITS: PERFORMANCE.SELECT_ALL_UNITS || MISSING_TRANSLATION,
			SELECT_ALL_LESSONS: PERFORMANCE.SELECT_ALL_LESSONS || MISSING_TRANSLATION,
			LOADING_DATA_MESSAGE: PERFORMANCE.LOADING_DATA_MESSAGE || MISSING_TRANSLATION,
			NO_DATA_MESSAGE: PERFORMANCE.NO_DATA_MESSAGE || MISSING_TRANSLATION,
			BROADEN_FILTERS_MESSAGE: PERFORMANCE.BROADEN_FILTERS_MESSAGE || MISSING_TRANSLATION,
			DATA_LIMITED_MESSAGE: PERFORMANCE.DATA_LIMITED_MESSAGE || (() => MISSING_TRANSLATION),
			IN_TIME_UNITS: PERFORMANCE.IN_TIME_UNITS || (() => MISSING_TRANSLATION),
			IN_SPEED_UNITS: PERFORMANCE.IN_SPEED_UNITS || (() => MISSING_TRANSLATION),
			IN_DEGREES: PERFORMANCE.IN_DEGREES || (() => MISSING_TRANSLATION),
			CHART_GENERATED_DATE: PERFORMANCE.CHART_GENERATED_DATE || (() => MISSING_TRANSLATION),
			FROM_DATE: PERFORMANCE.FROM_DATE || MISSING_TRANSLATION,
			TO_DATE: PERFORMANCE.TO_DATE || MISSING_TRANSLATION,
			DATE: PERFORMANCE.DATE || MISSING_TRANSLATION,
		};
		this.DATA_SOURCES = {
			SCORE: DATA_SOURCES.SCORE || MISSING_TRANSLATION,
			SCORED_TIME: DATA_SOURCES.SCORED_TIME || MISSING_TRANSLATION,
			ATTEMPTS: DATA_SOURCES.ATTEMPTS || MISSING_TRANSLATION,
			DURATION: DATA_SOURCES.DURATION || MISSING_TRANSLATION,
			COLLISIONS: DATA_SOURCES.COLLISIONS || MISSING_TRANSLATION,
			COLLISION_VELOCITY: DATA_SOURCES.COLLISION_VELOCITY || MISSING_TRANSLATION,
			MAXIMUM_LOAD_SWING: DATA_SOURCES.MAXIMUM_LOAD_SWING || MISSING_TRANSLATION,
			VIOLATIONS: DATA_SOURCES.VIOLATIONS || MISSING_TRANSLATION,
			MOVES_PER_HOUR: DATA_SOURCES.MOVES_PER_HOUR || MISSING_TRANSLATION,
		};
		this.CHART_TYPES = {
			BAR: CHART_TYPES.BAR || MISSING_TRANSLATION,
			LINE: CHART_TYPES.LINE || MISSING_TRANSLATION,
			SCATTERPLOT: CHART_TYPES.SCATTERPLOT || MISSING_TRANSLATION,
		};
		this.TIME_UNITS = {
			MINUTE: TIME_UNITS.MINUTE || MISSING_TRANSLATION,
			HOUR: TIME_UNITS.HOUR || MISSING_TRANSLATION,
			DAY: TIME_UNITS.DAY || MISSING_TRANSLATION,
		};
		this.AXIS_UNITS = {
			DATE: AXIS_UNITS.DATE || MISSING_TRANSLATION,
			LESSON: AXIS_UNITS.LESSON || MISSING_TRANSLATION,
		};
		this.SPEED_UNITS = {
			METERS_PER_SECOND: SPEED_UNITS.METERS_PER_SECOND || MISSING_TRANSLATION,
			FEET_PER_SECOND: SPEED_UNITS.FEET_PER_SECOND || MISSING_TRANSLATION,
			MILES_PER_HOUR: SPEED_UNITS.MILES_PER_HOUR || MISSING_TRANSLATION,
			KILOMETERS_PER_HOUR: SPEED_UNITS.KILOMETERS_PER_HOUR || MISSING_TRANSLATION,
		};
		this.AGG_DATA_OPTIONS = {
			AVERAGE: AGG_DATA_OPTIONS.AVERAGE || MISSING_TRANSLATION,
			MEDIAN: AGG_DATA_OPTIONS.MEDIAN || MISSING_TRANSLATION,
			TREND: AGG_DATA_OPTIONS.TREND || MISSING_TRANSLATION,
		};
		this.PRINT = PRINT || MISSING_TRANSLATION;
		this.SAVED = SAVED || MISSING_TRANSLATION;
		this.COLLAPSE = COLLAPSE || MISSING_TRANSLATION;
		this.CLICK_HERE = CLICK_HERE || MISSING_TRANSLATION;
		this.USER_MANAGEMENT = {
			USERS: USER_MANAGEMENT.USERS || MISSING_TRANSLATION,
			FIRST_NAME: USER_MANAGEMENT.FIRST_NAME || MISSING_TRANSLATION,
			LAST_NAME: USER_MANAGEMENT.LAST_NAME || MISSING_TRANSLATION,
			USERNAME: USER_MANAGEMENT.USERNAME || MISSING_TRANSLATION,
			EMAIL_ADDRESS: USER_MANAGEMENT.EMAIL_ADDRESS || MISSING_TRANSLATION,
			PASSWORD_CHANGED: USER_MANAGEMENT.PASSWORD_CHANGED || MISSING_TRANSLATION,
			LANGUAGE_PREFERENCE: USER_MANAGEMENT.LANGUAGE_PREFERENCE || MISSING_TRANSLATION,
			UNITS_PREFERENCE: USER_MANAGEMENT.UNITS_PREFERENCE || MISSING_TRANSLATION,
			GROUPS: USER_MANAGEMENT.GROUPS || MISSING_TRANSLATION,
			ACTIVE: USER_MANAGEMENT.ACTIVE || MISSING_TRANSLATION,
			ROLE: USER_MANAGEMENT.ROLE || MISSING_TRANSLATION,
			DATE_CREATED: USER_MANAGEMENT.DATE_CREATED || MISSING_TRANSLATION,
			DATE_LAST_MODIFIED: USER_MANAGEMENT.DATE_LAST_MODIFIED || MISSING_TRANSLATION,
			DATE_LAST_LOGIN: USER_MANAGEMENT.DATE_LAST_LOGIN || MISSING_TRANSLATION,
			SUBSCRIPTION_EXPIRATION_DATE: USER_MANAGEMENT.SUBSCRIPTION_EXPIRATION_DATE || MISSING_TRANSLATION,
			ADD_USER: USER_MANAGEMENT.ADD_USER || MISSING_TRANSLATION,
			USER_ADDED: USER_MANAGEMENT.USER_ADDED || MISSING_TRANSLATION,
			EDIT_USER: USER_MANAGEMENT.EDIT_USER || MISSING_TRANSLATION,
			DELETE_USER: USER_MANAGEMENT.DELETE_USER || MISSING_TRANSLATION,
			NO_USERS_MESSAGE: USER_MANAGEMENT.NO_USERS_MESSAGE || MISSING_TRANSLATION,
			SEARCH: USER_MANAGEMENT.SEARCH || MISSING_TRANSLATION,
			SORT_ORDER: USER_MANAGEMENT.SORT_ORDER || MISSING_TRANSLATION,
			ACTIVE_USERS_ONLY: USER_MANAGEMENT.ACTIVE_USERS_ONLY || MISSING_TRANSLATION,
			SELECTED_GROUPS: USER_MANAGEMENT.SELECTED_GROUPS || MISSING_TRANSLATION,
		};
		this.GROUP_MANAGEMENT = {
			GROUP_NAME: GROUP_MANAGEMENT.GROUP_NAME || MISSING_TRANSLATION,
			START_DATE: GROUP_MANAGEMENT.START_DATE || MISSING_TRANSLATION,
			END_DATE: GROUP_MANAGEMENT.END_DATE || MISSING_TRANSLATION,
			DATE_RANGE: GROUP_MANAGEMENT.DATE_RANGE || MISSING_TRANSLATION,
			ACTIVE: GROUP_MANAGEMENT.ACTIVE || MISSING_TRANSLATION,
			NOTES: GROUP_MANAGEMENT.NOTES || MISSING_TRANSLATION,
			COURSE: GROUP_MANAGEMENT.COURSE || MISSING_TRANSLATION,
			COURSES: GROUP_MANAGEMENT.COURSES || MISSING_TRANSLATION,
			GROUPS: GROUP_MANAGEMENT.GROUPS || MISSING_TRANSLATION,
			STUDENTS: GROUP_MANAGEMENT.STUDENTS || MISSING_TRANSLATION,
			INSTRUCTORS: GROUP_MANAGEMENT.INSTRUCTORS || MISSING_TRANSLATION,
			SELECT_ALL_COURSES: GROUP_MANAGEMENT.SELECT_ALL_COURSES || MISSING_TRANSLATION,
			SELECT_ALL_STUDENTS: GROUP_MANAGEMENT.SELECT_ALL_STUDENTS || MISSING_TRANSLATION,
			SELECT_ALL_INSTRUCTORS: GROUP_MANAGEMENT.SELECT_ALL_INSTRUCTORS || MISSING_TRANSLATION,
			ADD_NEW_GROUP: GROUP_MANAGEMENT.ADD_NEW_GROUP || MISSING_TRANSLATION,
			DELETE_GROUP: GROUP_MANAGEMENT.DELETE_GROUP || MISSING_TRANSLATION,
			EDIT_GROUP: GROUP_MANAGEMENT.EDIT_GROUP || MISSING_TRANSLATION,
			NO_GROUPS_MESSAGE: GROUP_MANAGEMENT.NO_GROUPS_MESSAGE || MISSING_TRANSLATION,
			UNASSIGNED_USERS: GROUP_MANAGEMENT.UNASSIGNED_USERS || MISSING_TRANSLATION,
			UNASSIGNED_USER_LIST: GROUP_MANAGEMENT.UNASSIGNED_USER_LIST || MISSING_TRANSLATION,
			GROUP_ROSTER: GROUP_MANAGEMENT.GROUP_ROSTER || MISSING_TRANSLATION,
			PRINT_GROUP_ROSTER: GROUP_MANAGEMENT.PRINT_GROUP_ROSTER || MISSING_TRANSLATION,
			GROUP_ROSTER_PRINT_HEADER: GROUP_MANAGEMENT.GROUP_ROSTER_PRINT_HEADER || (() => MISSING_TRANSLATION),
			ADD_COURSE: GROUP_MANAGEMENT.ADD_COURSE || MISSING_TRANSLATION,
			COURSE_LIST_PRINT_HEADER: GROUP_MANAGEMENT.COURSE_LIST_PRINT_HEADER || (() => MISSING_TRANSLATION),
		};
		this.ORG_DATA = {
			ASSOCIATED_INSTALLERS: ORG_DATA.ASSOCIATED_INSTALLERS || MISSING_TRANSLATION,
			ASSOCIATED_LAUNCHER_COLLECTION: ORG_DATA.ASSOCIATED_LAUNCHER_COLLECTION || MISSING_TRANSLATION,
			ASSOCIATED_SIMULATOR_COLLECTION: ORG_DATA.ASSOCIATED_SIMULATOR_COLLECTION || MISSING_TRANSLATION,
			ASSOCIATED_SCENARIO_EDITOR_COLLECTION: ORG_DATA.ASSOCIATED_SCENARIO_EDITOR_COLLECTION || MISSING_TRANSLATION,
			ASSOCIATED_IOS_COLLECTION: ORG_DATA.ASSOCIATED_IOS_COLLECTION || MISSING_TRANSLATION,
			CLOUD_BASED: ORG_DATA.CLOUD_BASED || MISSING_TRANSLATION,
			RESTRICT_CONNECTIONS_BY_LICENSE: ORG_DATA.RESTRICT_CONNECTIONS_BY_LICENSE || MISSING_TRANSLATION,
			STUDENT_LICENSES: ORG_DATA.STUDENT_LICENSES || MISSING_TRANSLATION,
			INSTRUCTOR_LICENSES: ORG_DATA.INSTRUCTOR_LICENSES || MISSING_TRANSLATION,
		};
		this.USER_IMPORT = {
			FILE_HEADERS: {
				USERNAME: USER_IMPORT.FILE_HEADERS.USERNAME || MISSING_TRANSLATION,
				FIRST_NAME: USER_IMPORT.FILE_HEADERS.FIRST_NAME || MISSING_TRANSLATION,
				LAST_NAME: USER_IMPORT.FILE_HEADERS.LAST_NAME || MISSING_TRANSLATION,
				EMAIL_ADDRESS: USER_IMPORT.FILE_HEADERS.EMAIL_ADDRESS || MISSING_TRANSLATION,
				ROLE: USER_IMPORT.FILE_HEADERS.ROLE || MISSING_TRANSLATION,
			},
			IMPORT_USERS: USER_IMPORT.IMPORT_USERS || MISSING_TRANSLATION,
			EXPORT_USERS: USER_IMPORT.EXPORT_USERS || MISSING_TRANSLATION,
			DOWNLOAD_TEMPLATE: USER_IMPORT.DOWNLOAD_TEMPLATE || MISSING_TRANSLATION,
			IMPORT_SUCCESSFUL: USER_IMPORT.IMPORT_SUCCESSFUL || (() => MISSING_TRANSLATION),
			ADD_USERS_TO_GROUPS: USER_IMPORT.ADD_USERS_TO_GROUPS || MISSING_TRANSLATION,
		};
		this.ROLES = {
			SYSTEM: ROLES.SYSTEM || MISSING_TRANSLATION,
			STUDENT: ROLES.STUDENT || MISSING_TRANSLATION,
			INSTRUCTOR: ROLES.INSTRUCTOR || MISSING_TRANSLATION,
			ADMIN: ROLES.ADMIN || MISSING_TRANSLATION,
			DEVELOPER: ROLES.DEVELOPER || MISSING_TRANSLATION,
			GLOBAL_ADMIN: ROLES.GLOBAL_ADMIN || MISSING_TRANSLATION,
		};
		this.EMAIL = {
			EMAIL_VERIFIED_MESSAGE: EMAIL.EMAIL_VERIFIED_MESSAGE || MISSING_TRANSLATION,
			EMAIL_VERIFIED_SUBMESSAGE_LOGGED_OUT: EMAIL.EMAIL_VERIFIED_SUBMESSAGE_LOGGED_OUT || MISSING_TRANSLATION,
			EMAIL_VERIFIED_SUBMESSAGE_LOGGED_IN: EMAIL.EMAIL_VERIFIED_SUBMESSAGE_LOGGED_IN || MISSING_TRANSLATION,
			VERIFYING_YOUR_EMAIL: EMAIL.VERIFYING_YOUR_EMAIL || MISSING_TRANSLATION,
			VERIFICATION_FAILED: EMAIL.VERIFICATION_FAILED || MISSING_TRANSLATION,
			NEW_LINK_MESSAGE: EMAIL.NEW_LINK_MESSAGE || MISSING_TRANSLATION,
			NEW_LINK_LOGGED_OUT_MESSAGE: EMAIL.NEW_LINK_LOGGED_OUT_MESSAGE || MISSING_TRANSLATION,
			RESEND_EMAIL: EMAIL.RESEND_EMAIL || MISSING_TRANSLATION,
			VERIFICATION_EMAIL_SENT: EMAIL.VERIFICATION_EMAIL_SENT || MISSING_TRANSLATION,
			SEND_VERIFICATION_EMAIL: EMAIL.SEND_VERIFICATION_EMAIL || MISSING_TRANSLATION,
			PASSWORD_RESET_EMAIL_SENT: EMAIL.PASSWORD_RESET_EMAIL_SENT || MISSING_TRANSLATION,
		};
		this.NOT_APPLICABLE = NOT_APPLICABLE || MISSING_TRANSLATION;
	}
}

function Translations() {
	//#region Individual Translations
	this[LANGUAGE_CODES.AFRIKANS] = new Translation({});
	this[LANGUAGE_CODES.ARABIC] = new Translation({
		MISSING_TRANSLATION: "_مفقود_الترجمة_",
	});
	this[LANGUAGE_CODES.DANISH] = new Translation({});
	this[LANGUAGE_CODES.GERMAN] = new Translation({});
	this[LANGUAGE_CODES.GREEK] = new Translation({});
	this[LANGUAGE_CODES.ENGLISH] = new Translation({
		MISSING_TRANSLATION: "____MISSING_TRANSLATION____",
		MEASUREMENT_UNITS: {
			METRIC: "Metric",
			IMPERIAL: "Imperial",
		},
		FORMS: {
			SELECT_PLACEHOLDER: "Select...",
			SELECT_ALL_OPTION: "All Options",
			NO_OPTIONS: "No options",
			SUBMIT: "Submit",
			CANCEL: "Cancel",
			RESET: "Reset",
			SAVE_CHANGES: "Save Changes",
			DISCARD_CHANGES: "Discard Changes",
			SELECT_FILE: "Select File",
			ORGANIZATION_NAME: "Organization Name",
			FIRST_NAME: "First Name",
			LAST_NAME: "Last Name",
			EMAIL_ADDRESS: "Email Address",
			EMAIL_VERIFIED: "Email Verified",
			USERNAME: "Username",
			LANGUAGE_PREFERENCE: "Language Preference",
			UNITS_PREFERENCE: "Units Preference",
			PASSWORD: "Password",
			CONFIRM_PASSWORD: "Confirm Password",
			CURRENT_PASSWORD: "Current Password",
			NEW_PASSWORD: "New Password",
			CONFIRM_NEW_PASSWORD: "Confirm New Password",
			USERNAME_REQUIREMENTS: [
				"Username must begin with a letter",
				`Username must contain between ${Validate.USERNAME_MIN_LENGTH} and ${Validate.USERNAME_MAX_LENGTH} characters`,
				`Username must only contain letters, numbers, and/or the following special characters: -_.@`,
			],
			PASSWORD_REQUIREMENTS: `Password must be at least ${Validate.PASSWORD_MIN_LENGTH} characters long`,
			FORGOT_PASSWORD: "Forgot Password?",
			FORGOT_USERNAME_OR_PASSWORD: "Forgot Username or Password?",
			BACK_TO_LOGIN: "Back to Login",
			BACK_TO_DASHBOARD: "Back to Dashboard",
			ALREADY_HAVE_AN_ACCOUNT: "Already have an account?",
			LOG_IN_INSTEAD: "Log In Instead",
			DONT_HAVE_AN_ACCOUNT: "Don't have an account?",
			SIGN_UP_INSTEAD: "Sign Up Instead",
			PERMANENT_ACTION_WARNING: "Warning! Action Cannot Be Undone!",
			BACK: "Back",
		},
		ERRORS: {
			ERROR: "Error",
			LINE_ERROR: (lineNumber, customMessage = "") => `Could not import line ${lineNumber}.${customMessage && ` ${customMessage}`}`,
			CANNOT_CREATE_USER: (lineNumber, customMessage = "") =>
				`Could not create user from line ${lineNumber}${customMessage && `: ${customMessage}`}`,
			FIELD_EMPTY: (fieldName = null) => (fieldName ? `Missing "${fieldName}"` : `Please enter a value`),
			NO_FILE_SELECTED: "Please select a file to import",
			NO_FILE_TO_DOWNLOAD: "No file available",
			DOWNLOADS_COMING_SOON: "Downloads Coming Soon!",
			DUPLICATE_USER: "Duplicate User",
			PASSWORD_MATCH: "Passwords do not match",
			CONFIRM_PASSWORD: "Please confirm your password",
			EMAIL_INVALID: "Please enter a valid email address",
			UNAUTHORIZED: "Unauthorized",
			NOT_AUTHORIZED: "Not Authorized",
			USERNAME_TAKEN: "Username taken",
			PASSWORD_INVALID: "Password does not meet requirements",
			TIMEOUT: "Service timed out during login. Please try again later",
			USER_ABORT: "Request aborted.",
			INVALID_CREDS: "Invalid Credentials",
			SUBSCRIPTION_EXPIRED: "Subscription Expired",
			TYPE_ERROR: "Failed to fetch. See logs for details",
			CANNOT_DELETE_SELF: "You cannot delete your own user",
			COLLECTION_ALREADY_EXISTS: "Collection already exists",
			COLLECTION_PATCHED: "Collection already exists, but had missing subdirectories. These subdirectories have been fixed",
			INVALID_USERNAME_LENGTH: `Username must be between ${Validate.USERNAME_MIN_LENGTH}-${Validate.USERNAME_MAX_LENGTH} characters`,
			INVALID_USERNAME_START: "Username must begin with a letter",
			INVALID_USERNAME_CHARS: "Username must only contain letters, numbers, and the following special characters: -_.@",
			INVALID_USERNAME_BLACKLIST: "Please choose a different username",
			INVALID_PASSWORD_LENGTH: `Password must be at least ${Validate.PASSWORD_MIN_LENGTH} characters long`,
			INVALID_PASSWORD_BLACKLIST: "Please choose a different password",
			INVALID_DATE_RANGE: "End Date must be after Start Date",
			MUST_SELECT_INTEGER: "Please choose a whole integer",
			EMAIL_ALREADY_VERIFIED: "Email address already verified",
			INVALID_TOKEN: "Invalid Verification Token",
			TOKEN_EXPIRED: "Link expired. Please request a new link and try again.",
		},
		HEADERS: {
			PAGE_NOT_FOUND: "Page Not Found",
			DOWNLOADS: "Downloads",
			DASHBOARD: "Dashboard",
			STATISTICS: "Statistics",
			GROUPS: "My Groups",
			PAYMENTS: "Payments",
			EVALUATION_HISTORY: "Evaluation History",
			PERFORMANCE: "Performance Data",
			PERFORMANCE_PRINTOUT: (dataType, studentName) => `${dataType} Data for ${studentName}`,
			PROFILE: "Profile",
			COMPLETE_PROFILE: "Complete Profile",
			VERIFY_EMAIL: "Verify Email",
			ORG_DATA: "Organization Data",
			USER_DATA: "User Data",
			RESET_PASSWORD: "Reset Password",
			REQUEST_USERNAME: "Request Username",
			SIGN_UP: "Sign Up",
			HELP: "Help",
			SIDE_NAV_INDEX: "Please select a topic from the options on the left",
			GETTING_STARTED: "Getting Started",
			DEVICE_SETUP: "Device Setup",
			RUNNING_A_CRANE: "Running a Crane",
			AAR_AND_DRONE_VIEW: "After Action Review",
			WHATS_NEW: "What's New",
			GROUP_MANAGEMENT: "Group Management",
			EDIT_GROUP: "Edit Group Details",
			ADD_GROUP: "Add New Group",
			GROUP_DETAILS: "Group Details",
			USER_MANAGEMENT: "User Management",
			EDIT_USER: "Edit User",
			USER_DETAILS: "User Details",
			ADD_USER: "Add User",
			PRIVACY_POLICY: "Privacy Policy",
			TERMS_OF_SERVICE: "Terms of Service",
			DATA_RETENTION_POLICY: "Data Retention Policy",
		},
		NAVLINKS: {
			DOWNLOADS: "Downloads",
			DASHBOARD: "Dashboard",
			PERFORMANCE: "Performance",
			PROFILE: "Profile",
			HELP: "Help",
			WHATS_NEW: "What's New",
			GROUP_MANAGEMENT: "Group Management",
			USER_MANAGEMENT: "User Management",
			DEVELOPER: "Developer Dashboard",
			LOG_IN: "Log In",
			SIGN_UP: "Sign Up",
			LOG_OUT: "Log Out",
			COMPLETE_PROFILE: "Complete Profile",
			VERIFY_EMAIL: "Verify Email",
			PASSWORD_RESET: "Password Reset",
			CONTACT: "Contact",
			ABOUT: "About",
			SIMULATORS: "Simulators",
			MODELS: "Models",
			INDUSTRIES: "Industries",
			MEDIA: "Media",
			BLOG: "Blog",
			PRIVACY_POLICY: "Privacy Policy",
			TERMS_OF_SERVICE: "Terms of Service",
			DATA_RETENTION_POLICY: "Data Retention Policy",
		},
		FOOTER: {
			LAST_LOGIN: "Last Login",
			SIMULATORS: "Simulators",
			CLOUD: "Cloud",
			ESSENTIAL: "Essential",
			FULL_MISSION: "Full Mission",
			ADVANCED: "Advanced",
			COORDINATOR_TEST_SYSTEM: "Coordinator Test System",
			RO_RO: "RoRo",
			REMOTE_STATION: "Remote Station",
			HOURS: "Hours",
			MONDAY_TO_FRIDAY: "Monday - Friday",
			WEEKDAY_HOURS: "7:30am to 4:30pm",
			MOUNTAIN_TIME: "Mountain Time",
			SATURDAY_TO_SUNDAY: "Saturday - Sunday",
			CLOSED: "Closed",
			PHONE: "Phone",
			ALL_RIGHTS_RESERVED: "All Rights Reserved",
		},
		DASHBOARD: {
			STATS: {
				ORGANIZATION_NAME: "Organization Name",
				COURSES_ENROLLED_IN: "Courses Enrolled In",
				ALL_COURSES: "All Courses",
				COURSE_TOTALS: "Totals by Course",
				GROUP_STATS: "Group Stats",
				NEXT_LESSON: "Next Lesson",
				RANK: "Rank",
			},
			EVAL_HISTORY: {
				COMPLETED: "Completed",
				COURSES: "Courses",
				COURSE: "Course",
				PERCENT_COMPLETE: "% Complete",
				TIME_SPENT: "Time Spent",
				TIME_REMAINING: "Estimated Time Remaining",
				UNIT: "Unit",
				LESSONS: "Lessons",
				LESSON: "Lesson",
				BEST_SCORE: "Best Score",
				LAST_SCORE: "Last Score",
				LAST_ATTEMPT: "Last Attempt",
				NUMBER_OF_ATTEMPTS: "Attempts",
				EVALUATION_HISTORY: "Evaluation History",
				DATE: "Date",
				TIME: "Time",
				ATTEMPT: "Attempt",
				SCORE: "Score",
				NO_DATA_MESSAGE: "No entries to show",
				RANK: "Rank",
			},
			EVAL_REPORT: {
				TITLE: "Student Evaluation Report",
				FIRST_NAME: "First Name",
				LAST_NAME: "Last Name",
				USERNAME: "Username",
				COURSE_NAME: "Course Name",
				UNIT_NAME: "Unit Name",
				LESSON_NAME: "Lesson Name",
				RUN_DATE: "Run Date",
				DURATION: "Duration",
				COMPLETED: "Completed",
				STARTING_SCORE: "Starting Score",
				FINAL_SCORE: "Final Score",
				MIN_SCORE: "Min Score",
				MAXIMUM_ATTEMPTS: "Maximum Attempts",
				REPORT_DATA_TITLE: "Report Data",
				REPORT_DATA_NAME: "Name",
				REPORT_DATA_VALUE: "Value",
				SCORING_FACTORS_TITLE: "Scoring Factors",
				SCORING_FACTORS_SIM_TIME: "Sim Time",
				SCORING_FACTORS_NAME: "Name",
				SCORING_FACTORS_OFFSET: "Offset",
				EVENT_HISTORY_TITLE: "Event History",
				EVENT_HISTORY_SIM_TIME: "Sim Time",
				EVENT_HISTORY_NAME: "Name",
				EVENT_HISTORY_DESCRIPTION: "Description",
				COLLISION_OCCURRED_MESSAGE: (severity) => `${severity} Collision Occured`,
			},
		},
		SEVERITY: {
			LOW: "Minor",
			MEDIUM: "Medium",
			HIGH: "Major",
			FATAL: "Fatal",
			VIOLATION: "Critical",
		},
		DOWNLOADS: {
			DOWNLOAD_LAUNCHER: "Download GS Cloud Launcher",
			INSTRUCTIONS: "If the button does not work, copy and paste the url below into a new tab of your browser",
		},
		PERFORMANCE: {
			STUDENTS: "Students",
			DATA_SOURCE: "Data Source",
			DATA_SOURCE_NOT_SUPPORTED: (dataSource) => `${dataSource} is not supported yet`,
			CHART_TYPE: "Chart Type",
			X_AXIS_UNIT: "X Axis Unit",
			SPEED_UNIT: "Speed Unit",
			DATE_RANGE: "Date Range",
			AGGREGATED_DATA_OPTIONS: "Aggregated Data Options",
			COURSE: "Course",
			COURSES: "Courses",
			UNIT: "Unit",
			UNITS: "Units",
			LESSON: "Lesson",
			LESSONS: "Lessons",
			UNIT_SELECT_PLACEHOLDER: "Please select a Course",
			LESSON_SELECT_PLACEHOLDER: "Please select a Unit",
			SELECT_ALL_STUDENTS: "All Students",
			SELECT_ALL_COURSES: "All Courses",
			SELECT_ALL_UNITS: "All Units",
			SELECT_ALL_LESSONS: "All Lessons",
			LOADING_DATA_MESSAGE: "Loading Performance Data",
			NO_DATA_MESSAGE: "No data available",
			BROADEN_FILTERS_MESSAGE: (
				<>
					No data available for your current selection.
					<br />
					Try broadening your filters for more results.
				</>
			),
			DATA_LIMITED_MESSAGE: (count) => `Data limited to ${count} results`,
			IN_TIME_UNITS: (label, timeUnit) => `${label} (${timeUnit}s)`,
			IN_SPEED_UNITS: (label, speedUnit) => `${label} (${speedUnit})`,
			IN_DEGREES: (label) => `${label} (degrees)`,
			CHART_GENERATED_DATE: (date) => `Chart Generated: ${date}`,
			FROM_DATE: "From",
			TO_DATE: "To",
			DATE: "Date",
		},
		DATA_SOURCES: {
			SCORE: "Score",
			SCORED_TIME: "Scored Time",
			ATTEMPTS: "Attempts",
			DURATION: "Duration",
			COLLISIONS: "Collisions",
			COLLISION_VELOCITY: "Collision Velocity",
			MAXIMUM_LOAD_SWING: "Maximum Load Swing",
			VIOLATIONS: "Violations",
			MOVES_PER_HOUR: "Moves Per Hour",
		},
		CHART_TYPES: {
			BAR: "Bar",
			LINE: "Line",
			SCATTERPLOT: "Scatter Plot",
		},
		TIME_UNITS: {
			MINUTE: "Minute",
			HOUR: "Hour",
			DAY: "Day",
		},
		AXIS_UNITS: {
			DATE: "Date",
			LESSON: "Lesson",
		},
		SPEED_UNITS: {
			METERS_PER_SECOND: "m/s",
			FEET_PER_SECOND: "f/s",
			MILES_PER_HOUR: "mph",
			KILOMETERS_PER_HOUR: "kph",
		},
		AGG_DATA_OPTIONS: {
			AVERAGE: "Average",
			MEDIAN: "Median",
			TREND: "Trend",
		},
		PRINT: "Print",
		SAVED: "Saved",
		COLLAPSE: "Collapse",
		CLICK_HERE: "Click Here",
		USER_MANAGEMENT: {
			USERS: "Users",
			FIRST_NAME: "First",
			LAST_NAME: "Last",
			USERNAME: "Username",
			EMAIL_ADDRESS: "Email",
			PASSWORD_CHANGED: "Password successfully reset",
			LANGUAGE_PREFERENCE: "Language Preference",
			UNITS_PREFERENCE: "Units Preference",
			GROUPS: "Groups",
			ACTIVE: "Active",
			ROLE: "Role",
			DATE_CREATED: "Date Created",
			DATE_LAST_MODIFIED: "Date Last Modified",
			DATE_LAST_LOGIN: "Date Last Login",
			SUBSCRIPTION_EXPIRATION_DATE: "Subscription Expiration Date",
			ADD_USER: "Add User",
			USER_ADDED: "User Added",
			EDIT_USER: "Edit User",
			DELETE_USER: "Delete User",
			NO_USERS_MESSAGE: "No Users to show",
			SEARCH: "Search Users",
			SORT_ORDER: "Sort Order",
			ACTIVE_USERS_ONLY: "Active Users Only",
			SELECTED_GROUPS: "Selected Groups",
		},
		GROUP_MANAGEMENT: {
			GROUP_NAME: "Group Name",
			START_DATE: "Start Date",
			END_DATE: "End Date",
			DATE_RANGE: "Date Range",
			ACTIVE: "Active",
			NOTES: "Notes",
			COURSE: "Course",
			COURSES: "Courses",
			GROUPS: "Groups",
			STUDENTS: "Students",
			INSTRUCTORS: "Instructors",
			SELECT_ALL_COURSES: "All Courses",
			SELECT_ALL_STUDENTS: "All Students",
			SELECT_ALL_INSTRUCTORS: "All Instructors",
			ADD_NEW_GROUP: "Add New Group",
			DELETE_GROUP: "Delete Group",
			EDIT_GROUP: "Edit Group",
			NO_GROUPS_MESSAGE: "No Groups to show",
			UNASSIGNED_USERS: "Unassigned Users",
			UNASSIGNED_USER_LIST: "Unassigned User List",
			GROUP_ROSTER: "Group Roster",
			PRINT_GROUP_ROSTER: "Print Group Roster",
			GROUP_ROSTER_PRINT_HEADER: (groupName) => `Group Roster for ${groupName}`,
			ADD_COURSE: "Add Course",
			COURSE_LIST_PRINT_HEADER: (courseName) => `Course List for ${courseName}`,
		},
		ORG_DATA: {
			ASSOCIATED_INSTALLERS: "Associated Installers",
			ASSOCIATED_LAUNCHER_COLLECTION: "Associated Launcher Collection",
			ASSOCIATED_SIMULATOR_COLLECTION: "Associated Simulator Collection",
			ASSOCIATED_SCENARIO_EDITOR_COLLECTION: "Associated Scenario Editor Collection",
			ASSOCIATED_IOS_COLLECTION: "Associated IOS Collection",
			CLOUD_BASED: "Cloud Based",
			RESTRICT_CONNECTIONS_BY_LICENSE: "Restrict Connections By License",
			STUDENT_LICENSES: "Student Licenses",
			INSTRUCTOR_LICENSES: "Instructor Licenses",
		},
		USER_IMPORT: {
			FILE_HEADERS: {
				USERNAME: "Username",
				FIRST_NAME: "First Name",
				LAST_NAME: "Last Name",
				EMAIL_ADDRESS: "Email Address",
				ROLE: "Role",
			},
			IMPORT_USERS: "Import Users",
			EXPORT_USERS: "Export Users",
			DOWNLOAD_TEMPLATE: "Download Template",
			IMPORT_SUCCESSFUL: (importedUserCount, updatedUserCount, createdUserCount, usersAddedToGroupCount, selectedGroups) => (
				<>
					{`Successfully imported ${importedUserCount} user${importedUserCount !== 1 ? "s" : ""}.`}
					<br />
					{`Updated ${updatedUserCount} user${updatedUserCount !== 1 ? "s" : ""}.`}
					<br />
					{`Created ${createdUserCount} user${createdUserCount !== 1 ? "s" : ""}.`}
					<br />
					{`Added ${usersAddedToGroupCount} user${usersAddedToGroupCount !== 1 ? "s" : ""} to selected group${
						selectedGroups.length !== 1 ? "s" : ""
					}.`}
				</>
			),
			ADD_USERS_TO_GROUPS: "Add users to which groups? (Optional)",
		},
		ROLES: {
			SYSTEM: "System",
			STUDENT: "Student",
			INSTRUCTOR: "Instructor",
			ADMIN: "Admin",
			DEVELOPER: "Developer",
			GLOBAL_ADMIN: "Global Admin",
		},
		EMAIL: {
			EMAIL_VERIFIED_MESSAGE: "Your email is verified!",
			EMAIL_VERIFIED_SUBMESSAGE_LOGGED_OUT: "You may now return to the Login Page",
			EMAIL_VERIFIED_SUBMESSAGE_LOGGED_IN: "You may now return to the Dashboard",
			VERIFYING_YOUR_EMAIL: "Verifying your email address",
			VERIFICATION_FAILED: "Verification Failed",
			NEW_LINK_MESSAGE: "Need a new link?",
			NEW_LINK_LOGGED_OUT_MESSAGE: "Please login to request a new verification link",
			RESEND_EMAIL: "Resend Email",
			VERIFICATION_EMAIL_SENT: "Email Sent. Please check your inbox to verify your email address.",
			SEND_VERIFICATION_EMAIL: "Click the button below to send a verification email to your inbox.",
			PASSWORD_RESET_EMAIL_SENT:
				"Password Reset requested. If the username you provided matches an existing account, an email will be sent to that account's associated email address.",
		},
		NOT_APPLICABLE: "N/A",
	});
	this[LANGUAGE_CODES.ENGLISH_US] = this[LANGUAGE_CODES.ENGLISH];
	this[LANGUAGE_CODES.SPANISH] = new Translation({
		MISSING_TRANSLATION: "____FALTA_LA_TRADUCCIÓN____",
		MEASUREMENT_UNITS: {
			METRIC: "Métrico",
			IMPERIAL: "Británico",
		},
		FORMS: {
			SELECT_PLACEHOLDER: "Seleccionar...",
			SELECT_ALL_OPTION: "Todas las opciones",
			NO_OPTIONS: "Sin opciones",
			SUBMIT: "Enviar",
			CANCEL: "Cancelar",
			RESET: "Reajustar",
			SAVE_CHANGES: "Guardar cambios",
			DISCARD_CHANGES: "Descartar cambios",
			SELECT_FILE: "Seleccionar archivo",
			ORGANIZATION_NAME: "Nombre de organización",
			FIRST_NAME: "Nombre",
			LAST_NAME: "Apellido",
			EMAIL_ADDRESS: "Email",
			EMAIL_VERIFIED: "Email verificado",
			USERNAME: "Usuario",
			LANGUAGE_PREFERENCE: "Preferencia de idioma",
			UNITS_PREFERENCE: "Preferencia de unidades",
			PASSWORD: "Contraseña",
			CONFIRM_PASSWORD: "Confirma la contraseña",
			CURRENT_PASSWORD: "Contraseña actual",
			NEW_PASSWORD: "Nueva contraseña",
			CONFIRM_NEW_PASSWORD: "Confirma la nueva contraseña",
			USERNAME_REQUIREMENTS: `El nombre de usuario debe comenzar con una letra, contener entre ${Validate.USERNAME_MIN_LENGTH} y ${Validate.USERNAME_MAX_LENGTH} caracteres y solo debe contener letras, números y/o los siguientes caracteres especiales: -_.@`,
			PASSWORD_REQUIREMENTS: `La contraseña debe tener al menos ${Validate.PASSWORD_MIN_LENGTH} caracteres`,
			FORGOT_PASSWORD: "¿Olvidó la contraseña?",
			FORGOT_USERNAME_OR_PASSWORD: "¿Olvidó su usuario o contraseña?",
			BACK_TO_LOGIN: "Atrás para iniciar sesión",
			BACK_TO_DASHBOARD: "Volver al panel",
			ALREADY_HAVE_AN_ACCOUNT: "¿Ya tienes una cuenta?",
			LOG_IN_INSTEAD: "Iniciar sesión en lugar",
			DONT_HAVE_AN_ACCOUNT: "¿No tienes una cuenta?",
			SIGN_UP_INSTEAD: "Regístrese en lugar",
			PERMANENT_ACTION_WARNING: "¡Advertencia! La acción no se puede deshacer.",
			BACK: "Atrás",
		},
		ERRORS: {
			ERROR: "Error",
			LINE_ERROR: (lineNumber, customMessage = "") => `No se pudo importar la línea ${lineNumber}.${customMessage && ` ${customMessage}`}`,
			CANNOT_CREATE_USER: (lineNumber, customMessage = "") =>
				`No se pudo crear el usuario desde la línea ${lineNumber}.${customMessage && ` ${customMessage}`}`,
			FIELD_EMPTY: (fieldName = null) => (fieldName ? `Falta "${fieldName}"` : `Introduzca un valor`),
			NO_FILE_SELECTED: "Selecciona un archivo para cargar",
			NO_FILE_TO_DOWNLOAD: "No hay archivo disponible",
			DOWNLOADS_COMING_SOON: "¡Descargas próximamente!",
			DUPLICATE_USER: "Usuario duplicado",
			PASSWORD_MATCH: "Las contraseñas no coinciden",
			CONFIRM_PASSWORD: "Por favor, confirme su contraseña",
			EMAIL_INVALID: "Introduce una dirección de correo electrónico válida",
			UNAUTHORIZED: "No autorizado",
			NOT_AUTHORIZED: "No autorizado",
			USERNAME_TAKEN: "Usuario tomado",
			PASSWORD_INVALID: "La contraseña no cumple los requisitos",
			TIMEOUT: "Se agotó el tiempo de espera del servicio durante el inicio de sesión. Inténtalo de nuevo más tarde",
			USER_ABORT: "Solicitud abortada",
			INVALID_CREDS: "Credenciales no válidas",
			SUBSCRIPTION_EXPIRED: "Suscripción caducada",
			TYPE_ERROR: "No se pudo buscar. Consulte los registros para obtener más información",
			CANNOT_DELETE_SELF: "No puede eliminar su propio usuario",
			COLLECTION_ALREADY_EXISTS: "Colección ya existe",
			COLLECTION_PATCHED: "La colección ya existe, pero le faltaban subdirectorios. Estos subdirectorios han sido corregidos",
			INVALID_USERNAME_LENGTH: `El usuario debe tener entre  ${Validate.USERNAME_MIN_LENGTH} y ${Validate.USERNAME_MAX_LENGTH} caracteres`,
			INVALID_USERNAME_START: "El usuario debe comenzar con una letra",
			INVALID_USERNAME_CHARS: "El usuario solo debe contener letras, números y los siguientes caracteres especiales: -_.@",
			INVALID_USERNAME_BLACKLIST: "Por favor elija un nombre de usuario diferente",
			INVALID_PASSWORD_LENGTH: `La contraseña debe tener al menos ${Validate.PASSWORD_MIN_LENGTH} caracteres`,
			INVALID_PASSWORD_BLACKLIST: "Por favor, elija una contraseña diferente",
			INVALID_DATE_RANGE: "La fecha de finalización debe ser posterior a la fecha de inicio",
			MUST_SELECT_INTEGER: "Elija un entero entero",
			EMAIL_ALREADY_VERIFIED: "Dirección de correo electrónico ya verificada",
			INVALID_TOKEN: "Token de verificación no válido",
			TOKEN_EXPIRED: "Enlace caducado. Solicite un nuevo enlace e inténtelo de nuevo.",
		},
		HEADERS: {
			PAGE_NOT_FOUND: "Página no encontrada",
			DOWNLOADS: "Descargas",
			DASHBOARD: "Tablero de control",
			STATISTICS: "Estadísticas",
			GROUPS: "Mi grupos",
			PAYMENTS: "Pagos",
			EVALUATION_HISTORY: "Historial de evaluación",
			PERFORMANCE: "Datos de rendimiento",
			PERFORMANCE_PRINTOUT: (dataType, studentName) => `Datos de ${dataType} para ${studentName}`,
			PROFILE: "Perfil",
			COMPLETE_PROFILE: "Completa tu perfil",
			VERIFY_EMAIL: "Verificar correo electrónico",
			ORG_DATA: "Datos de la organización",
			USER_DATA: "Datos de usuario",
			RESET_PASSWORD: "Cambiar la contraseña",
			REQUEST_USERNAME: "Solicitar usuario",
			SIGN_UP: "Regístrese",
			HELP: "Ayuda",
			SIDE_NAV_INDEX: "Seleccione un tema de las opciones de la izquierda",
			GETTING_STARTED: "Introducción",
			DEVICE_SETUP: "Configuración del dispositivo",
			RUNNING_A_CRANE: "Funcionamiento de una grúa",
			AAR_AND_DRONE_VIEW: "Después de la revisión de la acción",
			WHATS_NEW: "Qué hay de nuevo",
			GROUP_MANAGEMENT: "Gestión de grupos",
			EDIT_GROUP: "Editar detalles del grupo",
			ADD_GROUP: "Agregar grupo",
			GROUP_DETAILS: "Detalles del grupo",
			USER_MANAGEMENT: "Gestión de usuarios",
			EDIT_USER: "Editar usuario",
			USER_DETAILS: "Datos del usuario",
			ADD_USER: "Agregar usuario",
			PRIVACY_POLICY: "Política de privacidad",
			TERMS_OF_SERVICE: "Términos de servicio",
			DATA_RETENTION_POLICY: "Política de retención de datos",
		},
		NAVLINKS: {
			DOWNLOADS: "Descargas",
			DASHBOARD: "Tablero de control",
			PERFORMANCE: "Rendimiento",
			PROFILE: "Perfil",
			HELP: "Ayuda",
			WHATS_NEW: "Qué hay de nuevo",
			GROUP_MANAGEMENT: "Gestión de grupos",
			USER_MANAGEMENT: "Gestión de usuarios",
			DEVELOPER: "Panel del desarrollador",
			LOG_IN: "Iniciar sesión",
			SIGN_UP: "Regístrese",
			LOG_OUT: "Cerrar sesión",
			COMPLETE_PROFILE: "Perfil completo",
			VERIFY_EMAIL: "Verificar correo electrónico",
			PASSWORD_RESET: "Restablecimiento de contraseña",
			CONTACT: "Contactarnos",
			ABOUT: "Nosotros",
			SIMULATORS: "Simulador",
			MODELS: "Modelos",
			INDUSTRIES: "Industrias",
			MEDIA: "Cobertura informativa",
			BLOG: "Blog",
			PRIVACY_POLICY: "Política de privacidad",
			TERMS_OF_SERVICE: "Términos de servicio",
			DATA_RETENTION_POLICY: "Política de retención de datos",
		},
		FOOTER: {
			LAST_LOGIN: "Último inicio de sesión",
			SIMULATORS: "Simuladores",
			CLOUD: "Cloud",
			ESSENTIAL: "Esencial",
			FULL_MISSION: "Misión completa",
			ADVANCED: "Avanzado",
			COORDINATOR_TEST_SYSTEM: "Sistema de prueba del coordinador",
			RO_RO: "Ro-ro",
			REMOTE_STATION: "Estación remota",
			HOURS: "Horas",
			MONDAY_TO_FRIDAY: "Lunes - Viernes",
			WEEKDAY_HOURS: "7:30am a 4:30pm",
			MOUNTAIN_TIME: "Hora de la montaña",
			SATURDAY_TO_SUNDAY: "Sábado - Domingo",
			CLOSED: "Cerrado",
			PHONE: "Teléfono",
			ALL_RIGHTS_RESERVED: "Todos los derechos reservados",
		},
		DASHBOARD: {
			STATS: {
				ORGANIZATION_NAME: "Nombre de organización",
				COURSES_ENROLLED_IN: "Cursos matriculados en",
				ALL_COURSES: "Todos los cursos",
				COURSE_TOTALS: "Totales por curso",
				GROUP_STATS: "Estadísticas del grupo",
				NEXT_LESSON: "Siguiente lección",
				RANK: "Categoría",
			},
			EVAL_HISTORY: {
				COMPLETED: "Completado",
				COURSES: "Cursos",
				COURSE: "Curso",
				PERCENT_COMPLETE: "% completo",
				TIME_SPENT: "Tiempo",
				TIME_REMAINING: "Tiempo restante estimado",
				UNIT: "Módulo",
				LESSONS: "Lecciónes",
				LESSON: "Lección",
				BEST_SCORE: "Mejor puntuación",
				LAST_SCORE: "Última puntuación",
				LAST_ATTEMPT: "Último intento",
				NUMBER_OF_ATTEMPTS: "Intentos",
				EVALUATION_HISTORY: "Historial de evaluación",
				DATE: "Fecha",
				TIME: "Hora",
				ATTEMPT: "Intento",
				SCORE: "Puntuación",
				NO_DATA_MESSAGE: "No hay entradas para mostrar",
				RANK: "Categoría",
			},
			EVAL_REPORT: {
				TITLE: "Informe de evaluación",
				FIRST_NAME: "Nombre",
				LAST_NAME: "Apellido",
				USERNAME: "Usuario",
				COURSE_NAME: "Nombre del curso",
				UNIT_NAME: "Nombre del módulo",
				LESSON_NAME: "Nombre del lección",
				RUN_DATE: "Fecha de ejecución",
				DURATION: "Duración",
				COMPLETED: "Completo",
				STARTING_SCORE: "Puntuación inicial",
				FINAL_SCORE: "Puntuación final",
				MIN_SCORE: "Puntuación mínima",
				MAXIMUM_ATTEMPTS: "Intentos máximos",
				REPORT_DATA_TITLE: "Datos de informe",
				REPORT_DATA_NAME: "Nombre",
				REPORT_DATA_VALUE: "Valor",
				SCORING_FACTORS_TITLE: "Factores de puntuación",
				SCORING_FACTORS_SIM_TIME: "Hora de simulación",
				SCORING_FACTORS_NAME: "Nombre",
				SCORING_FACTORS_OFFSET: "Compensación",
				EVENT_HISTORY_TITLE: "Historial de eventos",
				EVENT_HISTORY_SIM_TIME: "Hora de simulación",
				EVENT_HISTORY_NAME: "Nombre",
				EVENT_HISTORY_DESCRIPTION: "Descripción",
				COLLISION_OCCURRED_MESSAGE: (severity) => `Se produjo una colisión ${severity}`,
			},
		},
		SEVERITY: {
			LOW: "Menor",
			MEDIUM: "Mediano",
			HIGH: "Mayor",
			FATAL: "Fatal",
			VIOLATION: "Crítica",
		},
		DOWNLOADS: {
			DOWNLOAD_LAUNCHER: "Descargar GS Cloud iniciador",
			INSTRUCTIONS: "Si el botón no funciona, copie y pegue la URL a continuación en una nueva pestaña de su navegador",
		},
		PERFORMANCE: {
			STUDENTS: "Estudiantes",
			DATA_SOURCE: "Fuente de datos",
			DATA_SOURCE_NOT_SUPPORTED: (dataSource) => `${dataSource} aún no es compatible`,
			CHART_TYPE: "Tipo de gráfico",
			X_AXIS_UNIT: "Unidad del eje X",
			SPEED_UNIT: "Unidad de velocidad",
			DATE_RANGE: "Intervalo de fechas",
			AGGREGATED_DATA_OPTIONS: "Opciones de datos agregados",
			COURSE: "Curso",
			COURSES: "Cursos",
			UNIT: "Módulo",
			UNITS: "Módulos",
			LESSON: "Lección",
			LESSONS: "Lecciónes",
			UNIT_SELECT_PLACEHOLDER: "Por favor, seleccione un curso",
			LESSON_SELECT_PLACEHOLDER: "Por favor, seleccione un módulo",
			SELECT_ALL_STUDENTS: "Todos los estudiantes",
			SELECT_ALL_COURSES: "Todos los cursos",
			SELECT_ALL_UNITS: "Todos los módulos",
			SELECT_ALL_LESSONS: "Todos los lecciónes",
			LOADING_DATA_MESSAGE: "Carga de datos de rendimiento",
			NO_DATA_MESSAGE: "No hay datos disponibles",
			BROADEN_FILTERS_MESSAGE: (
				<>
					No hay datos disponibles para su selección actual.
					<br />
					Prueba a ampliar los filtros para obtener más resultados.
				</>
			),
			DATA_LIMITED_MESSAGE: (count) => `Datos limitados a ${count} resultados`,
			IN_TIME_UNITS: (label, timeUnit) => `${label} (${timeUnit}s)`,
			IN_SPEED_UNITS: (label, speedUnit) => `${label} (${speedUnit})`,
			IN_DEGREES: (label) => `${label} (grados)`,
			CHART_GENERATED_DATE: (date) => `Gráfico generado: ${date}`,
			FROM_DATE: "De",
			TO_DATE: "A",
			DATE: "Fecha",
		},
		DATA_SOURCES: {
			SCORE: "Puntuación",
			SCORED_TIME: "Puntuación de tiempoo",
			ATTEMPTS: "Intentos",
			DURATION: "Duración",
			COLLISIONS: "Colisiónes",
			COLLISION_VELOCITY: "Velocidad de colisión",
			MAXIMUM_LOAD_SWING: "Oscilación de carga máxima",
			VIOLATIONS: "Violaciones",
			MOVES_PER_HOUR: "Mudanzas por hora",
		},
		CHART_TYPES: {
			BAR: "Barra",
			LINE: "Línea",
			SCATTERPLOT: "Dispersión",
		},
		TIME_UNITS: {
			MINUTE: "Minuto",
			HOUR: "Hora",
			DAY: "Día",
		},
		AXIS_UNITS: {
			DATE: "Fecha",
			LESSON: "Lección",
		},
		SPEED_UNITS: {
			METERS_PER_SECOND: "m/s",
			FEET_PER_SECOND: "f/s",
			MILES_PER_HOUR: "mph",
			KILOMETERS_PER_HOUR: "kph",
		},
		AGG_DATA_OPTIONS: {
			AVERAGE: "Promedio",
			MEDIAN: "Mediana",
			TREND: "Tendencia",
		},
		PRINT: "Imprimir",
		SAVED: "Guardará",
		COLLAPSE: "Contraer",
		CLICK_HERE: "Haga clic aquí",
		USER_MANAGEMENT: {
			USERS: "Usuarios",
			FIRST_NAME: "Nombre",
			LAST_NAME: "Apellido",
			USERNAME: "Usuario",
			EMAIL_ADDRESS: "Email",
			PASSWORD_CHANGED: "Contraseña restablecer correctamente",
			LANGUAGE_PREFERENCE: "Preferencia de idioma",
			UNITS_PREFERENCE: "Preferencia de unidades",
			GROUPS: "Grupos",
			ACTIVE: "Activo",
			ROLE: "Papel",
			DATE_CREATED: "Fecha de creación",
			DATE_LAST_MODIFIED: "Fecha de la última modificación",
			DATE_LAST_LOGIN: "Última fecha de inicio de sesión",
			SUBSCRIPTION_EXPIRATION_DATE: "Fecha de vencimiento de la suscripción",
			ADD_USER: "Agregar usuario",
			USER_ADDED: "Usuario agregado",
			EDIT_USER: "Editar usuario",
			DELETE_USER: "Borrar usuario",
			NO_USERS_MESSAGE: "No hay usuarios para mostrar",
			SEARCH: "Búsqueda",
			SORT_ORDER: "Orden de ordenación",
			ACTIVE_USERS_ONLY: "Solo usuarios activos",
			SELECTED_GROUPS: "Grupos seleccionados",
		},
		GROUP_MANAGEMENT: {
			GROUP_NAME: "Nombre del grupo",
			START_DATE: "Fecha de inicio",
			END_DATE: "Fecha de finalización",
			DATE_RANGE: "Intervalo de fechas",
			ACTIVE: "Activo",
			NOTES: "Notas",
			COURSE: "Curso",
			COURSES: "Cursos",
			GROUPS: "Grupos",
			STUDENTS: "Estudiantes",
			INSTRUCTORS: "Instructores",
			SELECT_ALL_COURSES: "Todos los cursos",
			SELECT_ALL_STUDENTS: "Todos los estudiantes",
			SELECT_ALL_INSTRUCTORS: "Todos los instructores",
			ADD_NEW_GROUP: "Agregar nuevo grupo",
			DELETE_GROUP: "Borrar grupo",
			EDIT_GROUP: "Editar grupo",
			NO_GROUPS_MESSAGE: "No hay grupos para mostrar",
			UNASSIGNED_USERS: "Usuarios sin asignar",
			UNASSIGNED_USER_LIST: "Lista de usuarios sin asignar",
			GROUP_ROSTER: "lista de usuarios",
			PRINT_GROUP_ROSTER: "Imprimir lista de usuarios para el grupo",
			GROUP_ROSTER_PRINT_HEADER: (groupName) => `Lista de usuarios para ${groupName}`,
			ADD_COURSE: "Agregar curso",
			COURSE_LIST_PRINT_HEADER: (groupName) => `Lista de cursos para ${groupName}`,
		},
		ORG_DATA: {
			ASSOCIATED_INSTALLERS: "Instaladores asociados",
			ASSOCIATED_LAUNCHER_COLLECTION: "Colección de iniciadores asociada",
			ASSOCIATED_SIMULATOR_COLLECTION: "Colección de simuladores asociados",
			ASSOCIATED_SCENARIO_EDITOR_COLLECTION: "Colección del editor de escenarios asociados",
			ASSOCIATED_IOS_COLLECTION: "Colección de estaciones de instructor asociadas",
			CLOUD_BASED: "Basado en la nube",
			RESTRICT_CONNECTIONS_BY_LICENSE: "Restringir conexiones por licencia",
			STUDENT_LICENSES: "Licencias de estudiante",
			INSTRUCTOR_LICENSES: "Licencias de instructor",
		},
		USER_IMPORT: {
			FILE_HEADERS: {
				USERNAME: "Usuario",
				FIRST_NAME: "Nombre",
				LAST_NAME: "Apellido",
				EMAIL_ADDRESS: "Email",
				ROLE: "Papel",
			},
			IMPORT_USERS: "Importar los usuarios",
			EXPORT_USERS: "Exportar los usuarios",
			DOWNLOAD_TEMPLATE: "Descargar plantilla",
			IMPORT_SUCCESSFUL: (importedUserCount, updatedUserCount, createdUserCount, usersAddedToGroupCount, selectedGroups) => (
				<>
					{`${importedUserCount} usuario${importedUserCount !== 1 ? "s" : ""} importado${
						importedUserCount !== 1 ? "s" : ""
					} correctamente.`}
					<br />
					{`Se han actualizado ${updatedUserCount} usuario${updatedUserCount !== 1 ? "s" : ""} existente${
						updatedUserCount !== 1 ? "s" : ""
					}.`}
					<br />
					{`Creado ${createdUserCount} usuario${createdUserCount !== 1 ? "s" : ""}.`}
					<br />
					{`Se ${
						usersAddedToGroupCount === 1 ? `agregó ${usersAddedToGroupCount} usuario` : `agregaron ${usersAddedToGroupCount} usuarios`
					} ${selectedGroups.length === 1 ? "al grupo seleccionado" : "a los grupos seleccionados"}.`}
				</>
			),
			ADD_USERS_TO_GROUPS: "¿Agregar usuarios a qué grupos? (Opcional)",
		},
		ROLES: {
			SYSTEM: "Sistema",
			STUDENT: "Estudiante",
			INSTRUCTOR: "Instructor",
			ADMIN: "Administrador",
			DEVELOPER: "Desarrollador",
			GLOBAL_ADMIN: "Administrador global",
		},
		EMAIL: {
			EMAIL_VERIFIED_MESSAGE: "¡Su correo electrónico está verificado!",
			EMAIL_VERIFIED_SUBMESSAGE_LOGGED_OUT: "Ahora puede volver a la página de inicio de sesión",
			EMAIL_VERIFIED_SUBMESSAGE_LOGGED_IN: "Ahora puede volver al panel de control",
			VERIFYING_YOUR_EMAIL: "Verificación de su dirección de correo electrónico",
			VERIFICATION_FAILED: "Error de verificación",
			NEW_LINK_MESSAGE: "¿Necesitas un nuevo enlace?",
			NEW_LINK_LOGGED_OUT_MESSAGE: "Inicie sesión para solicitar un nuevo enlace de verificación",
			RESEND_EMAIL: "Reenviar correo electrónico",
			VERIFICATION_EMAIL_SENT: "Correo electrónico enviado. Revise su bandeja de entrada para verificar su dirección de correo electrónico.",
			SEND_VERIFICATION_EMAIL: "Haga clic en el botón de abajo para enviar un correo electrónico de verificación a su bandeja de entrada.",
			PASSWORD_RESET_EMAIL_SENT:
				"Se solicita el restablecimiento de contraseña. Si el nombre de usuario que proporcionó coincide con una cuenta existente, se enviará un correo electrónico a la dirección de correo electrónico asociada a esa cuenta.",
		},
		NOT_APPLICABLE: "n/a",
	});
	this[LANGUAGE_CODES.FINNISH] = new Translation({});
	this[LANGUAGE_CODES.FRENCH] = new Translation({ MISSING_TRANSLATION: "____TRADUCTION_MANQUANTE____" });
	this[LANGUAGE_CODES.HEBREW] = new Translation({});
	this[LANGUAGE_CODES.HINDI] = new Translation({});
	this[LANGUAGE_CODES.INDONESIAN] = new Translation({});
	this[LANGUAGE_CODES.ITALIAN] = new Translation({});
	this[LANGUAGE_CODES.JAPANESE] = new Translation({ MISSING_TRANSLATION: "____翻訳がありません____" });
	this[LANGUAGE_CODES.KOREAN] = new Translation({});
	this[LANGUAGE_CODES.DUTCH] = new Translation({});
	this[LANGUAGE_CODES.NORWEIGAN] = new Translation({});
	this[LANGUAGE_CODES.PORTUGUESE] = new Translation({});
	this[LANGUAGE_CODES.RUSSIAN] = new Translation({});
	this[LANGUAGE_CODES.SWEDISH] = new Translation({});
	this[LANGUAGE_CODES.THAI] = new Translation({});
	this[LANGUAGE_CODES.VIETNAMESE] = new Translation({});
	this[LANGUAGE_CODES.CHINESE] = new Translation({});
	//#endregion

	//#region shortcuts to avoid passing lang code
	const currentLanguage = this[globalState.locale];
	this.MISSING_TRANSLATION = currentLanguage.MISSING_TRANSLATION;
	this.LANGUAGES = currentLanguage.LANGUAGES;
	this.MEASUREMENT_UNITS = {
		METRIC: currentLanguage.MEASUREMENT_UNITS.METRIC,
		IMPERIAL: currentLanguage.MEASUREMENT_UNITS.IMPERIAL,
	};
	this.FORMS = {
		SELECT_PLACEHOLDER: currentLanguage.FORMS.SELECT_PLACEHOLDER,
		SELECT_ALL_OPTION: currentLanguage.FORMS.SELECT_ALL_OPTION,
		NO_OPTIONS: currentLanguage.FORMS.NO_OPTIONS,
		ORGANIZATION_NAME: currentLanguage.FORMS.ORGANIZATION_NAME,
		FIRST_NAME: currentLanguage.FORMS.FIRST_NAME,
		LAST_NAME: currentLanguage.FORMS.LAST_NAME,
		EMAIL_ADDRESS: currentLanguage.FORMS.EMAIL_ADDRESS,
		EMAIL_VERIFIED: currentLanguage.FORMS.EMAIL_VERIFIED,
		USERNAME: currentLanguage.FORMS.USERNAME,
		LANGUAGE_PREFERENCE: currentLanguage.FORMS.LANGUAGE_PREFERENCE,
		UNITS_PREFERENCE: currentLanguage.FORMS.UNITS_PREFERENCE,
		PASSWORD: currentLanguage.FORMS.PASSWORD,
		CONFIRM_PASSWORD: currentLanguage.FORMS.CONFIRM_PASSWORD,
		CURRENT_PASSWORD: currentLanguage.FORMS.CURRENT_PASSWORD,
		NEW_PASSWORD: currentLanguage.FORMS.NEW_PASSWORD,
		CONFIRM_NEW_PASSWORD: currentLanguage.FORMS.CONFIRM_NEW_PASSWORD,
		USERNAME_REQUIREMENTS: currentLanguage.FORMS.USERNAME_REQUIREMENTS,
		PASSWORD_REQUIREMENTS: currentLanguage.FORMS.PASSWORD_REQUIREMENTS,
		SUBMIT: currentLanguage.FORMS.SUBMIT,
		CANCEL: currentLanguage.FORMS.CANCEL,
		RESET: currentLanguage.FORMS.RESET,
		SAVE_CHANGES: currentLanguage.FORMS.SAVE_CHANGES,
		DISCARD_CHANGES: currentLanguage.FORMS.DISCARD_CHANGES,
		SELECT_FILE: currentLanguage.FORMS.SELECT_FILE,
		FORGOT_PASSWORD: currentLanguage.FORMS.FORGOT_PASSWORD,
		FORGOT_USERNAME_OR_PASSWORD: currentLanguage.FORMS.FORGOT_USERNAME_OR_PASSWORD,
		BACK_TO_LOGIN: currentLanguage.FORMS.BACK_TO_LOGIN,
		BACK_TO_DASHBOARD: currentLanguage.FORMS.BACK_TO_DASHBOARD,
		ALREADY_HAVE_AN_ACCOUNT: currentLanguage.FORMS.ALREADY_HAVE_AN_ACCOUNT,
		LOG_IN_INSTEAD: currentLanguage.FORMS.LOG_IN_INSTEAD,
		DONT_HAVE_AN_ACCOUNT: currentLanguage.FORMS.DONT_HAVE_AN_ACCOUNT,
		SIGN_UP_INSTEAD: currentLanguage.FORMS.SIGN_UP_INSTEAD,
		PERMANENT_ACTION_WARNING: currentLanguage.FORMS.PERMANENT_ACTION_WARNING,
		BACK: currentLanguage.FORMS.BACK,
	};
	this.ERRORS = {
		ERROR: currentLanguage.ERRORS.ERROR,
		LINE_ERROR: currentLanguage.ERRORS.LINE_ERROR,
		CANNOT_CREATE_USER: currentLanguage.ERRORS.CANNOT_CREATE_USER,
		FIELD_EMPTY: currentLanguage.ERRORS.FIELD_EMPTY,
		NO_FILE_SELECTED: currentLanguage.ERRORS.NO_FILE_SELECTED,
		NO_FILE_TO_DOWNLOAD: currentLanguage.ERRORS.NO_FILE_TO_DOWNLOAD,
		DOWNLOADS_COMING_SOON: currentLanguage.ERRORS.DOWNLOADS_COMING_SOON,
		DUPLICATE_USER: currentLanguage.ERRORS.DUPLICATE_USER,
		PASSWORD_MATCH: currentLanguage.ERRORS.PASSWORD_MATCH,
		CONFIRM_PASSWORD: currentLanguage.ERRORS.CONFIRM_PASSWORD,
		EMAIL_INVALID: currentLanguage.ERRORS.EMAIL_INVALID,
		UNAUTHORIZED: currentLanguage.ERRORS.UNAUTHORIZED,
		NOT_AUTHORIZED: currentLanguage.ERRORS.NOT_AUTHORIZED,
		USERNAME_TAKEN: currentLanguage.ERRORS.USERNAME_TAKEN,
		PASSWORD_INVALID: currentLanguage.ERRORS.PASSWORD_INVALID,
		TIMEOUT: currentLanguage.ERRORS.TIMEOUT,
		USER_ABORT: currentLanguage.ERRORS.USER_ABORT,
		INVALID_CREDS: currentLanguage.ERRORS.INVALID_CREDS,
		SUBSCRIPTION_EXPIRED: currentLanguage.ERRORS.SUBSCRIPTION_EXPIRED,
		TYPE_ERROR: currentLanguage.ERRORS.TYPE_ERROR,
		CANNOT_DELETE_SELF: currentLanguage.ERRORS.CANNOT_DELETE_SELF,
		COLLECTION_ALREADY_EXISTS: currentLanguage.ERRORS.COLLECTION_ALREADY_EXISTS,
		COLLECTION_PATCHED: currentLanguage.ERRORS.COLLECTION_PATCHED,
		INVALID_USERNAME_LENGTH: currentLanguage.ERRORS.INVALID_USERNAME_LENGTH,
		INVALID_USERNAME_START: currentLanguage.ERRORS.INVALID_USERNAME_START,
		INVALID_USERNAME_CHARS: currentLanguage.ERRORS.INVALID_USERNAME_CHARS,
		INVALID_USERNAME_BLACKLIST: currentLanguage.ERRORS.INVALID_USERNAME_BLACKLIST,
		INVALID_PASSWORD_LENGTH: currentLanguage.ERRORS.INVALID_PASSWORD_LENGTH,
		INVALID_PASSWORD_BLACKLIST: currentLanguage.ERRORS.INVALID_PASSWORD_BLACKLIST,
		INVALID_DATE_RANGE: currentLanguage.ERRORS.INVALID_DATE_RANGE,
		MUST_SELECT_INTEGER: currentLanguage.ERRORS.MUST_SELECT_INTEGER,
		EMAIL_ALREADY_VERIFIED: currentLanguage.ERRORS.EMAIL_ALREADY_VERIFIED,
		INVALID_TOKEN: currentLanguage.ERRORS.INVALID_TOKEN,
		TOKEN_EXPIRED: currentLanguage.ERRORS.TOKEN_EXPIRED,
	};
	this.HEADERS = {
		PAGE_NOT_FOUND: currentLanguage.HEADERS.PAGE_NOT_FOUND,
		DOWNLOADS: currentLanguage.HEADERS.DOWNLOADS,
		DASHBOARD: currentLanguage.HEADERS.DASHBOARD,
		STATISTICS: currentLanguage.HEADERS.STATISTICS,
		GROUPS: currentLanguage.HEADERS.GROUPS,
		PAYMENTS: currentLanguage.HEADERS.PAYMENTS,
		EVALUATION_HISTORY: currentLanguage.HEADERS.EVALUATION_HISTORY,
		PERFORMANCE: currentLanguage.HEADERS.PERFORMANCE,
		PERFORMANCE_PRINTOUT: currentLanguage.HEADERS.PERFORMANCE_PRINTOUT,
		PROFILE: currentLanguage.HEADERS.PROFILE,
		COMPLETE_PROFILE: currentLanguage.HEADERS.COMPLETE_PROFILE,
		VERIFY_EMAIL: currentLanguage.HEADERS.VERIFY_EMAIL,
		ORG_DATA: currentLanguage.HEADERS.ORG_DATA,
		USER_DATA: currentLanguage.HEADERS.USER_DATA,
		RESET_PASSWORD: currentLanguage.HEADERS.RESET_PASSWORD,
		REQUEST_USERNAME: currentLanguage.HEADERS.REQUEST_USERNAME,
		SIGN_UP: currentLanguage.HEADERS.SIGN_UP,
		HELP: currentLanguage.HEADERS.HELP,
		SIDE_NAV_INDEX: currentLanguage.HEADERS.SIDE_NAV_INDEX,
		GETTING_STARTED: currentLanguage.HEADERS.GETTING_STARTED,
		DEVICE_SETUP: currentLanguage.HEADERS.DEVICE_SETUP,
		RUNNING_A_CRANE: currentLanguage.HEADERS.RUNNING_A_CRANE,
		AAR_AND_DRONE_VIEW: currentLanguage.HEADERS.AAR_AND_DRONE_VIEW,
		WHATS_NEW: currentLanguage.HEADERS.WHATS_NEW,
		GROUP_MANAGEMENT: currentLanguage.HEADERS.GROUP_MANAGEMENT,
		EDIT_GROUP: currentLanguage.HEADERS.EDIT_GROUP,
		ADD_GROUP: currentLanguage.HEADERS.ADD_GROUP,
		GROUP_DETAILS: currentLanguage.HEADERS.GROUP_DETAILS,
		USER_MANAGEMENT: currentLanguage.HEADERS.USER_MANAGEMENT,
		EDIT_USER: currentLanguage.HEADERS.EDIT_USER,
		USER_DETAILS: currentLanguage.HEADERS.USER_DETAILS,
		ADD_USER: currentLanguage.HEADERS.ADD_USER,
		PRIVACY_POLICY: currentLanguage.HEADERS.PRIVACY_POLICY,
		TERMS_OF_SERVICE: currentLanguage.HEADERS.TERMS_OF_SERVICE,
		DATA_RETENTION_POLICY: currentLanguage.HEADERS.DATA_RETENTION_POLICY,
	};
	this.NAVLINKS = {
		DOWNLOADS: currentLanguage.NAVLINKS.DOWNLOADS,
		DASHBOARD: currentLanguage.NAVLINKS.DASHBOARD,
		PERFORMANCE: currentLanguage.NAVLINKS.PERFORMANCE,
		PROFILE: currentLanguage.NAVLINKS.PROFILE,
		HELP: currentLanguage.NAVLINKS.HELP,
		WHATS_NEW: currentLanguage.NAVLINKS.WHATS_NEW,
		GROUP_MANAGEMENT: currentLanguage.NAVLINKS.GROUP_MANAGEMENT,
		USER_MANAGEMENT: currentLanguage.NAVLINKS.USER_MANAGEMENT,
		DEVELOPER: currentLanguage.NAVLINKS.DEVELOPER,
		LOG_IN: currentLanguage.NAVLINKS.LOG_IN,
		SIGN_UP: currentLanguage.NAVLINKS.SIGN_UP,
		LOG_OUT: currentLanguage.NAVLINKS.LOG_OUT,
		COMPLETE_PROFILE: currentLanguage.NAVLINKS.COMPLETE_PROFILE,
		VERIFY_EMAIL: currentLanguage.NAVLINKS.VERIFY_EMAIL,
		PASSWORD_RESET: currentLanguage.NAVLINKS.PASSWORD_RESET,
		CONTACT: currentLanguage.NAVLINKS.CONTACT,
		ABOUT: currentLanguage.NAVLINKS.ABOUT,
		SIMULATORS: currentLanguage.NAVLINKS.SIMULATORS,
		MODELS: currentLanguage.NAVLINKS.MODELS,
		INDUSTRIES: currentLanguage.NAVLINKS.INDUSTRIES,
		MEDIA: currentLanguage.NAVLINKS.MEDIA,
		BLOG: currentLanguage.NAVLINKS.BLOG,
		PRIVACY_POLICY: currentLanguage.NAVLINKS.PRIVACY_POLICY,
		TERMS_OF_SERVICE: currentLanguage.NAVLINKS.TERMS_OF_SERVICE,
		DATA_RETENTION_POLICY: currentLanguage.NAVLINKS.DATA_RETENTION_POLICY,
	};
	this.FOOTER = {
		LAST_LOGIN: currentLanguage.FOOTER.LAST_LOGIN,
		SIMULATORS: currentLanguage.FOOTER.SIMULATORS,
		CLOUD: currentLanguage.FOOTER.CLOUD,
		ESSENTIAL: currentLanguage.FOOTER.ESSENTIAL,
		FULL_MISSION: currentLanguage.FOOTER.FULL_MISSION,
		ADVANCED: currentLanguage.FOOTER.ADVANCED,
		COORDINATOR_TEST_SYSTEM: currentLanguage.FOOTER.COORDINATOR_TEST_SYSTEM,
		RO_RO: currentLanguage.FOOTER.RO_RO,
		REMOTE_STATION: currentLanguage.FOOTER.REMOTE_STATION,
		HOURS: currentLanguage.FOOTER.HOURS,
		MONDAY_TO_FRIDAY: currentLanguage.FOOTER.MONDAY_TO_FRIDAY,
		WEEKDAY_HOURS: currentLanguage.FOOTER.WEEKDAY_HOURS,
		MOUNTAIN_TIME: currentLanguage.FOOTER.MOUNTAIN_TIME,
		SATURDAY_TO_SUNDAY: currentLanguage.FOOTER.SATURDAY_TO_SUNDAY,
		CLOSED: currentLanguage.FOOTER.CLOSED,
		PHONE: currentLanguage.FOOTER.PHONE,
		ALL_RIGHTS_RESERVED: currentLanguage.FOOTER.ALL_RIGHTS_RESERVED,
	};
	this.DASHBOARD = {
		STATS: {
			ORGANIZATION_NAME: currentLanguage.DASHBOARD.STATS.ORGANIZATION_NAME,
			COURSES_ENROLLED_IN: currentLanguage.DASHBOARD.STATS.COURSES_ENROLLED_IN,
			ALL_COURSES: currentLanguage.DASHBOARD.STATS.ALL_COURSES,
			COURSE_TOTALS: currentLanguage.DASHBOARD.STATS.COURSE_TOTALS,
			GROUP_STATS: currentLanguage.DASHBOARD.STATS.GROUP_STATS,
			NEXT_LESSON: currentLanguage.DASHBOARD.STATS.NEXT_LESSON,
			RANK: currentLanguage.DASHBOARD.STATS.RANK,
		},
		EVAL_HISTORY: {
			COMPLETED: currentLanguage.DASHBOARD.EVAL_HISTORY.COMPLETED,
			COURSES: currentLanguage.DASHBOARD.EVAL_HISTORY.COURSES,
			COURSE: currentLanguage.DASHBOARD.EVAL_HISTORY.COURSE,
			PERCENT_COMPLETE: currentLanguage.DASHBOARD.EVAL_HISTORY.PERCENT_COMPLETE,
			TIME_SPENT: currentLanguage.DASHBOARD.EVAL_HISTORY.TIME_SPENT,
			TIME_REMAINING: currentLanguage.DASHBOARD.EVAL_HISTORY.TIME_REMAINING,
			UNIT: currentLanguage.DASHBOARD.EVAL_HISTORY.UNIT,
			LESSONS: currentLanguage.DASHBOARD.EVAL_HISTORY.LESSONS,
			LESSON: currentLanguage.DASHBOARD.EVAL_HISTORY.LESSON,
			BEST_SCORE: currentLanguage.DASHBOARD.EVAL_HISTORY.BEST_SCORE,
			LAST_SCORE: currentLanguage.DASHBOARD.EVAL_HISTORY.LAST_SCORE,
			LAST_ATTEMPT: currentLanguage.DASHBOARD.EVAL_HISTORY.LAST_ATTEMPT,
			NUMBER_OF_ATTEMPTS: currentLanguage.DASHBOARD.EVAL_HISTORY.NUMBER_OF_ATTEMPTS,
			EVALUATION_HISTORY: currentLanguage.DASHBOARD.EVAL_HISTORY.EVALUATION_HISTORY,
			DATE: currentLanguage.DASHBOARD.EVAL_HISTORY.DATE,
			TIME: currentLanguage.DASHBOARD.EVAL_HISTORY.TIME,
			ATTEMPT: currentLanguage.DASHBOARD.EVAL_HISTORY.ATTEMPT,
			SCORE: currentLanguage.DASHBOARD.EVAL_HISTORY.SCORE,
			NO_DATA_MESSAGE: currentLanguage.DASHBOARD.EVAL_HISTORY.NO_DATA_MESSAGE,
			RANK: currentLanguage.DASHBOARD.EVAL_HISTORY.RANK,
		},
		EVAL_REPORT: {
			TITLE: currentLanguage.DASHBOARD.EVAL_REPORT.TITLE,
			FIRST_NAME: currentLanguage.DASHBOARD.EVAL_REPORT.FIRST_NAME,
			LAST_NAME: currentLanguage.DASHBOARD.EVAL_REPORT.LAST_NAME,
			USERNAME: currentLanguage.DASHBOARD.EVAL_REPORT.USERNAME,
			COURSE_NAME: currentLanguage.DASHBOARD.EVAL_REPORT.COURSE_NAME,
			UNIT_NAME: currentLanguage.DASHBOARD.EVAL_REPORT.UNIT_NAME,
			LESSON_NAME: currentLanguage.DASHBOARD.EVAL_REPORT.LESSON_NAME,
			RUN_DATE: currentLanguage.DASHBOARD.EVAL_REPORT.RUN_DATE,
			DURATION: currentLanguage.DASHBOARD.EVAL_REPORT.DURATION,
			COMPLETED: currentLanguage.DASHBOARD.EVAL_REPORT.COMPLETED,
			STARTING_SCORE: currentLanguage.DASHBOARD.EVAL_REPORT.STARTING_SCORE,
			FINAL_SCORE: currentLanguage.DASHBOARD.EVAL_REPORT.FINAL_SCORE,
			MIN_SCORE: currentLanguage.DASHBOARD.EVAL_REPORT.MIN_SCORE,
			MAXIMUM_ATTEMPTS: currentLanguage.DASHBOARD.EVAL_REPORT.MAXIMUM_ATTEMPTS,
			REPORT_DATA_TITLE: currentLanguage.DASHBOARD.EVAL_REPORT.REPORT_DATA_TITLE,
			REPORT_DATA_NAME: currentLanguage.DASHBOARD.EVAL_REPORT.REPORT_DATA_NAME,
			REPORT_DATA_VALUE: currentLanguage.DASHBOARD.EVAL_REPORT.REPORT_DATA_VALUE,
			SCORING_FACTORS_TITLE: currentLanguage.DASHBOARD.EVAL_REPORT.SCORING_FACTORS_TITLE,
			SCORING_FACTORS_SIM_TIME: currentLanguage.DASHBOARD.EVAL_REPORT.SCORING_FACTORS_SIM_TIME,
			SCORING_FACTORS_NAME: currentLanguage.DASHBOARD.EVAL_REPORT.SCORING_FACTORS_NAME,
			SCORING_FACTORS_OFFSET: currentLanguage.DASHBOARD.EVAL_REPORT.SCORING_FACTORS_OFFSET,
			EVENT_HISTORY_TITLE: currentLanguage.DASHBOARD.EVAL_REPORT.EVENT_HISTORY_TITLE,
			EVENT_HISTORY_SIM_TIME: currentLanguage.DASHBOARD.EVAL_REPORT.EVENT_HISTORY_SIM_TIME,
			EVENT_HISTORY_NAME: currentLanguage.DASHBOARD.EVAL_REPORT.EVENT_HISTORY_NAME,
			EVENT_HISTORY_DESCRIPTION: currentLanguage.DASHBOARD.EVAL_REPORT.EVENT_HISTORY_DESCRIPTION,
			COLLISION_OCCURRED_MESSAGE: currentLanguage.DASHBOARD.EVAL_REPORT.COLLISION_OCCURRED_MESSAGE,
		},
	};
	this.SEVERITY = {
		LOW: currentLanguage.SEVERITY.LOW,
		MEDIUM: currentLanguage.SEVERITY.MEDIUM,
		HIGH: currentLanguage.SEVERITY.HIGH,
		FATAL: currentLanguage.SEVERITY.FATAL,
		VIOLATION: currentLanguage.SEVERITY.VIOLATION,
	};
	this.DOWNLOADS = {
		DOWNLOAD_LAUNCHER: currentLanguage.DOWNLOADS.DOWNLOAD_LAUNCHER,
		INSTRUCTIONS: currentLanguage.DOWNLOADS.INSTRUCTIONS,
	};
	this.PERFORMANCE = {
		STUDENTS: currentLanguage.PERFORMANCE.STUDENTS,
		DATA_SOURCE: currentLanguage.PERFORMANCE.DATA_SOURCE,
		DATA_SOURCE_NOT_SUPPORTED: currentLanguage.PERFORMANCE.DATA_SOURCE_NOT_SUPPORTED,
		CHART_TYPE: currentLanguage.PERFORMANCE.CHART_TYPE,
		X_AXIS_UNIT: currentLanguage.PERFORMANCE.X_AXIS_UNIT,
		SPEED_UNIT: currentLanguage.PERFORMANCE.SPEED_UNIT,
		DATE_RANGE: currentLanguage.PERFORMANCE.DATE_RANGE,
		AGGREGATED_DATA_OPTIONS: currentLanguage.PERFORMANCE.AGGREGATED_DATA_OPTIONS,
		COURSE: currentLanguage.PERFORMANCE.COURSE,
		COURSES: currentLanguage.PERFORMANCE.COURSES,
		UNIT: currentLanguage.PERFORMANCE.UNIT,
		UNITS: currentLanguage.PERFORMANCE.UNITS,
		LESSON: currentLanguage.PERFORMANCE.LESSON,
		LESSONS: currentLanguage.PERFORMANCE.LESSONS,
		UNIT_SELECT_PLACEHOLDER: currentLanguage.PERFORMANCE.UNIT_SELECT_PLACEHOLDER,
		LESSON_SELECT_PLACEHOLDER: currentLanguage.PERFORMANCE.LESSON_SELECT_PLACEHOLDER,
		SELECT_ALL_STUDENTS: currentLanguage.PERFORMANCE.SELECT_ALL_STUDENTS,
		SELECT_ALL_COURSES: currentLanguage.PERFORMANCE.SELECT_ALL_COURSES,
		SELECT_ALL_UNITS: currentLanguage.PERFORMANCE.SELECT_ALL_UNITS,
		SELECT_ALL_LESSONS: currentLanguage.PERFORMANCE.SELECT_ALL_LESSONS,
		LOADING_DATA_MESSAGE: currentLanguage.PERFORMANCE.LOADING_DATA_MESSAGE,
		NO_DATA_MESSAGE: currentLanguage.PERFORMANCE.NO_DATA_MESSAGE,
		BROADEN_FILTERS_MESSAGE: currentLanguage.PERFORMANCE.BROADEN_FILTERS_MESSAGE,
		DATA_LIMITED_MESSAGE: currentLanguage.PERFORMANCE.DATA_LIMITED_MESSAGE,
		IN_TIME_UNITS: currentLanguage.PERFORMANCE.IN_TIME_UNITS,
		IN_SPEED_UNITS: currentLanguage.PERFORMANCE.IN_SPEED_UNITS,
		IN_DEGREES: currentLanguage.PERFORMANCE.IN_DEGREES,
		CHART_GENERATED_DATE: currentLanguage.PERFORMANCE.CHART_GENERATED_DATE,
		FROM_DATE: currentLanguage.PERFORMANCE.FROM_DATE,
		TO_DATE: currentLanguage.PERFORMANCE.TO_DATE,
		DATE: currentLanguage.PERFORMANCE.DATE,
	};
	this.DATA_SOURCES = {
		SCORE: currentLanguage.DATA_SOURCES.SCORE,
		SCORED_TIME: currentLanguage.DATA_SOURCES.SCORED_TIME,
		ATTEMPTS: currentLanguage.DATA_SOURCES.ATTEMPTS,
		DURATION: currentLanguage.DATA_SOURCES.DURATION,
		COLLISIONS: currentLanguage.DATA_SOURCES.COLLISIONS,
		COLLISION_VELOCITY: currentLanguage.DATA_SOURCES.COLLISION_VELOCITY,
		MAXIMUM_LOAD_SWING: currentLanguage.DATA_SOURCES.MAXIMUM_LOAD_SWING,
		VIOLATIONS: currentLanguage.DATA_SOURCES.VIOLATIONS,
		MOVES_PER_HOUR: currentLanguage.DATA_SOURCES.MOVES_PER_HOUR,
	};
	this.CHART_TYPES = {
		BAR: currentLanguage.CHART_TYPES.BAR,
		LINE: currentLanguage.CHART_TYPES.LINE,
		SCATTERPLOT: currentLanguage.CHART_TYPES.SCATTERPLOT,
	};
	this.TIME_UNITS = {
		MINUTE: currentLanguage.TIME_UNITS.MINUTE,
		HOUR: currentLanguage.TIME_UNITS.HOUR,
		DAY: currentLanguage.TIME_UNITS.DAY,
	};
	this.AXIS_UNITS = {
		DATE: currentLanguage.AXIS_UNITS.DATE,
		LESSON: currentLanguage.AXIS_UNITS.LESSON,
	};
	this.SPEED_UNITS = {
		METERS_PER_SECOND: currentLanguage.SPEED_UNITS.METERS_PER_SECOND,
		FEET_PER_SECOND: currentLanguage.SPEED_UNITS.FEET_PER_SECOND,
		MILES_PER_HOUR: currentLanguage.SPEED_UNITS.MILES_PER_HOUR,
		KILOMETERS_PER_HOUR: currentLanguage.SPEED_UNITS.KILOMETERS_PER_HOUR,
	};
	this.AGG_DATA_OPTIONS = {
		AVERAGE: currentLanguage.AGG_DATA_OPTIONS.AVERAGE,
		MEDIAN: currentLanguage.AGG_DATA_OPTIONS.MEDIAN,
		TREND: currentLanguage.AGG_DATA_OPTIONS.TREND,
	};
	this.PRINT = currentLanguage.PRINT;
	this.SAVED = currentLanguage.SAVED;
	this.COLLAPSE = currentLanguage.COLLAPSE;
	this.CLICK_HERE = currentLanguage.CLICK_HERE;
	this.USER_MANAGEMENT = {
		USERS: currentLanguage.USER_MANAGEMENT.USERS,
		FIRST_NAME: currentLanguage.USER_MANAGEMENT.FIRST_NAME,
		LAST_NAME: currentLanguage.USER_MANAGEMENT.LAST_NAME,
		USERNAME: currentLanguage.USER_MANAGEMENT.USERNAME,
		EMAIL_ADDRESS: currentLanguage.USER_MANAGEMENT.EMAIL_ADDRESS,
		PASSWORD_CHANGED: currentLanguage.USER_MANAGEMENT.PASSWORD_CHANGED,
		LANGUAGE_PREFERENCE: currentLanguage.USER_MANAGEMENT.LANGUAGE_PREFERENCE,
		UNITS_PREFERENCE: currentLanguage.USER_MANAGEMENT.UNITS_PREFERENCE,
		GROUPS: currentLanguage.USER_MANAGEMENT.GROUPS,
		ACTIVE: currentLanguage.USER_MANAGEMENT.ACTIVE,
		ROLE: currentLanguage.USER_MANAGEMENT.ROLE,
		DATE_CREATED: currentLanguage.USER_MANAGEMENT.DATE_CREATED,
		DATE_LAST_MODIFIED: currentLanguage.USER_MANAGEMENT.DATE_LAST_MODIFIED,
		DATE_LAST_LOGIN: currentLanguage.USER_MANAGEMENT.DATE_LAST_LOGIN,
		SUBSCRIPTION_EXPIRATION_DATE: currentLanguage.USER_MANAGEMENT.SUBSCRIPTION_EXPIRATION_DATE,
		ADD_USER: currentLanguage.USER_MANAGEMENT.ADD_USER,
		USER_ADDED: currentLanguage.USER_MANAGEMENT.USER_ADDED,
		EDIT_USER: currentLanguage.USER_MANAGEMENT.EDIT_USER,
		DELETE_USER: currentLanguage.USER_MANAGEMENT.DELETE_USER,
		NO_USERS_MESSAGE: currentLanguage.USER_MANAGEMENT.NO_USERS_MESSAGE,
		SEARCH: currentLanguage.USER_MANAGEMENT.SEARCH,
		SORT_ORDER: currentLanguage.USER_MANAGEMENT.SORT_ORDER,
		ACTIVE_USERS_ONLY: currentLanguage.USER_MANAGEMENT.ACTIVE_USERS_ONLY,
		SELECTED_GROUPS: currentLanguage.USER_MANAGEMENT.SELECTED_GROUPS,
	};
	this.GROUP_MANAGEMENT = {
		GROUP_NAME: currentLanguage.GROUP_MANAGEMENT.GROUP_NAME,
		START_DATE: currentLanguage.GROUP_MANAGEMENT.START_DATE,
		END_DATE: currentLanguage.GROUP_MANAGEMENT.END_DATE,
		DATE_RANGE: currentLanguage.GROUP_MANAGEMENT.DATE_RANGE,
		ACTIVE: currentLanguage.GROUP_MANAGEMENT.ACTIVE,
		NOTES: currentLanguage.GROUP_MANAGEMENT.NOTES,
		COURSE: currentLanguage.GROUP_MANAGEMENT.COURSE,
		COURSES: currentLanguage.GROUP_MANAGEMENT.COURSES,
		GROUPS: currentLanguage.GROUP_MANAGEMENT.GROUPS,
		STUDENTS: currentLanguage.GROUP_MANAGEMENT.STUDENTS,
		INSTRUCTORS: currentLanguage.GROUP_MANAGEMENT.INSTRUCTORS,
		SELECT_ALL_COURSES: currentLanguage.GROUP_MANAGEMENT.SELECT_ALL_COURSES,
		SELECT_ALL_STUDENTS: currentLanguage.GROUP_MANAGEMENT.SELECT_ALL_STUDENTS,
		SELECT_ALL_INSTRUCTORS: currentLanguage.GROUP_MANAGEMENT.SELECT_ALL_INSTRUCTORS,
		ADD_NEW_GROUP: currentLanguage.GROUP_MANAGEMENT.ADD_NEW_GROUP,
		DELETE_GROUP: currentLanguage.GROUP_MANAGEMENT.DELETE_GROUP,
		EDIT_GROUP: currentLanguage.GROUP_MANAGEMENT.EDIT_GROUP,
		NO_GROUPS_MESSAGE: currentLanguage.GROUP_MANAGEMENT.NO_GROUPS_MESSAGE,
		UNASSIGNED_USERS: currentLanguage.GROUP_MANAGEMENT.UNASSIGNED_USERS,
		UNASSIGNED_USER_LIST: currentLanguage.GROUP_MANAGEMENT.UNASSIGNED_USER_LIST,
		GROUP_ROSTER: currentLanguage.GROUP_MANAGEMENT.GROUP_ROSTER,
		PRINT_GROUP_ROSTER: currentLanguage.GROUP_MANAGEMENT.PRINT_GROUP_ROSTER,
		GROUP_ROSTER_PRINT_HEADER: currentLanguage.GROUP_MANAGEMENT.GROUP_ROSTER_PRINT_HEADER,
		ADD_COURSE: currentLanguage.GROUP_MANAGEMENT.ADD_COURSE,
		COURSE_LIST_PRINT_HEADER: currentLanguage.GROUP_MANAGEMENT.COURSE_LIST_PRINT_HEADER,
	};
	this.ORG_DATA = {
		ASSOCIATED_INSTALLERS: currentLanguage.ORG_DATA.ASSOCIATED_INSTALLERS,
		ASSOCIATED_LAUNCHER_COLLECTION: currentLanguage.ORG_DATA.ASSOCIATED_LAUNCHER_COLLECTION,
		ASSOCIATED_SIMULATOR_COLLECTION: currentLanguage.ORG_DATA.ASSOCIATED_SIMULATOR_COLLECTION,
		ASSOCIATED_SCENARIO_EDITOR_COLLECTION: currentLanguage.ORG_DATA.ASSOCIATED_SCENARIO_EDITOR_COLLECTION,
		ASSOCIATED_IOS_COLLECTION: currentLanguage.ORG_DATA.ASSOCIATED_IOS_COLLECTION,
		CLOUD_BASED: currentLanguage.ORG_DATA.CLOUD_BASED,
		RESTRICT_CONNECTIONS_BY_LICENSE: currentLanguage.ORG_DATA.RESTRICT_CONNECTIONS_BY_LICENSE,
		STUDENT_LICENSES: currentLanguage.ORG_DATA.STUDENT_LICENSES,
		INSTRUCTOR_LICENSES: currentLanguage.ORG_DATA.INSTRUCTOR_LICENSES,
	};
	this.USER_IMPORT = {
		FILE_HEADERS: {
			USERNAME: currentLanguage.USER_IMPORT.FILE_HEADERS.USERNAME,
			FIRST_NAME: currentLanguage.USER_IMPORT.FILE_HEADERS.FIRST_NAME,
			LAST_NAME: currentLanguage.USER_IMPORT.FILE_HEADERS.LAST_NAME,
			EMAIL_ADDRESS: currentLanguage.USER_IMPORT.FILE_HEADERS.EMAIL_ADDRESS,
			ROLE: currentLanguage.USER_IMPORT.FILE_HEADERS.ROLE,
		},
		IMPORT_USERS: currentLanguage.USER_IMPORT.IMPORT_USERS,
		EXPORT_USERS: currentLanguage.USER_IMPORT.EXPORT_USERS,
		DOWNLOAD_TEMPLATE: currentLanguage.USER_IMPORT.DOWNLOAD_TEMPLATE,
		IMPORT_SUCCESSFUL: currentLanguage.USER_IMPORT.IMPORT_SUCCESSFUL,
		ADD_USERS_TO_GROUPS: currentLanguage.USER_IMPORT.ADD_USERS_TO_GROUPS,
	};
	this.ROLES = {
		SYSTEM: currentLanguage.ROLES.SYSTEM,
		STUDENT: currentLanguage.ROLES.STUDENT,
		INSTRUCTOR: currentLanguage.ROLES.INSTRUCTOR,
		ADMIN: currentLanguage.ROLES.ADMIN,
		DEVELOPER: currentLanguage.ROLES.DEVELOPER,
		GLOBAL_ADMIN: currentLanguage.ROLES.GLOBAL_ADMIN,
	};
	this.EMAIL = {
		EMAIL_VERIFIED_MESSAGE: currentLanguage.EMAIL.EMAIL_VERIFIED_MESSAGE,
		EMAIL_VERIFIED_SUBMESSAGE_LOGGED_OUT: currentLanguage.EMAIL.EMAIL_VERIFIED_SUBMESSAGE_LOGGED_OUT,
		EMAIL_VERIFIED_SUBMESSAGE_LOGGED_IN: currentLanguage.EMAIL.EMAIL_VERIFIED_SUBMESSAGE_LOGGED_IN,
		VERIFYING_YOUR_EMAIL: currentLanguage.EMAIL.VERIFYING_YOUR_EMAIL,
		VERIFICATION_FAILED: currentLanguage.EMAIL.VERIFICATION_FAILED,
		NEW_LINK_MESSAGE: currentLanguage.EMAIL.NEW_LINK_MESSAGE,
		NEW_LINK_LOGGED_OUT_MESSAGE: currentLanguage.EMAIL.NEW_LINK_LOGGED_OUT_MESSAGE,
		RESEND_EMAIL: currentLanguage.EMAIL.RESEND_EMAIL,
		VERIFICATION_EMAIL_SENT: currentLanguage.EMAIL.VERIFICATION_EMAIL_SENT,
		SEND_VERIFICATION_EMAIL: currentLanguage.EMAIL.SEND_VERIFICATION_EMAIL,
		PASSWORD_RESET_EMAIL_SENT: currentLanguage.EMAIL.PASSWORD_RESET_EMAIL_SENT,
	};
	this.NOT_APPLICABLE = "N/A";
	//#endregion
}

export let TRANSLATIONS = new Translations();
//#endregion

onGlobalStateChange(() => {
	TRANSLATIONS = new Translations();
});
