import React from "react";
import { PulseLoader } from "react-spinners";
import { GS_COLORS } from "./style-constants";

export const Loading = ({ color = GS_COLORS.GLOBALSIM_RED }) => {
	return (
		<span className="w100 flexbox">
			<PulseLoader color={color} className="pulse-loader load-icon" />
		</span>
	);
};
