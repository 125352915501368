import React, { Component } from "react";
import GSForms from "./form-elements";
import { TMSHelpers } from "../helpers/tms-helpers";
import { HelperFunctions } from "../helpers/helper-functions";
import { Loading } from "../common/loading-icon";
import { NavLink } from "react-router-dom";
import Nav from "../data-structures/navigation";
import { globalState, onGlobalStateChange } from "../store";
import { TRANSLATIONS } from "../data-structures/localization";
import { ERROR_CODES } from "../data-structures/gs-constants";
import { Validate } from "../helpers/validation";
import FormHelpers from "../helpers/form-helpers";

export default class ResetPasswordForm extends Component {
	//#region Initialization
	constructor(props) {
		super(props);

		this.INITIAL_STATE = {
			currentPassword: "",
			newPassword: "",
			confirmNewPassword: "",
		};

		this.state = {
			...this.INITIAL_STATE,

			formErrors: [],
			responseError: null,

			submitting: false,
			saved: false,
		};
	}

	componentDidMount() {
		this.unsubscribe = onGlobalStateChange(() =>
			this.setState({
				...(this.state.formErrors.length > 0 && { formErrors: this.getFormErrors() || [] }),
				...(this.state.responseError && {
					responseError: Object.values(ERROR_CODES).find((x) => x.key === this.state.responseError.key),
				}),
			})
		);
	}

	componentWillUnmount() {
		this.unsubscribe();
	}
	//#endregion

	handleSubmit = async () => {
		const formErrors = this.getFormErrors();
		if (formErrors.length > 0) this.setState({ submitting: false, saved: false, responseError: null, formErrors: formErrors });
		else {
			if (this.props.requireCurrentPassword)
				this.setState({ submitting: true, saved: false, responseError: null, formErrors: [] }, async () => {
					await TMSHelpers.sendRequest("UserAuthRequest", {
						Username: this.props.username || globalState.userData.username,
						Password: this.state.currentPassword,
					}).then(async (auth) => {
						if (auth.Success) this.sendPasswordResetRequest();
						else {
							this.setState({
								submitting: false,
								saved: false,
								...this.getResponseErrorState(auth),
							});
						}
					});
				});
			else this.sendPasswordResetRequest();
		}
	};

	sendPasswordResetRequest = async () => {
		this.props.verificationToken
			? await TMSHelpers.resetPasswordViaEmail(this.props.userId, this.state.newPassword, this.props.verificationToken).then((response) =>
					this.setState({
						...this.INITIAL_STATE,
						submitting: false,
						saved: response.Success,
						...this.getResponseErrorState(response),
					})
			  )
			: await TMSHelpers.resetPassword(this.props.userId || globalState.userData.id, this.state.newPassword).then((response) =>
					this.setState({
						...this.INITIAL_STATE,
						submitting: false,
						saved: response.Success,
						...this.getResponseErrorState(response),
					})
			  );
	};

	getFormErrors = () => {
		const errors = [];
		const state = this.state;
		if (this.props.requireCurrentPassword && Validate.isNullOrEmpty(state.currentPassword)) {
			errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["currentPassword"]));
		}

		errors.push(...FormHelpers.getPasswordErrors(state.newPassword, ["newPassword"]));

		if (Validate.isNullOrEmpty(state.confirmNewPassword)) {
			errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.CONFIRM_PASSWORD, ["confirmNewPassword"]));
		} else if (state.newPassword !== state.confirmNewPassword) {
			errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.PASSWORD_MATCH, ["newPassword", "confirmNewPassword"]));
		}

		return errors;
	};

	getResponseErrorState = (response) => {
		let errorState = { formErrors: [], responseError: null };

		if (response && response.errorMsg) {
			switch (response.key) {
				case ERROR_CODES.PASSWORD_INVALID.key:
					errorState = { ...errorState, formErrors: [new GSForms.FormError(response.errorMsg, ["newPassword"])] };
					break;

				case ERROR_CODES.INVALID_CREDS.key:
					errorState = {
						...errorState,
						formErrors: [new GSForms.FormError("Password Incorrect. Please enter your current password", ["currentPassword"])],
					};
					break;

				default:
					errorState = { ...errorState, responseError: HelperFunctions.getResponseError(response) };
					break;
			}
		}

		return errorState;
	};

	render() {
		return (
			<div className="reset-password-form">
				<h2>{TRANSLATIONS.HEADERS.RESET_PASSWORD}</h2>
				{this.props.userId || globalState.userData.id ? (
					<form autoComplete="on">
						{this.props.requireCurrentPassword && (
							<GSForms.InputField
								label={TRANSLATIONS.FORMS.CURRENT_PASSWORD}
								dataState="currentPassword"
								fieldValue={this.state.currentPassword}
								autoCompleteString="current-password"
								formField
								errors={this.state.formErrors}
								type="password"
								handleChange={(e) => FormHelpers.handleChange(e, this)}
							/>
						)}
						<GSForms.InputField
							label={TRANSLATIONS.FORMS.NEW_PASSWORD}
							tooltipContent={TRANSLATIONS.FORMS.PASSWORD_REQUIREMENTS}
							dataState="newPassword"
							fieldValue={this.state.newPassword}
							autoCompleteString="new-password"
							formField
							errors={this.state.formErrors}
							type="password"
							handleChange={(e) => FormHelpers.handleChange(e, this)}
						/>
						<GSForms.InputField
							label={TRANSLATIONS.FORMS.CONFIRM_NEW_PASSWORD}
							dataState="confirmNewPassword"
							fieldValue={this.state.confirmNewPassword}
							autoCompleteString="new-password"
							formField
							errors={this.state.formErrors}
							type="password"
							handleChange={(e) => FormHelpers.handleChange(e, this)}
						/>

						<GSForms.ErrorContainer message={this.state.responseError} />
						{this.state.submitting && <Loading />}
						{this.state.saved && (
							<GSForms.SavedCheck
								customMessage={TRANSLATIONS.USER_MANAGEMENT.PASSWORD_CHANGED}
								includeCheck={false}
								toggleVisible={() => this.setState({ saved: false })}
							/>
						)}

						<GSForms.Button onClick={this.handleSubmit} />
						{/* {this.props.requireCurrentPassword && <NavLink to={Nav.PATHS.PASSWORD_RESET}>{TRANSLATIONS.FORMS.FORGOT_PASSWORD}</NavLink>} */}
					</form>
				) : (
					<Loading />
				)}
			</div>
		);
	}
}
