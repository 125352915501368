import React from "react";
import { TRANSLATIONS } from "../../data-structures/localization";

export const Payments = () => {
	return (
		<div>
			<h2>{TRANSLATIONS.HEADERS.PAYMENTS}</h2>
			{TRANSLATIONS.HEADERS.PAYMENTS}
		</div>
	);
};

export const ManagePayments = () => {
	return (
		<div>
			<Payments />
		</div>
	);
};
