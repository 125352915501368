import React, { useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import Nav from "../../data-structures/navigation";
import { TRANSLATIONS } from "../../data-structures/localization";
import { HelperFunctions } from "../../helpers/helper-functions";
import { onGlobalStateChange, useForceUpdate } from "../../store";
import "./dashboard.scss";

export const Dashboard = () => {
	const forceUpdate = useForceUpdate();

	useEffect(() => {
		onGlobalStateChange(() => {
			forceUpdate();
		});
	});

	return (
		<div className="side-nav-container container">
			<h1 className="no-print">{TRANSLATIONS.HEADERS.DASHBOARD}</h1>
			<div className="side-nav-content row">
				<div className="side-nav-sidebar no-print col-md-2 sticky-top">
					<NavLink to={Nav.PATHS.DASHBOARD.STATISTICS}>{TRANSLATIONS.HEADERS.STATISTICS}</NavLink>
					{HelperFunctions.loggedInAsInstructor() && <NavLink to={Nav.PATHS.DASHBOARD.GROUPS}>{TRANSLATIONS.HEADERS.GROUPS}</NavLink>}
					{Nav.ROUTES.DASHBOARD.children.PAYMENTS.active && (
						<NavLink to={Nav.PATHS.DASHBOARD.PAYMENTS}>{TRANSLATIONS.HEADERS.PAYMENTS}</NavLink>
					)}
				</div>
				<div className="side-nav-section col-md-10">
					<Outlet />
				</div>
			</div>
		</div>
	);
};
