import ParamAssignableObject from "./param-assignable-object.tsx";
import { ISelectOption } from "./select-options.tsx";

class CatalogItem extends ParamAssignableObject implements ISelectOption<string> {
	id;
	active;
	deleted;
	description;
	displayPosition;
	minimumEditPermission;
	minimumViewPermission;
	name;

	public get label(): string {
		return this.name;
	}

	public get value(): string {
		return this.id;
	}
}

export class CourseData extends CatalogItem {
	id;
	active;
	deleted;
	description;
	displayPosition;
	minimumEditPermission;
	minimumViewPermission;
	name;
	unitList;

	constructor(courseParams: {
		id: any;
		active: any;
		deleted: any;
		description: any;
		displayPosition: any;
		minimumEditPermission: any;
		minimumViewPermission: any;
		name: any;
		unitList?: Array<UnitData>;
	}) {
		super();
		this.constructFromParams(courseParams);
	}

	static constructFromDB = (dbCourse) => {
		const newCourse = new CourseData({
			id: dbCourse.CourseId,
			active: dbCourse.Active,
			deleted: dbCourse.Deleted,
			description: dbCourse.Description,
			displayPosition: dbCourse.DisplayPosition,
			minimumEditPermission: dbCourse.MinimumEditPermission,
			minimumViewPermission: dbCourse.MinimumViewPermission,
			name: dbCourse.Name,
		});
		newCourse.unitList = dbCourse.UnitList.$values
			.map((dbUnit) => UnitData.constructFromDB(dbUnit, newCourse))
			.sort((a, b) => a.displayPosition - b.displayPosition);
		return newCourse;
	};
}

export class UnitData extends CatalogItem {
	id;
	active;
	deleted;
	description;
	displayPosition;
	minimumEditPermission;
	minimumViewPermission;
	name;
	unitImageId;
	lessonList;
	course: CourseData;

	constructor(unitParams: {
		id: any;
		active: any;
		deleted: any;
		description: any;
		displayPosition: any;
		minimumEditPermission: any;
		minimumViewPermission: any;
		name: any;
		unitImageId: any;
		course: CourseData;
		lessonList?: Array<LessonData>;
	}) {
		super();
		this.constructFromParams(unitParams);
	}

	static constructFromDB = (dbUnit, course) => {
		const newUnit = new UnitData({
			id: dbUnit.UnitId,
			active: dbUnit.Active,
			deleted: dbUnit.Deleted,
			description: dbUnit.Description,
			displayPosition: dbUnit.DisplayPosition,
			minimumEditPermission: dbUnit.MinimumEditPermission,
			minimumViewPermission: dbUnit.MinimumViewPermission,
			name: dbUnit.Name,
			unitImageId: dbUnit.UnitImageId,
			course: course,
		});
		newUnit.lessonList = dbUnit.LessonList.$values
			.map((dbLesson) => LessonData.constructFromDB(dbLesson, course, newUnit))
			.sort((a, b) => a.displayPosition - b.displayPosition);
		return newUnit;
	};
}

export class LessonData extends CatalogItem {
	id;
	active;
	deleted;
	description;
	displayPosition;
	minimumEditPermission;
	minimumViewPermission;
	name;
	curriculumId;
	estimatedTime;
	lessonType;
	maxAttempts;
	maxSims;
	minSims;
	minimumPassingScore;
	required;
	scenarioId;
	startingScore;
	course: CourseData;
	unit: UnitData;

	constructor(lessonParams: {
		id: any;
		active: any;
		curriculumId: any;
		description: any;
		estimatedTime: any;
		lessonType: any;
		maxAttempts: any;
		maxSims: any;
		minSims: any;
		minimumEditPermission: any;
		minimumPassingScore: any;
		minimumViewPermission: any;
		name: any;
		required: any;
		scenarioId: any;
		startingScore: any;
		course: CourseData;
		unit: UnitData;
	}) {
		super();
		this.constructFromParams(lessonParams);
	}

	static constructFromDB = (dbLesson, course, unit) => {
		return new LessonData({
			id: dbLesson.LessonId,
			active: dbLesson.Active,
			curriculumId: dbLesson.CurriculumId,
			description: dbLesson.Description,
			estimatedTime: dbLesson.EstimatedTime,
			lessonType: dbLesson.LessonType,
			maxAttempts: dbLesson.MaxAttempts,
			maxSims: dbLesson.MaxSims,
			minSims: dbLesson.MinSims,
			minimumEditPermission: dbLesson.MinimumEditPermission,
			minimumPassingScore: dbLesson.MinimumPassingScore,
			minimumViewPermission: dbLesson.MinimumViewPermission,
			name: dbLesson.Name,
			required: dbLesson.Required,
			scenarioId: dbLesson.ScenarioId,
			startingScore: dbLesson.StartingScore,
			course: course,
			unit: unit,
		});
	};
}
