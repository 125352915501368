import { configureStore } from "@reduxjs/toolkit";
import { LANGUAGES, LANGUAGE_CODES } from "./data-structures/lang-codes";
import { useState } from "react";
import { AuthHeader } from "./data-structures/auth-header.tsx";
import { UserData } from "./data-structures/user-data.tsx";
import { OrgData } from "./data-structures/org-data.tsx";
import { GroupData } from "./data-structures/group-data.tsx";
import { CourseData } from "./data-structures/course-catalog-data.tsx";

//#region Initial State Setup
let initialLanguage = localStorage.getItem("locale") || window.navigator.userLanguage || window.navigator.language;

const foundLanguage = Object.entries(LANGUAGES).find((l) => l[0] === initialLanguage);
if (foundLanguage) {
	initialLanguage = foundLanguage[1].value;

	if (!initialLanguage.active) {
		initialLanguage = LANGUAGE_CODES.ENGLISH;
	}
} else {
	console.error(`Language Code not recognized: ${initialLanguage}`);
	initialLanguage = LANGUAGE_CODES.ENGLISH;
}

const INITIAL_GLOBAL_STATE = {
	locale: initialLanguage,
	loggedIn: AuthHeader.retrieveFromStorage() ? true : false,
	ghostMode: localStorage.getItem("ghostMode") ? true : false,
};
//#endregion

const reducer = (newState, action) => {
	let returnState = newState;
	if (action.type === "CHANGE_STATE") {
		Object.entries(action.payload)
			.map((x) => ({ key: x[0], value: x[1] }))
			.forEach((item) => {
				let newValue = item.value;
				switch (item.key) {
					case "loggedIn":
						if (newValue) {
							newValue = newValue ? true : false;
							localStorage.setItem(item.key, newValue);
						} else localStorage.removeItem(item.key);
						break;

					case "locale":
						if (newValue) {
							if (!Object.values(LANGUAGE_CODES).find((l) => l === newValue)) {
								console.error(`Language Code not recognized: ${newValue}`);
								newValue = LANGUAGE_CODES.ENGLISH;
							}
							localStorage.setItem(item.key, newValue);
						} else localStorage.removeItem(item.key);
						break;

					case "groupList":
					case "orgData":
					case "userData":
					case "courseCatalog":
					case "nonGhostUserCache":
					case "ghostMode":
					case "installerCollections":
					default:
						// don't store in localStorage
						break;
				}

				returnState = { ...returnState, [item.key]: newValue };
			});
	}
	return returnState;
};
export const store = configureStore({
	reducer,
	preloadedState: INITIAL_GLOBAL_STATE,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: false,
			serializableCheck: false,
			immutableCheck: false,
		}),
	devTools: process.env.NODE_ENV !== "production",
});
export const onGlobalStateChange = store.subscribe;

class GlobalState {
	locale;
	loggedIn;
	userData;
	orgData;
	groupList;
	courseCatalog;
	nonGhostUserCache;
	ghostMode;
	installerCollections;

	get activeCourses() {
		return this.courseCatalog?.filter((c) => c.active && !c.deleted);
	}

	constructor(newState) {
		this.locale = newState.locale;
		this.loggedIn = newState.loggedIn;
		this.userData = UserData.exists(newState.userData) ? new UserData(newState.userData) : null;
		this.orgData = OrgData.exists(newState.orgData) ? new OrgData(newState.orgData) : null;
		this.groupList = newState.groupList?.map((g) => new GroupData(g));
		this.courseCatalog = newState.courseCatalog?.map((c) => new CourseData(c));
		this.nonGhostUserCache = newState.nonGhostUserCache;
		this.ghostMode = newState.ghostMode;
		this.installerCollections = newState.installerCollections;
	}
}
export let globalState = new GlobalState(store.getState());

export const setGlobalState = (newState) => store.dispatch({ type: "CHANGE_STATE", payload: newState });

export const resetGlobalState = () =>
	setGlobalState({
		loggedIn: false,
		userData: null,
		orgData: null,
		groupList: null,
		courseCatalog: null,
		ghostMode: null,
		nonGhostUserCache: null,
		installerCollections: null,
	});

onGlobalStateChange(() => {
	globalState = new GlobalState(store.getState());

	const html = document.querySelector("html");
	const rtl = LANGUAGES[globalState.locale].RTL;
	html.classList.add(rtl ? "rtl" : "ltr");
	html.classList.remove(rtl ? "ltr" : "rtl");
});

export function useForceUpdate() {
	const [value, setValue] = useState(0);
	return () => setValue((value) => value + 1); // update state to force render
}
