import { CourseData, LessonData, UnitData } from "./course-catalog-data.tsx";
import ParamAssignableObject from "./param-assignable-object.tsx";
import { Validate } from "../helpers/validation.js";
import { HelperFunctions } from "../helpers/helper-functions.js";
import { MEASUREMENT_UNITS } from "./gs-constants";

export class Evaluation extends ParamAssignableObject {
	id;
	studentId;
	completed;
	duration;
	evaluationDate;
	hasPlayback;
	scoring: Scoring;
	eventList;
	dataList;
	course: CourseData;
	unit: UnitData;
	lesson: LessonData;

	constructor(evalParams: {
		id: any;
		studentId: any;
		completed: any;
		duration: any;
		evaluationDate: any;
		hasPlayback: any;
		scoring: Scoring;
		eventList: any;
		dataList: any;
		course: CourseData;
		unit: UnitData;
		lesson: LessonData;
	}) {
		super();
		this.constructFromParams(evalParams);
	}

	static constructFromDB = (dbEvaluation, lessonList) => {
		const lesson = lessonList.find((l) => l.id === dbEvaluation.LessonId);
		return new Evaluation({
			id: dbEvaluation.Id,
			studentId: dbEvaluation.UserId,
			completed: dbEvaluation.Completed,
			duration: dbEvaluation.Duration,
			evaluationDate: dbEvaluation.EvaluationDate,
			hasPlayback: dbEvaluation.HasPlayback,
			scoring: Scoring.constructFromDB(dbEvaluation.Scoring),
			eventList: dbEvaluation.EventList.$values.map((dbEvent) => EvalEvent.constructFromDB(dbEvent)),
			dataList: dbEvaluation.DataList.$values.map((dataPoint) => EvalDataPoint.constructFromDB(dataPoint)),
			course: lesson?.course,
			unit: lesson?.unit,
			lesson: lesson,
		});
	};
}

//#region Eval Scoring
class Scoring extends ParamAssignableObject {
	finalScore;
	maxAttempts;
	minimumPassingScore;
	startingScore;
	scoringFactors;

	constructor(scoringObj: { finalScore: any; maxAttempts: any; minimumPassingScore: any; startingScore: any; scoringFactors: any }) {
		super();
		this.constructFromParams(scoringObj);
	}

	static constructFromDB = (dbScoring) =>
		new Scoring({
			finalScore: dbScoring.FinalScore,
			maxAttempts: dbScoring.MaxAttempts,
			minimumPassingScore: dbScoring.MinimumPassingScore,
			startingScore: dbScoring.StartingScore,
			scoringFactors: dbScoring.ScoringFactors.$values.map((dbsf) => ScoringFactor.constructFromDB(dbsf)),
		});
}

class ScoringFactor extends ParamAssignableObject {
	name;
	count;
	offset;
	simTime;
	forcedFailure;

	constructor(scoringFactor: { name: any; count: any; offset: any; simTime: any; forcedFailure: any }) {
		super();
		this.constructFromParams(scoringFactor);
	}

	static constructFromDB = (dbScoringFactor) =>
		new ScoringFactor({
			name: dbScoringFactor.ScoringFactorName,
			count: dbScoringFactor.Count,
			offset: dbScoringFactor.Offset,
			simTime: dbScoringFactor.SimTime,
			forcedFailure: dbScoringFactor.ForcedFailure,
		});
}
//#endregion

//#region Evaluation Events
abstract class EvalEvent extends ParamAssignableObject {
	actualTime;
	id;
	type;
	simTime;

	constructor(evalEventData: { actualTime: any; id: any; type: any; simTime: any }) {
		super();
		this.constructFromParams(evalEventData);
	}

	static constructFromDB = (dbEvalEvent) => {
		const dbEventBody = JSON.parse(dbEvalEvent.EventBody);
		const eventType = dbEventBody.$type;
		const flatDbEvent = { ...dbEvalEvent, ...dbEventBody };

		const isType = (typeMatch) => eventType.includes(typeMatch);
		switch (true) {
			case isType("MoveEvent"):
				return MoveEvent.constructFromDB(flatDbEvent);
			case isType("CollisionEvent"):
				return CollisionEvent.constructFromDB(flatDbEvent);
			case isType("FaultEvent"):
				return FaultEvent.constructFromDB(flatDbEvent);
			case isType("AlarmEvent"):
				return AlarmEvent.constructFromDB(flatDbEvent);
			case isType("IncidentEvent"):
				return IncidentEvent.constructFromDB(flatDbEvent);
			default:
				break;
		}
	};

	abstract get reportData(): { title: string; description: string };
}

class MoveEvent extends EvalEvent {
	movedLoads: Array<MovedLoad>;
	duration;

	constructor(moveEventData: { actualTime: any; id: any; type: any; simTime: any; movedLoads: Array<MovedLoad>; duration: any }) {
		super(moveEventData);
		this.constructFromParams(moveEventData);
	}

	static constructFromDB = (eventData) => {
		return new MoveEvent({
			actualTime: eventData.ActualTime,
			id: eventData.Id,
			type: eventData.$type,
			simTime: eventData.SimulationTime,

			movedLoads: eventData.MovedLoads.$values.map((loadData) => MovedLoad.constructFromDB(loadData)),
			duration: eventData.Duration,
		});
	};

	public get reportData() {
		return {
			title: "Move Recorded",
			description: this.movedLoads?.map((load) => `${load.reportData}`).join("\n") ?? "",
		};
	}
}
class MovedLoad extends ParamAssignableObject {
	name;
	weight;
	id;

	constructor(loadData: { name: any; weight: any; id: any }) {
		super();
		this.constructFromParams(loadData);
	}

	static constructFromDB = (dbLoadData) => {
		return new MovedLoad({
			name: dbLoadData.LoadName,
			weight: dbLoadData.LoadWeight,
			id: dbLoadData.LoadId,
		});
	};

	public get reportData() {
		return `ID: ${this.id} | Type: ${this.name} | Weight: ${this.weight}`;
	}
}

class FaultEvent extends EvalEvent {
	faultName;
	duration;

	constructor(faultEventData: { actualTime: any; id: any; type: any; simTime: any; faultName: any; duration: any }) {
		super(faultEventData);
		this.constructFromParams(faultEventData);
	}

	static constructFromDB = (eventData) => {
		return new FaultEvent({
			actualTime: eventData.ActualTime,
			id: eventData.Id,
			type: eventData.$type,
			simTime: eventData.SimulationTime,

			faultName: eventData.FaultName,
			duration: eventData.Duration,
		});
	};

	public get reportData() {
		const friendlyDuration = HelperFunctions.getFriendlyDuration(this.duration);
		return {
			title: "Fault",
			description: `${this.faultName} Duration: ${friendlyDuration}`,
		};
	}
}

class CollisionEvent extends EvalEvent {
	actorObject;
	collidedWith;
	description;
	severity;
	velocity;

	constructor(collisionEventData: {
		actualTime: any;
		id: any;
		type: any;
		simTime: any;

		actorObject: any;
		collidedWith: any;
		description: any;
		severity: any;
		velocity: any;
	}) {
		super(collisionEventData);
		this.constructFromParams(collisionEventData);
	}

	static constructFromDB = (eventData) => {
		return new CollisionEvent({
			actualTime: eventData.ActualTime,
			id: eventData.Id,
			type: eventData.$type,
			simTime: eventData.SimulationTime,

			actorObject: eventData.ActorObject,
			collidedWith: eventData.CollidedWith,
			description: eventData.Description,
			severity: eventData.Severity,
			velocity: eventData.Velocity,
		});
	};

	get reportData() {
		const useMetric = HelperFunctions.currentUnits().value === MEASUREMENT_UNITS.METRIC.value;
		const convertedVelocity = useMetric ? HelperFunctions.convertToKPH(this.velocity) : HelperFunctions.convertToMPH(this.velocity);
		const friendlyVelocity = `${Math.round(convertedVelocity * 100) / 100} ${useMetric ? "kph" : "mph"}`;

		return {
			title: `${HelperFunctions.getSeverityLabel(this.severity)} Collision Occurred`,
			description: `${this.actorObject} collided with ${this.collidedWith} at velocity ${friendlyVelocity}`,
		};
	}
}

class AlarmEvent extends EvalEvent {
	name;
	description;

	constructor(alarmEventData: { actualTime: any; id: any; type: any; simTime: any; name: any; description: any }) {
		super(alarmEventData);
		this.constructFromParams(alarmEventData);
	}

	static constructFromDB = (eventData) => {
		return new AlarmEvent({
			actualTime: eventData.ActualTime,
			id: eventData.Id,
			type: eventData.$type,
			simTime: eventData.SimulationTime,

			name: eventData.Name,
			description: eventData.Description,
		});
	};

	public get reportData() {
		return {
			title: "Alarm Occurred",
			description: `Description: ${this.description}`,
		};
	}
}

class IncidentEvent extends EvalEvent {
	name;
	duration;
	description;
	severity;

	constructor(incidentEventData: { actualTime: any; id: any; type: any; simTime: any; name: any; duration: any; description: any; severity: any }) {
		super(incidentEventData);
		this.constructFromParams(incidentEventData);
	}

	static constructFromDB = (eventData) => {
		return new IncidentEvent({
			actualTime: eventData.ActualTime,
			id: eventData.Id,
			type: eventData.$type,
			simTime: eventData.SimulationTime,

			name: eventData.Name,
			duration: eventData.Duration,
			description: eventData.Description,
			severity: eventData.Severity,
		});
	};

	public get reportData() {
		const severityLabel = HelperFunctions.getSeverityLabel(this.severity);
		const friendlyDuration = HelperFunctions.getFriendlyDuration(this.duration);
		return {
			title: "Incident Recorded",
			description: `${this.name} occurred with severity ${severityLabel} Description: ${this.description} Duration: ${friendlyDuration}`,
		};
	}
}
//#endregion

class EvalDataPoint extends ParamAssignableObject {
	name;
	value;

	constructor(dataPoint: { name: any; value: any }) {
		super();
		this.constructFromParams(dataPoint);
	}

	static constructFromDB = (dataPoint) =>
		new EvalDataPoint({
			name: dataPoint.Name,
			value: dataPoint.Value,
		});
}

//#region Completion Data & Ranking
export class CompletionData extends ParamAssignableObject {
	percentComplete;
	timeSpent;
	timeRemaining;
	rank;

	constructor(dbCompletionData: { percentComplete: any; timeSpent: any; timeRemaining: any; rank: any }) {
		super();
		this.constructFromParams(dbCompletionData);
	}

	static constructFromDB = (dbCompletionData) =>
		new CompletionData({
			percentComplete: dbCompletionData.PercentComplete,
			timeSpent: dbCompletionData.TimeSpent,
			timeRemaining: dbCompletionData.TimeRemaining,
			rank: dbCompletionData.Rank,
		});
}

export class RankTier extends ParamAssignableObject {
	rank;
	userIds;

	constructor(dbRankData: { rank: any; userIds: any }) {
		super();
		this.constructFromParams(dbRankData);
	}

	static constructFromDB = (dbRankData) =>
		new RankTier({
			rank: dbRankData.Rank,
			userIds: dbRankData.UserIds?.$values.filter((userId) => !Validate.isNullOrEmpty(userId)),
		});
}
//#endregion
