import React, { useState, useEffect } from "react";
import { TRANSLATIONS } from "../data-structures/localization";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import Nav from "../data-structures/navigation";
import { Validate } from "../helpers/validation";
import { Loading } from "../common/loading-icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Help = () => (
	<div className="side-nav-container container">
		<h1>{TRANSLATIONS.HEADERS.HELP}</h1>

		<div className="side-nav-content row">
			{/* <div className="side-nav-sidebar no-print col-md-2 sticky-top">
				<NavLink to={Nav.PATHS.HELP.ROOT} end>
					{TRANSLATIONS.HEADERS.HELP}
				</NavLink>
				{[
					{ path: Nav.PATHS.HELP.GETTING_STARTED, title: TRANSLATIONS.HEADERS.GETTING_STARTED },
					{ path: Nav.PATHS.HELP.DEVICE_SETUP, title: TRANSLATIONS.HEADERS.DEVICE_SETUP },
					{ path: Nav.PATHS.HELP.RUNNING_A_CRANE, title: TRANSLATIONS.HEADERS.RUNNING_A_CRANE },
					{ path: Nav.PATHS.HELP.AAR_AND_DRONE_VIEW, title: TRANSLATIONS.HEADERS.AAR_AND_DRONE_VIEW },
				]
					.filter((path) => !Validate.isNullOrEmpty(Nav.ARTICLES[path.path]))
					.map((path, i) => (
						<NavLink key={i} to={path.path}>
							{path.title}
						</NavLink>
					))}
			</div> */}
			<div className="side-nav-section col-md-10">
				<Outlet />
			</div>
		</div>
	</div>
);

export const HelpIndex = () => {
	return (
		<div className="side-nav-index">
			{/* <h4>
				<FontAwesomeIcon icon="fa-regular fa-arrow-left" />
				<span>{TRANSLATIONS.HEADERS.SIDE_NAV_INDEX}</span>
			</h4> */}
			<a className="btn" href="https://globalsiminc.atlassian.net/wiki/spaces/HELP/pages/58327382/Help" target="_blank" rel="noreferrer">
				{TRANSLATIONS.CLICK_HERE}
			</a>
		</div>
	);
};

let lastArticle = null;
export const Article = (props = { path: "" }) => {
	const [walkthroughLoaded, setWalkthroughLoaded] = useState(false);
	const [videoLoaded, setVideoLoaded] = useState(false);

	const location = useLocation();
	useEffect(() => {
		let currentArticle = window.location.pathname;
		if (!currentArticle.includes(lastArticle)) {
			setWalkthroughLoaded(false);
			setVideoLoaded(false);
		}
		lastArticle = currentArticle;
	}, [location]);

	let article = Nav.ARTICLES[props.path];
	return (
		<div key={props.path}>
			{!Validate.isNullOrEmpty(article) && (
				<>
					{article.video != null && (
						<>
							{!videoLoaded && <Loading />}
							<iframe
								title={article.video.title}
								src={article.video.path}
								width="100%"
								height="640"
								allow="autoplay; fullscreen; picture-in-picture"
								allowFullScreen
								onLoad={() => setVideoLoaded(true)}
							></iframe>
						</>
					)}

					{article.walkthrough != null && (
						<>
							{!walkthroughLoaded && <Loading />}
							<iframe
								title={props.path}
								src={article.walkthrough}
								width="100%"
								height="640"
								allowFullScreen
								onLoad={() => setWalkthroughLoaded(true)}
							></iframe>
						</>
					)}
				</>
			)}
		</div>
	);
};
