import React from "react";
import { NavLink } from "react-router-dom";
import Nav from "../../data-structures/navigation";
import { TRANSLATIONS } from "../../data-structures/localization";
import RegisterUserForm from "../../forms/register-user-form";
import ImageCarousel from "./image-carousel";
import "./registration.scss";

export const Signup = () => (
	<div className="registration-container">
		<div className="container">
			<ImageCarousel id="signup-carousel" />

			<div className="signup-container col-lg-4 col-xl-5">
				<RegisterUserForm cloudSelfRegister />

				<div className="login-btn w100">
					<div>{TRANSLATIONS.FORMS.ALREADY_HAVE_AN_ACCOUNT}</div>
					<NavLink to={Nav.PATHS.LOGIN}>{TRANSLATIONS.FORMS.LOG_IN_INSTEAD}</NavLink>
				</div>
			</div>
		</div>
	</div>
);
