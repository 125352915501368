import React from 'react';
import { TRANSLATIONS } from '../../data-structures/localization';
import GroupList from '../group-management/group-list';

export const MyGroups = () => {
	return (
		<div className="groups-container">
			<h2 className="no-print">{TRANSLATIONS.HEADERS.GROUPS}</h2>
			<GroupList assignedGroupsOnly viewOnly />
		</div>
	);
};