import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import GSForms from "./form-elements";
import Nav from "../data-structures/navigation";
import { TRANSLATIONS } from "../data-structures/localization";
import { globalState } from "../store";
import { TMSHelpers } from "../helpers/tms-helpers";
import FormHelpers from "../helpers/form-helpers";
import { HelperFunctions } from "../helpers/helper-functions";
import { Loading } from "../common/loading-icon";
import { ERROR_CODES } from "../data-structures/gs-constants";
import { Validate } from "../helpers/validation";
import ResetPasswordForm from "./reset-password-form";

export default class ForgotPassword extends Component {
	constructor(props) {
		super(props);

		const queryParameters = HelperFunctions.getUrlParams();

		this.state = {
			initialized: false,

			userIdFromURL: queryParameters.get("id"),
			tokenFromURL: queryParameters.get("token"),
			tokenVerified: null,

			username: "",

			formErrors: [],
			responseError: null,

			submitting: false,
			saved: false,
		};
	}

	componentDidMount() {
		if (this.state.tokenFromURL) {
			TMSHelpers.validatePasswordToken(this.state.userIdFromURL, this.state.tokenFromURL).then((response) => {
				this.setState({ tokenVerified: response.Success, initialized: true, ...this.getResponseErrorState(response) });
			});
		} else {
			this.setState({ initialized: true });
		}
	}

	handleTriggerPasswordReset = () => {
		this.setState({ submitting: true, saved: false }, () => {
			TMSHelpers.triggerPasswordResetRequest(this.state.username).then((response) => {
				this.setState({
					submitting: false,
					saved: true,
				});
			});
		});
	};

	getFormErrors = () => {
		const errors = [];
		const state = this.state;
		if (this.props.requireCurrentPassword && Validate.isNullOrEmpty(state.currentPassword)) {
			errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["currentPassword"]));
		}

		errors.push(...FormHelpers.getPasswordErrors(state.newPassword, ["newPassword"]));

		if (Validate.isNullOrEmpty(state.confirmNewPassword)) {
			errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.CONFIRM_PASSWORD, ["confirmNewPassword"]));
		} else if (state.newPassword !== state.confirmNewPassword) {
			errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.PASSWORD_MATCH, ["newPassword", "confirmNewPassword"]));
		}

		return errors;
	};

	getResponseErrorState = (response) => {
		let errorState = { formErrors: [], responseError: null };

		if (response && response.errorMsg) {
			switch (response.key) {
				case ERROR_CODES.PASSWORD_INVALID.key:
					errorState = { ...errorState, formErrors: [new GSForms.FormError(response.errorMsg, ["newPassword"])] };
					break;

				case ERROR_CODES.INVALID_CREDS.key:
					errorState = {
						...errorState,
						formErrors: [new GSForms.FormError("Password Incorrect. Please enter your current password", ["currentPassword"])],
					};
					break;

				default:
					errorState = { ...errorState, responseError: HelperFunctions.getResponseError(response) };
					break;
			}
		}

		return errorState;
	};

	render() {
		return (
			<div className="reset-password-container container">
				{this.state.initialized ? (
					<>
						{this.state.tokenFromURL &&
						this.state.userIdFromURL &&
						this.state.responseError?.key !== ERROR_CODES.INVALID_TOKEN.key &&
						this.state.responseError?.key !== ERROR_CODES.TOKEN_EXPIRED.key ? (
							this.state.tokenVerified ? (
								<ResetPasswordForm userId={this.state.userIdFromURL} verificationToken={this.state.tokenFromURL} />
							) : (
								<>
									<h2>{TRANSLATIONS.HEADERS.RESET_PASSWORD}</h2>
									<Loading />
								</>
							)
						) : (
							<form>
								<h2>{TRANSLATIONS.HEADERS.RESET_PASSWORD}</h2>

								<GSForms.InputField
									label={TRANSLATIONS.FORMS.USERNAME}
									dataState="username"
									fieldValue={this.state.username}
									autoCompleteString="username"
									formField
									handleChange={(e) => FormHelpers.handleChange(e, this)}
								/>
								<GSForms.Button onClick={this.handleTriggerPasswordReset} />
							</form>
						)}

						<GSForms.ErrorContainer message={this.state.responseError} />
						{this.state.submitting && <Loading />}
						{this.state.saved && <GSForms.SavedCheck customMessage={TRANSLATIONS.EMAIL.PASSWORD_RESET_EMAIL_SENT} />}
					</>
				) : (
					<>
						<h2>{TRANSLATIONS.HEADERS.RESET_PASSWORD}</h2>
						<Loading />
					</>
				)}

				{globalState.loggedIn ? (
					<NavLink to={Nav.PATHS.DASHBOARD.ROOT}>{TRANSLATIONS.FORMS.BACK_TO_DASHBOARD}</NavLink>
				) : (
					<NavLink to={Nav.PATHS.LOGIN}>{TRANSLATIONS.FORMS.BACK_TO_LOGIN}</NavLink>
				)}
			</div>
		);
	}
}
