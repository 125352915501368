import React, { Component } from "react";
import { TRANSLATIONS } from "../../data-structures/localization";
import { USER_IMPORT_HEADERS } from "../../data-structures/gs-constants";
import { globalState, onGlobalStateChange } from "../../store";
import RegisterUserForm from "../../forms/register-user-form";
import GSForms from "../../forms/form-elements";
import { Loading } from "../../common/loading-icon";
import { UserImportExport } from "../../forms/user-import-export";
import ResetPasswordForm from "../../forms/reset-password-form";
import EditUserForm from "../../forms/edit-user-form";
import { Card, CardList, CardSelectionPanel } from "../../common/cards";
import { ImportHelpers } from "../../helpers/import-helpers";
import { CSVLink } from "react-csv";
import { TMSHelpers } from "../../helpers/tms-helpers";
import { HelperFunctions } from "../../helpers/helper-functions";
import { Validate } from "../../helpers/validation";
import "./user-management.scss";

export default class UserManagement extends Component {
	//#region Initialization
	constructor(props) {
		super(props);

		this.filterOptions = [
			{ label: "First Name - A-Z", value: "FIRST_NAME_A_TO_Z" },
			{ label: "First Name - Z-A", value: "FIRST_NAME_Z_TO_A" },
			{ label: "Last Name - A-Z", value: "LAST_NAME_A_TO_Z" },
			{ label: "Last Name - Z-A", value: "LAST_NAME_Z_TO_A" },
			{ label: "Username - A-Z", value: "USERNAME_A_TO_Z" },
			{ label: "Username - Z-A", value: "USERNAME_Z_TO_A" },
			{ label: "Newest - Oldest", value: "OLDEST_NEWEST" },
			{ label: "Oldest - Newest", value: "NEWEST_OLDEST" },
		];

		this.state = {
			addingNewUser: false,
			selectedUser: null,
			editMode: false,
			userIdToDelete: null,

			users: null,
			searchQuery: "",
			sortOrder: this.filterOptions[0],
			activeOnly: false,
			selectedGroups: [],

			importingUsers: false,
			deletingUser: false,
		};
	}

	componentDidMount() {
		const userList = this.getSortedFilteredUserList();
		if (userList) this.setState({ users: userList });

		this.unsubscribe = onGlobalStateChange(() => {
			if (globalState.orgData?.userList) {
				this.setState({
					users: this.getSortedFilteredUserList(),
					selectedUser: globalState.orgData.userList.find((x) => x.id === this.state.selectedUser?.id),
				});
			}
		});
	}

	componentWillUnmount() {
		this.unsubscribe();
	}
	//#endregion

	handleFilterChange = (newState) => {
		this.setState(newState, () => {
			const userList = this.getSortedFilteredUserList();
			this.setState({ users: userList || [] });
		});
	};

	getSortedFilteredUserList = () => {
		const accessibleUsers =
			globalState.orgData &&
			(HelperFunctions.loggedInAsAdmin()
				? globalState.orgData.userList
				: globalState.orgData?.userList.filter((user) => HelperFunctions.userIsStudent(user) || user.id === globalState.userData.id));

		return accessibleUsers
			?.filter((user) => {
				const activeStatusMatches = !this.state.activeOnly || user.active;

				const groupsMatch =
					this.state.selectedGroups.length === 0 || user.groups.some((id) => this.state.selectedGroups.some((sg) => sg.id === id));

				const searchableEmailString = this.state.searchQuery?.includes("@")
					? user.emailAddress
					: user.emailAddress?.substring(0, user.emailAddress.indexOf("@")) || "";
				const searchableTermsKey = [user.username, user.firstName, user.lastName, searchableEmailString].join(", ").toLowerCase();
				const searchMatches = this.state.searchQuery
					.toLowerCase()
					.replace(",", " ")
					.split(" ")
					.filter((qParam) => !Validate.isNullOrEmpty(qParam))
					.every((qParam) => searchableTermsKey.includes(qParam));

				return activeStatusMatches && groupsMatch && searchMatches;
			})
			.sort((a, b) => {
				const sortUsers = (a, b, ascending = false) => {
					if (typeof a === "string" || typeof b === "string") [a, b] = [a.toUpperCase(), b.toUpperCase()];
					return a === b ? 0 : (ascending ? a < b : a > b) ? -1 : 1;
				};

				switch (this.state.sortOrder.value) {
					case "FIRST_NAME_A_TO_Z":
						return sortUsers(a.firstName, b.firstName, true);
					case "FIRST_NAME_Z_TO_A":
						return sortUsers(a.firstName, b.firstName);
					case "LAST_NAME_A_TO_Z":
						return sortUsers(a.lastName, b.lastName, true);
					case "LAST_NAME_Z_TO_A":
						return sortUsers(a.lastName, b.lastName);
					case "USERNAME_A_TO_Z":
						return sortUsers(a.username, b.username, true);
					case "USERNAME_Z_TO_A":
						return sortUsers(a.username, b.username);
					case "OLDEST_NEWEST":
						return sortUsers(new Date(a.dateCreated), new Date(b.dateCreated));
					case "NEWEST_OLDEST":
						return sortUsers(new Date(a.dateCreated), new Date(b.dateCreated), true);
					default:
						return 0;
				}
			});
	};

	generateUserCard = (user) => {
		const isSelected = this.state.selectedUser?.id === user.id;
		return (
			<div key={user.id}>
				<Card
					className="selectable"
					title={`${user.firstName} ${user.lastName}`}
					subtitle={HelperFunctions.getRole(user.role).label}
					detailsObj={{
						[TRANSLATIONS.USER_MANAGEMENT.USERNAME]: user.username,
					}}
					isDisabled={this.state.deletingUser}
					isSelected={isSelected}
					isActive={user.active}
					handleSelect={() =>
						this.setState({
							selectedUser: user,
							editMode: false,
							addingNewUser: false,
							importingUsers: false,
						})
					}
					displayEditButton={true}
					inEditMode={this.state.editMode}
					handleEdit={() => {
						this.setState({
							...(!isSelected && { selectedUser: user }),
							editMode: true,
						});
					}}
					cancelEdit={() => this.setState({ editMode: false })}
					displayRemoveButton={HelperFunctions.loggedInAsAdmin() && user.id !== globalState.userData.id}
					handleRemove={() => this.setState({ userIdToDelete: user.id })}
					cancelRemove={() => this.setState({ userIdToDelete: null })}
					markedForRemoval={this.state.userIdToDelete === user.id}
				/>
				{this.state.userIdToDelete === user.id &&
					(this.state.deletingUser ? (
						<Loading />
					) : (
						<div className="flexbox col-12">
							<GSForms.PermanentActionWarning />

							<GSForms.Button
								buttonText={TRANSLATIONS.FORMS.CANCEL}
								onClick={() => this.setState({ userIdToDelete: null })}
								type="button"
							/>
							<GSForms.Button
								className="btn-red"
								buttonText={TRANSLATIONS.USER_MANAGEMENT.DELETE_USER}
								onClick={() =>
									this.setState(
										{
											selectedUser: this.state.selectedUser?.id !== user.id ? this.state.selectedUser : null,
											deletingUser: true,
										},
										() =>
											TMSHelpers.deleteUserRecord(user.username).then(() => {
												this.setState({
													deletingUser: false,
												});
												TMSHelpers.refreshGlobalData();
											})
									)
								}
								type="button"
							/>
						</div>
					))}
			</div>
		);
	};

	UserPanel = () => (
		<>
			{this.state.selectedUser && (
				<>
					<EditUserForm
						key={this.state.selectedUser.id}
						selectedUser={this.state.selectedUser}
						editMode={this.state.editMode}
						toggleEditMode={(value) => this.setState({ editMode: value })}
					/>
					{!HelperFunctions.currentOrgIsCloudBased() && (
						<ResetPasswordForm
							username={this.state.selectedUser.username}
							userId={this.state.selectedUser.id}
							requireCurrentPassword={this.state.selectedUser.id === globalState.userData.id}
						/>
					)}
				</>
			)}
			{this.state.addingNewUser && (
				<RegisterUserForm
					cloudSelfRegister={false}
					title={TRANSLATIONS.USER_MANAGEMENT.ADD_USER}
					onAddUser={(newUserId) =>
						this.setState({
							addingNewUser: false,
							searchQuery: "",
							selectedUser: this.state.users.find((user) => user.id === newUserId),
						})
					}
				>
					<GSForms.Button buttonText={TRANSLATIONS.FORMS.CANCEL} onClick={() => this.setState({ addingNewUser: false })} type="button" />
				</RegisterUserForm>
			)}
			{this.state.importingUsers && (
				<UserImportExport userList={this.state.users} toggleVisibility={() => this.setState({ importingUsers: false })} />
			)}
		</>
	);

	render() {
		return (
			<div className="user-management-container container">
				<h1>{TRANSLATIONS.HEADERS.USER_MANAGEMENT}</h1>

				{this.state.users ? (
					<>
						<div className="user-filters">
							<GSForms.SearchBox
								label={TRANSLATIONS.USER_MANAGEMENT.SEARCH}
								fieldValue={this.state.searchQuery}
								dataState="searchQuery"
								handleChange={(e) => this.handleFilterChange({ searchQuery: e.target.value })}
							/>
							<GSForms.SelectField
								label={TRANSLATIONS.USER_MANAGEMENT.SORT_ORDER}
								dataState={"sortOrder"}
								fieldValue={this.state.sortOrder}
								fieldOptions={this.filterOptions}
								handleChange={(option) => this.handleFilterChange({ sortOrder: option })}
							/>
							<GSForms.CheckBoxField
								label={TRANSLATIONS.USER_MANAGEMENT.ACTIVE_USERS_ONLY}
								labelFirst
								dataState="activeOnly"
								fieldValue={this.state.activeOnly}
								handleChange={(e) => this.handleFilterChange({ [e.target.dataset.state]: e.target.checked })}
							/>
							<GSForms.MultiSelectField
								label={TRANSLATIONS.USER_MANAGEMENT.SELECTED_GROUPS}
								dataState={"selectedGroups"}
								fieldValue={this.state.selectedGroups}
								fieldOptions={globalState.groupList.map((g) => ({ ...g, label: g.name, value: g.id }))}
								handleChange={(option) => this.handleFilterChange({ selectedGroups: option })}
							/>
						</div>

						<div className="flexbox justify-end">
							<GSForms.Button
								onClick={() => this.setState({ addingNewUser: true, importingUsers: false, selectedUser: null })}
								buttonText={TRANSLATIONS.USER_MANAGEMENT.ADD_USER}
								type="button"
							/>
							<GSForms.Button
								onClick={() => this.setState({ importingUsers: true, addingNewUser: false, selectedUser: null })}
								buttonText={TRANSLATIONS.USER_IMPORT.IMPORT_USERS}
								type="button"
							/>
							<GSForms.Button
								customButton={
									<CSVLink
										className="btn"
										data={ImportHelpers.prepUserListForExport(this.state.users)}
										headers={USER_IMPORT_HEADERS}
										target="_blank"
										filename={"user-list.csv"}
										onClick={() => this.setState({ importingUsers: false, addingNewUser: false })}
									>
										{TRANSLATIONS.USER_IMPORT.EXPORT_USERS}
									</CSVLink>
								}
							/>
						</div>

						<CardSelectionPanel
							panelIsOpen={this.state.selectedUser || this.state.addingNewUser || this.state.importingUsers}
							handleDetailsClose={() => this.setState({ selectedUser: null, addingNewUser: false, importingUsers: false })}
							detailsTitle={this.state.selectedUser && `${this.state.selectedUser.firstName} ${this.state.selectedUser.lastName}`}
						>
							<div>
								<h2>{TRANSLATIONS.USER_MANAGEMENT.USERS}</h2>
								<CardList>
									{this.state.users && this.state.users.length > 0
										? this.state.users.map((user) => this.generateUserCard(user))
										: TRANSLATIONS.USER_MANAGEMENT.NO_USERS_MESSAGE}
								</CardList>
							</div>
							<div>{this.UserPanel()}</div>
						</CardSelectionPanel>
					</>
				) : (
					<Loading />
				)}
			</div>
		);
	}
}
