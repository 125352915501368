import { LANGUAGE_CODES } from "./lang-codes.js";
import ParamAssignableObject from "./param-assignable-object.tsx";
import { Validate } from "../helpers/validation.js";

export class OrgData extends ParamAssignableObject {
	id;
	name;
	defaultUnits;
	defaultLanguage;
	subscriptionExpirationDate;
	launcherCollection;
	simulatorCollection;
	scenarioEditorCollection;
	iosCollection;
	isCloudBased;
	restrictConnectionsByLicense;
	studentLicenses;
	instructorLicenses;
	isCloudStandardOrg;
	userList;

	constructor(orgParams: {
		id: any;
		name: any;
		defaultUnits: any;
		defaultLanguage: any;
		subscriptionExpirationDate: any;
		launcherCollection: any;
		simulatorCollection: any;
		scenarioEditorCollection: any;
		iosCollection: any;
		isCloudBased: any;
		restrictConnectionsByLicense: any;
		studentLicenses: any;
		instructorLicenses: any;
		isCloudStandardOrg: any;
	}) {
		super();
		this.constructFromParams(orgParams);
	}

	static constructFromDB = (dbOrg, isCloudStandardOrg) =>
		new OrgData({
			id: dbOrg.Id,
			name: dbOrg.Name,
			defaultUnits: dbOrg.DefaultUnits,
			defaultLanguage: dbOrg.DefaultLanguageCode?.toLowerCase() || LANGUAGE_CODES.ENGLISH,
			subscriptionExpirationDate: Validate.isValidDate(dbOrg.SubscriptionExpiration) ? new Date(dbOrg.SubscriptionExpiration) : null,
			launcherCollection: dbOrg.AssociatedLauncherCollection,
			simulatorCollection: dbOrg.AssociatedSimulatorCollection,
			scenarioEditorCollection: dbOrg.AssociatedScenarioEditorCollection,
			iosCollection: dbOrg.AssociatedIOSCollection,
			isCloudBased: dbOrg.IsCloudBased,
			restrictConnectionsByLicense: dbOrg.RestrictConnectionsByLicense,
			studentLicenses: dbOrg.StudentLicenses,
			instructorLicenses: dbOrg.InstructorLicenses,
			isCloudStandardOrg: isCloudStandardOrg,
		});
}
