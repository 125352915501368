import React, { Component } from "react";
import GSForms from "./form-elements";
import { HelperFunctions } from "../helpers/helper-functions";
import { LANGUAGES } from "../data-structures/lang-codes";
import { TRANSLATIONS } from "../data-structures/localization";
import { MEASUREMENT_UNITS, ERROR_CODES } from "../data-structures/gs-constants";
import { TMSHelpers } from "../helpers/tms-helpers";
import { Loading } from "../common/loading-icon";
import { globalState, setGlobalState, onGlobalStateChange } from "../store";
import { NavLink, Navigate } from "react-router-dom";
import Nav from "../data-structures/navigation";
import { Validate } from "../helpers/validation";
import FormHelpers from "../helpers/form-helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class UserDataForm extends Component {
	//#region Initialization
	constructor(props) {
		super(props);

		this.state = {
			firstName: "",
			lastName: "",
			username: "",
			emailAddress: "",
			emailVerified: "",
			language: "",
			units: "",

			formErrors: [],
			responseError: null,

			submitting: false,
			saved: false,

			profileComplete: false,
		};
	}

	componentDidMount() {
		this.initialize();

		this.unsubscribe = onGlobalStateChange(() => {
			if (globalState.userData) {
				this.initialize();
				this.setState({
					units: Object.values(MEASUREMENT_UNITS).find((x) => x.value === globalState.userData.units),
					...(this.state.formErrors.length > 0 && { formErrors: this.getFormErrors() || [] }),
					...(this.state.responseError && {
						responseError: Object.values(ERROR_CODES).find((x) => x.key === this.state.responseError.key),
					}),
				});
			}
		});
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	initialize = () => {
		const userData = globalState.userData;
		userData &&
			this.setState({
				firstName: userData.firstName,
				lastName: userData.lastName,
				username: userData.username,
				emailAddress: userData.emailAddress,
				emailVerified: userData.emailVerified,
				language: Object.values(LANGUAGES).find((x) => x.value === userData.language),
				units: Object.values(MEASUREMENT_UNITS).find((x) => x.value === userData.units),
				formErrors: [],
				responseError: null,
				profileComplete: HelperFunctions.profileIsComplete(),
			});
	};
	//#endregion

	handleSubmit = async () => {
		const formErrors = this.getFormErrors();
		if (formErrors.length > 0) this.setState({ submitting: false, saved: false, responseError: null, formErrors: formErrors });
		else {
			this.setState({ submitting: true, saved: false, responseError: null, formErrors: [] }, async () => {
				await TMSHelpers.sendRequest("UpdateUserRequest", {
					UserToUpdate: {
						Id: globalState.userData.id,
						FirstName: this.state.firstName,
						LastName: this.state.lastName,
						Username: this.state.username,
						EmailAddress: this.state.emailAddress,
						Units: this.state.units.value,
						LanguageCode: this.state.language.value.toUpperCase(),
						UserType: globalState.userData.role,
					},
				}).then((response) => {
					let trimmedUserData;
					if (response.Success) {
						trimmedUserData = TMSHelpers.trimUserData(JSON.parse(response.ResponseMessage).UpdatedUser);
						TMSHelpers.storeUserData(trimmedUserData);
						setGlobalState({ userData: trimmedUserData, locale: trimmedUserData.language });
					}
					this.setState({
						submitting: false,
						saved: response.Success,
						...this.getErrorState(response),
						profileComplete: HelperFunctions.profileIsComplete(trimmedUserData),
					});
				});
			});
		}
	};

	getFormErrors = () => {
		const errors = [];
		const state = this.state;

		if (Validate.isNullOrEmpty(state.firstName)) {
			errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["firstName"]));
		}

		if (Validate.isNullOrEmpty(state.lastName)) {
			errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["lastName"]));
		}

		errors.push(...FormHelpers.getUsernameErrors(state.username, ["username"]));

		if (HelperFunctions.currentOrgIsCloudBased()) {
			if (Validate.isNullOrEmpty(state.emailAddress)) {
				errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["emailAddress"]));
			} else if (!Validate.isValidEmailAddress(state.emailAddress)) {
				errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.EMAIL_INVALID, ["emailAddress"]));
			}
		}

		return errors;
	};

	getErrorState = (response) => {
		if (response && response.errorMsg)
			switch (response.key) {
				case ERROR_CODES.USERNAME_TAKEN.key:
					return { formErrors: [new GSForms.FormError(response.errorMsg, ["username"])] };

				default:
					return { responseError: HelperFunctions.getResponseError(response) };
			}
	};

	render() {
		return this.props.newSignup && this.state.profileComplete ? (
			<Navigate to={Nav.PATHS.DOWNLOADS} />
		) : (
			<div className="user-data-form form-container">
				{!this.props.newSignup && <h2>{TRANSLATIONS.HEADERS.USER_DATA}</h2>}
				{globalState.userData && globalState.orgData ? (
					<form autoComplete="on">
						<GSForms.InputField
							label={TRANSLATIONS.FORMS.FIRST_NAME}
							dataState="firstName"
							fieldValue={this.state.firstName}
							autoCompleteString="given-name"
							formField
							errors={this.state.formErrors}
							handleChange={(e) => FormHelpers.handleChange(e, this)}
						/>
						<GSForms.InputField
							label={TRANSLATIONS.FORMS.LAST_NAME}
							dataState="lastName"
							fieldValue={this.state.lastName}
							autoCompleteString="family-name"
							formField
							errors={this.state.formErrors}
							handleChange={(e) => FormHelpers.handleChange(e, this)}
						/>
						<GSForms.InputField
							label={TRANSLATIONS.FORMS.USERNAME}
							dataState="username"
							fieldValue={this.state.username}
							autoCompleteString="username"
							formField
							errors={this.state.formErrors}
							handleChange={(e) => FormHelpers.handleChange(e, this)}
						/>
						<GSForms.InputField
							label={TRANSLATIONS.FORMS.EMAIL_ADDRESS}
							dataState="emailAddress"
							fieldValue={this.state.emailAddress}
							autoCompleteString="email"
							formField
							type="email"
							errors={this.state.formErrors}
							handleChange={(e) => FormHelpers.handleChange(e, this)}
						/>
						{HelperFunctions.currentOrgIsCloudBased() && (
							<GSForms.InfoField
								label={TRANSLATIONS.FORMS.EMAIL_VERIFIED}
								fieldValue={
									this.state.emailVerified ? (
										<FontAwesomeIcon icon="fa-solid fa-check" />
									) : (
										<NavLink to={Nav.PATHS.VERIFY_EMAIL}>{TRANSLATIONS.NAVLINKS.VERIFY_EMAIL}</NavLink>
									)
								}
								formField
							/>
						)}
						<GSForms.SelectField
							label={TRANSLATIONS.FORMS.LANGUAGE_PREFERENCE}
							fieldOptions={HelperFunctions.getSortedLanguages()}
							fieldValue={this.state.language}
							formField
							errors={this.state.formErrors}
							handleChange={(option) => FormHelpers.handleSelectChange(option, "language", this)}
						/>
						<GSForms.SelectField
							label={TRANSLATIONS.FORMS.UNITS_PREFERENCE}
							fieldOptions={Object.values(MEASUREMENT_UNITS)}
							fieldValue={this.state.units}
							formField
							errors={this.state.formErrors}
							handleChange={(option) => FormHelpers.handleSelectChange(option, "units", this)}
						/>

						{globalState.userData.subscriptionExpirationDate && (
							<GSForms.InfoField
								label={TRANSLATIONS.USER_MANAGEMENT.SUBSCRIPTION_EXPIRATION_DATE}
								fieldValue={new Date(globalState.userData.subscriptionExpirationDate).toLocaleString(globalState.locale, {
									dateStyle: "short",
									timeStyle: "medium",
								})}
								formField
							/>
						)}

						<GSForms.ErrorContainer message={this.state.responseError} />
						{this.state.submitting && <Loading />}
						{this.state.saved && <GSForms.SavedCheck toggleVisible={() => this.setState({ saved: false })} />}

						<div className="flexbox">
							<GSForms.Button buttonText={TRANSLATIONS.FORMS.RESET} onClick={this.initialize} type="button" />
							<GSForms.Button onClick={this.handleSubmit} />
						</div>
					</form>
				) : (
					<Loading />
				)}
			</div>
		);
	}
}
