import { ChartDatapoint, SortType } from "../data-structures/chart-data.tsx";
import { CourseData, LessonData, UnitData } from "../data-structures/course-catalog-data.tsx";
import { Evaluation } from "../data-structures/evaluation-data.tsx";
import { globalState } from "../store.js";

export class EvalHelpers {
	//#region Completion Data
	static isLessonComplete = (lesson, evalHistory) => this.getEvalsForLesson(lesson.id, evalHistory).find((e) => e.completed);

	static getCourseCompletionData = (course: CourseData, evalHistory) => {
		let cd = {
			completedLessonCount: 0,
			totalLessonCount: 0,
			timeSpent: 0,
			timeRemaining: 0,
			percentComplete: 0,
			nextLesson: null,
		};

		if (!course.active || course.deleted) return {};

		course.unitList.forEach((unit: UnitData) => {
			if (!unit.active || unit.deleted) return;

			unit.lessonList.forEach((lesson: LessonData) => {
				if (!lesson.active || lesson.deleted) return;

				const lessonComplete = this.isLessonComplete(lesson, evalHistory);
				if (!cd.nextLesson && !lessonComplete) cd.nextLesson = lesson;
				cd.completedLessonCount += lessonComplete ? 1 : 0;
				cd.totalLessonCount++;
				cd.timeSpent += this.getTimeSpentOnLesson(lesson, evalHistory);
				cd.timeRemaining += this.getTimeRemainingOnLesson(lesson, evalHistory);
			});
		});

		if (cd.totalLessonCount > 0) {
			cd.percentComplete = cd.completedLessonCount / cd.totalLessonCount;
			return cd;
		} else return {};
	};

	static getAggCourseCompletionData = (courseIds, evalHistory) => {
		return courseIds.reduce((tcd, courseId) => {
			const course = globalState.courseCatalog?.find((x) => x.id === courseId);
			if (course) {
				const cd: any = EvalHelpers.getCourseCompletionData(course, evalHistory);
				return {
					completedLessonCount: (tcd.completedLessonCount || 0) + cd.completedLessonCount,
					totalLessonCount: (tcd.totalLessonCount || 0) + cd.totalLessonCount,
					timeSpent: (tcd.timeSpent || 0) + cd.timeSpent,
					timeRemaining: (tcd.timeRemaining || 0) + cd.timeRemaining,
					percentComplete: Math.round(((tcd.completedLessonCount || 0) / tcd.totalLessonCount) * 100),
					nextLesson: tcd.nextLesson || cd.nextLesson,
				};
			}
			return tcd;
		}, {});
	};
	//#endregion

	//#region Time Spent/Time Remaining
	static getTimeRemainingOnLesson = (lesson, evalHistory) => (!this.isLessonComplete(lesson, evalHistory) ? lesson.estimatedTime : 0);

	static getTimeSpentOnLesson = (lesson, evalHistory) =>
		this.getEvalsForLesson(lesson.id, evalHistory).reduce((total, cur) => (total += cur.duration), 0);
	//#endregion

	// #region CourseCatalog/EvalHistory Hierarchy HelperFunctions
	static getEvalsForLesson = (lessonId, evalHistory) => evalHistory.filter((e) => e.lesson && e.lesson.id === lessonId);

	static getSortedDatapoints<T extends Evaluation | ChartDatapoint>(data: Array<T>, sortType: SortType) {
		if (sortType === SortType.Date) {
			return data.sort((a, b) => new Date(a.evaluationDate).getTime() - new Date(b.evaluationDate).getTime());
		} else {
			const orderedLessonIds = this.getFlattenedLessonList(globalState.courseCatalog).map((l: LessonData) => l.id);
			return data.sort((a, b) => {
				const aPosition = a.lesson?.id ? orderedLessonIds.indexOf(a.lesson.id) : orderedLessonIds.length;
				const bPosition = b.lesson?.id ? orderedLessonIds.indexOf(b.lesson.id) : orderedLessonIds.length;
				return aPosition - bPosition;
			});
		}
	}

	static getFlattenedLessonList = (courseList) => courseList.flatMap((c) => c.unitList.flatMap((u) => u.lessonList));
	//#endregion
}
