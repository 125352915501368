import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import GSForms from "../../forms/form-elements";
import Nav from "../../data-structures/navigation";
import { TMSHelpers } from "../../helpers/tms-helpers";
import { HelperFunctions } from "../../helpers/helper-functions";
import { Loading } from "../../common/loading-icon";
import { TRANSLATIONS } from "../../data-structures/localization";
import { ERROR_CODES } from "../../data-structures/gs-constants";
import { GSLogo } from "../../common/logo/logo";
import { onGlobalStateChange } from "../../store";
import { Validate } from "../../helpers/validation";
import ImageCarousel from "./image-carousel";
import "./registration.scss";
import FormHelpers from "../../helpers/form-helpers";

export default class Login extends Component {
	//#region Initialization
	constructor(props) {
		super(props);

		this.state = {
			orgId: null,
			username: "",
			password: "",

			loggingIn: false,
			formErrors: [],
			responseError: null,
		};
	}

	componentDidMount() {
		this.unsubscribe = onGlobalStateChange(() =>
			this.setState({
				...(this.state.formErrors.length > 0 && { formErrors: this.getFormErrors() || [] }),
				...(this.state.responseError && {
					responseError: Object.values(ERROR_CODES).find((x) => x.key === this.state.responseError.key),
				}),
			})
		);
	}

	componentWillUnmount() {
		this.unsubscribe();
	}
	//#endregion

	handleChange = (e) => this.setState({ [e.target.dataset.state]: [e.target.value] });

	handleLogin = () => {
		const formErrors = this.getFormErrors();
		if (formErrors.length > 0) this.setState({ loggingIn: false, responseError: null, formErrors: formErrors });
		else {
			this.setState(
				{ loggingIn: true, responseError: null, formErrors: [] },
				async () =>
					await TMSHelpers.authenticateUser(this.state.username, this.state.password).then((response) =>
						this.setState({ loggingIn: false, ...this.getErrorState(response) })
					)
			);
		}
	};

	getFormErrors = () => {
		const errors = [];
		const state = this.state;
		if (Validate.isNullOrEmpty(state.username)) errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["username"]));
		if (Validate.isNullOrEmpty(state.password)) errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["password"]));

		return errors;
	};

	getErrorState = (response) => {
		if (response && response.errorMsg)
			switch (response.key) {
				default:
					return { responseError: HelperFunctions.getResponseError(response) };
			}
	};

	render() {
		return (
			<div className="registration-container">
				<ImageCarousel id="login-carousel" />

				<div className="container">
					<div className="login-container col-lg-4 col-xl-5">
						<form>
							<GSLogo />

							<GSForms.InputField
								containerClassName={"registration-field"}
								label={TRANSLATIONS.FORMS.USERNAME}
								dataState="username"
								fieldValue={this.state.username}
								autoCompleteString="username"
								columnLayout
								fullWidth
								errors={this.state.formErrors}
								handleChange={(e) => FormHelpers.handleChange(e, this)}
							/>
							<GSForms.InputField
								containerClassName={"registration-field"}
								label={TRANSLATIONS.FORMS.PASSWORD}
								dataState="password"
								fieldValue={this.state.password}
								type="password"
								autoCompleteString="password"
								columnLayout
								fullWidth
								errors={this.state.formErrors}
								handleChange={(e) => FormHelpers.handleChange(e, this)}
							/>

							<GSForms.ErrorContainer message={this.state.responseError} />
							{this.state.loggingIn && <Loading />}

							<GSForms.Button text="Login" onClick={this.handleLogin} />

							<NavLink to={Nav.PATHS.PASSWORD_RESET}>{TRANSLATIONS.FORMS.FORGOT_PASSWORD}</NavLink>
						</form>

						<div className="signup-btn w100">
							<div>{TRANSLATIONS.FORMS.DONT_HAVE_AN_ACCOUNT}</div>
							<NavLink to={""}>Signup Coming Soon</NavLink>
							{/* <NavLink to={Nav.PATHS.SIGNUP}>{TRANSLATIONS.FORMS.SIGN_UP_INSTEAD}</NavLink> */}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
