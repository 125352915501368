import React, { Component } from "react";
import GSForms from "./form-elements";
import { TRANSLATIONS } from "../data-structures/localization";
import { ERROR_CODES } from "../data-structures/gs-constants";
import { Loading } from "../common/loading-icon";
import { globalState, setGlobalState, onGlobalStateChange } from "../store";
import { TMSHelpers } from "../helpers/tms-helpers";
import { HelperFunctions } from "../helpers/helper-functions";
import { Validate } from "../helpers/validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormHelpers from "../helpers/form-helpers";

export class GroupForm extends Component {
	//#region Initialization
	constructor(props) {
		super(props);
		if (props.addingGroup) {
			const availableUsers = globalState.orgData.userList.map((x) => ({
				value: x.id,
				label: `${x.firstName} ${x.lastName}`,
				username: x.username,
				role: x.role,
			}));

			this.getInitialState = (propsToUse) => ({
				name: "",
				startDate: new Date(),
				endDate: new Date(),
				notes: "",
				active: true,
				enrolledCourses: [],
				enrolledUsers: [],
				enrolledStudents: [],
				enrolledInstructors: [],

				availableCourses: globalState.courseCatalog.map((x) => ({ value: x.id, label: x.name })),
				availableUsers: availableUsers,
				availableStudents: this.getStudents(availableUsers),
				availableInstructors: this.getInstructors(availableUsers),

				formErrors: [],
				responseError: null,

				submitting: false,
				saved: false,
			});
		} else {
			this.getInitialState = (propsToUse) => ({
				name: propsToUse.selectedGroup.name,
				startDate: propsToUse.selectedGroup.startDate,
				endDate: propsToUse.selectedGroup.endDate,
				notes: propsToUse.selectedGroup.notes || "",
				active: propsToUse.selectedGroup.active,

				formErrors: [],
				responseError: null,

				submitting: false,
				saved: false,
			});
		}

		this.state = this.getInitialState(props);
	}

	componentDidMount() {
		this.unsubscribe = onGlobalStateChange(() =>
			this.setState({
				...(this.state.formErrors.length > 0 && { formErrors: this.getFormErrors() || [] }),
				...(this.state.responseError && {
					responseError: Object.values(ERROR_CODES).find((x) => x.key === this.state.responseError.key),
				}),
			})
		);
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	componentDidUpdate(nextProps) {
		if (!this.props.addingGroup && nextProps.editMode !== this.props.editMode) {
			this.setState({ ...this.getInitialState(this.props), saved: this.state.saved });
		}
	}
	//#endregion

	//#region New Group Utilities
	getStudents = (userList) => userList.filter((x) => HelperFunctions.userIsStudent(x));
	getInstructors = (userList) => userList.filter((x) => HelperFunctions.userIsInstructor(x));

	handleCourseSelect = (newSelectedCourses) => {
		newSelectedCourses = HelperFunctions.sortByCatalogOrder(newSelectedCourses, this.state.availableCourses);
		this.setState({ enrolledCourses: newSelectedCourses });
	};

	handleStudentSelect = (newSelectedStudents) => {
		newSelectedStudents = HelperFunctions.sortByCatalogOrder(newSelectedStudents, this.state.availableStudents);
		const newFullUserList = HelperFunctions.sortByCatalogOrder([...this.state.enrolledInstructors, ...newSelectedStudents], this.state.availableUsers);
		this.setState({ enrolledStudents: newSelectedStudents, enrolledUsers: newFullUserList });
	};

	handleInstructorSelect = (newSelectedInstructors) => {
		newSelectedInstructors = HelperFunctions.sortByCatalogOrder(newSelectedInstructors, this.state.availableInstructors);
		const newFullUserList = HelperFunctions.sortByCatalogOrder([...this.state.enrolledStudents, ...newSelectedInstructors], this.state.availableUsers);
		this.setState({ enrolledInstructors: newSelectedInstructors, enrolledUsers: newFullUserList });
	};
	//#endregion

	//#region Submit HelperFunctions
	getFormErrors = () => {
		const errors = [];
		const state = this.state;
		if (Validate.isNullOrEmpty(state.name)) errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["name"]));
		if (!Validate.isValidDateRange(state.startDate, state.endDate))
			errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.INVALID_DATE_RANGE, ["endDate"]));
		return errors;
	};

	getErrorState = (response) => {
		if (response && response.errorMsg)
			switch (response.key) {
				default:
					return { responseError: HelperFunctions.getResponseError(response) };
			}
	};

	handleSubmit = async () => {
		const formErrors = this.getFormErrors();
		if (formErrors.length > 0) this.setState({ submitting: false, saved: false, responseError: null, formErrors: formErrors });
		else {
			this.setState({ submitting: true, saved: false, responseError: null, formErrors: [] }, async () => {
				const request = this.props.addingGroup
					? TMSHelpers.sendRequest("CreateGroupRequest", {
							OrganizationId: globalState.orgData.id,
							Name: this.state.name,
							Notes: this.state.notes,
							StartDate: new Date(this.state.startDate),
							EndDate: new Date(this.state.endDate),
							EnrolledCourses: { CourseIdList: this.state.enrolledCourses.map((x) => x.value) },
							EnrolledUsers: { UserIdList: this.state.enrolledUsers.map((x) => x.value) },
							Active: this.state.active,
					  }).then((response) => {
							if (response.Success) {
								const trimmedGroupData = TMSHelpers.trimGroupData(JSON.parse(response.ResponseMessage).CreatedGroup);
								setGlobalState({ groupList: [...globalState.groupList, trimmedGroupData] });
								this.props.toggleAddGroup();
							}
							return response;
					  })
					: TMSHelpers.updateGroupRequest({
							id: this.props.selectedGroup.id,
							orgId: globalState.orgData.id,
							name: this.state.name,
							notes: this.state.notes,
							startDate: this.state.startDate,
							endDate: this.state.endDate,
							courseIds: this.props.selectedGroup.courseIds,
							userIds: Object.keys(this.props.selectedGroup.userList),
							active: this.state.active,
					  }).then((response) => {
							if (response.Success) {
								this.props.toggleEditMode();
							}
							return response;
					  });

				request.then((response) => {
					if (response.Success) {
						TMSHelpers.refreshGlobalData();
					}
					this.setState({ submitting: false, saved: response.Success, ...this.getErrorState(response) });
				});
			});
		}
	};
	//#endregion

	render() {
		const addingGroup = this.props.addingGroup;
		return (
			<div className={`group-form${addingGroup ? " no-print" : ""}`}>
				<h2>
					{addingGroup ? (
						TRANSLATIONS.HEADERS.ADD_GROUP
					) : (
						<>
							{this.props.editMode ? TRANSLATIONS.HEADERS.EDIT_GROUP : TRANSLATIONS.HEADERS.GROUP_DETAILS}
							{!this.props.viewOnly &&
								(this.props.editMode ? (
									<FontAwesomeIcon
										className="icon-btn"
										icon="fa-solid fa-arrow-rotate-left"
										onClick={(e) => {
											e.stopPropagation();
											this.props.toggleEditMode(false);
										}}
									/>
								) : (
									<FontAwesomeIcon
										className="icon-btn"
										icon="fa-solid fa-pencil"
										onClick={(e) => {
											e.stopPropagation();
											this.props.toggleEditMode(true);
										}}
									/>
								))}
						</>
					)}
				</h2>

				<form autoComplete="on">
					{addingGroup || (!this.props.viewOnly && this.props.editMode) ? (
						<>
							<GSForms.CheckBoxField
								label={TRANSLATIONS.GROUP_MANAGEMENT.ACTIVE}
								id="active-group"
								fieldValue={this.state.active}
								dataState="active"
								formField
								labelFirst
								errors={this.state.formErrors}
								handleChange={(e) => FormHelpers.handleCheckboxChange(e, this)}
							/>
							<GSForms.InputField
								label={TRANSLATIONS.GROUP_MANAGEMENT.GROUP_NAME}
								fieldValue={this.state.name}
								dataState="name"
								formField
								errors={this.state.formErrors}
								handleChange={(e) => FormHelpers.handleChange(e, this)}
							/>
							<GSForms.DatePickerField
								label={TRANSLATIONS.GROUP_MANAGEMENT.START_DATE}
								formField
								fieldValue={new Date(this.state.startDate)}
								dataState="startDate"
								errors={this.state.formErrors}
								handleChange={(startDate) => this.setState({ startDate: new Date(startDate).setHours(0, 0, 0, 0) })}
							/>
							<GSForms.DatePickerField
								label={TRANSLATIONS.GROUP_MANAGEMENT.END_DATE}
								formField
								fieldValue={new Date(this.state.endDate)}
								dataState="endDate"
								errors={this.state.formErrors}
								handleChange={(endDate) => this.setState({ endDate: new Date(endDate).setHours(23, 59, 59, 59) })}
							/>
							<GSForms.InputField
								label={TRANSLATIONS.GROUP_MANAGEMENT.NOTES}
								fieldValue={this.state.notes}
								dataState="notes"
								formField
								type="textarea"
								errors={this.state.formErrors}
								handleChange={(e) => FormHelpers.handleChange(e, this)}
							/>

							{addingGroup && (
								<>
									<GSForms.MultiSelectField
										label={TRANSLATIONS.GROUP_MANAGEMENT.COURSES}
										dataState="enrolledCourses"
										fieldValue={this.state.enrolledCourses}
										fieldOptions={this.state.availableCourses}
										formField
										allOptionsLabel={TRANSLATIONS.GROUP_MANAGEMENT.SELECT_ALL_COURSES}
										handleChange={this.handleCourseSelect}
									/>
									<GSForms.MultiSelectField
										label={TRANSLATIONS.GROUP_MANAGEMENT.STUDENTS}
										dataState="enrolledStudents"
										fieldValue={this.state.enrolledStudents}
										fieldOptions={this.state.availableStudents}
										formField
										allOptionsLabel={TRANSLATIONS.GROUP_MANAGEMENT.SELECT_ALL_STUDENTS}
										handleChange={this.handleStudentSelect}
									/>
									<GSForms.MultiSelectField
										label={TRANSLATIONS.GROUP_MANAGEMENT.INSTRUCTORS}
										dataState="enrolledInstructors"
										fieldValue={this.state.enrolledInstructors}
										fieldOptions={this.state.availableInstructors}
										formField
										isDisabled={!HelperFunctions.loggedInAsAdmin()}
										allOptionsLabel={TRANSLATIONS.GROUP_MANAGEMENT.SELECT_ALL_INSTRUCTORS}
										handleChange={this.handleInstructorSelect}
									/>
								</>
							)}

							<GSForms.ErrorContainer message={this.state.responseError} />
							{this.state.submitting && <Loading />}

							<div className="flexbox">
								<GSForms.Button
									type="button"
									buttonText={TRANSLATIONS.FORMS.CANCEL}
									onClick={addingGroup ? this.props.toggleAddGroup : this.props.toggleEditMode}
								/>
								<GSForms.Button type="submit" onClick={this.handleSubmit} />
							</div>
						</>
					) : (
						<>
							<GSForms.InfoField
								label={TRANSLATIONS.GROUP_MANAGEMENT.ACTIVE}
								fieldValue={<FontAwesomeIcon icon={`fa-solid ${this.state.active ? "fa-check" : "fa-x"}`} />}
								formField
							/>
							<GSForms.InfoField label={TRANSLATIONS.GROUP_MANAGEMENT.GROUP_NAME} fieldValue={this.state.name} formField />
							<GSForms.InfoField
								label={TRANSLATIONS.GROUP_MANAGEMENT.START_DATE}
								fieldValue={new Date(this.state.startDate).toLocaleDateString()}
								formField
							/>
							<GSForms.InfoField
								label={TRANSLATIONS.GROUP_MANAGEMENT.END_DATE}
								fieldValue={new Date(this.state.endDate).toLocaleDateString()}
								formField
							/>
							<GSForms.InfoField label={TRANSLATIONS.GROUP_MANAGEMENT.NOTES} fieldValue={this.state.notes} formField />
						</>
					)}

					{this.state.saved && <GSForms.SavedCheck toggleVisible={() => this.setState({ saved: false })} />}
				</form>
			</div>
		);
	}
}
