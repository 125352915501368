import React from "react";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const GSTooltip = ({ id, content }) => {
	const parsedContent = Array.isArray(content) ? `<div>${content.map((item) => `<li>${item}</li>`).join("")}</div>` : `<span>${content}</span>`;

	return (
		<>
			<div className="tooltip-base" data-tooltip-id={id || content} data-tooltip-html={parsedContent}>
				<FontAwesomeIcon icon="fa-solid fa-circle-question" />
			</div>
			<Tooltip id={id || content} place="top" className="tooltip" />
		</>
	);
};
