import React from "react";
import GSForms from "../forms/form-elements";
import { DEFAULT_MEASUREMENT_UNIT, USER_ROLES, USER_IMPORT_HEADERS } from "../data-structures/gs-constants";
import { LANGUAGES } from "../data-structures/lang-codes";
import { TRANSLATIONS } from "../data-structures/localization";
import { globalState } from "../store";
import { HelperFunctions } from "./helper-functions";
import { TMSHelpers } from "./tms-helpers";
import { Validate } from "./validation";

const fileReader = new FileReader();
export class FileImporter {
	static fileRef = React.createRef();

	static reader = fileReader;

	static importFile = (file) => fileReader.readAsText(file);
	static onFileImport = (callback) => (fileReader.onload = callback);
}

export class ImportHelpers {
	static parseUserFile = async (e) => {
		const returnData = {
			parsedUsers: [],
			importErrors: [],
			updatedUserCount: 0,
			newUserCount: 0,
		};

		const rawData = e.target.result.trim().split("\n");
		const lintedHeaders = rawData
			.shift()
			.split(",")
			.slice(0, 4)
			.map((h, i) => USER_IMPORT_HEADERS[i].key);
		const lintedRows = rawData.map((x) => x.trim().split(","));
		const userArray = lintedRows
			.map((dataRow, i) => {
				const obj = lintedHeaders.reduce((object, header, index) => {
					object[header] = dataRow[index].replace(/"/g, "").trim();
					return object;
				}, {});
				obj.lineNumber = i + 2; // +2 to compensate for header row and coverts 0 based index to 1 based line number
				return obj;
			})
			.filter((x) => x.username !== "" || x.firstName !== "" || x.lastName !== "" || x.emailAddress !== "");

		for (const user of userArray) {
			const userImportErrors = [];
			if (Validate.isNullOrEmpty(user.username))
				userImportErrors.push(
					new GSForms.FormError(
						TRANSLATIONS.ERRORS.LINE_ERROR(
							user.lineNumber,
							TRANSLATIONS.ERRORS.FIELD_EMPTY(TRANSLATIONS.USER_IMPORT.FILE_HEADERS.USERNAME)
						),
						["importUserFile"]
					)
				);
			else if (userArray.filter((x) => x.username === user.username).length > 1)
				userImportErrors.push(
					new GSForms.FormError(TRANSLATIONS.ERRORS.LINE_ERROR(user.lineNumber, TRANSLATIONS.ERRORS.DUPLICATE_USER), ["importUserFile"])
				);
			if (userImportErrors.length > 0) {
				returnData.importErrors.push(...userImportErrors);
				continue;
			}

			let existingUser = globalState.orgData.userList.find((x) => x.username === user.username);

			if (existingUser) {
				if (
					existingUser.role > globalState.userData.role ||
					(existingUser.id !== globalState.userData.id &&
						!HelperFunctions.loggedInAsAdmin() &&
						HelperFunctions.userIsInstructor(existingUser))
				) {
					userImportErrors.push(
						new GSForms.FormError(TRANSLATIONS.ERRORS.LINE_ERROR(user.lineNumber, TRANSLATIONS.ERRORS.NOT_AUTHORIZED), ["importUserFile"])
					);
				} else if (!Validate.isNullOrEmpty(user.emailAddress) && !Validate.isValidEmailAddress(user.emailAddress)) {
					userImportErrors.push(
						new GSForms.FormError(TRANSLATIONS.ERRORS.LINE_ERROR(user.lineNumber, TRANSLATIONS.ERRORS.EMAIL_INVALID), ["importUserFile"])
					);
				}

				if (userImportErrors.length > 0) {
					returnData.importErrors.push(...userImportErrors);
					continue;
				}

				if (
					(!Validate.isNullOrEmpty(user.firstName) && user.firstName !== existingUser.firstName) ||
					(!Validate.isNullOrEmpty(user.lastName) && user.lastName !== existingUser.lastName) ||
					(!Validate.isNullOrEmpty(user.emailAddress) && user.emailAddress !== existingUser.emailAddress)
				) {
					await TMSHelpers.editUserRecord({
						id: existingUser.id,
						firstName: Validate.isNullOrEmpty(user.firstName) ? existingUser.firstName : user.firstName,
						lastName: Validate.isNullOrEmpty(user.lastName) ? existingUser.lastName : user.lastName,
						username: existingUser.username,
						emailAddress: Validate.isNullOrEmpty(user.emailAddress) ? existingUser.emailAddress : user.emailAddress,
						active: existingUser.active,
						role: existingUser.role,
						groups: existingUser.groups,
						units: existingUser.units,
						language: existingUser.language,
					}).then((response) => {
						response = JSON.parse(response.ResponseMessage);
						if (response.Success) {
							existingUser = TMSHelpers.trimUserData(response.UpdatedUser);
							returnData.updatedUserCount++;
						}
					});
				}

				returnData.parsedUsers.push(existingUser);
			} else {
				if (Validate.isNullOrEmpty(user.firstName)) {
					userImportErrors.push(
						new GSForms.FormError(
							TRANSLATIONS.ERRORS.CANNOT_CREATE_USER(
								user.lineNumber,
								TRANSLATIONS.ERRORS.FIELD_EMPTY(TRANSLATIONS.USER_IMPORT.FILE_HEADERS.FIRST_NAME)
							),
							["importUserFile"]
						)
					);
				}

				if (Validate.isNullOrEmpty(user.lastName)) {
					userImportErrors.push(
						new GSForms.FormError(
							TRANSLATIONS.ERRORS.CANNOT_CREATE_USER(
								user.lineNumber,
								TRANSLATIONS.ERRORS.FIELD_EMPTY(TRANSLATIONS.USER_IMPORT.FILE_HEADERS.LAST_NAME)
							),
							["importUserFile"]
						)
					);
				}

				if (HelperFunctions.currentOrgIsCloudBased()) {
					if (Validate.isNullOrEmpty(user.emailAddress)) {
						userImportErrors.push(
							new GSForms.FormError(
								TRANSLATIONS.ERRORS.CANNOT_CREATE_USER(
									user.lineNumber,
									TRANSLATIONS.ERRORS.FIELD_EMPTY(TRANSLATIONS.USER_IMPORT.FILE_HEADERS.EMAIL_ADDRESS)
								),
								["importUserFile"]
							)
						);
					} else if (!Validate.isValidEmailAddress(user.emailAddress)) {
						userImportErrors.push(
							new GSForms.FormError(TRANSLATIONS.ERRORS.CANNOT_CREATE_USER(user.lineNumber, TRANSLATIONS.ERRORS.EMAIL_INVALID), [
								"importUserFile",
							])
						);
					}
				}

				if (userImportErrors.length > 0) {
					returnData.importErrors.push(...userImportErrors);
					continue;
				}

				await TMSHelpers.registerUser(
					{
						firstName: user.firstName,
						lastName: user.lastName,
						username: user.username,
						emailAddress: user.emailAddress,
						language: Object.values(LANGUAGES).find((x) => x.value === globalState.locale),
						units: DEFAULT_MEASUREMENT_UNIT,
						role: HelperFunctions.getRole(USER_ROLES.STUDENT.value),
						orgId: globalState.orgData.id,
					},
					false
				).then((response) => {
					response = JSON.parse(response.ResponseMessage);
					if (response.Success) {
						const newUser = TMSHelpers.trimUserData(response.AddedUser);
						returnData.parsedUsers.push(newUser);
						returnData.newUserCount++;
					}
				});
			}
		}
		return [returnData.parsedUsers, returnData.importErrors, returnData.updatedUserCount, returnData.newUserCount];
	};

	static prepUserListForExport = (userList) => userList.map((user) => ({ ...user, role: HelperFunctions.getRole(user.role).label }));
}
