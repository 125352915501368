import { TRANSLATIONS } from "../data-structures/localization";
import { Validate } from "./validation";
import GSForms from "../forms/form-elements";

//#region helper functions
export default class FormHelpers {
	//#region Field Change Handlers
	static handleChange = (e, thisComponent, customValue = null) =>
		thisComponent.setState({ [e.target.dataset.state]: customValue || e.target.value });
	static handleSelectChange = (option, key, thisComponent) => thisComponent.setState({ [key]: option });
	static handleCheckboxChange = (e, thisComponent) => thisComponent.setState({ [e.target.dataset.state]: e.target.checked });
	//#endregion

	//#region Username/Password requirements
	static getUsernameErrors = (username, errorFields) => {
		const errors = [];
		if (Validate.isNullOrEmpty(username)) {
			errors.push(TRANSLATIONS.ERRORS.FIELD_EMPTY());
		} else {
			if (username.length < Validate.USERNAME_MIN_LENGTH || username.length > Validate.USERNAME_MAX_LENGTH) {
				errors.push(TRANSLATIONS.ERRORS.INVALID_USERNAME_LENGTH);
			}
			if (!/^[\p{L}]/u.test(username)) {
				// if the first letter is anything other than a letter (in any language)
				errors.push(TRANSLATIONS.ERRORS.INVALID_USERNAME_START);
			}
			if (!/^[\w\-@.]+$/.test(username)) {
				// if the string contains anything that is not a letter, a number, or approved special characters
				errors.push(TRANSLATIONS.ERRORS.INVALID_USERNAME_CHARS);
			}
			if (Validate.USERNAME_BLACKLIST.some((x) => username.toLowerCase() === x.toLowerCase())) {
				errors.push(TRANSLATIONS.ERRORS.INVALID_USERNAME_BLACKLIST);
			}
		}
		return errors.map((e) => new GSForms.FormError(e, errorFields));
	};

	static getPasswordErrors = (password, errorFields) => {
		const errors = [];
		if (Validate.isNullOrEmpty(password)) {
			errors.push(TRANSLATIONS.ERRORS.FIELD_EMPTY());
		}
		if (password.length < Validate.PASSWORD_MIN_LENGTH) {
			errors.push(TRANSLATIONS.ERRORS.INVALID_PASSWORD_LENGTH);
		}
		if (Validate.PASSWORD_BLACKLIST.some((x) => password.toLowerCase() === x.toLowerCase())) {
			errors.push(TRANSLATIONS.ERRORS.INVALID_PASSWORD_BLACKLIST);
		}
		return errors.map((e) => new GSForms.FormError(e, errorFields));
	};
	//#endregion
}
//#endregion
