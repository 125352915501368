import React, { Component } from "react";
import GSForms from "../forms/form-elements";
import { LANGUAGES } from "../data-structures/lang-codes";
import { TRANSLATIONS } from "../data-structures/localization";
import { DEFAULT_MEASUREMENT_UNIT, MEASUREMENT_UNITS, ERROR_CODES, USER_ROLES } from "../data-structures/gs-constants";
import { TMSHelpers } from "../helpers/tms-helpers";
import { Loading } from "../common/loading-icon";
import { HelperFunctions } from "../helpers/helper-functions";
import { globalState, onGlobalStateChange } from "../store";
import { GSLogo } from "../common/logo/logo";
import { Validate } from "../helpers/validation";
import FormHelpers from "../helpers/form-helpers";

export default class RegisterUserForm extends Component {
	//#region Initialization
	constructor(props) {
		super(props);
		this.INITIAL_STATE = {
			firstName: "",
			lastName: "",
			emailAddress: "",
			username: "",
			password: "",
			confirmPassword: "",

			language: Object.values(LANGUAGES).find((l) => l.value === globalState.locale),
			units: DEFAULT_MEASUREMENT_UNIT,
			role: HelperFunctions.getRole(USER_ROLES.STUDENT.value),
		};

		this.state = {
			...this.INITIAL_STATE,
			formErrors: [],
			responseError: null,

			registering: false,
			saved: false,
		};
	}

	componentDidMount() {
		this.unsubscribe = onGlobalStateChange(() => {
			this.setState({
				units: Object.values(MEASUREMENT_UNITS).find((x) => x.value === DEFAULT_MEASUREMENT_UNIT.value),
				language: Object.values(LANGUAGES).find((x) => x.value === globalState.locale),
				role: HelperFunctions.getRole(this.state.role.value),
				...(this.state.formErrors.length > 0 && { formErrors: this.getFormErrors() || [] }),
				...(this.state.responseError && {
					responseError: Object.values(ERROR_CODES).find((x) => x.key === this.state.responseError.key),
				}),
			});
		});
	}

	componentWillUnmount() {
		this.unsubscribe();
	}
	//#endregion

	handleSignUp = async () => {
		const formErrors = this.getFormErrors();
		if (formErrors.length > 0) this.setState({ registering: false, responseError: null, formErrors: formErrors });
		else {
			this.setState({ registering: true, responseError: null, formErrors: [] }, async () => {
				const newUserData = {
					...this.state,
					...(!this.props.cloudSelfRegister && { orgId: globalState.orgData.id }),
				};
				await TMSHelpers.registerUser(newUserData, this.props.cloudSelfRegister).then((response) => {
					this.setState(
						{
							registering: false,
							saved: response.Success,
							...this.getErrorState(response),
							...(response.Success && this.INITIAL_STATE),
						},
						() => {
							const addedUser = response.ResponseMessage && JSON.parse(response.ResponseMessage)?.AddedUser;
							if (response.Success && addedUser) {
								this.props.onAddUser(addedUser.Id);
							}
						}
					);
				});
			});
		}
	};

	getFormErrors = () => {
		const errors = [];
		const state = this.state;
		if (!this.props.cloudSelfRegister) {
			if (Validate.isNullOrEmpty(state.firstName)) {
				errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["firstName"]));
			}
			if (Validate.isNullOrEmpty(state.lastName)) {
				errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["lastName"]));
			}
		}

		errors.push(...FormHelpers.getUsernameErrors(state.username, ["username"]));

		if (HelperFunctions.currentOrgIsCloudBased()) {
			if (Validate.isNullOrEmpty(state.emailAddress)) {
				errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.FIELD_EMPTY(), ["emailAddress"]));
			} else if (!Validate.isValidEmailAddress(state.emailAddress)) {
				errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.EMAIL_INVALID, ["emailAddress"]));
			}
		} else {
			errors.push(...FormHelpers.getPasswordErrors(state.password, ["password"]));

			if (Validate.isNullOrEmpty(state.confirmPassword)) {
				errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.CONFIRM_PASSWORD, ["confirmPassword"]));
			} else if (state.password.trim() !== state.confirmPassword.trim()) {
				errors.push(new GSForms.FormError(TRANSLATIONS.ERRORS.PASSWORD_MATCH, ["password", "confirmPassword"]));
			}
		}

		return errors;
	};

	getErrorState = (response) => {
		if (response && response.errorMsg)
			switch (response.key) {
				case ERROR_CODES.USERNAME_TAKEN.key:
					return { formErrors: [new GSForms.FormError(response.errorMsg, ["username"])] };

				case ERROR_CODES.INVALID_CREDS.key:
					return { formErrors: [new GSForms.FormError(response.errorMsg, ["username"])] };

				default:
					return { responseError: HelperFunctions.getResponseError(response) };
			}
	};

	render() {
		return (
			<div className="register-user-form container">
				{this.props.title ? <h2>{this.props.title}</h2> : <GSLogo />}
				<form autoComplete="on">
					{!this.props.cloudSelfRegister && (
						<>
							<GSForms.InputField
								label={TRANSLATIONS.FORMS.FIRST_NAME}
								containerClassName={"registration-field"}
								dataState="firstName"
								autoCompleteString="given-name"
								fieldValue={this.state.firstName}
								{...(this.props.cloudSelfRegister ? { columnLayout: true, fullWidth: true } : { formField: true })}
								errors={this.state.formErrors}
								handleChange={(e) => FormHelpers.handleChange(e, this)}
							/>
							<GSForms.InputField
								label={TRANSLATIONS.FORMS.LAST_NAME}
								containerClassName={"registration-field"}
								dataState="lastName"
								autoCompleteString="family-name"
								fieldValue={this.state.lastName}
								{...(this.props.cloudSelfRegister ? { columnLayout: true, fullWidth: true } : { formField: true })}
								errors={this.state.formErrors}
								handleChange={(e) => FormHelpers.handleChange(e, this)}
							/>
						</>
					)}
					<GSForms.InputField
						label={TRANSLATIONS.FORMS.EMAIL_ADDRESS}
						containerClassName={"registration-field"}
						dataState="emailAddress"
						autoCompleteString="email"
						fieldValue={this.state.emailAddress}
						{...(this.props.cloudSelfRegister ? { columnLayout: true, fullWidth: true } : { formField: true })}
						errors={this.state.formErrors}
						handleChange={(e) => FormHelpers.handleChange(e, this)}
					/>
					<GSForms.InputField
						label={TRANSLATIONS.FORMS.USERNAME}
						tooltipContent={TRANSLATIONS.FORMS.USERNAME_REQUIREMENTS}
						containerClassName={"registration-field"}
						dataState="username"
						autoCompleteString="username"
						fieldValue={this.state.username}
						{...(this.props.cloudSelfRegister ? { columnLayout: true, fullWidth: true } : { formField: true })}
						errors={this.state.formErrors}
						handleChange={(e) => FormHelpers.handleChange(e, this)}
					/>
					{!HelperFunctions.currentOrgIsCloudBased() && (
						<>
							<GSForms.InputField
								label={TRANSLATIONS.FORMS.PASSWORD}
								tooltipContent={TRANSLATIONS.FORMS.PASSWORD_REQUIREMENTS}
								containerClassName={"registration-field"}
								dataState="password"
								autoCompleteString="new-password"
								fieldValue={this.state.password}
								type="password"
								{...(this.props.cloudSelfRegister ? { columnLayout: true, fullWidth: true } : { formField: true })}
								errors={this.state.formErrors}
								handleChange={(e) => FormHelpers.handleChange(e, this)}
							/>
							<GSForms.InputField
								label={TRANSLATIONS.FORMS.CONFIRM_PASSWORD}
								containerClassName={"registration-field"}
								dataState="confirmPassword"
								autoCompleteString="new-password"
								fieldValue={this.state.confirmPassword}
								type="password"
								{...(this.props.cloudSelfRegister ? { columnLayout: true, fullWidth: true } : { formField: true })}
								errors={this.state.formErrors}
								handleChange={(e) => FormHelpers.handleChange(e, this)}
							/>
						</>
					)}
					{!this.props.cloudSelfRegister && (
						<>
							<GSForms.SelectField
								label={TRANSLATIONS.FORMS.LANGUAGE_PREFERENCE}
								fieldOptions={HelperFunctions.getSortedLanguages()}
								fieldValue={this.state.language}
								formField
								errors={this.state.formErrors}
								handleChange={(option) => FormHelpers.handleSelectChange(option, "language", this)}
							/>
							<GSForms.SelectField
								label={TRANSLATIONS.FORMS.UNITS_PREFERENCE}
								fieldOptions={Object.values(MEASUREMENT_UNITS)}
								fieldValue={this.state.units}
								formField
								errors={this.state.formErrors}
								handleChange={(option) => FormHelpers.handleSelectChange(option, "units", this)}
							/>
						</>
					)}
					{!this.props.cloudSelfRegister && globalState.loggedIn && HelperFunctions.loggedInAsAdmin() && (
						<GSForms.SelectField
							label={TRANSLATIONS.USER_MANAGEMENT.ROLE}
							fieldValue={this.state.role}
							dataState="role"
							formField
							fieldOptions={HelperFunctions.getAvailableRoles()}
							errors={this.state.errors}
							handleChange={(option) => FormHelpers.handleSelectChange(option, "role", this)}
						/>
					)}

					<GSForms.ErrorContainer message={this.state.responseError} />
					{this.state.registering && <Loading />}
					{this.state.saved && (
						<GSForms.SavedCheck
							customMessage={TRANSLATIONS.USER_MANAGEMENT.USER_ADDED}
							toggleVisible={() => this.setState({ saved: false })}
						/>
					)}

					<div className="flexbox w100">
						{this.props.children}
						<GSForms.Button type="submit" onClick={this.handleSignUp} />
					</div>
				</form>
			</div>
		);
	}
}
