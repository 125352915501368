import React, { Component, useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { TRANSLATIONS } from "../../data-structures/localization";
import { APP_TYPES, ERROR_CODES, RELEASE_CHANNELS, USER_ROLES } from "../../data-structures/gs-constants";
import Nav from "../../data-structures/navigation";
import { globalState, onGlobalStateChange } from "../../store";
import { TMSHelpers } from "../../helpers/tms-helpers";
import { Validate } from "../../helpers/validation";
import { HelperFunctions } from "../../helpers/helper-functions";
import GSForms from "../../forms/form-elements";
import FormHelpers from "../../helpers/form-helpers";
import OrgDataForm from "../../forms/org-data-form";
import { Loading } from "../../common/loading-icon";
import { SelectOption } from "../../data-structures/select-options";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileImporter } from "../../helpers/import-helpers";
import JSZip from "jszip";

//#region Index & Templates
export class DeveloperDashboard extends Component {
	render() {
		const links = [
			{ path: Nav.PATHS.DEVELOPER.ORG_MANAGEMENT, title: "Org Management" },
			{ path: Nav.PATHS.DEVELOPER.RELEASES, title: "Releases" },
			{ path: Nav.PATHS.DEVELOPER.PORTAL_MANAGEMENT, title: "Portal Management" },
		];

		return (
			<div className="side-nav-container container">
				<h1>Developer Dashboard</h1>
				<GSForms.InfoField label="Endpoint" className="endpoint" fieldValue={TMSHelpers.getCurrentEndpoint()} />

				<hr />
				<div className="side-nav-content row">
					<div className="side-nav-sidebar no-print col-md-2 sticky-top">
						<NavLink to={Nav.PATHS.DEVELOPER.ROOT} end>
							Developer Dashboard
						</NavLink>
						{links.map((path, i) => (
							<NavLink key={i} to={path.path}>
								{path.title}
							</NavLink>
						))}
					</div>
					<div className="side-nav-section col-md-10">
						<Outlet />
					</div>
				</div>
			</div>
		);
	}
}

export const DeveloperIndex = () => {
	return (
		<div className="side-nav-index">
			<h4>
				<FontAwesomeIcon icon="fa-regular fa-arrow-left" />
				<span>{TRANSLATIONS.HEADERS.SIDE_NAV_INDEX}</span>
			</h4>
		</div>
	);
};

const DevPage = (props = { title: "", sections: [] }) => {
	return (
		<div>
			<h2>{props.title}</h2>
			{props.sections.length === 0 || props.sections.every((s) => !s) ? (
				<div>
					<hr />
					{userHasAccess() ? (
						inGhostMode() ? (
							<>
								Please exit <NavLink to={`${Nav.PATHS.DEVELOPER.ROOT}/${Nav.PATHS.DEVELOPER.ORG_MANAGEMENT}`}>Ghost Mode</NavLink> to
								access this page
							</>
						) : (
							"No tools available"
						)
					) : (
						`You must be a ${MIN_ACCESS_ROLE.label} or higher to access this page`
					)}
				</div>
			) : (
				props.sections.map(
					(x, i) =>
						x && (
							<div key={i}>
								<hr />
								{x}
							</div>
						)
				)
			)}
		</div>
	);
};

const MIN_ACCESS_ROLE = USER_ROLES.GLOBAL_ADMIN;
const userHasAccess = () => globalState.userData?.role >= MIN_ACCESS_ROLE.value;
const inGhostMode = () => globalState.ghostMode;
//#endregion

//#region Org Management
export class OrgManagement extends Component {
	//#region Initialization
	constructor() {
		super();
		this.state = {
			orgList: null,
			ghostMode: null,
		};

		this.initialized = false;
	}

	componentDidMount() {
		this.initialize();

		this.unsubscribe = onGlobalStateChange(() => this.initialize());
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	initialize = () => {
		if (!this.initialized && !this.state.orgList && globalState.userData) {
			this.initialized = true;
			TMSHelpers.sendRequest("GetOrganizationListRequest").then((response) => {
				if (response.Success) {
					this.setState({
						orgList: JSON.parse(response.ResponseMessage).OrganizationList.$values.map((org) => {
							const trimmedOrgData = TMSHelpers.trimOrgData(org);
							return {
								...trimmedOrgData,
								label: trimmedOrgData.name,
								value: trimmedOrgData.id,
							};
						}),
					});
				}
			});
		}
		this.setState({ ghostMode: globalState.ghostMode });
	};
	//#endregion

	addOrgToList = (trimmedOrgToAdd) =>
		this.setState({ orgList: [...this.state.orgList, { ...trimmedOrgToAdd, label: trimmedOrgToAdd.name, value: trimmedOrgToAdd.id }] });

	render() {
		return (
			<DevPage
				title="Organization Management"
				sections={[
					// Org Management Sections
					<GhostMode orgList={this.state.orgList} />,
					!inGhostMode() && userHasAccess() && <CreateOrganization addOrgToList={this.addOrgToList} />,
					!inGhostMode() && userHasAccess() && <CloudOrgList orgList={this.state.orgList} />,
				]}
			/>
		);
	}
}

class GhostMode extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedOrg: null,

			transforming: false,
			transformationSuccess: false,
		};
	}

	transform = () => {
		const handleTransformResult = (response) => {
			if (response.Success) {
				this.setState({ transforming: false, transformationSuccess: true });
			} else {
				this.setState({ transforming: false });
			}
		};

		this.setState({ transforming: true, transformationSuccess: false }, () => {
			globalState.ghostMode
				? TMSHelpers.revertToNonGhostUser().then(handleTransformResult)
				: TMSHelpers.authenticateGhostUser(this.state.selectedOrg.id).then(handleTransformResult);
		});
	};

	render() {
		const userHasAccess = globalState.userData?.role >= USER_ROLES.GLOBAL_ADMIN.value;
		const foreignOrgList = this.props.orgList?.filter((o) => o.id !== globalState.userData.orgId);

		return (
			<div>
				<h3>Ghost Mode</h3>
				{globalState.userData ? (
					<>
						{!globalState.ghostMode && (
							<div className="flexbox column">
								<strong>What is Ghost Mode?</strong>
								<div style={{ width: "50%" }}>
									For all intents and purposes, "Ghost Mode" is equivalent to logging in to an organization as an admin user.
									<br />
									<br />
									"Ghost Mode" allows Global Admins to log into an organization as a "Ghost User", granting temporary admin-level
									access to that organization without creating a user account within that organization.
								</div>
							</div>
						)}

						{userHasAccess ? (
							<>
								<h2 style={{ color: "red", textDecoration: "underline" }}>USE WISELY</h2>

								{foreignOrgList ? (
									globalState.ghostMode ? (
										<>
											<GSForms.Button
												buttonText={`Exit Ghost Mode`}
												onClick={this.transform}
												isDisabled={this.state.transforming}
											/>
											{this.state.transforming && (
												<div>
													Exiting Ghost Mode <Loading />
												</div>
											)}
											{this.state.transformationSuccess && (
												<GSForms.SavedCheck
													customMessage="Ghostification Complete"
													toggleVisible={() => this.setState({ transformationSuccess: false })}
												/>
											)}
										</>
									) : (
										<>
											<GSForms.SelectField
												label="Select an Organization"
												formField
												dataState={"selectedOrg"}
												fieldOptions={foreignOrgList}
												fieldValue={this.state.selectedOrg}
												isDisabled={this.state.transforming}
												handleChange={(option) => FormHelpers.handleSelectChange(option, "selectedOrg", this)}
											/>
											<GSForms.Button
												buttonText={
													this.state.selectedOrg
														? `Login to ${this.state.selectedOrg.name} as a Ghost User`
														: "Please Select an Organization"
												}
												onClick={this.transform}
												isDisabled={!this.state.selectedOrg || this.state.transforming}
											/>

											{this.state.transforming && (
												<div>
													Going Ghost <Loading />
												</div>
											)}
											{this.state.transformationSuccess && (
												<GSForms.SavedCheck
													customMessage="Deghostification Complete"
													toggleVisible={() => this.setState({ transformationSuccess: false })}
												/>
											)}
										</>
									)
								) : (
									<Loading />
								)}
							</>
						) : (
							<div style={{ color: "red" }}>
								<br />
								Note: Only Global Admins can access Ghost Mode. Please contact a supervisor to gain access
							</div>
						)}

						{!this.state.transforming && (
							<>
								<hr />
								<OrgDataForm />
							</>
						)}
					</>
				) : (
					<Loading />
				)}
			</div>
		);
	}
}

const CreateOrganization = (props) => {
	const INITIAL_STATE = {
		newOrganizationName: "",
		isCloudBased: true,
	};
	const [state, setEntireState] = useState({
		...INITIAL_STATE,

		submitting: false,
		saved: false,
		formErrors: [],
		responseError: null,
	});
	const setState = (newState) => setEntireState({ ...state, ...newState });

	const getFormErrors = () => {
		const errors = [];

		if (Validate.isNullOrEmpty(state.newOrganizationName))
			errors.push(new GSForms.FormError("Please Enter a name for this Organization", ["newOrganizationName"]));

		return errors;
	};

	const getErrorState = (response) => {
		if (response && response.errorMsg)
			switch (response.key) {
				default:
					return { responseError: HelperFunctions.getResponseError(response) };
			}
		else return { responseError: null };
	};

	const handleCreateOrganization = async () => {
		setState({ submitting: true, saved: false, formErrors: [], responseError: null });
		const formErrors = getFormErrors();
		if (formErrors.length > 0) setState({ submitting: false, saved: false, formErrors: formErrors });
		else {
			await TMSHelpers.createOrganization(state.newOrganizationName, state.isCloudBased).then((response) => {
				setState({
					submitting: false,
					saved: response.Success,
					formErrors: [],
					...getErrorState(response),
					...(response.Success ? INITIAL_STATE : {}),
				});
				response.newOrg && props.addOrgToList(response.newOrg);
			});
		}
	};

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<h3>Create Organization</h3>
			<GSForms.InputField
				label="Organization Name"
				fieldValue={state.newOrganizationName}
				dataState="newOrganizationName"
				formField
				errors={state.formErrors}
				handleChange={(e) => setState({ newOrganizationName: e.target.value })}
			/>
			<GSForms.CheckBoxField
				label={TRANSLATIONS.ORG_DATA.CLOUD_BASED}
				id="cloud-based"
				fieldValue={state.isCloudBased}
				dataState="isCloudBased"
				formField
				labelFirst
				errors={state.formErrors}
				handleChange={(e) => setState({ isCloudBased: e.target.checked })}
			/>

			<GSForms.ErrorContainer message={state.responseError} inlineFormError />
			{state.submitting && <Loading />}
			{state.saved && <GSForms.SavedCheck customMessage={"Organization Created"} toggleVisible={() => setState({ saved: false })} />}

			<GSForms.Button type="submit" buttonText={"Create Organization"} onClick={handleCreateOrganization} isDisabled={state.submitting} />
		</form>
	);
};

const CloudOrgList = (props = { orgList: [] }) => {
	return (
		<div>
			<h3>Cloud-Based Orgs</h3>
			{props.orgList ? (
				props.orgList.map((org) => org.isCloudBased && <GSForms.InfoField key={org.id} label={org.name} fieldValue={org.id} formField />)
			) : (
				<Loading />
			)}
		</div>
	);
};
//#endregion

//#region Releases
export const Releases = (props = { userHasAccess: false }) => {
	return (
		<DevPage
			title="Releases"
			sections={[
				// Releases Sections
				!inGhostMode() && userHasAccess() && <InstallerManagement />,
				!inGhostMode() && userHasAccess() && <CreateInstallerCollection />,
				!inGhostMode() && userHasAccess() && <DeleteFileShareDirectory />,
			]}
		/>
	);
};

class InstallerManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			installerArchive: [],

			selectedInstallerCollection: null,
			selectedAppType: null,
			selectedReleaseChannel: null,
			selectedInstallerOption: null,

			submitting: false,
			saved: false,
			formErrors: [],
			responseError: null,
		};
	}

	handleReset = () => {
		this.setState({
			selectedInstallerCollection: null,
			selectedAppType: null,
			selectedReleaseChannel: null,
		});
	};

	getFormErrors = () => {
		const errors = [];
		const state = this.state;
		if (Validate.isNullOrEmpty(state.selectedInstallerOption))
			errors.push(new GSForms.FormError("Please select an Installer Option", ["selectedInstallerOption"]));
		if (Validate.isNullOrEmpty(state.selectedInstallerCollection))
			errors.push(new GSForms.FormError("Please Select an InstallerCollection", ["selectedInstallerCollection"]));
		if (Validate.isNullOrEmpty(state.selectedAppType)) errors.push(new GSForms.FormError("Please Select an App Type", ["selectedAppType"]));
		if (Validate.isNullOrEmpty(state.selectedReleaseChannel))
			errors.push(new GSForms.FormError("Please Select a Release Channel", ["selectedReleaseChannel"]));
		return errors;
	};

	getErrorState = (response) => {
		if (response && response.errorMsg)
			switch (response.key) {
				default:
					return { responseError: HelperFunctions.getResponseError(response) };
			}
	};

	//#region Manifest Management
	handleUpdateManifest = () => {
		const formErrors = this.getFormErrors();
		if (formErrors.length > 0) this.setState({ submitting: false, saved: false, formErrors: formErrors });
		else {
			this.setState(
				{
					submitting: true,
					saved: false,
					formErrors: [],
				},
				() => {
					TMSHelpers.updateInstallerManifest(
						this.state.selectedAppType.appRole,
						this.state.selectedInstallerCollection.value,
						this.state.selectedReleaseChannel.enumValue,
						this.state.selectedInstallerOption.value
					).then((response) => {
						this.setState({
							submitting: false,
							saved: response.Success,
							...this.getErrorState(response),
						});
					});
				}
			);
		}
	};

	getInstallerArchive = () => {
		this.setState({ selectedInstallerOption: null, installerArchive: [] }, () => {
			if (!this.state.selectedAppType || !this.state.selectedInstallerCollection || !this.state.selectedReleaseChannel) return;

			TMSHelpers.getInstallerArchive(
				this.state.selectedAppType.appRole,
				this.state.selectedInstallerCollection.value,
				this.state.selectedReleaseChannel.enumValue
			).then((installerArchive) => {
				this.setState({ installerArchive: Array.isArray(installerArchive) ? installerArchive : [] });
			});
		});
	};
	//#endregion

	render() {
		return (
			<div>
				<h3>Installer Management</h3>
				{globalState.installerCollections ? (
					<>
						<form>
							<GSForms.SelectField
								label="Release Channel"
								formField
								dataState={"selectedReleaseChannel"}
								fieldOptions={Object.values(RELEASE_CHANNELS)}
								fieldValue={this.state.selectedReleaseChannel}
								handleChange={(option) => this.setState({ selectedReleaseChannel: option }, this.getInstallerArchive)}
								isDisabled={this.state.submitting}
								errors={this.state.formErrors}
							/>
							<GSForms.SelectField
								label="App Type"
								formField
								dataState={"selectedAppType"}
								fieldOptions={Object.values(APP_TYPES).filter((x) => x.active)}
								fieldValue={this.state.selectedAppType}
								handleChange={(option) => this.setState({ selectedAppType: option }, this.getInstallerArchive)}
								isDisabled={this.state.submitting}
								errors={this.state.formErrors}
							/>
							<GSForms.SelectField
								label="Installer Collection"
								formField
								dataState={"selectedInstallerCollection"}
								fieldOptions={globalState.installerCollections.map((ic) => new SelectOption({ label: ic.name, value: ic.name }))}
								fieldValue={this.state.selectedInstallerCollection}
								handleChange={(option) => this.setState({ selectedInstallerCollection: option }, this.getInstallerArchive)}
								isDisabled={this.state.submitting}
								errors={this.state.formErrors}
							/>
							{this.state.installerArchive ? (
								<GSForms.SelectField
									label="Installer Options"
									noOptionsMessage={
										!this.state.selectedAppType
											? "Please Select an App"
											: !this.state.selectedReleaseChannel
											? "Please Select a Release Channel"
											: !this.state.selectedInstallerCollection
											? "Please Select an Installer Collection"
											: "No Installers Uploaded"
									}
									placeholder={
										!this.state.selectedAppType
											? "Select an App..."
											: !this.state.selectedReleaseChannel
											? "Select a Release Channel..."
											: !this.state.selectedInstallerCollection
											? "Select an Installer Collection..."
											: "Select..."
									}
									formField
									dataState={"selectedInstallerOption"}
									fieldOptions={this.state.installerArchive.map((option) => new SelectOption({ label: option, value: option }))}
									fieldValue={this.state.selectedInstallerOption}
									handleChange={(option) => FormHelpers.handleSelectChange(option, "selectedInstallerOption", this)}
									isDisabled={this.state.submitting}
									errors={this.state.formErrors}
								/>
							) : (
								<Loading />
							)}

							<div className="flexbox">
								<GSForms.Button
									type="button"
									buttonText={TRANSLATIONS.FORMS.CANCEL}
									onClick={this.handleReset}
									isDisabled={this.state.submitting}
								/>
								<GSForms.Button
									type="button"
									buttonText={"Update Manifest File"}
									isDisabled={this.state.submitting}
									onClick={this.handleUpdateManifest}
								/>
							</div>

							<div className="col-sm-12">
								<GSForms.ErrorContainer message={this.state.responseError} />
								{this.state.submitting && <Loading />}
								{this.state.saved && <GSForms.SavedCheck toggleVisible={() => this.setState({ saved: false })} />}
							</div>
						</form>
					</>
				) : (
					<Loading />
				)}
			</div>
		);
	}
}

const CreateInstallerCollection = () => {
	const [state, setEntireState] = useState({
		newCollectionName: "",
		submitting: false,
		saved: false,
		formErrors: [],
		responseError: null,
	});
	const setState = (newState) => setEntireState({ ...state, ...newState });

	const getFormErrors = () => {
		const errors = [];
		if (Validate.isNullOrEmpty(state.newCollectionName))
			errors.push(new GSForms.FormError("Please Enter a Collection Name", ["newCollectionName"]));
		else if (!Validate.isValidCollectionName(state.newCollectionName))
			errors.push(new GSForms.FormError("Collection names can only contain letters, numbers, and underscores", ["newCollectionName"]));
		return errors;
	};

	const getErrorState = (response) => {
		if (response && response.errorMsg)
			switch (response.key) {
				default:
					return { responseError: HelperFunctions.getResponseError(response) };
			}
		else return { responseError: null };
	};

	const handleCreateInstallerCollection = async () => {
		setState({ submitting: true, saved: false, formErrors: [], responseError: null });
		const formErrors = getFormErrors();
		if (formErrors.length > 0) setState({ submitting: false, saved: false, formErrors: formErrors });
		else {
			await TMSHelpers.createInstallerCollection(state.newCollectionName).then((response) => {
				setState({ submitting: false, saved: response.Success, formErrors: [], ...getErrorState(response) });
			});
		}
	};

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<h3>Create Installer Collection</h3>
			<GSForms.InputField
				label="Collection Name"
				fieldValue={state.newCollectionName}
				dataState="newCollectionName"
				formField
				errors={state.formErrors}
				handleChange={(e) => setState({ newCollectionName: e.target.value })}
			/>

			<GSForms.ErrorContainer message={state.responseError} inlineFormError />
			{state.submitting && <Loading />}
			{state.saved && <GSForms.SavedCheck customMessage={"Collection Created"} toggleVisible={() => setState({ saved: false })} />}

			<GSForms.Button
				type="submit"
				buttonText={"Create New Collection"}
				onClick={() => handleCreateInstallerCollection()}
				isDisabled={state.submitting}
			/>
		</form>
	);
};

const DeleteFileShareDirectory = () => {
	const [state, setEntireState] = useState({
		visibleDirectories: globalState.installerCollections,
		directories: [],
		submitting: false,
		saved: false,
		responseError: null,
	});
	const setState = (newState) => setEntireState({ ...state, ...newState });

	useEffect(() => {
		const unsubscribe = onGlobalStateChange(() => {
			if (globalState.installerCollections) setState({ visibleDirectories: globalState.installerCollections });
		});

		return () => unsubscribe();
	});

	const getErrorState = (response) => {
		if (response && response.errorMsg)
			switch (response.key) {
				default:
					return { responseError: HelperFunctions.getResponseError(response) };
			}
		else return { responseError: null };
	};

	const getDirectoryPath = () => state.directories.map((dir) => dir.name).join("/");

	const handleSelectDirectory = async (dir, level) => {
		setState({
			visibleDirectories: dir?.subdirectories || globalState.installerCollections,
			directories: level === state.directories.length ? [...state.directories, dir] : state.directories.slice(0, -1),
		});
	};

	const handleDeleteDirectory = async () => {
		setState({ submitting: true, saved: false, responseError: null });
		await TMSHelpers.deleteFileshareDirectory(getDirectoryPath()).then((response) => {
			setState({
				visibleDirectories: globalState.installerCollections,
				directories: [],
				submitting: false,
				saved: response.Success,
				...getErrorState(response),
			});
		});
	};

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<h3>Delete FileShare Directory</h3>

			<div>Directory to Delete:</div>
			<div>
				<strong>{getDirectoryPath() || "-"}</strong>
			</div>

			{(state.visibleDirectories && (
				<>
					<div className="flexbox">
						{state.visibleDirectories.length > 0 ? (
							state.visibleDirectories.map((dir, i) => {
								return (
									<GSForms.Button
										key={i}
										type="button"
										buttonText={dir.name}
										isDisabled={state.submitting}
										onClick={() => handleSelectDirectory(dir, state.directories.length)}
									/>
								);
							})
						) : (
							<div>No Subdirectories to Select</div>
						)}
					</div>

					<GSForms.ErrorContainer message={state.responseError} inlineFormError />
					{state.submitting && <Loading />}
					{state.saved && <GSForms.SavedCheck customMessage={"Directory Deleted"} toggleVisible={() => setState({ saved: false })} />}

					<div className="flexbox">
						{state.directories.length > 0 && (
							<GSForms.Button
								type="button"
								buttonText={"Back"}
								isDisabled={state.submitting}
								onClick={() => handleSelectDirectory(state.directories.at(-2), state.directories.length - 1)}
							/>
						)}
						<GSForms.Button
							type="submit"
							buttonText={"Delete Directory"}
							isDisabled={state.submitting}
							onClick={() => handleDeleteDirectory()}
						/>
					</div>
				</>
			)) || <Loading />}
		</form>
	);
};
//#endregion

//#region Portal Management
export const PortalManagement = (props = { userHasAccess: false }) => {
	return (
		<DevPage
			title="Portal Management"
			sections={[
				// Portal Management Sections
				!inGhostMode() && userHasAccess() && <CloudUpdater />,
			]}
		/>
	);
};

const CloudUpdater = () => {
	return (
		<div>
			<h3>Cloud Version Management</h3>
			<GSForms.Button buttonText="Update Cloud Version" onClick={TMSHelpers.updateSiteVersion} />
			<GSForms.Button
				buttonText="Check Cloud Version"
				onClick={() => {
					TMSHelpers.checkSiteVersion();
					console.log(`Current Version: ${localStorage.getItem("gs-cloud-version")}`);
				}}
			/>
		</div>
	);
};
//#endregion
