export interface ISelectOption<T> {
	label: string;
	value: T;
}
export class SelectOption<T> implements ISelectOption<T> {
	label: string;
	value: T;
	constructor(params: { label: string; value: T }) {
		this.label = params.label;
		this.value = params.value;
	}
}
export class MultiSelectOption<ISelectOption> {
	label: string;
	options: Array<ISelectOption>;
	constructor(params: { label: string; options: Array<ISelectOption> }) {
		this.label = params.label;
		this.options = params.options;
	}
}
