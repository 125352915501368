import ParamAssignableObject from "./param-assignable-object.tsx";
import { ISelectOption } from "./select-options.tsx";

export abstract class Entity extends ParamAssignableObject implements ISelectOption<string> {
	id;
	active;

	abstract readonly entityType;
	public abstract get label(): string;
	public get value(): string {
		return this.id;
	}
}
