import { globalState, onGlobalStateChange } from "../store";
import { LANGUAGES } from "./lang-codes";
import { TRANSLATIONS } from "../data-structures/localization";
import { UserData } from "./user-data.tsx";
import { SelectOption } from "./select-options";

//#region Errors
function ErrorCodes() {
	this.UNAUTHORIZED = { key: "unauthorized", errorMsg: TRANSLATIONS.ERRORS.UNAUTHORIZED };
	this.NOT_AUTHORIZED = { key: "not authorized", errorMsg: TRANSLATIONS.ERRORS.NOT_AUTHORIZED };
	this.USERNAME_TAKEN = { key: "username in use", errorMsg: TRANSLATIONS.ERRORS.USERNAME_TAKEN };
	this.PASSWORD_INVALID = { key: "password does not meet requirements", errorMsg: TRANSLATIONS.ERRORS.PASSWORD_INVALID };
	this.TIMEOUT = { key: "timeout", errorMsg: TRANSLATIONS.ERRORS.TIMEOUT };
	this.INVALID_CREDS = { key: "invalid credentials", errorMsg: TRANSLATIONS.ERRORS.INVALID_CREDS };
	this.SUBSCRIPTION_EXPIRED = { key: "subscription expired", errorMsg: TRANSLATIONS.ERRORS.SUBSCRIPTION_EXPIRED };
	this.TYPE_ERROR = { key: "type error. Failed to Fetch", errorMsg: TRANSLATIONS.ERRORS.TYPE_ERROR };
	this.CANNOT_DELETE_SELF = { key: "cannot delete own user", errorMsg: TRANSLATIONS.ERRORS.CANNOT_DELETE_SELF };
	this.COLLECTION_ALREADY_EXISTS = { key: "collection already exists", errorMsg: TRANSLATIONS.ERRORS.COLLECTION_ALREADY_EXISTS };
	this.COLLECTION_PATCHED = {
		key: "collection patched. missing subdirectories created.",
		errorMsg: TRANSLATIONS.ERRORS.COLLECTION_PATCHED,
	};
	this.USER_ABORT = { key: "The user aborted a request.", errorMsg: TRANSLATIONS.ERRORS.USER_ABORT };
	this.EMAIL_ALREADY_VERIFIED = { key: "email address already verified for user", errorMsg: TRANSLATIONS.ERRORS.EMAIL_ALREADY_VERIFIED };
	this.INVALID_TOKEN = { key: "invalid token", errorMsg: TRANSLATIONS.ERRORS.INVALID_TOKEN };
	this.TOKEN_EXPIRED = { key: "token expired", errorMsg: TRANSLATIONS.ERRORS.TOKEN_EXPIRED };
}
export let ERROR_CODES = new ErrorCodes();
//#endregion

//#region Roles
function UserRoles() {
	this.SYSTEM = new SelectOption({ value: 0, label: TRANSLATIONS.ROLES.SYSTEM }); // Limited Slave System
	this.STUDENT = new SelectOption({ value: 1, label: TRANSLATIONS.ROLES.STUDENT });
	this.INSTRUCTOR = new SelectOption({ value: 2, label: TRANSLATIONS.ROLES.INSTRUCTOR }); // Instructor
	this.ADMIN = new SelectOption({ value: 3, label: TRANSLATIONS.ROLES.ADMIN }); // Customer-Level Administrator
	this.DEVELOPER = new SelectOption({ value: 4, label: TRANSLATIONS.ROLES.DEVELOPER }); // Like Global Admin
	this.GLOBAL_ADMIN = new SelectOption({ value: 5, label: TRANSLATIONS.ROLES.GLOBAL_ADMIN }); // GLOBAL ADMIN
}
export let USER_ROLES = new UserRoles();
//#endregion

//#region Measurement Units
function MeasurementUnits() {
	this.METRIC = new SelectOption({ value: 0, label: TRANSLATIONS.MEASUREMENT_UNITS.METRIC });
	this.IMPERIAL = new SelectOption({ value: 1, label: TRANSLATIONS.MEASUREMENT_UNITS.IMPERIAL });
}
export let MEASUREMENT_UNITS = new MeasurementUnits();

function DefaultMeasurementUnit() {
	this.value = MEASUREMENT_UNITS[LANGUAGES[globalState.locale].defaultUnit];
}
export let DEFAULT_MEASUREMENT_UNIT = new DefaultMeasurementUnit().value;
//#endregion

//#region User Import Headers
function UserImportHeaders() {
	this.values = [
		{ key: "username", label: TRANSLATIONS.USER_IMPORT.FILE_HEADERS.USERNAME },
		{ key: "firstName", label: TRANSLATIONS.USER_IMPORT.FILE_HEADERS.FIRST_NAME },
		{ key: "lastName", label: TRANSLATIONS.USER_IMPORT.FILE_HEADERS.LAST_NAME },
		{ key: "emailAddress", label: TRANSLATIONS.USER_IMPORT.FILE_HEADERS.EMAIL_ADDRESS },
		{ key: "role", label: TRANSLATIONS.USER_IMPORT.FILE_HEADERS.ROLE },
	];
}
export let USER_IMPORT_HEADERS = new UserImportHeaders().values;
//#endregion

//#region Server-Side Constants
export const APP_ROLES = {
	SIMULATION_APP: 0,
	SCENARIO_EDITOR: 1,
	IOS: 2,
	TMS_CONSOLE: 3,
	TMS_GUI: 4,
	HOST: 5,
	HMI_APP: 6,
	MOVE_ORDER: 7,
	SCENARIO_EDITOR_UNITY: 8,
	DRONE_VIEW: 9,
	CLOUD_PORTAL: 10,
	LAUNCHER: 11,
};

export const APP_TYPES = {
	SIMULATOR: {
		active: true,
		label: "Falcon",
		value: "Simulator",
		appRole: APP_ROLES.SIMULATION_APP,
	},
	SCENARIO_EDITOR: {
		active: true,
		label: "Scenario Editor",
		value: "ScenarioEditor",
		appRole: APP_ROLES.SCENARIO_EDITOR,
	},
	LAUNCHER: {
		active: true,
		label: "Launcher",
		value: "Launcher",
		appRole: APP_ROLES.LAUNCHER,
	},
	IOS: {
		active: true,
		label: "IOS",
		value: "IOS",
		appRole: APP_ROLES.IOS,
	},
};

export const RELEASE_CHANNELS = {
	DEVELOPMENT: {
		label: "Development",
		value: "development",
		enumValue: 1,
	},
	RELEASE_CANDIDATE: {
		label: "Release Candidate",
		value: "release_candidate",
		enumValue: 2,
	},
	RELEASE: {
		label: "Release",
		value: "release",
		enumValue: 3,
	},
};
//#endregion

//#region Severity
function Severities() {
	this.LOW = new SelectOption({ value: 0, label: TRANSLATIONS.SEVERITY.LOW });
	this.MEDIUM = new SelectOption({ value: 1, label: TRANSLATIONS.SEVERITY.MEDIUM });
	this.HIGH = new SelectOption({ value: 2, label: TRANSLATIONS.SEVERITY.HIGH });
	this.FATAL = new SelectOption({ value: 3, label: TRANSLATIONS.SEVERITY.FATAL });
	this.VIOLATION = new SelectOption({ value: 4, label: TRANSLATIONS.SEVERITY.VIOLATION });
}
export let SEVERITIES = new Severities();
//#endregion

//#region Ghost Mode
export const createGhostUser = (corporealData, orgIdToHaunt) =>
	new UserData({
		id: corporealData.id,
		active: true,
		units: corporealData.units,
		emailAddress: "ghost@ghost-mail.com",
		firstName: "Ghosty",
		lastName: "Ghosterson",
		groups: [],
		language: corporealData.language.toLowerCase(),
		orgId: orgIdToHaunt,
		role: corporealData.role,
		username: corporealData.username,
		nextLesson: null,
		completionData: { total: {}, dataSets: {} },
	});
//#endregion

//#region Organizations
export const TMS_APP_ROLES = {
	SIMULATION_APP: { label: "Simulation App", value: 0 },
	SCENARIO_EDITOR: { label: "Scenario Editor", value: 1 },
	IOS: { label: "IOS", value: 2 },
	TMS_CONSOLE: { label: "TMS Console", value: 3 },
	TMS_GUI: { label: "TMS GUI", value: 4 },
	HOST: { label: "Host", value: 5 },
	HMI_APP: { label: "HMI App", value: 6 },
	MOVE_ORDER: { label: "Move Order", value: 7 },
	SCENARIO_EDITOR_UNITY: { label: "Scenario Editor Unity", value: 8 },
	DRONE_VIEW: { label: "Drone View", value: 9 },
	CLOUD_PORTAL: { label: "Cloud Portal", value: 10 },
	LAUNCHER: { label: "Launcher", value: 11 },
};
//#endregion

onGlobalStateChange(() => {
	ERROR_CODES = new ErrorCodes();
	USER_IMPORT_HEADERS = new UserImportHeaders().values;
	USER_ROLES = new UserRoles();
	MEASUREMENT_UNITS = new MeasurementUnits();
	DEFAULT_MEASUREMENT_UNIT = new DefaultMeasurementUnit().value;
});
