export const GS_COLORS = {
	GLOBALSIM_RED: "#e10600",
	GLOBALSIM_BLUE: "#7099BA",

	GENERIC_BLUE: "#1f77b4",
	GENERIC_PINK: "#e377c2",
	GENERIC_ORANGE: "#ff7f0e",
	GENERIC_GREEN: "#2ca02c",
	GENERIC_YELLOW: "#dcdc17",
	GENERIC_RED: "#d62728",
	GENERIC_SKY: "#17becf",
	GENERIC_PURPLE: "#9467bd",
	GENERIC_GRAY: "#7f7f7f",
	GENERIC_BROWN: "#8c564b",
	GENERIC_SILVER: "#cecece",
};
