import React from "react";
import { NavLink } from "react-router-dom";

export const LOGO_TYPE = {
	DEFAULT: "red",
	RED: "red",
	WHITE: "white",
};

export const GSLogo = ({ type = LOGO_TYPE.DEFAULT, className = "", linkTo = null }) => {
	const [altText, src992_1x, src992_2x, src768_1x, src768_2x, srcimg_1x, srcimg_2x] = getLogoData(type);
	const imageElement = (
		<picture>
			<source data-lazy-srcset={`${src992_1x} 1x, ${src992_2x} 2x`} media="(min-width: 992px)" srcSet={`${src992_1x} 1x, ${src992_2x} 2x`} />
			<source data-lazy-srcset={`${src768_1x} 1x, ${src768_2x} 2x`} media="(min-width: 768px)" srcSet={`${src768_1x} 1x, ${src768_2x} 2x`} />
			<img
				className="entered lazyloaded"
				data-lazy-srcset={`${srcimg_1x} 1x, ${srcimg_2x} 2x`}
				src={`${src992_1x}`}
				alt={altText}
				draggable="false"
				loading="lazy"
				data-lazy-src={`${src992_1x}`}
				data-ll-status="loaded"
				srcSet={`${srcimg_1x} 1x, ${srcimg_2x} 2x`}
			/>
			<noscript>
				<img srcSet={`${srcimg_1x} 1x, ${srcimg_2x} 2x`} src={`${src992_1x}`} alt={altText} draggable="false" loading="lazy" />
			</noscript>
		</picture>
	);
	return <div className={`logo ${className}`}>{linkTo ? <NavLink to={linkTo}>{imageElement}</NavLink> : imageElement}</div>;
};

const getLogoData = (type) => {
	switch (type) {
		default:
		case LOGO_TYPE.RED:
			return [
				"GlobalSim Logo",
				require("./GlobalSim_Logo_R.png"),
				require("./GlobalSim_Logo_R.png"),
				require("./GlobalSim_Logo_R.png"),
				require("./GlobalSim_Logo_R.png"),
				require("./GlobalSim_Logo_R.png"),
				require("./GlobalSim_Logo_R.png"),
			];
		case LOGO_TYPE.WHITE:
			return [
				"GlobalSim Logo",
				require("./GlobalSim_Logo_W.png"),
				require("./GlobalSim_Logo_W.png"),
				require("./GlobalSim_Logo_W.png"),
				require("./GlobalSim_Logo_W.png"),
				require("./GlobalSim_Logo_W.png"),
				require("./GlobalSim_Logo_W.png"),
			];
	}
};
