import React, { Component } from "react";
import GSForms from "../../forms/form-elements";
import { APP_ROLES, RELEASE_CHANNELS } from "../../data-structures/gs-constants";
import { TRANSLATIONS } from "../../data-structures/localization";
import { TMSHelpers } from "../../helpers/tms-helpers";
import { globalState, onGlobalStateChange } from "../../store";
import { Loading } from "../../common/loading-icon";
import "./downloads.scss";

export default class Downloads extends Component {
	//#region Initialization
	constructor(props) {
		super(props);
		this.state = {
			responseError: null,
			fileData: null,
		};
	}

	componentDidMount() {
		this.generateDownloadLink();

		this.unsubscribe = onGlobalStateChange(() => this.generateDownloadLink());
	}

	componentWillUnmount() {
		this.unsubscribe();
	}
	//#endregion

	generateDownloadLink = () => {
		if (globalState.userData && !this.state.fileData) {
			TMSHelpers.getInstallerDownloadURLs(globalState.orgData.id, RELEASE_CHANNELS.RELEASE.value, APP_ROLES.LAUNCHER).then((response) => {
				this.setState(
					response?.errorMsg ? { responseError: TRANSLATIONS.ERRORS.DOWNLOADS_COMING_SOON } : { fileData: response[0], responseError: null }
				);
			});
		} else {
			this.forceUpdate();
		}
	};

	render() {
		return (
			<div className="container downloads-container">
				<h1>{TRANSLATIONS.HEADERS.DOWNLOADS}</h1>
				<div>
					{this.state.fileData ? (
						<>
							<GSForms.Button
								buttonText={TRANSLATIONS.DOWNLOADS.DOWNLOAD_LAUNCHER}
								onClick={() => TMSHelpers.downloadFileFromURL(this.state.fileData)}
							/>

							<div>{TRANSLATIONS.DOWNLOADS.INSTRUCTIONS}</div>
							<div className="download-url">{this.state.fileData.downloadURL}</div>
						</>
					) : this.state.responseError ? (
						<GSForms.ErrorContainer message={this.state.responseError} inlineFormError />
					) : (
						<Loading />
					)}
				</div>
			</div>
		);
	}
}
