import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./icon-button.scss";

export const IconButton = (props = { icon: "", onClick: () => {} }) => {
	return (
		<FontAwesomeIcon
			className="icon-btn"
			icon={props.icon}
			onClick={(e) => {
				e.stopPropagation();
				props.onClick(e);
			}}
		/>
	);
};
