import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Loading } from "../../common/loading-icon";
import { TRANSLATIONS } from "../../data-structures/localization";
import { EvalHelpers } from "../../helpers/eval-helpers.tsx";
import { HelperFunctions } from "../../helpers/helper-functions";
import { TMSHelpers } from "../../helpers/tms-helpers";
import { Validate } from "../../helpers/validation";
import { globalState, onGlobalStateChange, setGlobalState } from "../../store";
import { UserData } from "../../data-structures/user-data.tsx";
import { CompletionData } from "../../data-structures/evaluation-data.tsx";

export default class Stats extends Component {
	//#region Initialization
	constructor(props) {
		super(props);
		this.state = {
			sortAxis: null,
			ascending: true,
			userDataIsLoaded: false,
			nextLessonIsLoaded: false,
			rankListsLoaded: 0,
			completionLinesLoaded: 0,
		};

		this.completionDataInitialized = false;
		this.sortAxisInitialized = false;
		this.nextLessonInitialized = false;
		this.loadingNextLesson = true;
	}

	componentDidMount() {
		this.initialize();

		this.unsubscribe = onGlobalStateChange(() => this.initialize());
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	initialize = () => {
		if (globalState.userData && globalState.groupList && globalState.activeCourses) {
			if (!this.state.userDataIsLoaded) this.setState({ userDataIsLoaded: true });
			this.getAggregateData();
			this.setDefaultSortAxis();
			this.getNextLesson();
		}
	};
	//#endregion

	//#region data getters
	getAggregateData = () => {
		if (!this.completionDataInitialized) {
			const userIsInstructor = HelperFunctions.loggedInAsInstructor();

			if (userIsInstructor) {
				this.completionDataInitialized = true;
				this.getDataByGroup();
			} else if (
				Object.entries(globalState.userData.completionData.total).length === 0 &&
				Object.entries(globalState.userData.completionData.dataSets).length === 0
			) {
				this.completionDataInitialized = true;
				this.getDataByCourse();
			}
		}
	};

	setDefaultSortAxis = () => {
		if (!this.state.sortAxis && !this.sortAxisInitialized) {
			this.sortAxisInitialized = true;
			this.setState({ sortAxis: HelperFunctions.loggedInAsInstructor() ? SORT_AXES.USERNAME : SORT_AXES.COURSE_NAME });
		}
	};

	getNextLesson = () => {
		if (globalState.userData.nextLesson) {
			this.loadingNextLesson = true;
			this.nextLessonInitialized = true;
			this.setState({ nextLessonIsLoaded: true });
		} else if (!this.nextLessonInitialized) {
			this.nextLessonInitialized = true;
			if (!HelperFunctions.loggedInAsStudent) this.loadingNextLesson = false;

			if (this.loadingNextLesson && globalState.userData && globalState.activeCourses && !globalState.userData.nextLesson) {
				this.loadingNextLesson = false;
				TMSHelpers.getEvalHistory(globalState.userData.id).then((evalHistory) => {
					if (evalHistory) {
						const nextLesson = EvalHelpers.getAggCourseCompletionData(
							globalState.activeCourses.map((c) => c.id),
							evalHistory
						).nextLesson;

						setGlobalState({
							userData: new UserData({
								...globalState.userData,
								nextLesson: nextLesson || TRANSLATIONS.NOT_APPLICABLE,
							}),
						});
						this.setState({ nextLessonIsLoaded: true });
					}
				});
			}
		}
	};

	getDataByGroup = async () => {
		Object.values(globalState.groupList.filter((x) => x.active)).forEach((group) => {
			let shouldCalculateRanks = false;
			Object.keys(group.userList).forEach(async (userId) => {
				const found = globalState.groupList.find((g) => g.id === group.id).userList[userId].completionData;
				if (!found?.rank) shouldCalculateRanks = true;

				if (
					!found ||
					Validate.isNullOrEmpty(found.percentComplete) ||
					Validate.isNullOrEmpty(found.timeSpent) ||
					Validate.isNullOrEmpty(found.timeRemaining)
				) {
					const foundInGroup = group.userList[userId].completionData;
					const activeGroupCourseIds = group.courseIds.filter((cId) => globalState.activeCourses.map((c) => c.id).includes(cId));
					TMSHelpers.getCompletionData(userId, activeGroupCourseIds, null, (newCompletionData) => {
						Object.assign(foundInGroup, {
							...newCompletionData,
							rank: (newCompletionData.rank !== -1 && newCompletionData.rank) || foundInGroup?.rank || null,
						});

						const newGroupList = [...globalState.groupList];
						newGroupList.find((x) => x.id === group.id).userList = group.userList;

						setGlobalState({ groupList: newGroupList });
						this.setState({ rankListsLoaded: this.state.rankListsLoaded + 1 });
					});
				}
			});

			if (shouldCalculateRanks) {
				TMSHelpers.getRanksForGroup(group, (rankTiers) => {
					rankTiers.forEach((tier) => {
						tier.userIds.forEach((userId) => {
							Object.assign(group.userList[userId].completionData, { rank: tier.rank });
						});
					});

					const newGroupList = [...globalState.groupList];
					newGroupList.find((g) => g.id === group.id).userList = group.userList;

					setGlobalState({ groupList: newGroupList });
					this.setState({ completionLinesLoaded: this.state.completionLinesLoaded + 1 });
				});
			}
		});
	};

	getEmptyCompletionData = (assignedGroups) => {
		const newUserData = new UserData(globalState.userData);
		Object.assign(newUserData.completionData.total, { rank: -1 });
		Object.assign(
			newUserData.completionData.allCourses,
			globalState.activeCourses.reduce((list, course) => ({ ...list, [course.id]: new CompletionData() }), {})
		);
		Object.assign(
			newUserData.completionData.dataSets,
			assignedGroups.reduce(
				(dataSets, group) => ({
					...dataSets,
					[group.id]: {
						groupId: group.id,
						courses: group.courseIds.reduce(
							(courses, cId) => ({
								...courses,
								...(globalState.activeCourses.find((x) => x.id === cId) && { [cId]: new CompletionData() }),
							}),
							{}
						),
					},
				}),
				{}
			)
		);
		return newUserData;
	};

	getDataByCourse = async () => {
		const assignedGroups = globalState.groupList.filter((group) => group.active && Object.keys(group.userList).includes(globalState.userData.id));

		setGlobalState({ userData: new UserData({ ...globalState.userData, ...this.getEmptyCompletionData(assignedGroups) }) });

		TMSHelpers.getCompletionData(
			globalState.userData.id,
			globalState.activeCourses.map((c) => c.id),
			null,
			(newCompletionData) => {
				const newUserData = new UserData(globalState.userData);
				Object.assign(newUserData.completionData.total, {
					...newCompletionData,
					rank: newCompletionData.rank || newUserData.completionData.total.rank || -1,
				});

				setGlobalState({ userData: new UserData({ ...globalState.userData, ...newUserData }) });
				this.setState({ completionLinesLoaded: this.state.completionLinesLoaded + 1 });
			}
		);

		globalState.activeCourses.forEach((course) => {
			TMSHelpers.getCompletionData(globalState.userData.id, [course.id], null, (courseCompletionData) => {
				const newUserData = new UserData(globalState.userData);
				const foundCourseData = newUserData.completionData.allCourses[course.id];
				if (foundCourseData) {
					Object.assign(foundCourseData, {
						...courseCompletionData,
						rank: courseCompletionData.rank || foundCourseData.rank || -1,
					});

					setGlobalState({ userData: new UserData({ ...globalState.userData, ...newUserData }) });
					this.setState({ completionLinesLoaded: this.state.completionLinesLoaded + 1 });
				}
			});
		});

		assignedGroups.forEach((group) => {
			group.courseIds
				.filter((cId) => globalState.activeCourses.find((c) => c.id === cId))
				.forEach((cId) => {
					TMSHelpers.getCompletionData(globalState.userData.id, [cId], group.id, (courseCompletionData) => {
						const newUserData = new UserData(globalState.userData);
						const foundCourseData = newUserData.completionData.dataSets[group.id]?.courses[cId];
						if (foundCourseData) {
							Object.assign(foundCourseData, {
								...courseCompletionData,
								rank: courseCompletionData.rank || foundCourseData.rank || -1,
							});

							setGlobalState({ userData: new UserData({ ...globalState.userData, ...newUserData }) });
							this.setState({ completionLinesLoaded: this.state.completionLinesLoaded + 1 });
						}
					});
				});
		});
	};

	getRank = (completionData) => {
		return !Validate.isNullOrEmpty(completionData?.rank) && !Validate.isNullOrEmpty(completionData?.timeSpent) ? (
			completionData.rank > 0 && completionData.timeSpent > 0 ? (
				`# ${completionData.rank}`
			) : (
				"-"
			)
		) : (
			<Loading />
		);
	};

	getPercentComplete = (completionData) => {
		return !Validate.isNullOrEmpty(completionData?.percentComplete) && !Validate.isNullOrEmpty(completionData?.timeSpent) ? (
			completionData.percentComplete >= 0 && completionData.timeSpent > 0 ? (
				`${Math.round(completionData.percentComplete * 100)}%`
			) : (
				"-"
			)
		) : (
			<Loading />
		);
	};

	getTimeSpent = (completionData) => {
		return !Validate.isNullOrEmpty(completionData?.timeSpent) ? (
			completionData.timeSpent > 0 ? (
				HelperFunctions.getFriendlyDuration(completionData.timeSpent)
			) : (
				"-"
			)
		) : (
			<Loading />
		);
	};

	getTimeRemaining = (completionData) => {
		return !Validate.isNullOrEmpty(completionData?.timeRemaining) ? (
			completionData.timeRemaining > 0 ? (
				HelperFunctions.getFriendlyDuration(completionData.timeRemaining)
			) : (
				"-"
			)
		) : (
			<Loading />
		);
	};
	//#endregion

	//#region sub-components
	generateUserDetails = () => {
		return (
			<div className="stats-data kv-table">
				<div className="key">{TRANSLATIONS.USER_MANAGEMENT.USERNAME}:</div>
				<div className="value">{globalState.userData?.username || <Loading />}</div>
				<div className="key">{TRANSLATIONS.USER_MANAGEMENT.ROLE}:</div>
				<div className="value">{globalState.userData ? HelperFunctions.getRole(globalState.userData.role).label : <Loading />}</div>
				{globalState.orgData && (!globalState.orgData.isCloudStandardOrg || HelperFunctions.loggedInAsDeveloper()) && (
					<>
						<div className="key">{TRANSLATIONS.DASHBOARD.STATS.ORGANIZATION_NAME}:</div>
						<div className="value">{globalState.orgData.name}</div>
					</>
				)}
				<div className="key">{TRANSLATIONS.DASHBOARD.STATS.COURSES_ENROLLED_IN}:</div>
				<div className="value">{globalState.activeCourses ? globalState.activeCourses.length : <Loading />}</div>
			</div>
		);
	};

	generateNextLessonBlock = () => {
		return (
			<h5>
				<strong>{TRANSLATIONS.DASHBOARD.STATS.NEXT_LESSON}: </strong>
				{this.state.nextLessonIsLoaded && globalState.userData?.nextLesson ? (
					globalState.userData.nextLesson.course ? (
						`${globalState.userData.nextLesson.course.name} > ${globalState.userData.nextLesson.unit.name} > ${globalState.userData.nextLesson.name}`
					) : (
						globalState.userData.nextLesson
					)
				) : (
					<Loading />
				)}
			</h5>
		);
	};

	generateTableSortHeaders = (userIsStudent) => {
		return (
			<div className="table-row table-headers" key={0}>
				{[
					{ label: TRANSLATIONS.DASHBOARD.STATS.RANK, sortAxis: SORT_AXES.RANK },
					userIsStudent
						? { label: TRANSLATIONS.DASHBOARD.EVAL_HISTORY.COURSE, sortAxis: SORT_AXES.COURSE_NAME }
						: { label: TRANSLATIONS.USER_MANAGEMENT.USERNAME, sortAxis: SORT_AXES.USERNAME },
					{
						label: TRANSLATIONS.DASHBOARD.EVAL_HISTORY.PERCENT_COMPLETE,
						sortAxis: SORT_AXES.PERCENT_COMPLETE,
					},
					{ label: TRANSLATIONS.DASHBOARD.EVAL_HISTORY.TIME_SPENT, sortAxis: SORT_AXES.TIME_SPENT },
					{ label: TRANSLATIONS.DASHBOARD.EVAL_HISTORY.TIME_REMAINING, sortAxis: SORT_AXES.TIME_REMAINING },
				].map((x, i) => (
					<button
						className={`table-header ${this.state.sortAxis === x.sortAxis ? "active" : ""}`}
						key={i}
						data-sortaxis={x.sortAxis}
						onClick={(e) => {
							const selectedSortAxis = e.currentTarget.dataset.sortaxis;
							this.setState({
								sortAxis: selectedSortAxis,
								ascending: this.state.sortAxis === selectedSortAxis ? !this.state.ascending : true,
							});
						}}
					>
						<div className="header-content flexbox">
							<div>{x.label}</div>
							<FontAwesomeIcon
								icon={`fa-solid ${this.state.sortAxis === x.sortAxis && !this.state.ascending ? "fa-chevron-up" : "fa-chevron-down"}`}
								className="sort-arrow"
							/>
						</div>
					</button>
				))}
			</div>
		);
	};

	generateCourseTable = () => {
		return [
			this.generateCourseRow("all", TRANSLATIONS.DASHBOARD.STATS.ALL_COURSES, globalState.userData.completionData.total),

			<div className="table-row table-headers table-divider-header" key={"all-courses"}>
				<div className="table-cell">{TRANSLATIONS.DASHBOARD.STATS.COURSE_TOTALS}</div>
			</div>,
			...Object.entries(globalState.userData.completionData.allCourses)
				.sort((a, b) =>
					this.dashboardDataSort(
						{
							completionData: a[1],
							course: globalState.activeCourses?.find((x) => x.id === a[0]),
						},
						{
							completionData: b[1],
							course: globalState.activeCourses?.find((x) => x.id === b[0]),
						}
					)
				)
				.flatMap((cData, i) => {
					const course = globalState.activeCourses?.find((x) => x.id === cData[0]);
					return course ? this.generateCourseRow(i + 1, course.name, cData[1]) : [];
				}),

			...Object.values(globalState.userData.completionData.dataSets).map((dataSet) => {
				const group = globalState.groupList.find((x) => x.id === dataSet.groupId);
				const courseEntries = Object.entries(dataSet.courses);
				return (
					courseEntries.length > 0 && [
						<div className="table-row table-headers table-divider-header" key={group.id}>
							<div>{`${TRANSLATIONS.DASHBOARD.STATS.GROUP_STATS} - ${group.name}`}</div>
						</div>,
						...courseEntries
							.sort((a, b) =>
								this.dashboardDataSort(
									{
										completionData: a[1],
										course: globalState.activeCourses?.find((x) => x.id === a[0]),
									},
									{
										completionData: b[1],
										course: globalState.activeCourses?.find((x) => x.id === b[0]),
									}
								)
							)
							.flatMap((cData, i) => {
								const course = globalState.activeCourses?.find((x) => x.id === cData[0]);
								return course ? this.generateCourseRow(i + 1, course.name, cData[1]) : [];
							}),
					]
				);
			}),
		];
	};

	generateGroupTable = () => {
		return Object.values(globalState.groupList)
			.filter((x) => x.active)
			.map((group) => {
				const userEntries = Object.values(group.userList);
				return (
					userEntries.length > 0 && [
						<div className="table-row table-headers table-divider-header" key={group.id}>
							<div className="table-cell">{group.name}</div>
						</div>,
						...userEntries
							.sort((a, b) => this.dashboardDataSort(a, b))
							.map((userData, i) => this.generateUserRow(`${group.id} - ${i}`, userData.username, userData.completionData)),
					]
				);
			});
	};

	generateUserRow = (rowKey, username, completionData) => {
		return (
			<div className="table-row" key={rowKey}>
				<div className="table-cell">{this.getRank(completionData)}</div>
				<div className="table-cell">{username}</div>
				<div className="table-cell">{this.getPercentComplete(completionData)}</div>
				<div className="table-cell">{this.getTimeSpent(completionData)}</div>
				<div className="table-cell">{this.getTimeRemaining(completionData)}</div>
			</div>
		);
	};

	generateCourseRow = (rowKey, courseName, completionData) => {
		return (
			<div className="table-row" key={rowKey}>
				<div className="table-cell">{this.getRank(completionData)}</div>
				<div className="table-cell">{courseName}</div>
				<div className="table-cell">{this.getPercentComplete(completionData)}</div>
				<div className="table-cell">{this.getTimeSpent(completionData)}</div>
				<div className="table-cell">{this.getTimeRemaining(completionData)}</div>
			</div>
		);
	};
	//#endregion

	dashboardDataSort = (a, b) => {
		let [item1, item2] = this.state.ascending ? [a, b] : [b, a];
		let [sortV1, sortV2] = [null, null];
		const defaultSort = this.state.ascending ? 1 : -1;

		switch (this.state.sortAxis) {
			case SORT_AXES.USERNAME:
				return ("" + item1?.username).localeCompare(item2?.username);

			case SORT_AXES.PERCENT_COMPLETE:
				sortV1 = item1.completionData?.timeSpent > 0 ? item1.completionData.percentComplete : null;
				sortV2 = item2.completionData?.timeSpent > 0 ? item2.completionData.percentComplete : null;
				break;

			case SORT_AXES.TIME_SPENT:
				sortV1 = item1.completionData?.timeSpent;
				sortV2 = item2.completionData?.timeSpent;
				break;

			case SORT_AXES.TIME_REMAINING:
				sortV1 = item1.completionData?.timeRemaining;
				sortV2 = item2.completionData?.timeRemaining;
				break;

			case SORT_AXES.RANK:
				sortV1 = item1.completionData?.timeSpent <= 0 || item1.completionData?.rank <= 0 ? null : item1.completionData?.rank;
				sortV2 = item2.completionData?.timeSpent <= 0 || item2.completionData?.rank <= 0 ? null : item2.completionData?.rank;
				break;

			case SORT_AXES.COURSE_NAME:
			default:
				return defaultSort;
		}

		const primaryOrder = !sortV1 ? defaultSort : !sortV2 ? -defaultSort : sortV1 - sortV2;
		const secondaryOrder = HelperFunctions.loggedInAsStudent() ? defaultSort : ("" + item1?.username).localeCompare(item2?.username);

		return sortV1 !== sortV2 ? primaryOrder : secondaryOrder;
	};

	render() {
		const userIsStudent = HelperFunctions.loggedInAsStudent();

		const completionDataAvailable =
			globalState.groupList?.length > 0 ||
			(globalState.userData?.completionData &&
				(Object.entries(globalState.userData.completionData.total).length > 0 ||
					Object.entries(globalState.userData.completionData.dataSets).length > 0));

		return (
			<div className="stats-container">
				<h2>{TRANSLATIONS.HEADERS.STATISTICS}</h2>

				{this.generateUserDetails()}

				{userIsStudent && this.generateNextLessonBlock()}

				{globalState.userData ? (
					completionDataAvailable ? (
						<div className="dashboard-performance">
							<div className="dashboard-table table-container table-responsive">
								{this.generateTableSortHeaders(userIsStudent)}
								{userIsStudent ? this.generateCourseTable() : this.generateGroupTable()}
							</div>
						</div>
					) : globalState.groupList?.length === 0 ? (
						TRANSLATIONS.GROUP_MANAGEMENT.NO_GROUPS_MESSAGE
					) : (
						<Loading />
					)
				) : (
					<Loading />
				)}
			</div>
		);
	}
}

const SORT_AXES = {
	RANK: "RANK",
	COURSE_NAME: "COURSE_NAME",
	USERNAME: "USERNAME",
	PERCENT_COMPLETE: "PERCENT_COMPLETE",
	TIME_SPENT: "TIME_SPENT",
	TIME_REMAINING: "TIME_REMAINING",
};
