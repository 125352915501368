import React from "react";
import Select from "react-select";
import { LANGUAGES } from "../../data-structures/lang-codes";
import { HelperFunctions } from "../../helpers/helper-functions";
import { globalState, setGlobalState } from "../../store";
import "./locale-picker.scss";

export const LocalePicker = () => {
	return (
		<div id="locale-picker-container" className="no-print">
			<Select
				id="locale-picker"
				classNamePrefix="locale-picker"
				value={LANGUAGES[globalState.locale]}
				options={HelperFunctions.getSortedLanguages()}
				defaultValue={LANGUAGES[globalState.locale]}
				onChange={(option) => {
					setGlobalState({ locale: option.value });
				}}
				menuPlacement="top"
				// temp props
				// controlShouldRenderValue={false}
				// defaultMenuIsOpen={true}
				// closeMenuOnSelect={false}
			/>
		</div>
	);
};
