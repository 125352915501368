import React, { Component } from "react";
import { Bar, Line, Scatter } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { draw as drawPattern } from "patternomaly";
import annotationPlugin from "chartjs-plugin-annotation";
import EvalHistory from "./eval-history";
import { EvalHelpers } from "../../helpers/eval-helpers.tsx";
import GSForms, { SELECT_ALL_VALUE } from "../../forms/form-elements";
import { GS_COLORS } from "../../common/style-constants";
import { Loading } from "../../common/loading-icon";
import { globalState, onGlobalStateChange } from "../../store";
import { TRANSLATIONS } from "../../data-structures/localization";
import { USER_ROLES, SEVERITIES } from "../../data-structures/gs-constants.js";
import { Evaluation } from "../../data-structures/evaluation-data";
import {
	ChartType,
	ChartDatapoint,
	ChartDataset,
	DataSourceType,
	DataSource,
	Dataset,
	EntityType,
	SortType,
	SpeedUnit,
	TIME_UNITS,
	ChartOptions,
	SPEED_UNITS,
} from "../../data-structures/chart-data.tsx";
import { Entity } from "../../data-structures/entity-data.tsx";
import { UserData } from "../../data-structures/user-data.tsx";
import { GroupData } from "../../data-structures/group-data.tsx";
import { CourseData, LessonData, UnitData } from "../../data-structures/course-catalog-data.tsx";
import { HelperFunctions } from "../../helpers/helper-functions";
import { TMSHelpers } from "../../helpers/tms-helpers.js";
import { Validate } from "../../helpers/validation";
import { MultiSelectOption, SelectOption } from "../../data-structures/select-options.tsx";
import "./performance.scss";

Chart.register(...registerables, annotationPlugin);

interface IProps {}

interface IState {
	initialized: boolean;

	dataSources: Array<DataSource>;
	chartTypes: Array<SelectOption<ChartType>>;
	axisUnits: Array<SelectOption<SortType>>;
	speedUnits: Array<SelectOption<string>>;
	loadingChart: boolean;
	datasets: Array<Dataset>;
	chartData: Array<ChartDataset>;
	trendData: Array<any>;
	availableEntities: Array<Entity | MultiSelectOption<Entity>>;
	availableChartTypes: Array<SelectOption<ChartType>>;
	availableCourses: Array<CourseData>;
	availableUnits: Array<MultiSelectOption<UnitData>>;
	availableLessons: Array<MultiSelectOption<LessonData>>;
	dataLimit: number | null;

	// filters
	selectedEntities: Array<Entity>;
	dataSource: DataSource | null;
	xAxisUnit: SelectOption<SortType>;
	speedUnit: SelectOption<SpeedUnit>;
	displaySpeedFilter: boolean;
	chartType: SelectOption<ChartType>;
	startDate: Date;
	endDate: Date;
	minDate: Date;
	maxDate: Date;
	selectedCourses: Array<CourseData>;
	selectedUnits: Array<UnitData>;
	selectedLessons: Array<LessonData>;
	aggDisplayOptions: Array<SelectOption<string>>;
}

export default class Performance extends Component<IProps, IState> {
	chartOptions: ChartOptions;
	DEFAULT_RANGE_IN_MONTHS = 1;

	//#region Initialization
	constructor(props: IProps) {
		super(props);

		this.chartOptions = this.getNewChartOptions();

		this.state = {
			initialized: false,

			dataSources: this.chartOptions.dataSources,
			chartTypes: this.chartOptions.chartTypes,
			axisUnits: this.chartOptions.axisUnits,
			speedUnits: this.chartOptions.speedUnits,
			loadingChart: true,
			datasets: null,
			chartData: [],
			trendData: [],
			availableEntities: [],
			availableChartTypes: [],
			availableCourses: [],
			availableUnits: [],
			availableLessons: [],
			dataLimit: null,

			// filters
			selectedEntities: [],
			dataSource: null,
			xAxisUnit: this.chartOptions.axisUnits.find((au) => au.value === SortType.Date),
			speedUnit: null,
			displaySpeedFilter: false,
			chartType: null,
			startDate: new Date(),
			endDate: new Date(),
			minDate: new Date(),
			maxDate: new Date(),
			selectedCourses: [],
			selectedUnits: [],
			selectedLessons: [],
			aggDisplayOptions: [],
		};
	}

	componentDidMount = () => {
		const getDefaultDataset = (evalHistory: Array<Evaluation>) =>
			globalState.ghostMode
				? []
				: [
						new Dataset({
							data: evalHistory,
							entity: new UserData(globalState.userData),
						}),
				  ];

		if (globalState.userData && globalState.activeCourses) {
			TMSHelpers.getEvalHistory(globalState.userData.id).then((evalHistory) => {
				if (evalHistory) this.initialize(getDefaultDataset(evalHistory));
			});
		}

		this.unsubscribe = onGlobalStateChange(() => {
			const refreshOptions = () => {
				this.chartOptions = this.getNewChartOptions();
				const newDataSource = this.state.dataSource && this.chartOptions.dataSources.find((ds) => ds.value === this.state.dataSource?.value);
				if (newDataSource) {
					const newAvailableChartTypes = this.getFilteredChartTypes(newDataSource, this.chartOptions.chartTypes);
					this.setState({
						dataSources: this.chartOptions.dataSources,
						chartTypes: this.chartOptions.chartTypes,
						axisUnits: this.chartOptions.axisUnits,
						speedUnits: this.chartOptions.speedUnits,
						dataSource: newDataSource,
						availableChartTypes: newAvailableChartTypes,
						chartType: newAvailableChartTypes.find((x) => x.value === this.state.chartType?.value),
						xAxisUnit: this.chartOptions.axisUnits.find(
							(axisUnit: SelectOption<SortType>) => axisUnit.value === this.state.xAxisUnit?.value
						),
						aggDisplayOptions: this.state.aggDisplayOptions.map((x) =>
							Object.values<SelectOption<string>>(this.chartOptions.aggDataOptions).find((o: any) => o.value === x.value)
						),
					});
				}
			};

			if (globalState.userData && globalState.activeCourses && globalState.orgData) {
				this.setState({ initialized: true }, () => {
					if (globalState.ghostMode) {
						this.initialize(getDefaultDataset([]));
						refreshOptions();
					} else
						TMSHelpers.getEvalHistory(globalState.userData.id).then((evalHistory) => {
							if (evalHistory) {
								this.initialize(getDefaultDataset(evalHistory));
								refreshOptions();
							}
						});
				});
			}
		});
	};

	unsubscribe;
	componentWillUnmount() {
		this.unsubscribe();
	}

	initialize = (datasets: Array<Dataset>) => {
		let startDate = new Date();
		let endDate = new Date();
		let minDate = new Date();
		let maxDate = new Date();

		if (datasets.length > 0 && datasets.some((dataset) => dataset.data.length > 0)) {
			[minDate, maxDate] = this.getMinMaxDates(datasets);
			endDate = maxDate;
			endDate.setHours(23, 59, 59);

			startDate = new Date(endDate);
			startDate.setMonth(endDate.getMonth() - this.DEFAULT_RANGE_IN_MONTHS);
			startDate = new Date(Math.max(startDate.valueOf(), minDate.valueOf()));
			startDate.setHours(0, 0, 0, 0);
		}

		const dataSource = this.state.dataSource || this.state.dataSources.find((d) => d.value === DataSourceType.Score);
		this.setState(
			{
				datasets: datasets,
				selectedEntities: datasets.length > 0 ? datasets.map((d) => d.entity) : [],
				startDate: startDate,
				endDate: endDate,
				minDate: minDate,
				maxDate: maxDate,
				dataSource: dataSource,
				chartType: this.state.chartType || this.state.chartTypes.find((ct) => ct.value === dataSource?.defaultChartType.value),
				xAxisUnit: this.state.xAxisUnit || this.chartOptions.axisUnits.find((au) => au.value === SortType.Date),
				speedUnit: this.state.speedUnit || this.chartOptions.speedUnits.METERS_PER_SECOND,
				displaySpeedFilter: dataSource?.useSpeedUnit,
				availableEntities: this.getAvailableEntities(),
				availableChartTypes: dataSource?.supportedChartTypes,
				availableCourses: this.getAvailableCourses(),
				availableUnits: this.state.availableUnits || [],
				availableLessons: this.state.availableLessons || [],
			},
			() => this.handleDataFilterChange()
		);
	};

	getNewChartOptions = () => {
		const bar = new SelectOption({ label: TRANSLATIONS.CHART_TYPES.BAR, value: ChartType.Bar });
		const line = new SelectOption({ label: TRANSLATIONS.CHART_TYPES.LINE, value: ChartType.Line });
		const scatter = new SelectOption({ label: TRANSLATIONS.CHART_TYPES.SCATTERPLOT, value: ChartType.ScatterPlot });

		return new ChartOptions({
			dataSources: [
				new DataSource({
					value: DataSourceType.Score,
					label: TRANSLATIONS.DATA_SOURCES.SCORE,
					defaultChartType: line,
					supportedChartTypes: [line, bar, scatter],
				}),
				new DataSource({
					value: DataSourceType.ScoredTime,
					label: TRANSLATIONS.DATA_SOURCES.SCORED_TIME,
					defaultChartType: line,
					supportedChartTypes: [line, bar, scatter],
				}),
				new DataSource({
					value: DataSourceType.Attempts,
					label: TRANSLATIONS.DATA_SOURCES.ATTEMPTS,
					defaultChartType: bar,
					supportedChartTypes: [line, bar, scatter],
				}),
				new DataSource({
					value: DataSourceType.Duration,
					label: TRANSLATIONS.DATA_SOURCES.DURATION,
					defaultChartType: line,
					supportedChartTypes: [line, bar, scatter],
				}),
				new DataSource({
					value: DataSourceType.Collisions,
					label: TRANSLATIONS.DATA_SOURCES.COLLISIONS,
					defaultChartType: line,
					supportedChartTypes: [line, bar, scatter],
				}),
				new DataSource({
					value: DataSourceType.CollisionVelocity,
					label: TRANSLATIONS.DATA_SOURCES.COLLISION_VELOCITY,
					defaultChartType: scatter,
					supportedChartTypes: [line, bar, scatter],
					useSpeedUnit: true,
				}),
				new DataSource({
					value: DataSourceType.MaximumLoadSwing,
					label: TRANSLATIONS.DATA_SOURCES.MAXIMUM_LOAD_SWING,
					defaultChartType: line,
					supportedChartTypes: [line, bar, scatter],
				}),
				new DataSource({
					value: DataSourceType.Violations,
					label: TRANSLATIONS.DATA_SOURCES.VIOLATIONS,
					defaultChartType: line,
					supportedChartTypes: [line, bar, scatter],
				}),
				new DataSource({
					value: DataSourceType.MovesPerHour,
					label: TRANSLATIONS.DATA_SOURCES.MOVES_PER_HOUR,
					defaultChartType: line,
					supportedChartTypes: [line, bar, scatter],
				}),
			],
			chartTypes: [bar, line, scatter],
			axisUnits: [
				new SelectOption({ label: TRANSLATIONS.AXIS_UNITS.DATE, value: SortType.Date }),
				new SelectOption({ label: TRANSLATIONS.AXIS_UNITS.LESSON, value: SortType.Lesson }),
			],
			speedUnits: {
				METERS_PER_SECOND: new SelectOption<SpeedUnit>({
					label: TRANSLATIONS.SPEED_UNITS.METERS_PER_SECOND,
					value: SPEED_UNITS.METERS_PER_SECOND,
				}),
				KILOMETERS_PER_HOUR: new SelectOption<SpeedUnit>({
					label: TRANSLATIONS.SPEED_UNITS.KILOMETERS_PER_HOUR,
					value: SPEED_UNITS.KILOMETERS_PER_HOUR,
				}),
				FEET_PER_SECOND: new SelectOption<SpeedUnit>({
					label: TRANSLATIONS.SPEED_UNITS.FEET_PER_SECOND,
					value: SPEED_UNITS.FEET_PER_SECOND,
				}),
				MILES_PER_HOUR: new SelectOption<SpeedUnit>({
					label: TRANSLATIONS.SPEED_UNITS.MILES_PER_HOUR,
					value: SPEED_UNITS.MILES_PER_HOUR,
				}),
			},
			aggDataOptions: {
				AVERAGE: new SelectOption({ label: TRANSLATIONS.AGG_DATA_OPTIONS.AVERAGE, value: "AVERAGE" }),
				MEDIAN: new SelectOption({ label: TRANSLATIONS.AGG_DATA_OPTIONS.MEDIAN, value: "MEDIAN" }),
				TREND: new SelectOption({ label: TRANSLATIONS.AGG_DATA_OPTIONS.TREND, value: "TREND" }),
			},
		});
	};
	//#endregion

	//#region Handlers
	handleUserSelect = (selections) => {
		this.setState({ loadingChart: true, selectedEntities: selections }, async () => {
			this.initialize(
				selections.length > 0
					? await Promise.all(
							selections.map(async (sel: Entity): Promise<Dataset> => {
								switch (sel.entityType) {
									case EntityType.Group:
										return new Dataset({
											entity: sel,
											data: await Promise.all<Array<Evaluation>>(
												Object.values<UserData>((sel as GroupData).userList)
													.filter((u) => u.active && !this.state.selectedEntities.find((su) => su.id === u.id))
													.map((user) => TMSHelpers.getEvalHistory(user.id) as Array<Evaluation>)
											).then((userDatasets) => userDatasets.flat()),
										});

									case EntityType.User:
										return TMSHelpers.getEvalHistory(sel.id).then((evals) => new Dataset({ entity: sel, data: evals }));
								}
							})
					  )
					: []
			);
		});
	};

	handleDataFilterChange = (newState: any = {}) => {
		const filterEvals = (evals: Array<Evaluation>) =>
			evals.filter((evl) => {
				// filter out data that is outside of the selected timeframe
				const evalDate = new Date(evl.evaluationDate).getTime();
				if (this.state.startDate && new Date(this.state.startDate).getTime() > evalDate) return false;
				if (this.state.endDate && new Date(this.state.endDate).getTime() < evalDate) return false;

				// filter out data that doesn't belong to any of the selected courses, units, or lessons
				if (
					(this.state.selectedCourses.length > 0 &&
						!this.state.selectedCourses.some((sc) => sc.value === SELECT_ALL_VALUE || (evl.course && evl.course.id === sc.value))) ||
					(this.state.selectedUnits.length > 0 &&
						!this.state.selectedUnits.some((su) => su.value === SELECT_ALL_VALUE || (evl.unit && evl.unit.id === su.value))) ||
					(this.state.selectedLessons.length > 0 &&
						!this.state.selectedLessons.some((sl) => sl.value === SELECT_ALL_VALUE || (evl.lesson && evl.lesson.id === sl.value)))
				)
					return false;

				// if the data wasn't caught by any filters, include it
				return true;
			});

		this.setState({ loadingChart: true, ...newState }, () => {
			const filteredData = this.state.datasets.map((dataset) => new Dataset({ entity: dataset.entity, data: filterEvals(dataset.data) }));
			const dataSource = newState.dataSource || this.state.dataSource;
			const chartData: { dataPoints: Array<ChartDataset>; resultLimit: number } = this.getChartData(filteredData, dataSource);
			this.setState({
				loadingChart: false,
				chartData: chartData.dataPoints,
				trendData: this.getTrendData(chartData.dataPoints),
				availableChartTypes: this.getFilteredChartTypes(dataSource),
				dataLimit: chartData.resultLimit,
			});
		});
	};

	handleDataSourceSelect = (option) => {
		const availableChartTypes = this.getFilteredChartTypes(option);
		this.handleDataFilterChange({
			dataSource: option,
			displaySpeedFilter: option.useSpeedUnit,
			...((!this.state.chartType || !availableChartTypes.find((ct) => ct.value === this.state.chartType.value)) && {
				chartType: availableChartTypes.find((ct) => ct.value === option.defaultChartType),
			}),
		});
	};

	handleCourseSelect = (newSelectedCourses) => {
		newSelectedCourses = HelperFunctions.sortByCatalogOrder(newSelectedCourses, this.state.availableCourses);

		const newAvailableUnits = this.getAvailableUnits(newSelectedCourses);
		const newSelectedUnits = this.filterCatalogByNewSelection(this.state.selectedUnits, newAvailableUnits, newSelectedCourses);

		const newAvailableLessons = this.getAvailableLessons(newSelectedUnits);
		const newSelectedLessons = this.filterCatalogByNewSelection(this.state.selectedLessons, newAvailableLessons, newSelectedUnits);

		this.handleDataFilterChange({
			selectedCourses: newSelectedCourses,
			selectedUnits: newSelectedUnits,
			selectedLessons: newSelectedLessons,
			availableUnits: newAvailableUnits,
			availableLessons: newAvailableLessons,
		});
	};

	handleUnitSelect = (newSelectedUnits) => {
		newSelectedUnits = HelperFunctions.sortByCatalogOrder(newSelectedUnits, this.state.availableUnits);

		const newAvailableLessons = this.getAvailableLessons(newSelectedUnits);
		const newSelectedLessons = this.filterCatalogByNewSelection(this.state.selectedLessons, newAvailableLessons, newSelectedUnits);

		this.handleDataFilterChange({
			selectedUnits: newSelectedUnits,
			selectedLessons: newSelectedLessons,
			availableLessons: newAvailableLessons,
		});
	};

	handleLessonSelect = (newSelectedLessons) => {
		this.handleDataFilterChange({ selectedLessons: HelperFunctions.sortByCatalogOrder(newSelectedLessons, this.state.availableLessons) });
	};
	//#endregion

	//#region Data Gathering
	getAvailableEntities = (): Array<Entity | MultiSelectOption<Entity>> => {
		if (globalState.userData?.role <= USER_ROLES.STUDENT) {
			return [globalState.userData];
		} else if (globalState.orgData?.userList) {
			return [
				// groups
				new MultiSelectOption({
					label: TRANSLATIONS.GROUP_MANAGEMENT.GROUPS,
					options: globalState.groupList.filter((x) => x.active),
				}),
				// individuals
				new MultiSelectOption({
					label: TRANSLATIONS.GROUP_MANAGEMENT.STUDENTS,
					options: globalState.orgData?.userList.filter((x) => x.active),
				}),
			];
		}
	};

	getMinMaxDates = (selectedDatasets: Array<Dataset>) => {
		const selectedIndividuals = selectedDatasets.filter((dataset) => dataset.entityType === EntityType.User);
		const flattenedEvalDates = this.getFlatSortedDatapoints<Evaluation>(
			selectedIndividuals.length > 0 ? selectedIndividuals : selectedDatasets
		).map((x) => new Date(x.evaluationDate).valueOf());
		return [new Date(Math.min(...flattenedEvalDates)), new Date(Math.max(...flattenedEvalDates))];
	};

	getFlatSortedDatapoints<T extends Evaluation | ChartDatapoint>(datasets: Array<Dataset | ChartDataset>): Array<T> {
		return EvalHelpers.getSortedDatapoints<T>(
			datasets.flatMap((dataset) => dataset.data as T[]),
			this.state.xAxisUnit?.value
		);
	}

	itemIsSelected = (id, selectedOptions) => selectedOptions.length === 0 || selectedOptions.find((s) => s.value === id);

	getFlatSelectedLessonData = () => {
		return EvalHelpers.getFlattenedLessonList(this.state.availableCourses).flatMap((lesson) =>
			this.itemIsSelected(lesson.course.id, this.state.selectedCourses) ||
			this.itemIsSelected(lesson.unit.id, this.state.selectedUnits) ||
			this.itemIsSelected(lesson.id, this.state.selectedLessons)
				? new ChartDatapoint({
						x: lesson.id,
						y: 0,
						lesson: lesson,
						unit: lesson.unit,
						course: lesson.course,
				  })
				: []
		);
	};

	getAvailableCourses = () => globalState.activeCourses;

	getAvailableUnits = (selectedCourses) => {
		return selectedCourses.flatMap((sc) => ({
			label: sc.label,
			options: this.state.availableCourses
				.find((ac) => ac.id === sc.value)
				.unitList.filter((x) => x.active && !x.deleted)
				.map((u) => ({ label: u.name, value: u.id, id: sc.value, ...u })),
		}));
	};

	getAvailableLessons = (selectedUnitGroups) => {
		return selectedUnitGroups.flatMap((su) => {
			return {
				label: su.label,
				options: this.state.availableCourses
					.find((c) => c.id === su.course.id)
					.unitList.filter((x) => x.active && !x.deleted)
					.find((u) => u.id === su.id)
					.lessonList.filter((x) => x.active && !x.deleted)
					.map((l) => ({ label: l.name, value: l.id, id: su.id, ...l })),
			};
		});
	};

	filterCatalogByNewSelection = (selectedData, availableData, selectionToFilterBy) =>
		HelperFunctions.sortByCatalogOrder(
			selectedData.filter((sd) => selectionToFilterBy.some((filter) => filter.value === sd.id)),
			availableData
		);

	//#endregion

	//#region Chart Configuration
	getChartData = (datasets: Array<Dataset>, dataSource): { dataPoints: Array<ChartDataset>; resultLimit: number } => {
		const datasetReducer = (agg: Array<ChartDatapoint>, evl: Evaluation) => {
			let newDataPoints = [];

			switch (dataSource.value) {
				case DataSourceType.Score:
					newDataPoints.push(evl.scoring.finalScore);
					break;

				case DataSourceType.ScoredTime:
					const scoredTime = evl.dataList.find((dataPoint) => dataPoint.name.toUpperCase().includes("SCORED TIME"));
					if (scoredTime) newDataPoints.push(Math.round(scoredTime.value / 60));
					break;

				case DataSourceType.Attempts:
					switch (this.state.xAxisUnit.value) {
						case SortType.Date: {
							const foundI = agg.findIndex((item) => new Date(item.x).getDate() === new Date(evl.evaluationDate).getDate());
							if (foundI !== -1) {
								agg[foundI].y++;
							} else {
								newDataPoints.push(1);
							}
							break;
						}

						case SortType.Lesson: {
							if (agg.length === 0) agg = this.getFlatSelectedLessonData();
							agg.find((item) => evl.lesson && item.x === evl.lesson.id).y++;
							break;
						}

						default:
							break;
					}
					break;

				case DataSourceType.Duration:
					newDataPoints.push(evl.duration / 60);
					break;

				case DataSourceType.Collisions:
					const counts = evl.eventList.reduce(
						(counts, e) => {
							if (e.type.includes("CollisionEvent")) {
								counts[Object.values<SelectOption<string>>(SEVERITIES).find((x) => x.value === e.severity).value]++;
							}
							return counts;
						},
						{
							[SEVERITIES.LOW.value]: 0,
							[SEVERITIES.HIGH.value]: 0,
							[SEVERITIES.FATAL.value]: 0,
						}
					);

					newDataPoints.push({
						collisions: counts,
						value: Object.values(counts).reduce((total: number, c: any) => (total += c), 0),
					});
					break;

				case DataSourceType.CollisionVelocity:
					const conversionRate = this.state.speedUnit.value.conversionRate;
					newDataPoints = evl.eventList.map((event) => Math.round(event.velocity * conversionRate * 100) / 100);
					break;

				case DataSourceType.MaximumLoadSwing:
					const maxLoadSwing = evl.dataList.find(
						(dataPoint) =>
							dataPoint.name.toUpperCase().includes("MAXIMUM SIDE LOAD ANGLE") ||
							dataPoint.name.toUpperCase().includes("MAXIMUM SIDE SWING ANGLE")
					);
					if (maxLoadSwing) newDataPoints.push(parseFloat(maxLoadSwing.value));
					break;

				case DataSourceType.Violations:
					newDataPoints.push(evl.eventList.filter((x) => x.type.includes("IncidentEvent")).length);
					break;

				case DataSourceType.MovesPerHour:
					const movesPerHour = evl.dataList.find((dataPoint) => dataPoint.name.toUpperCase() === "MOVES PER HOUR");
					if (movesPerHour) newDataPoints.push(parseFloat(movesPerHour.value));
					break;

				default:
					break;
			}

			agg.push(
				...newDataPoints.map(
					(dp) =>
						new ChartDatapoint({
							x: this.state.xAxisUnit.value === SortType.Lesson ? evl.lesson?.id : evl.evaluationDate,
							y: dp.value || dp,
							lesson: evl.lesson,
							unit: evl.unit,
							course: evl.course,
							evaluationDate: evl.evaluationDate,
							collisionData: dp.collisions,
						})
				)
			);

			return agg;
		};

		return {
			dataPoints: datasets
				.map(
					(dataset: Dataset) =>
						new ChartDataset({
							entity: dataset.entity,
							data: EvalHelpers.getSortedDatapoints<ChartDatapoint>(
								dataset.data.reduce(datasetReducer, []),
								this.state.xAxisUnit.value
							),
						})
				)
				.sort((a: ChartDataset, b: ChartDataset) => {
					if (a.entityType === b.entityType) return 0;
					else return a.entityType === EntityType.Group ? -1 : 1;
				}),
			resultLimit: this.state.xAxisUnit.value === SortType.Lesson ? 50 : null,
		};
	};

	getTrendData = (datasets) =>
		this.getFlatSortedDatapoints<ChartDatapoint>(datasets)
			// groups data by x axis
			.reduce((agg, dataPoint) => {
				let xAxisGroup: string | Date;
				switch (this.state.xAxisUnit.value) {
					case SortType.Date:
						const roundedDate = new Date(dataPoint.x);
						roundedDate.setHours(roundedDate.getHours() + Math.round(roundedDate.getMinutes() / 60), 0, 0);
						xAxisGroup = roundedDate.toLocaleString();
						break;

					case SortType.Lesson:
					default:
						xAxisGroup = dataPoint.x;
						break;
				}

				let found = agg.find((item) => item.group === xAxisGroup);
				if (found) {
					found.data.push(dataPoint.y);
				} else {
					agg.push({ groupName: dataPoint.x, group: xAxisGroup, data: [dataPoint.y] });
				}
				return agg;
			}, [])
			// find the average per grouping
			.map((dataGroup) => {
				switch (this.state.xAxisUnit.value) {
					case SortType.Date:
						return new ChartDatapoint({
							x: new Date(dataGroup.group),
							y: HelperFunctions.getAverage(dataGroup.data),
						});

					case SortType.Lesson:
					default:
						return new ChartDatapoint({
							x: dataGroup.groupName,
							y: HelperFunctions.getAverage(dataGroup.data),
						});
				}
			});

	getFilteredChartTypes = (dataSource: DataSource, chartTypes = this.state.chartTypes): Array<SelectOption<ChartType>> =>
		dataSource ? chartTypes.filter((type) => dataSource.supportedChartTypes.some((ct) => ct.value === type.value)) : chartTypes;

	getChartLabels = () => {
		switch (this.state.xAxisUnit.value) {
			case SortType.Lesson:
				return HelperFunctions.getUniqueValues(
					this.state.chartData.flatMap((cd) => cd.data),
					"x"
				).map((d) => d.x);

			case SortType.Date:
			default:
				return this.state.chartData
					.reduce((largestDataset, user) => (user.data.length > largestDataset.length ? user.data : largestDataset), [])
					.map((d) => d.x);
		}
	};

	getXAxisProps = () => {
		switch (this.state.xAxisUnit.value) {
			case SortType.Date:
				const fse = this.getFlatSortedDatapoints<ChartDatapoint>(this.state.chartData);
				const minDate = new Date(fse.length > 0 ? fse[0].x : this.state.startDate);
				const maxDate = new Date(fse.length > 0 ? fse[fse.length - 1].x : this.state.endDate);
				const timeUnit =
					Math.round((maxDate.valueOf() - minDate.valueOf()) / TIME_UNITS.DAY.milliseconds) > 2 ? TIME_UNITS.DAY : TIME_UNITS.HOUR;
				const timeBuffer = timeUnit.milliseconds * timeUnit.buffer;
				const minDateAdj = minDate.getTime() - timeBuffer;
				const maxDateAdj = maxDate.getTime() + timeBuffer;
				return {
					title: {
						display: true,
						text: TRANSLATIONS.PERFORMANCE.DATE,
					},
					offset: true,
					suggestedMin: minDateAdj,
					suggestedMax: maxDateAdj,
					type: this.state.xAxisUnit.value === SortType.Date ? "time" : "category",
					time: {
						unit: timeUnit.value.toLowerCase(),
					},
					ticks: {
						callback: function (val, i, ticks) {
							return timeUnit.value === TIME_UNITS.HOUR.value
								? [
										new Date(ticks[i].value).toLocaleString(globalState.locale, {
											hour: "2-digit",
										}),
										new Date(ticks[i].value).toLocaleString(globalState.locale, {
											month: "2-digit",
											day: "2-digit",
										}),
								  ]
								: val;
						},
					},
				};

			case SortType.Lesson:
				const lessons: Array<ChartDatapoint> = this.getFlatSelectedLessonData();
				return {
					title: {
						display: false,
					},
					type: "category",
					ticks: {
						autoSkip: false,
						maxTicksLimit: this.state.dataLimit,
						precision: 0,
						maxRotation: 90,
						minRotation: 90,
						labelOffset: -7,
						callback: function (value) {
							const chartTick = this;
							const lessonId = chartTick.getLabelForValue(value);
							return lessons.find((l) => l.lesson?.id === lessonId)?.lesson.name;
						},
					},
				};

			default:
				return {};
		}
	};

	getYAxisProps = () => {
		let yAxisLabel = this.state.dataSource?.label;
		switch (this.state.dataSource?.value) {
			case DataSourceType.CollisionVelocity:
				yAxisLabel = TRANSLATIONS.PERFORMANCE.IN_SPEED_UNITS(TRANSLATIONS.DATA_SOURCES.COLLISION_VELOCITY, this.state.speedUnit.label);
				break;

			case DataSourceType.Duration:
				yAxisLabel = TRANSLATIONS.PERFORMANCE.IN_TIME_UNITS(TRANSLATIONS.DATA_SOURCES.DURATION, TRANSLATIONS.TIME_UNITS.MINUTE);
				break;

			case DataSourceType.MaximumLoadSwing:
				yAxisLabel = TRANSLATIONS.PERFORMANCE.IN_DEGREES(TRANSLATIONS.DATA_SOURCES.MAXIMUM_LOAD_SWING);
				break;

			default:
				break;
		}

		return {
			suggestedMin: 0,
			suggestedMax: 10,
			title: {
				display: true,
				text: yAxisLabel,
			},
			ticks: {
				precision: 0,
			},
		};
	};

	getAnnotationProps = (xAxisSuggestedMax) => {
		const sortedData = this.getFlatSortedDatapoints<ChartDatapoint>(this.state.chartData)
			.map((d) => d.y)
			.sort((a, b) => a - b);

		return this.state.aggDisplayOptions.reduce((propsObj, option) => {
			const dataOption: any = Object.values(this.chartOptions.aggDataOptions).find((aggOption: any) => aggOption.value === option.value);

			const middleIndex = Math.floor(sortedData.length / 2);
			const dataValue = {
				[this.chartOptions.aggDataOptions.AVERAGE.value]: HelperFunctions.getAverage(sortedData, 2),
				[this.chartOptions.aggDataOptions.MEDIAN.value]:
					sortedData.length % 2 === 0 ? (sortedData[middleIndex - 1] + sortedData[middleIndex]) / 2 : sortedData[middleIndex],
			}[dataOption.value];

			return {
				...propsObj,
				...(dataOption &&
					!Validate.isNullOrEmpty(dataValue) && {
						[`${dataOption.value.toLowerCase()}`]: {
							type: "line",
							borderColor: GS_COLORS.GLOBALSIM_BLUE,
							borderWidth: 5,
							scaleID: "y",
							value: dataValue,
						},
						[`${dataOption.value.toLowerCase()}Label`]: {
							type: "label",
							backgroundColor: GS_COLORS.GLOBALSIM_BLUE,
							color: "white",
							content: `${dataOption.label}: ${dataValue}`,
							xValue: xAxisSuggestedMax,
							yValue: dataValue,
							position: {
								x: "end",
								y: "end",
							},
						},
					}),
			};
		}, {});
	};

	getChartProps = (labels) => {
		const xAxisProps = this.getXAxisProps();
		const groupCount = this.state.chartData.filter((x) => x.entityType === EntityType.Group).length;
		return {
			datasetIdKey: "id",
			id: "performance-chart",
			data: {
				datasets: [
					...this.state.chartData.map((dataset, i, cd) => ({
						id: cd.length - i,
						label: dataset.user?.username || dataset.group?.name,
						data:
							this.state.xAxisUnit.value === SortType.Lesson
								? dataset.data.filter((d) => labels.slice(0, this.state.dataLimit || -1).find((l) => l === d.x))
								: dataset.data,
						backgroundColor:
							dataset.entityType === EntityType.Group
								? this.chartOptions.chartStyles.GROUP_COLORS[i % this.chartOptions.chartStyles.GROUP_COLORS.length]
								: drawPattern(
										this.chartOptions.chartStyles.PATTERNS[(i - groupCount) % this.chartOptions.chartStyles.PATTERNS.length],
										this.chartOptions.chartStyles.USER_COLORS[(i - groupCount) % this.chartOptions.chartStyles.USER_COLORS.length]
								  ),
						order: cd.length - i + 1,
						...(this.state.chartType?.value === ChartType.Line && dataset.entityType === EntityType.Group && { showLine: false }),
					})),
					...(this.state.aggDisplayOptions?.some((x) => x.value === this.chartOptions.aggDataOptions.TREND.value)
						? [
								{
									id: this.state.datasets.length + 1,
									type: "line",
									order: 1,
									label: TRANSLATIONS.AGG_DATA_OPTIONS.TREND,
									data: this.state.trendData,
									backgroundColor: GS_COLORS.GLOBALSIM_BLUE,
									borderColor: GS_COLORS.GLOBALSIM_BLUE,
									tension: 0.1,
								},
						  ]
						: []),
				],
			},
			options: {
				scales: {
					x: xAxisProps,
					y: this.getYAxisProps(),
				},
				plugins: {
					tooltip: {
						callbacks: {
							title: (context) => {
								const dataPoint: ChartDatapoint = context[0].raw;
								if (dataPoint.isAggregate) return "";

								const date =
									dataPoint.evaluationDate &&
									new Date(dataPoint.evaluationDate).toLocaleString(globalState.locale, {
										dateStyle: "medium",
										timeStyle: "short",
									});
								return [
									...(date ? [`Date: ${date}`] : []),
									`${TRANSLATIONS.PERFORMANCE.COURSE}: "${dataPoint.lesson.course.name}"`,
									`${TRANSLATIONS.PERFORMANCE.UNIT}: "${dataPoint.lesson.unit.name}"`,
									`${TRANSLATIONS.PERFORMANCE.LESSON}: "${dataPoint.lesson.name}"`,
								];
							},
							label: (context) => {
								const dataPoint: ChartDatapoint = context.raw;
								switch (this.state.dataSource?.value) {
									case DataSourceType.Duration:
										return `${context.dataset.label}: ${new Date(new Date(0).setSeconds(dataPoint.y * 60)).toLocaleString(
											globalState.locale,
											{
												minute: "numeric",
												second: "numeric",
											}
										)}`;

									case DataSourceType.Collisions:
										return [
											`${context.dataset.label}: ${dataPoint.y} ${TRANSLATIONS.DATA_SOURCES.COLLISIONS}`,
											...Object.entries(dataPoint.collisionData).map(
												(c) =>
													`${c[1]} ${Object.values<any>(SEVERITIES).find((s) => s.value.toString() === c[0]).label} ${
														TRANSLATIONS.DATA_SOURCES.COLLISIONS
													}`
											),
										];

									case DataSourceType.CollisionVelocity:
										return [`${context.dataset.label}: ${Math.round(dataPoint.y * 100) / 100} ${this.state.speedUnit.label}`];

									default:
										return `${context.dataset.label}: ${dataPoint.y}`;
								}
							},
						},
					},
					colors: {
						enabled: false,
					},
					legend: {
						rtl: true,
					},
					annotation: {
						annotations: this.getAnnotationProps(xAxisProps.suggestedMax),
					},
				},
				responsive: true,
				maintainAspectRatio: false,
				spanGaps: true,
				interaction: {
					mode: "nearest",
					axis: "xy",
					intersect: false,
				},
			},
		};
	};
	//#endregion

	//#region Page Sections
	PerformancePrintout = () => (
		<div id="performance-printout">
			<h2 className="print-only">
				{TRANSLATIONS.HEADERS.PERFORMANCE_PRINTOUT(
					this.state.dataSource && this.state.dataSource.label,
					(this.state.selectedEntities[0] as UserData).fullName
				)}
			</h2>

			<div className="container print-only row">
				<div className="flexbox column col-4">
					<div>
						<strong>{TRANSLATIONS.PERFORMANCE.FROM_DATE}</strong>: {this.state.startDate.toLocaleDateString()}
					</div>
					<div>
						<strong>{TRANSLATIONS.PERFORMANCE.TO_DATE}</strong>: {this.state.endDate.toLocaleDateString()}
					</div>
				</div>
				<div className="col-4">
					<h3>{TRANSLATIONS.PERFORMANCE.STUDENTS}</h3>
					<ul>
						{this.state.selectedEntities.map((x) => (
							<li key={x.value}>{x.label}</li>
						))}
					</ul>
				</div>
				<div className="col-4">
					<h3>{TRANSLATIONS.PERFORMANCE.DATA_SOURCE}</h3>
					{this.state.dataSource?.label}
				</div>
				<div className="col-4">
					<h3>{TRANSLATIONS.PERFORMANCE.COURSES}</h3>
					{this.state.selectedCourses.length === 0 ? (
						TRANSLATIONS.PERFORMANCE.SELECT_ALL_COURSES
					) : (
						<ul>
							{this.state.selectedCourses.map((x) => (
								<li key={x.value}>{x.label}</li>
							))}
						</ul>
					)}
				</div>
				<div className="col-4">
					<h3>{TRANSLATIONS.PERFORMANCE.UNITS}</h3>
					{this.state.selectedUnits.length === 0 ? (
						TRANSLATIONS.PERFORMANCE.SELECT_ALL_UNITS
					) : (
						<ul>
							{this.state.selectedUnits.map((x) => (
								<li key={x.value}>{x.label}</li>
							))}
						</ul>
					)}
				</div>
				<div className="col-4">
					<h3>{TRANSLATIONS.PERFORMANCE.LESSONS}</h3>
					{this.state.selectedLessons.length === 0 ? (
						TRANSLATIONS.PERFORMANCE.SELECT_ALL_LESSONS
					) : (
						<ul>
							{this.state.selectedLessons.map((x) => (
								<li key={x.value}>{x.label}</li>
							))}
						</ul>
					)}
				</div>
			</div>
		</div>
	);

	ChartFilters = () => (
		<div className="chart-filters no-print">
			{globalState.orgData && HelperFunctions.loggedInAsInstructor() && (
				<GSForms.MultiSelectField
					label={TRANSLATIONS.PERFORMANCE.STUDENTS}
					className="chart-filter"
					fieldValue={this.state.selectedEntities}
					fieldOptions={this.state.availableEntities}
					allOptionsLabel={new GSForms.SelectAllOption().label}
					defaultValue={this.state.availableEntities.find((x) => (x as Entity).value === null)}
					isDisabled={this.state.loadingChart}
					handleChange={this.handleUserSelect}
				/>
			)}
			<GSForms.SelectField
				label={TRANSLATIONS.PERFORMANCE.DATA_SOURCE}
				className="chart-filter"
				fieldValue={this.state.dataSource}
				fieldOptions={this.state.dataSources}
				isDisabled={this.state.loadingChart}
				handleChange={this.handleDataSourceSelect}
			/>
			{this.state.displaySpeedFilter && (
				<GSForms.SelectField
					label={TRANSLATIONS.PERFORMANCE.SPEED_UNIT}
					className="chart-filter"
					fieldValue={this.state.speedUnit}
					fieldOptions={Object.values(this.state.speedUnits)}
					isDisabled={this.state.loadingChart}
					handleChange={(option) => this.handleDataFilterChange({ speedUnit: option })}
				/>
			)}
			<GSForms.SelectField
				label={TRANSLATIONS.PERFORMANCE.CHART_TYPE}
				className="chart-filter"
				fieldValue={this.state.chartType}
				fieldOptions={this.state.availableChartTypes}
				isDisabled={this.state.loadingChart}
				handleChange={(option) => this.handleDataFilterChange({ chartType: option })}
			/>
			<GSForms.SelectField
				label={TRANSLATIONS.PERFORMANCE.X_AXIS_UNIT}
				className="chart-filter"
				fieldValue={this.state.xAxisUnit}
				fieldOptions={this.state.axisUnits}
				isDisabled={this.state.loadingChart}
				handleChange={(option) => this.handleDataFilterChange({ xAxisUnit: option })}
			/>
			<GSForms.MultiSelectField
				label={TRANSLATIONS.PERFORMANCE.AGGREGATED_DATA_OPTIONS}
				className="chart-filter"
				fieldValue={this.state.aggDisplayOptions}
				fieldOptions={Object.values(this.chartOptions.aggDataOptions)}
				isDisabled={this.state.loadingChart}
				handleChange={(selectedOptions) => this.handleDataFilterChange({ aggDisplayOptions: selectedOptions })}
			/>
			<GSForms.MultiSelectField
				label={TRANSLATIONS.PERFORMANCE.COURSES}
				className="chart-filter"
				fieldValue={this.state.selectedCourses}
				fieldOptions={this.state.availableCourses}
				allOptionsLabel={TRANSLATIONS.PERFORMANCE.SELECT_ALL_COURSES}
				defaultValue={this.state.availableCourses.find((x) => x.value === null)}
				isDisabled={this.state.loadingChart}
				handleChange={this.handleCourseSelect}
			/>
			<GSForms.MultiSelectField
				label={TRANSLATIONS.PERFORMANCE.UNITS}
				className="chart-filter"
				fieldValue={this.state.selectedUnits}
				fieldOptions={this.state.availableUnits}
				allOptionsLabel={TRANSLATIONS.PERFORMANCE.SELECT_ALL_UNITS}
				placeholder={
					this.state.selectedCourses.length !== 0 && !this.state.selectedCourses.find((sc) => sc.value === SELECT_ALL_VALUE)
						? TRANSLATIONS.FORMS.SELECT_PLACEHOLDER
						: TRANSLATIONS.PERFORMANCE.UNIT_SELECT_PLACEHOLDER
				}
				isDisabled={this.state.loadingChart}
				handleChange={this.handleUnitSelect}
			/>
			<GSForms.MultiSelectField
				label={TRANSLATIONS.PERFORMANCE.LESSONS}
				className="chart-filter"
				fieldValue={this.state.selectedLessons}
				fieldOptions={this.state.availableLessons}
				allOptionsLabel={TRANSLATIONS.PERFORMANCE.SELECT_ALL_LESSONS}
				placeholder={
					this.state.selectedUnits.length !== 0 && !this.state.selectedUnits.find((su) => su.value === SELECT_ALL_VALUE)
						? TRANSLATIONS.FORMS.SELECT_PLACEHOLDER
						: TRANSLATIONS.PERFORMANCE.LESSON_SELECT_PLACEHOLDER
				}
				isDisabled={this.state.loadingChart}
				handleChange={this.handleLessonSelect}
			/>
			<GSForms.DateRangeField
				label={TRANSLATIONS.PERFORMANCE.DATE_RANGE}
				id="date-range"
				className="chart-filter"
				startDate={this.state.startDate}
				endDate={this.state.endDate}
				minDate={this.state.minDate}
				maxDate={this.state.maxDate}
				isDisabled={this.state.loadingChart}
				handleChangeStart={(startDate) => this.handleDataFilterChange({ startDate: startDate })}
				handleChangeEnd={(endDate) => this.handleDataFilterChange({ endDate: endDate })}
			/>
		</div>
	);

	Chart = () => {
		window.addEventListener("beforeprint", () => {
			let chart = Object.values(Chart.instances)[0];
			if (chart) chart.resize(800, 800);
		});
		window.addEventListener("afterprint", () => {
			let chart = Object.values(Chart.instances)[0];
			if (chart) chart.resize();
		});
		const flatChartData = this.getFlatSortedDatapoints<ChartDatapoint>(this.state.chartData);

		if (this.state.xAxisUnit) {
			let labels = this.getChartLabels();
			let chartProps = this.getChartProps(labels);

			return (
				<div className={`${!this.state.loadingChart && flatChartData.length > 0 ? "chart-container " : ""}flexbox column pagebreak`}>
					{(this.state.loadingChart && <Loading />) ||
						(this.state.availableChartTypes.length === 0 && this.state.dataSource && (
							<div className="chart-error">{TRANSLATIONS.PERFORMANCE.DATA_SOURCE_NOT_SUPPORTED(this.state.dataSource.label)}</div>
						)) ||
						(flatChartData.length === 0 && (
							<div className="chart-error">
								{this.state.datasets.some((user) => user.data.length > 0)
									? TRANSLATIONS.PERFORMANCE.BROADEN_FILTERS_MESSAGE
									: TRANSLATIONS.PERFORMANCE.NO_DATA_MESSAGE}
							</div>
						)) || (
							<>
								<div className="grid w100">
									{this.state.dataLimit &&
										((this.state.xAxisUnit.value === SortType.Date && flatChartData.length >= this.state.dataLimit) ||
											(this.state.xAxisUnit.value === SortType.Lesson && labels.length > this.state.dataLimit)) && (
											<h3>{TRANSLATIONS.PERFORMANCE.DATA_LIMITED_MESSAGE(this.state.dataLimit)}</h3>
										)}
									<button onClick={window.print} className="print-btn no-print">
										{TRANSLATIONS.PRINT}
									</button>
								</div>
								{this.state.chartType && this.getChartComponent(this.state.chartType.value, chartProps)}
								<div className="print-only">{TRANSLATIONS.PERFORMANCE.CHART_GENERATED_DATE(new Date().toLocaleString())}</div>
							</>
						)}
				</div>
			);
		} else return <Loading />;
	};

	getChartComponent = (chartType, chartProps) => {
		switch (chartType) {
			case ChartType.Line:
				return <Line {...chartProps} />;
			case ChartType.Bar:
				return <Bar {...chartProps} />;
			case ChartType.ScatterPlot:
				return <Scatter {...chartProps} />;
		}
	};
	//#endregion

	render() {
		return (
			<div className="performance-container container">
				{this.state.availableCourses ? (
					<>
						<h1 className="no-print">{TRANSLATIONS.HEADERS.PERFORMANCE}</h1>

						{this.state.selectedEntities?.length > 0 && this.PerformancePrintout()}

						{this.ChartFilters()}

						{this.Chart()}

						{this.state.selectedEntities?.length === 1 && (
							<EvalHistory
								courses={this.state.availableCourses.filter((x) => this.itemIsSelected(x.id, this.state.selectedCourses))}
								units={
									this.state.selectedUnits.length > 0
										? this.state.availableUnits
												.flatMap((au) => au.options)
												.filter((x) => this.itemIsSelected(x.id, this.state.selectedUnits))
										: [SELECT_ALL_VALUE]
								}
								lessons={
									this.state.selectedLessons.length > 0
										? this.state.availableLessons
												.flatMap((al) => al.options)
												.filter((x) => this.itemIsSelected(x.id, this.state.selectedLessons))
										: [SELECT_ALL_VALUE]
								}
								userData={this.state.selectedEntities[0]}
								evalHistory={!this.state.loadingChart && this.state.datasets.at(0)?.data}
							/>
						)}
					</>
				) : (
					<>
						<h2 className="no-print">{TRANSLATIONS.PERFORMANCE.LOADING_DATA_MESSAGE}</h2>
						<Loading />
					</>
				)}
			</div>
		);
	}
}
