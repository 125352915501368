import { LANGUAGES } from "../data-structures/lang-codes";
import { ERROR_CODES, MEASUREMENT_UNITS, SEVERITIES, USER_ROLES } from "../data-structures/gs-constants";
import { globalState } from "../store";
import { Validate } from "./validation";

export class HelperFunctions {
	static getAverage = (dataArray, precision = 0) => {
		const precisionMultiplier = Math.pow(10, precision);
		return Math.round((dataArray.reduce((total, d) => total + d, 0) / dataArray.length) * precisionMultiplier) / precisionMultiplier;
	};

	static getUniqueValues = (array, selector) => {
		let selectors = selector?.split(".");
		return array.filter(
			(item, i, arr) =>
				i ===
				arr.findIndex((x) => {
					let [value1, value2] = [x, item];
					if (selectors) selectors.forEach((sel) => ([value1, value2] = [value1[sel], value2[sel]]));
					return value1 === value2;
				})
		);
	};

	static getFriendlyDateTime = (date) => new Date(date).toLocaleString(globalState.locale);
	static getFriendlyDate = (date) =>
		new Date(date).toLocaleDateString(globalState.locale, { weekday: "long", year: "numeric", month: "short", day: "numeric" });
	static getFriendlyTime = (date) => new Date(date).toLocaleString(globalState.locale, { hour: "numeric", minute: "numeric", second: "numeric" });
	static getFriendlyDuration = (timeInSeconds) => {
		let remainder = timeInSeconds;
		const hours = Math.floor(remainder / 3600);
		remainder -= hours * 3600;
		const minutes = Math.floor(remainder / 60);
		remainder -= minutes * 60;
		return timeInSeconds > 0
			? `${hours.toString().padStart(2, 0)}:${minutes.toString().padStart(2, 0)}:${remainder.toString().padStart(2, 0)}`
			: "-";
	};

	static getSeverityLabel = (severityValue) => Object.values(SEVERITIES).find((x) => x.value === severityValue).label;

	static convertToMPH = (metersPerSecond) => metersPerSecond * 0.000621 * 60 * 60;
	static convertToKPH = (metersPerSecond) => metersPerSecond * 0.001 * 60 * 60;

	static getSortedLanguages = () =>
		Object.values(LANGUAGES)
			.reduce((unique, lang) => (lang.active && !unique.find((x) => x.value === lang.value) ? unique.concat([lang]) : unique), [])
			.sort((a, b) => {
				if (a.value === globalState.locale) return -1;
				else return a.label.localeCompare(b.label);
			});

	static sortByCatalogOrder = (selectedData, availableData) => {
		availableData = availableData.flatMap((ad) => ad.options || ad);
		return selectedData.sort((a, b) => {
			a = availableData.find((ad) => ad.value === a.value);
			b = availableData.find((ad) => ad.value === b.value);
			return availableData.indexOf(a) - availableData.indexOf(b);
		});
	};

	static currentUnits = () => Object.values(MEASUREMENT_UNITS).find((x) => x.value === (globalState?.userData?.units ?? 0));

	static getAvailableRoles = () =>
		Object.values(USER_ROLES).filter((r) => r.value <= globalState.userData.role && r.value !== USER_ROLES.SYSTEM.value);
	static getRole = (role) => Object.values(USER_ROLES).find((x) => x.value === role);

	static userIsGlobalAdmin = (userData) => userData?.role >= USER_ROLES.GLOBAL_ADMIN.value;
	static userIsDeveloper = (userData) => userData?.role >= USER_ROLES.DEVELOPER.value;
	static userIsAdmin = (userData) => userData?.role >= USER_ROLES.ADMIN.value;
	static userIsInstructor = (userData) => userData?.role >= USER_ROLES.INSTRUCTOR.value;
	static userIsStudent = (userData) => userData?.role === USER_ROLES.STUDENT.value;
	static loggedInAsGlobalAdmin = () => this.userIsGlobalAdmin(globalState.userData);
	static loggedInAsDeveloper = () => this.userIsDeveloper(globalState.userData);
	static loggedInAsAdmin = () => this.userIsAdmin(globalState.userData);
	static loggedInAsInstructor = () => this.userIsInstructor(globalState.userData);
	static loggedInAsStudent = () => this.userIsStudent(globalState.userData);

	static currentOrgIsCloudBased = () => globalState.orgData?.isCloudBased || false;

	static getResponseError = (response) => response && (Object.values(ERROR_CODES).find((x) => x.key === response.key) || response.errorMsg);

	static profileIsComplete = (user = globalState.userData) =>
		user &&
		!Validate.isNullOrEmpty(user.firstName) &&
		!Validate.isNullOrEmpty(user.lastName) &&
		!Validate.isNullOrEmpty(user.username) &&
		(!HelperFunctions.currentOrgIsCloudBased() || Validate.isValidEmailAddress(user.emailAddress));

	static getUrlParams = () => new URLSearchParams(window.location.search);
}
