import React from "react";
import { TRANSLATIONS } from "../../data-structures/localization";
import UserDataForm from "../../forms/user-data-form";

export const CompleteProfile = () => {
	return (
		<div>
			<h1>{TRANSLATIONS.HEADERS.COMPLETE_PROFILE}</h1>
			<UserDataForm newSignup />
			{/* <Payments /> */}
		</div>
	);
};
