import { Navigate, Route } from "react-router-dom";
import { USER_ROLES } from "../data-structures/gs-constants";
import { HelperFunctions } from "../helpers/helper-functions";
import { globalState } from "../store";
import { DataRetentionPolicy, PrivacyPolicy, TermsOfService } from "../pages/legal/legal";
import Login from "../pages/registration/login";
import { Signup } from "../pages/registration/signup";
import Profile from "../pages/profile/profile";
import ForgotPassword from "../forms/forgot-password";
import { Dashboard } from "../pages/dashboard/dashboard";
import Downloads from "../pages/downloads/downloads";
import Performance from "../pages/performance/performance.tsx";
import { Article, Help, HelpIndex } from "../pages/help";
import { WhatsNew } from "../pages/whats-new";
import UserManagement from "../pages/user-management/user-management";
import { DeveloperDashboard, PortalManagement, DeveloperIndex, OrgManagement, Releases } from "../pages/dashboard/developer-dashboard";
import { ManagePayments } from "../pages/profile/payments";
import { CompleteProfile } from "../pages/profile/complete-profile.js";
import Stats from "../pages/dashboard/stats";
import { MyGroups } from "../pages/dashboard/my-groups";
import { GroupManagement } from "../pages/group-management/group-management";
import { TRANSLATIONS } from "../data-structures/localization";
import VerifyEmail from "../forms/verify-email.js";

class NavRoute {
	constructor({ active, index, component, path, exact, title, children, openInNewTab } = {}) {
		this.active = active;
		this.index = index;
		this.component = component;
		this.path = path;
		this.exact = exact;
		this.title = title;
		this.children = children;
		this.externalURL = openInNewTab;
	}
}

export default class Nav {
	static MAIN_SITE_PATH = `https://www.globalsim.com`;

	static PATHS = {
		HOME: "/",
		WHATS_NEW: "/whats-new",
		DASHBOARD: {
			ROOT: "/dashboard",
			STATISTICS: "statistics",
			GROUPS: "my-groups",
			PAYMENTS: "payments",
		},
		PERFORMANCE: "/performance",
		GROUP_MANAGEMENT: "/group-management",
		USER_MANAGEMENT: "/user-management",
		LOGIN: "/login",
		SIGNUP: "/signup",
		COMPLETE_PROFILE: "/complete-profile",
		VERIFY_EMAIL: "/verify-email",
		PASSWORD_RESET: "/password-reset",
		PROFILE: "/profile",
		DOWNLOADS: "/downloads",
		DEVELOPER: {
			ROOT: "/developer",
			ORG_MANAGEMENT: "org-management",
			RELEASES: "releases",
			PORTAL_MANAGEMENT: "portal-management",
		},
		HELP: {
			ROOT: "/help",
			GETTING_STARTED: "getting-started",
			DEVICE_SETUP: "device-setup",
			RUNNING_A_CRANE: "running-a-crane",
			AAR_AND_DRONE_VIEW: "aar-and-drone-view",
		},
		PRIVACY_POLICY: "/privacy-policy",
		TERMS_OF_SERVICE: "/terms-of-service",
		DATA_RETENTION: "/data-retention",
	};

	static REDIRECTS = {
		LOGIN: <Navigate to={this.PATHS.LOGIN} />,
		DASHBOARD: <Navigate to={this.PATHS.DASHBOARD.ROOT} />,
		COMPLETE_PROFILE: <Navigate to={this.PATHS.COMPLETE_PROFILE} />,
		VERIFY_EMAIL: <Navigate to={this.PATHS.VERIFY_EMAIL} />,
		DEVELOPER: <Navigate to={this.PATHS.DEVELOPER.ROOT} />,
	};

	static ROUTES = {
		INDEX: new NavRoute({ active: true, index: true, component: this.REDIRECTS.LOGIN }),
		HOME: new NavRoute({ active: true, path: this.PATHS.HOME, component: this.REDIRECTS.LOGIN, exact: true }),
		WHATS_NEW: new NavRoute({ active: false, path: this.PATHS.WHATS_NEW, title: TRANSLATIONS.NAVLINKS.WHATS_NEW, component: <WhatsNew /> }),
		LOGIN: new NavRoute({ active: true, path: this.PATHS.LOGIN, title: TRANSLATIONS.NAVLINKS.LOG_IN, component: <Login /> }),
		SIGNUP: new NavRoute({ active: false, path: this.PATHS.SIGNUP, title: TRANSLATIONS.NAVLINKS.SIGN_UP, component: <Signup /> }),
		COMPLETE_PROFILE: new NavRoute({
			active: true,
			path: this.PATHS.COMPLETE_PROFILE,
			title: TRANSLATIONS.NAVLINKS.COMPLETE_PROFILE,
			component: <CompleteProfile />,
		}),
		VERIFY_EMAIL: new NavRoute({
			active: true,
			path: this.PATHS.VERIFY_EMAIL,
			title: TRANSLATIONS.NAVLINKS.VERIFY_EMAIL,
			component: <VerifyEmail />,
		}),
		PASSWORD_RESET: new NavRoute({
			active: true,
			path: this.PATHS.PASSWORD_RESET,
			title: TRANSLATIONS.NAVLINKS.PASSWORD_RESET,
			component: <ForgotPassword />,
		}),
		PROFILE: new NavRoute({ active: true, path: this.PATHS.PROFILE, title: TRANSLATIONS.NAVLINKS.PROFILE, component: <Profile /> }),
		DASHBOARD: new NavRoute({
			active: true,
			path: this.PATHS.DASHBOARD.ROOT,
			title: TRANSLATIONS.NAVLINKS.DASHBOARD,
			component: <Dashboard />,
			children: {
				INDEX: new NavRoute({ active: true, index: true, component: <Navigate to={this.PATHS.DASHBOARD.STATISTICS} /> }),
				STATISTICS: new NavRoute({ active: true, path: this.PATHS.DASHBOARD.STATISTICS, component: <Stats /> }),
				GROUPS: new NavRoute({ active: true, path: this.PATHS.DASHBOARD.GROUPS, component: <MyGroups /> }),
				PAYMENTS: new NavRoute({ active: false, path: this.PATHS.DASHBOARD.PAYMENTS, component: <ManagePayments /> }),
			},
		}),
		PERFORMANCE: new NavRoute({
			active: true,
			path: this.PATHS.PERFORMANCE,
			title: TRANSLATIONS.NAVLINKS.PERFORMANCE,
			component: <Performance />,
		}),
		GROUP_MANAGEMENT: new NavRoute({
			active: true,
			path: this.PATHS.GROUP_MANAGEMENT,
			title: TRANSLATIONS.NAVLINKS.GROUP_MANAGEMENT,
			component: <GroupManagement />,
		}),
		USER_MANAGEMENT: new NavRoute({
			active: true,
			path: this.PATHS.USER_MANAGEMENT,
			title: TRANSLATIONS.NAVLINKS.USER_MANAGEMENT,
			component: <UserManagement />,
		}),
		DOWNLOADS: new NavRoute({ active: true, path: this.PATHS.DOWNLOADS, title: TRANSLATIONS.NAVLINKS.DOWNLOADS, component: <Downloads /> }),
		DEVELOPER: new NavRoute({
			active: true,
			path: this.PATHS.DEVELOPER.ROOT,
			title: TRANSLATIONS.NAVLINKS.DEVELOPER,
			component: <DeveloperDashboard />,
			children: {
				INDEX: new NavRoute({ active: true, index: true, component: <DeveloperIndex /> }),
				ORG_MANAGEMENT: new NavRoute({ active: true, path: this.PATHS.DEVELOPER.ORG_MANAGEMENT, component: <OrgManagement /> }),
				RELEASES: new NavRoute({ active: true, path: this.PATHS.DEVELOPER.RELEASES, component: <Releases /> }),
				PORTAL_MANAGEMENT: new NavRoute({ active: true, path: this.PATHS.DEVELOPER.PORTAL_MANAGEMENT, component: <PortalManagement /> }),
			},
		}),
		HELP: new NavRoute({
			active: true,
			path: this.PATHS.HELP.ROOT,
			// path: "https://globalsiminc.atlassian.net/wiki/spaces/HELP/pages/58327382/Help",
			// openInNewTab: true,
			title: TRANSLATIONS.NAVLINKS.HELP,
			component: <Help />,
			children: {
				INDEX: new NavRoute({ active: true, index: true, component: <HelpIndex /> }),
				// 	GETTING_STARTED: new NavRoute({
				// 		active: true,
				// 		path: this.PATHS.HELP.GETTING_STARTED,
				// 		component: <Article path={this.PATHS.HELP.GETTING_STARTED} />,
				// 	}),
				// 	DEVICE_SETUP: new NavRoute({
				// 		active: true,
				// 		path: this.PATHS.HELP.DEVICE_SETUP,
				// 		component: <Article path={this.PATHS.HELP.DEVICE_SETUP} />,
				// 	}),
				// 	RUNNING_A_CRANE: new NavRoute({
				// 		active: true,
				// 		path: this.PATHS.HELP.RUNNING_A_CRANE,
				// 		component: <Article path={this.PATHS.HELP.RUNNING_A_CRANE} />,
				// 	}),
				// 	AAR_AND_DRONE_VIEW: new NavRoute({
				// 		active: true,
				// 		path: this.PATHS.HELP.AAR_AND_DRONE_VIEW,
				// 		component: <Article path={this.PATHS.HELP.AAR_AND_DRONE_VIEW} />,
				// 	}),
			},
		}),
		PRIVACY_POLICY: new NavRoute({
			active: true,
			path: this.PATHS.PRIVACY_POLICY,
			title: TRANSLATIONS.NAVLINKS.PRIVACY_POLICY,
			component: <PrivacyPolicy />,
		}),
		TERMS_OF_SERVICE: new NavRoute({
			active: true,
			path: this.PATHS.TERMS_OF_SERVICE,
			title: TRANSLATIONS.NAVLINKS.TERMS_OF_SERVICE,
			component: <TermsOfService />,
		}),
		DATA_RETENTION: new NavRoute({
			active: true,
			path: this.PATHS.DATA_RETENTION,
			title: TRANSLATIONS.NAVLINKS.DATA_RETENTION,
			component: <DataRetentionPolicy />,
		}),
	};

	static ARTICLES = {
		// [this.PATHS.HELP.<ARTICLE_PATH>]: {
		// 	walkthrough: "https://<walkthrough_url>.com",
		// 	video: { title: "Title of Video", path: "https://<video_url>.com" },
		// },
		// [this.PATHS.HELP.GETTING_STARTED]: {
		// 	walkthrough: "https://scribehow.com/page/Getting_Started__V9u6VtJoTKmcBA0aPtUW2A",
		// 	video: null,
		// },
		// [this.PATHS.HELP.DEVICE_SETUP]: {
		// 	walkthrough: "https://scribehow.com/page/Device_Setup__XIXOIBWOQP6jw08PouUoZA",
		// 	video: null,
		// },
		// [this.PATHS.HELP.RUNNING_A_CRANE]: {
		// 	walkthrough: "https://scribehow.com/page-embed/Crane_Controls__k3X-vAi5R9m9gXfZM2RwPg",
		// 	video: null,
		// },
		// [this.PATHS.HELP.AAR_AND_DRONE_VIEW]: {
		// 	walkthrough: "https://scribehow.com/page/AARDrone_View__02LpG947SberKpU_LOHSJA",
		// 	video: null,
		// },
	};

	static generateRoutes = () => {
		return Object.values(this.ROUTES)
			.map((routeData) => ({ ...routeData, component: this.#handleAccess(routeData) }))
			.map((route, i) => (
				<Route
					key={i}
					index={route.index}
					exact={route.exact}
					path={route.path}
					element={route.component}
					children={
						route.children &&
						Object.values(route.children)?.map((child, ci) => (
							<Route key={`child-${ci}`} index={child.index} path={child.path} element={child.component} />
						))
					}
				/>
			));
	};

	static #handleAccess = (routeData) => {
		const loggedIn = globalState.loggedIn;
		if (routeData.active) {
			let requiresLogin = false;
			let requiresLogout = false;
			let requiresEmailVerification = false;
			let requiresCompleteProfile = false;
			let minimumClearance = 0;
			let ghostBlocker = false;

			switch (routeData.path) {
				case this.PATHS.DEVELOPER.ROOT:
					requiresLogin = true;
					minimumClearance = USER_ROLES.DEVELOPER.value;
					requiresCompleteProfile = true;
					requiresEmailVerification = true;
					break;

				case this.PATHS.GROUP_MANAGEMENT:
				case this.PATHS.USER_MANAGEMENT:
				case this.PATHS.DASHBOARD.GROUPS:
					requiresLogin = true;
					minimumClearance = USER_ROLES.INSTRUCTOR.value;
					requiresCompleteProfile = true;
					requiresEmailVerification = true;
					break;

				case this.PATHS.WHATS_NEW:
				case this.PATHS.DASHBOARD.ROOT:
				case this.PATHS.PERFORMANCE:
				case this.PATHS.DOWNLOADS:
					requiresLogin = true;
					requiresCompleteProfile = true;
					requiresEmailVerification = true;
					break;

				case this.PATHS.HOME:
				case this.PATHS.PROFILE:
				case this.PATHS.COMPLETE_PROFILE:
					requiresLogin = true;
					ghostBlocker = true;
					break;

				case this.PATHS.HELP.ROOT:
					requiresLogin = true;
					break;

				case this.PATHS.LOGIN:
				case this.PATHS.SIGNUP:
					requiresLogout = true;
					break;

				default:
					break;
			}

			const devRole = USER_ROLES.GLOBAL_ADMIN.value;
			const userRole = globalState.userData?.role;
			if (requiresLogin && !loggedIn) {
				return this.REDIRECTS.LOGIN;
			} else if (loggedIn) {
				if (this.ROUTES.COMPLETE_PROFILE.active && globalState.userData) {
					if (requiresCompleteProfile && !HelperFunctions.profileIsComplete()) {
						return this.REDIRECTS.COMPLETE_PROFILE;
					}

					if (
						requiresEmailVerification &&
						HelperFunctions.currentOrgIsCloudBased() &&
						!globalState.userData.emailVerified &&
						!globalState.nonGhostUserCache
					) {
						return this.REDIRECTS.VERIFY_EMAIL;
					}
				}

				if (this.ROUTES.DEVELOPER.active && requiresLogout && userRole >= devRole) {
					return this.REDIRECTS.DEVELOPER;
				}

				if (requiresLogout || userRole < minimumClearance) {
					return this.REDIRECTS.DASHBOARD;
				}

				if (ghostBlocker && globalState.ghostMode) {
					return this.ROUTES.DEVELOPER.active ? this.REDIRECTS.DEVELOPER : this.REDIRECTS.DASHBOARD;
				}
			}

			return routeData.component;
		} else return loggedIn ? this.REDIRECTS.DASHBOARD : this.REDIRECTS.LOGIN;
	};

	static getCurrentPathText = (currentPath) =>
		Object.values(this.ROUTES).find((route) => route.title && currentPath.includes(route.path))?.title || "";
}
