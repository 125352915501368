import { CourseData, LessonData, UnitData } from "./course-catalog-data.tsx";
import { Entity } from "./entity-data.tsx";
import { GroupData } from "./group-data.tsx";
import { UserData } from "./user-data.tsx";
import { Validate } from "../helpers/validation.js";
import { GS_COLORS } from "../common/style-constants";
import { Evaluation } from "./evaluation-data.tsx";
import ParamAssignableObject from "./param-assignable-object.tsx";
import { SelectOption } from "./select-options.tsx";

export enum EntityType {
	Unknown = 0,
	User = 1,
	Group = 2,
}
export enum SortType {
	Date = 1,
	Lesson = 2,
}
export enum DataSourceType {
	Score,
	ScoredTime,
	Attempts,
	Duration,
	Collisions,
	CollisionVelocity,
	MaximumLoadSwing,
	Violations,
	MovesPerHour,
}
export enum ChartType {
	Bar,
	Line,
	ScatterPlot,
}

//#region Performance
export class DataSource extends SelectOption<DataSourceType> {
	defaultChartType: SelectOption<ChartType>;
	supportedChartTypes: Array<SelectOption<ChartType>>;
	useSpeedUnit: boolean;

	constructor(dataSourceParams: {
		value: DataSourceType;
		label: string;
		defaultChartType: SelectOption<ChartType>;
		supportedChartTypes: Array<SelectOption<ChartType>>;
		useSpeedUnit?: boolean;
	}) {
		super(dataSourceParams);
		this.defaultChartType = dataSourceParams.defaultChartType;
		this.supportedChartTypes = dataSourceParams.supportedChartTypes;
		this.useSpeedUnit = dataSourceParams.useSpeedUnit;
	}
}

export class TimeUnit {
	value: string;
	milliseconds: number;
	buffer: number;

	constructor(params: { value: string; milliseconds: number; buffer: number }) {
		this.value = params.value;
		this.milliseconds = params.milliseconds;
		this.buffer = params.buffer;
	}
}
export class SpeedUnit {
	value: string;
	conversionRate: number;

	constructor(params: { value: string; conversionRate: number }) {
		this.value = params.value;
		this.conversionRate = params.conversionRate;
	}
}
//#endregion

//#region Chart Constants
export const TIME_UNITS = {
	DAY: new TimeUnit({ value: "DAY", milliseconds: 1000 * 60 * 60 * 24, buffer: 0.55 }),
	HOUR: new TimeUnit({ value: "HOUR", milliseconds: 1000 * 60 * 60, buffer: 1.7 }),
};
export const SPEED_UNITS = {
	METERS_PER_SECOND: new SpeedUnit({ value: "METERS_PER_SECOND", conversionRate: 1 }),
	KILOMETERS_PER_HOUR: new SpeedUnit({ value: "KILOMETERS_PER_HOUR", conversionRate: 3.6 }),
	FEET_PER_SECOND: new SpeedUnit({ value: "FEET_PER_SECOND", conversionRate: 3.28084 }),
	MILES_PER_HOUR: new SpeedUnit({ value: "MILES_PER_HOUR", conversionRate: 2.236936 }),
};
//#endregion

//#region Chart Options
export class ChartOptions extends ParamAssignableObject {
	dataSources: Array<DataSource>;
	chartTypes: Array<SelectOption<ChartType>>;
	axisUnits: Array<SelectOption<SortType>>;
	speedUnits: any;
	aggDataOptions: any;
	chartStyles: any;

	constructor(params: {
		dataSources: Array<DataSource>;
		chartTypes: Array<SelectOption<ChartType>>;
		axisUnits: Array<SelectOption<SortType>>;
		speedUnits: any;
		aggDataOptions: any;
	}) {
		super();
		this.constructFromParams(params);
		this.chartStyles = {
			PATTERNS: [
				"square",
				"triangle",
				"disc",
				"diagonal-right-left",
				"zigzag",
				"diamond",
				"plus",
				"dot",
				"dash",
				"weave",
				"line-vertical",
				"box",
				"triangle-inverted",
				"ring",
				"diagonal",
				"zigzag-vertical",
				"diamond-box",
				"cross",
				"dot-dash",
				"cross-dash",
				"line",
			],
			USER_COLORS: [
				GS_COLORS.GENERIC_BLUE,
				GS_COLORS.GENERIC_PINK,
				GS_COLORS.GENERIC_ORANGE,
				GS_COLORS.GENERIC_GREEN,
				GS_COLORS.GENERIC_YELLOW,
				GS_COLORS.GENERIC_RED,
				GS_COLORS.GENERIC_SKY,
				GS_COLORS.GENERIC_PURPLE,
				GS_COLORS.GENERIC_GRAY,
				GS_COLORS.GENERIC_BROWN,
			],
			GROUP_COLORS: [GS_COLORS.GENERIC_SILVER],
		};
	}
}
//#endregion

//#region Datasets
export class Dataset {
	data: Array<Evaluation>;
	entity: Entity;

	constructor(datasetParams: { data: Array<Evaluation>; entity: Entity }) {
		this.data = datasetParams.data;
		this.entity = datasetParams.entity;
	}

	public get entityType(): EntityType {
		return this.entity.entityType;
	}
}

export class ChartDataset {
	data: Array<ChartDatapoint>;
	entity: Entity;

	constructor(chartDataParams: { data: Array<ChartDatapoint>; entity: Entity }) {
		this.data = chartDataParams.data;
		this.entity = chartDataParams.entity;
	}

	public get entityType(): EntityType {
		return this.entity.entityType;
	}

	public get user(): UserData {
		return this.entityType === EntityType.User ? (this.entity as UserData) : null;
	}

	public get group(): GroupData {
		return this.entityType === EntityType.Group ? (this.entity as GroupData) : null;
	}
}

export class ChartDatapoint {
	x: string | Date;
	y: number;

	lesson: LessonData;
	unit: UnitData;
	course: CourseData;

	evaluationDate: Date;
	collisionData: any;

	constructor(params: {
		x: string | Date;
		y: number;
		lesson?: LessonData;
		unit?: UnitData;
		course?: CourseData;
		evaluationDate?: Date;
		collisionData?: any;
	}) {
		this.x = params.x;
		this.y = params.y;
		this.lesson = params.lesson;
		this.unit = params.unit;
		this.course = params.course;
		this.evaluationDate = params.evaluationDate;
		this.collisionData = params.collisionData;
	}

	public get isAggregate(): boolean {
		return !Validate.hasValue(this.lesson);
	}
}
//#endregion
