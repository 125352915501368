import { EntityType } from "./chart-data.tsx";
import { Entity } from "./entity-data.tsx";
import { Validate } from "../helpers/validation.js";

export class UserData extends Entity {
	id;
	active;
	customFields;
	dateCreated;
	dateLastLoggedIn;
	dateLastModified;
	subscriptionExpirationDate;
	units;
	emailAddress;
	emailVerified;
	firstName;
	groups;
	lastName;
	language;
	orgId;
	pWHash;
	pWSalt;
	pinCode;
	role;
	username;
	nextLesson;
	completionData = { total: {}, allCourses: {}, dataSets: {} };

	constructor(userParams: {
		id: any;
		active: any;
		customFields: any;
		dateCreated: any;
		dateLastLoggedIn: any;
		dateLastModified: any;
		subscriptionExpirationDate: any;
		units: any;
		emailAddress: any;
		emailVerified: any;
		firstName: any;
		groups: any;
		lastName: any;
		language: any;
		orgId: any;
		pWHash: any;
		pWSalt: any;
		pinCode: any;
		role: any;
		username: any;
		nextLesson?: any;
		completionData?: any;
	}) {
		super();
		this.constructFromParams(userParams);
	}

	static constructFromDB = (dbUser) =>
		dbUser
			? new UserData({
					active: dbUser.Active,
					customFields: dbUser.CustomFields,
					dateCreated: dbUser.DateCreated,
					dateLastLoggedIn: dbUser.DateLastLoggedIn,
					dateLastModified: dbUser.DateLastModified,
					subscriptionExpirationDate: Validate.isValidDate(dbUser.SubscriptionExpirationDate)
						? new Date(dbUser.SubscriptionExpirationDate)
						: null,
					units: dbUser.Units,
					emailAddress: dbUser.EmailAddress,
					emailVerified: dbUser.EmailVerified,
					firstName: dbUser.FirstName,
					groups: dbUser.Groups || [],
					id: dbUser.Id,
					lastName: dbUser.LastName,
					language: dbUser.LanguageCode?.toLowerCase(),
					orgId: dbUser.OrganizationId,
					pWHash: dbUser.PWHash,
					pWSalt: dbUser.PWSalt,
					pinCode: dbUser.PinCode,
					role: dbUser.UserType,
					username: dbUser.Username,
			  })
			: null;

	public entityType = EntityType.User;

	public get fullName(): string {
		return `${this.firstName} ${this.lastName}`;
	}

	public get label(): string {
		return this.username;
	}
}
