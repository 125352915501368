import React from "react";
import { TRANSLATIONS } from "../data-structures/localization";

export const PageNotFound = () => {
	return (
		<div>
			<h1>404: {TRANSLATIONS.HEADERS.PAGE_NOT_FOUND}</h1>
		</div>
	);
};
