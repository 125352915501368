//#region Imports
import { getCurrentRoute } from "../pages/app/app";
import { APP_ROLES, ERROR_CODES, createGhostUser } from "../data-structures/gs-constants";
import { globalState, resetGlobalState, setGlobalState } from "../store";
import { EvalHelpers } from "./eval-helpers.tsx";
import { HelperFunctions } from "./helper-functions";
import buildData from "../build-data";
import { AuthHeader } from "../data-structures/auth-header.tsx";
import { UserData } from "../data-structures/user-data.tsx";
import { OrgData } from "../data-structures/org-data.tsx";
import { CourseData } from "../data-structures/course-catalog-data.tsx";
import { GroupData } from "../data-structures/group-data.tsx";
import { CompletionData, Evaluation, RankTier } from "../data-structures/evaluation-data.tsx";
import { FileShareDirectory } from "../data-structures/fileshare.tsx";
import { Validate } from "./validation.js";
//#endregion

// anything with a # before it is private. For database integrity, please keep everything in this file private by default.
export class TMSHelpers {
	static #endpoint = process.env.REACT_APP_ENDPOINT;

	static getCurrentEndpoint = () => this.#endpoint;

	//#region Request Boilerplate
	static #RUNNING_ASYNC_TASKS = [];
	static cancelAsyncTasks = (cancelAll = false) => {
		this.#RUNNING_ASYNC_TASKS.forEach((x) => {
			if (cancelAll || (!x.crossPageTask && !getCurrentRoute().includes(x.origin))) x.controller.abort("AbortError");
		});
		this.#RUNNING_ASYNC_TASKS = [];
	};

	static #checkServerStatus = () => fetch(this.#endpoint).then((response) => response.ok);

	static sendRequest = async (messageType, body = {}, crossPageTask = false) => {
		const abortController = new AbortController();
		this.#RUNNING_ASYNC_TASKS.push({ controller: abortController, crossPageTask: crossPageTask, origin: getCurrentRoute() });

		const authHeader = AuthHeader.retrieveFromStorage();

		return await fetch(this.#endpoint, {
			method: "POST",
			signal: abortController.signal,
			headers: { "Content-Type": "application/json; charset=UTF-8" },
			body: JSON.stringify({
				$type: `GSFalconMessages.${messageType}, GSFalconMessages`,
				Username: globalState.userData?.username || localStorage.getItem("username"),
				AuthHeader: authHeader?.prepareToSend(),
				...body, // additional properties and property overrides
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				const refreshedAuthHeader = AuthHeader.constructFromDB(data["AuthHeader"]);
				if (refreshedAuthHeader) {
					const newAuthHeader = new AuthHeader({
						id: (authHeader || refreshedAuthHeader).id,
						username: (authHeader || refreshedAuthHeader).username,
						authToken: (authHeader || refreshedAuthHeader).authToken,
						subscriptionExpirationDate: refreshedAuthHeader.subscriptionExpirationDate,
						tokenExpirationDate: refreshedAuthHeader.tokenExpirationDate,
						rollingToken: (authHeader || refreshedAuthHeader).rollingToken,
						auditLog: (authHeader || refreshedAuthHeader).auditLog,
					});

					localStorage.setItem("authHeader", JSON.stringify(newAuthHeader));
				}

				if (!data.Success) throw Error(JSON.stringify(data));
				return data;
			})
			.catch((e) => {
				let returnMsg = e;
				if (e.message) {
					try {
						const reason = JSON.parse(e.message).Reason;
						if (reason) {
							returnMsg = Object.values(ERROR_CODES).find((errorKey) => reason === errorKey.key) || {
								key: "error",
								errorMsg: reason,
							};
						}
					} catch (f) {
						return ERROR_CODES.USER_ABORT;
					}
				}
				if (e.toString().includes("TypeError")) returnMsg = ERROR_CODES.TYPE_ERROR;

				if (!returnMsg.errorMsg && e.name !== "AbortError") console.error(`${messageType} Request failed. Reason:\n\n`, e);
				return returnMsg;
			});
	};

	static sendCustomRequest = (
		endpoint,
		method = "POST",
		headers = { "Content-Type": "application/json; charset=UTF-8" },
		body = null,
		crossPageTask = false
	) => {
		const abortController = new AbortController();
		this.#RUNNING_ASYNC_TASKS.push({ controller: abortController, crossPageTask: crossPageTask });

		return fetch(endpoint, {
			method: method,
			signal: abortController.signal,
			headers: headers,
			body: body,
		});
	};
	//#endregion

	//#region Versioning
	static checkSiteVersion = () => {
		this.sendRequest("GetCloudVersionRequest").then((response) => {
			if (!response.ResponseMessage) return;

			const latestVersion = parseFloat(JSON.parse(response.ResponseMessage).CloudVersion);

			if (localStorage.getItem("gs-cloud-version") < latestVersion) {
				localStorage.setItem("gs-cloud-version", latestVersion);
				window.location.reload(true);
			}
		});
	};

	static updateSiteVersion = () => {
		// TODO: replace dumb versioning to semantic versioning (see pre-build.js). For now, just use a datetime
		const getNumWithLeadingZeros = (num) => (num < 10 ? "0" : "") + num;
		const newDumbVersion = parseInt(
			[
				new Date(Date.now()).getFullYear(),
				getNumWithLeadingZeros(new Date(Date.now()).getMonth() + 1),
				getNumWithLeadingZeros(new Date(Date.now()).getDate()),
				getNumWithLeadingZeros(new Date(Date.now()).getHours()),
				getNumWithLeadingZeros(new Date(Date.now()).getMinutes()),
				getNumWithLeadingZeros(new Date(Date.now()).getSeconds()),
			].join("")
		);
		this.sendRequest("UpdateCloudVersionRequest", {
			CloudVersion: newDumbVersion,
			// CloudVersion: buildData.version,
		}).then((response) => {
			console.log(`Updated Cloud Version to: ${JSON.parse(response.ResponseMessage).CloudVersion}`);
		});
	};
	//#endregion

	//#region Login & Authentication
	static authenticateUser = async (username, password) => {
		if (this.#checkServerStatus()) {
			const authResponse = await this.sendRequest(
				"UserAuthRequest",
				{ Username: username.toString(), Password: password.toString(), ApplicationRole: APP_ROLES.CLOUD_PORTAL },
				true
			);
			if (!authResponse || authResponse.errorMsg) return authResponse;

			const newAuthHeader = AuthHeader.constructFromDB(authResponse["AuthHeader"]);
			localStorage.setItem("authHeader", JSON.stringify(newAuthHeader));

			const userData = this.trimUserData(JSON.parse(authResponse.ResponseMessage).AuthenticatedUser);
			this.storeUserData(userData);

			setGlobalState({
				loggedIn: true,
				locale: userData.language,
				userData: userData,
			});

			const orgData = await this.#getOrganizationData(userData);
			if (!orgData || orgData.errorMsg) return orgData;

			const groupList = await this.#getGroupList(orgData, userData);
			const installerCollections = await this.#getInstallerCollections();
			setGlobalState({
				orgData: orgData,
				groupList: groupList,
				installerCollections: installerCollections,
			});

			const courseCatalog = await this.#getCourseCatalog(orgData.id);
			setGlobalState({
				...(courseCatalog && { courseCatalog: courseCatalog }),
			});
		}
	};

	static authenticateGhostUser = async (orgId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const cachedUserData = globalState.userData;
				const ghostUserData = createGhostUser(cachedUserData, orgId);

				this.storeUserData(ghostUserData);
				localStorage.setItem("ghostMode", true);

				setGlobalState({
					loggedIn: true,
					locale: ghostUserData.language,
					userData: ghostUserData,
					nonGhostUserCache: cachedUserData,
				});

				const orgData = await this.#getOrganizationData(ghostUserData);
				const groupList = await this.#getGroupList(orgData, ghostUserData);
				setGlobalState({
					orgData: orgData.errorMsg ? null : orgData,
					groupList: groupList,
				});

				const courseCatalog = await this.#getCourseCatalog(orgData.id);
				const installerCollections = await this.#getInstallerCollections();
				setGlobalState({
					...(courseCatalog && { courseCatalog: courseCatalog }),
					...(installerCollections && { installerCollections: installerCollections }),
				});

				// ghost mode is fully activated so we can safely set ghost data
				setGlobalState({
					ghostMode: true,
				});

				resolve({
					Success: true,
				});
			} catch (error) {
				reject({
					Success: false,
					Error: error,
				});
			}
		});
	};

	static revertToNonGhostUser = async () => {
		return new Promise(async (resolve, reject) => {
			try {
				const cachedUserData = globalState.nonGhostUserCache;
				this.storeUserData(cachedUserData);
				localStorage.removeItem("ghostMode");
				const orgData = await this.#getOrganizationData(cachedUserData);
				const groupList = await this.#getGroupList(orgData, cachedUserData);
				setGlobalState({
					loggedIn: true,
					locale: cachedUserData.language,
					userData: cachedUserData,
					orgData: orgData.errorMsg ? null : orgData,
					groupList: groupList,
				});

				const courseCatalog = await this.#getCourseCatalog(orgData.id);
				const installerCollections = await this.#getInstallerCollections();
				setGlobalState({
					...(courseCatalog && { courseCatalog: courseCatalog }),
					...(installerCollections && { installerCollections: installerCollections }),
				});

				// ghost mode is fully deactivated so we can safely clear out ghost data
				setGlobalState({
					nonGhostUserCache: null,
					ghostMode: false,
				});

				resolve({
					Success: true,
				});
			} catch (error) {
				reject({
					Success: false,
					Error: error,
				});
			}
		});
	};

	static refreshGlobalData = async (userId, updateCourseCatalog = false) => {
		if (this.checkAuthExpired()) return;

		if (!(localStorage.getItem("userId") && localStorage.getItem("username") && localStorage.getItem("orgId"))) {
			this.handleLogout();
			return;
		}

		const ghostModeActive = localStorage.getItem("ghostMode");
		const orgId = localStorage.getItem("orgId");

		const initialUserData = await this.#getUserData(userId || localStorage.getItem("userId"));
		const userData = ghostModeActive ? createGhostUser(initialUserData, orgId) : initialUserData;
		this.storeUserData(userData);

		const orgData = await this.#getOrganizationData(userData);
		const groupList = await this.#getGroupList(orgData, userData);
		setGlobalState({
			ghostMode: ghostModeActive,
			nonGhostUserCache: ghostModeActive ? initialUserData : null,
			userData: userData,
			orgData: orgData.errorMsg ? null : orgData,
			groupList: groupList,
		});

		const courseCatalog = updateCourseCatalog && (await this.#getCourseCatalog(orgData.id));
		const installerCollections = await this.#getInstallerCollections();
		setGlobalState({
			...(courseCatalog && { courseCatalog: courseCatalog }),
			...(installerCollections && { installerCollections: installerCollections }),
		});
	};

	static checkAuthExpired = () => {
		const authHeader = AuthHeader.retrieveFromStorage();
		if (!authHeader) {
			this.handleLogout();
			return true;
		}

		const authIsExpired = new Date(authHeader.tokenExpirationDate).valueOf() <= Date.now();
		const subscriptionExpired = authHeader.subscriptionExpirationDate && new Date(authHeader.subscriptionExpirationDate).valueOf() <= Date.now();
		if (authIsExpired || subscriptionExpired) {
			this.handleLogout();
			return true;
		}
	};

	static handleLogout = async () => {
		const deAuthBody = {
			AuthHeader: AuthHeader.retrieveFromStorage()?.prepareToSend(),
			Username: localStorage.getItem("username"),
		};

		this.cancelAsyncTasks(true);

		localStorage.clear();
		resetGlobalState();

		if (!deAuthBody.Username || !deAuthBody.AuthHeader) {
			return;
		} else {
			await this.sendRequest("UserDeauthRequest", deAuthBody, true);
		}
	};
	//#endregion

	//#region Data Getters
	static #getUserData = async (userId) => {
		const data = await this.sendRequest("GetUserRequest", { Id: userId }, true);
		if (!data || data.errorMsg) return data;

		return this.trimUserData(JSON.parse(data.ResponseMessage).User);
	};

	static #getOrganizationData = async (user) => {
		const data = await this.sendRequest("GetOrganizationRequest", { Id: localStorage.getItem("orgId") }, true);
		if (!data || data.errorMsg) return data;

		const responseMessage = JSON.parse(data.ResponseMessage);
		const newOrgData = this.trimOrgData(responseMessage.Organization, responseMessage.IsCloudStandardOrg);
		if (HelperFunctions.userIsInstructor(user)) newOrgData.userList = await this.#getOrganizationUserList();

		return newOrgData;
	};

	static #getOrganizationUserList = async () => {
		const data = await this.sendRequest("UserListRequest", { OrganizationId: localStorage.getItem("orgId") }, true);
		return !data || data.errorMsg ? data : this.#trimOrgUserList(JSON.parse(data.ResponseMessage).Users);
	};

	static #getGroupList = async (orgData, userData) => {
		const data = await this.sendRequest("GetGroupListRequest", { OrganizationId: orgData.id, UserId: userData.id }, true);
		return !data || data.errorMsg
			? data
			: JSON.parse(data.ResponseMessage).RetrievedGroup.$values.map((g) => {
					const group = this.trimGroupData(g, orgData.userList, userData);
					Object.keys(group.userList).forEach((uId) => {
						if (userData.id === uId) userData.groups.push(group.id);
						let foundUser = orgData?.userList?.find((u) => u.id === uId);
						if (foundUser) foundUser.groups.push(group.id);
					});
					return group;
			  });
	};

	static #getCourseCatalog = async (orgId) => {
		const data = await this.sendRequest("GetCourseCatalogRequest", { OrganizationId: orgId }, true);
		const newCourseCatalog = data.Success ? this.#trimCourseCatalog(JSON.parse(data.ResponseMessage).CourseCatalog) : [];
		return newCourseCatalog;
	};

	static getEvalHistory = async (studentId) => {
		const currentUserId = localStorage.getItem("userId");
		const data = await this.sendRequest("EvaluationListRequest", { UserId: studentId || currentUserId });
		if (!data.Success && data.key !== ERROR_CODES.USER_ABORT.key) console.error("getEvalRequest failed. Please check network logs");
		const newEvalHistory = data.Success && this.#trimEvalHistory(JSON.parse(data.ResponseMessage).Evaluations.$values);
		return newEvalHistory;
	};

	static getCompletionData = (userId, courseIds, groupId, callback) => {
		this.sendRequest("GetCompletionDataRequest", {
			UserId: userId,
			CourseIds: courseIds,
			GroupId: groupId,
		}).then((response) => {
			if (response && response.Success) {
				callback(CompletionData.constructFromDB(JSON.parse(response.ResponseMessage).CompletionData));
			}
		});
	};

	static getRanksForGroup = (group, callback) => {
		this.sendRequest("GetGroupRankingsRequest", {
			GroupId: group.id,
		}).then((response) => {
			if (response && response.Success) {
				callback(this.#trimRankData(JSON.parse(response.ResponseMessage).GroupRankings));
			}
		});
	};
	//#endregion

	//#region Data Setters
	static storeUserData = (trimmedUserData) => {
		if (trimmedUserData) {
			if (trimmedUserData.id) {
				localStorage.setItem("userId", trimmedUserData.id);
			}
			if (trimmedUserData.orgId) {
				localStorage.setItem("orgId", trimmedUserData.orgId);
			}
			if (trimmedUserData.username) {
				localStorage.setItem("username", trimmedUserData.username);
			}
			if (trimmedUserData.language) {
				localStorage.setItem("locale", trimmedUserData.language);
			}
		} else console.trace("Tried to store user data, but no user data was found!");
	};
	//#endregion

	//#region Data Trimmers
	static trimUserData = (userData) => UserData.constructFromDB(userData);
	static trimOrgData = (orgData, isCloudStandardOrg = false) => OrgData.constructFromDB(orgData, isCloudStandardOrg);
	static #trimOrgUserList = (userList) => userList.$values.map((user) => this.trimUserData(user));
	static trimGroupData = (groupData, orgUsers = [], userData = globalState.userData) => GroupData.constructFromDB(groupData, orgUsers, userData);
	static #trimCourseCatalog = (catalog) =>
		catalog.CourseList.$values.map((dbCourse) => CourseData.constructFromDB(dbCourse)).sort((a, b) => a.displayPosition - b.displayPosition);
	static #trimEvalHistory = (evals) =>
		evals.map((evaluation) => Evaluation.constructFromDB(evaluation, EvalHelpers.getFlattenedLessonList(globalState.courseCatalog)));
	static #trimFileShareData = (fileShareData) => fileShareData.$values.map((item) => FileShareDirectory.constructFromDB(item));
	static #trimRankData = (rankData) => rankData.$values.map((rankTier) => RankTier.constructFromDB(rankTier));
	//#endregion

	//#region User CRUD requests
	static registerUser = async (userDataToRegister, cloudSelfRegister = true) => {
		if (this.#checkServerStatus()) {
			userDataToRegister = new UserData(userDataToRegister);
			const data = await this.sendRequest(
				cloudSelfRegister ? "RegisterUserOnlineRequest" : "RegisterUserRequest",
				{
					UserToAdd: {
						FirstName: userDataToRegister.firstName,
						LastName: userDataToRegister.lastName,
						Username: userDataToRegister.username,
						EmailAddress: userDataToRegister.emailAddress,
						LanguageCode: userDataToRegister.language.value.toUpperCase(),
						Units: userDataToRegister.units.value,
						UserType: userDataToRegister.role.value,
						...(!cloudSelfRegister && { OrganizationId: userDataToRegister.orgId }),
					},
					...(cloudSelfRegister && { Password: userDataToRegister.password }),
				},
				true
			);

			if (!data || data.errorMsg) return data;

			if (data.Success) {
				if (cloudSelfRegister) {
					await this.authenticateUser(userDataToRegister.username, userDataToRegister.password);
				} else {
					const newUserData = this.trimUserData(JSON.parse(data.ResponseMessage).AddedUser);

					if (!HelperFunctions.currentOrgIsCloudBased()) {
						await this.sendRequest(
							"SetUserPasswordRequest",
							{ UserIdToUpdate: newUserData.id, PasswordToSet: userDataToRegister.password },
							true
						);
					}

					const newOrgData = globalState.orgData;
					newOrgData.userList.push(newUserData);
					setGlobalState({ orgData: newOrgData });
				}
			}

			return data;
		}
	};

	static editUserRecord = async (userData) => {
		userData = new UserData(userData);

		const newSubscriptionDate = new Date(userData.subscriptionExpirationDate);
		const clearSubscription = !Validate.hasValue(newSubscriptionDate) || !Validate.isValidDate(newSubscriptionDate);
		const utcDate = clearSubscription ? null : new Date(newSubscriptionDate.getTime() - newSubscriptionDate.getTimezoneOffset() * 60000);

		const data = await this.sendRequest(
			"UpdateUserRequest",
			{
				UserToUpdate: {
					Id: userData.id,
					FirstName: userData.firstName,
					LastName: userData.lastName,
					UserName: userData.username,
					EmailAddress: userData.emailAddress,
					Active: userData.active,
					UserType: userData.role,
					Units: userData.units,
					LanguageCode: userData.language.toUpperCase(),
					...(!clearSubscription ? { SubscriptionExpirationDate: utcDate.toISOString() } : {}),
				},
				...(clearSubscription ? { ClearSubscription: true } : {}),
			},
			true
		);

		if (!data || data.errorMsg) return data;

		if (data.Success) {
			const updatedUserData = JSON.parse(data.ResponseMessage).UpdatedUser;
			updatedUserData.Groups = userData.groups || [];
			const newUserData = this.trimUserData(updatedUserData);
			const newOrgData = globalState.orgData;
			newOrgData.userList[newOrgData.userList.findIndex((x) => x.id === newUserData.id)] = newUserData;

			const newPersonalData = newUserData.id === globalState.userData.id && {
				userData: newUserData,
				locale: newUserData.language,
			};

			setGlobalState({ orgData: newOrgData, ...newPersonalData });
		}

		return data;
	};

	static deleteUserRecord = async (username) => {
		if (username === globalState.userData.username) return ERROR_CODES.CANNOT_DELETE_SELF; // can't delete your own user;

		const data = await this.sendRequest("DeleteUserRequest", { UserToDelete: { Username: username } }, true);

		if (!data || data.errorMsg) return data;

		if (data.Success) {
			const newOrgData = globalState.orgData;
			newOrgData.userList.splice(
				newOrgData.userList.findIndex((x) => x.username === username),
				1
			);

			setGlobalState({ orgData: newOrgData });
		}

		return data;
	};
	//#endregion

	//#region Group requests
	static updateGroupRequest = async (
		updatedGroupInfo = {
			id: null,
			orgId: null,
			name: null,
			notes: null,
			startDate: null,
			endDate: null,
			courseIds: null,
			userIds: null,
			active: null,
		}
	) => {
		const data = await this.sendRequest(
			"UpdateGroupRequest",
			{
				Id: updatedGroupInfo.id,
				OrganizationId: updatedGroupInfo.orgId,
				Name: updatedGroupInfo.name,
				Notes: updatedGroupInfo.notes,
				StartDate: new Date(updatedGroupInfo.startDate),
				EndDate: new Date(updatedGroupInfo.endDate),
				EnrolledCourses: { CourseIdList: updatedGroupInfo.courseIds },
				EnrolledUsers: { UserIdList: updatedGroupInfo.userIds },
				Active: updatedGroupInfo.active,
			},
			true
		);

		if (!data || data.errorMsg) return data;

		if (data.Success) {
			const trimmedGroupData = this.trimGroupData(JSON.parse(data.ResponseMessage).UpdatedGroup, globalState.orgData?.userList);
			const newGroupList = globalState.groupList;
			newGroupList[newGroupList.findIndex((g) => g.id === trimmedGroupData.id)] = trimmedGroupData;

			setGlobalState({ groupList: newGroupList });
			return data;
		}
	};

	static deleteGroupRequest = async (groupId) => {
		const data = await this.sendRequest("DeleteGroupRequest", { Id: groupId }, true);

		if (!data || data.errorMsg) return data;

		if (data.Success) {
			setGlobalState({ groupList: globalState.groupList.filter((g) => g.id !== groupId) });
			this.refreshGlobalData();
		}

		return data;
	};

	static updateGroupUserList = async (groupId, newUserList) => {
		const groupToUpdate = globalState.groupList.find((g) => g.id === groupId);

		return await this.updateGroupRequest({
			id: groupToUpdate.id,
			orgId: globalState.orgData.id,
			name: groupToUpdate.name,
			notes: groupToUpdate.notes,
			startDate: groupToUpdate.startDate,
			endDate: groupToUpdate.endDate,
			courseIds: groupToUpdate.courseIds,
			userIds: newUserList,
			active: groupToUpdate.active,
		}).then((response) => {
			this.refreshGlobalData();
			return response;
		});
	};

	static updateGroupCourseList = async (groupId, newCourseList) => {
		const groupToUpdate = globalState.groupList.find((g) => g.id === groupId);
		return await this.updateGroupRequest({
			id: groupToUpdate.id,
			orgId: globalState.orgData.id,
			name: groupToUpdate.name,
			notes: groupToUpdate.notes,
			startDate: groupToUpdate.startDate,
			endDate: groupToUpdate.endDate,
			courseIds: newCourseList,
			userIds: Object.keys(groupToUpdate.userList),
			active: groupToUpdate.active,
		});
	};
	//#endregion

	//#region Installer Storage
	static getInstallerDownloadURLs = (orgId, releaseChannel, appToDownload) => this.#getInstallerURLs(orgId, releaseChannel, appToDownload, false);
	static getInstallerUploadURLs = (orgId, releaseChannel, appToDownload) => this.#getInstallerURLs(orgId, releaseChannel, appToDownload, true);

	static #getInstallerURLs = async (orgId, releaseChannel, appToDownload = APP_ROLES.Launcher, upload = false) => {
		return this.sendRequest("GetInstallerURLRequest", {
			AppRoleToDownload: appToDownload,
			OrganizationId: orgId,
			ReleaseChannel: releaseChannel,
			Upload: upload,
		}).then((response) => {
			return !response || response.errorMsg
				? response
				: JSON.parse(response.ResponseMessage).InstallerFiles.$values.map((file) => ({
						name: file.Name,
						fileSize: file.FileSize,
						downloadURL: file.DownloadURL,
				  }));
		});
	};

	static #getInstallerCollections = async () => {
		return HelperFunctions.loggedInAsDeveloper()
			? this.sendRequest("GetInstallerCollectionsRequest", {}, true).then((response) => {
					if (!response.ResponseMessage) return null;

					return response && !response.errorMsg && this.#trimFileShareData(JSON.parse(response.ResponseMessage).InstallerCollections);
			  })
			: null;
	};

	static createInstallerCollection = async (collectionName) => {
		return this.sendRequest("CreateInstallerCollectionRequest", {
			Name: collectionName,
		}).then((response) => {
			if (!response || response.errorMsg) return response;

			const newCollections = this.#trimFileShareData(JSON.parse(response.ResponseMessage).InstallerCollections);
			setGlobalState({ installerCollections: newCollections });
			return response;
		});
	};

	static deleteFileshareDirectory = async (directoryPath) => {
		return this.sendRequest("DeleteFileshareDirectoryRequest", {
			FullPath: directoryPath,
		}).then((response) => {
			if (!response || response.errorMsg) return response;

			const newCollections = this.#trimFileShareData(JSON.parse(response.ResponseMessage).InstallerCollections);
			setGlobalState({ installerCollections: newCollections });
			return response;
		});
	};

	static updateInstallerManifest = async (appRole, installerCollection, releaseChannel, installerName) => {
		return this.sendRequest("UpdateInstallerManifestRequest", {
			InstallerCollection: installerCollection,
			ReleaseChannel: releaseChannel,
			AppRole: appRole,
			InstallerName: installerName,
		}).then((response) => response);
	};

	static getInstallerArchive = async (appRole, installerCollection, releaseChannel) => {
		return this.sendRequest("GetInstallerArchiveRequest", {
			InstallerCollection: installerCollection,
			ReleaseChannel: releaseChannel,
			AppRole: appRole,
		}).then((response) => {
			return !response || response.errorMsg ? response : JSON.parse(response.ResponseMessage).InstallerArchive.$values;
		});
	};

	static downloadFileFromURL = async (file) => {
		var downloadLink = document.createElement("a");
		downloadLink.href = file.downloadURL;
		downloadLink.target = "_blank";
		downloadLink.download = file.name;

		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};
	//#endregion

	//#region Create Organization
	static createOrganization = async (orgName, orgIsCloudBased) => {
		return this.sendRequest("AddOrganizationRequest", {
			OrganizationName: orgName,
			OrgIsCloudBased: orgIsCloudBased,
		}).then((response) => {
			if (!response || response.errorMsg) return response;
			else return { newOrg: this.trimOrgData(JSON.parse(response.ResponseMessage).AddedOrganization) };
		});
	};
	//#endregion

	//#region Email Verification
	static triggerEmailVerificationRequest = async () => {
		return this.sendRequest("TriggerEmailVerificationRequest").then((response) => response);
	};

	static verifyEmailAddress = async (userId, verificationToken) => {
		return this.sendRequest("VerifyEmailRequest", {
			UserId: userId,
			VerificationToken: verificationToken,
		}).then((response) => response);
	};
	//#endregion

	//#region Password Reset
	static triggerPasswordResetRequest = async (username) => {
		return this.sendRequest("TriggerResetPasswordRequest", {
			Username: username,
		}).then((response) => response);
	};

	// Reset Password for user with auth
	static resetPassword = async (userId, newPassword) => {
		return this.sendRequest("SetUserPasswordRequest", {
			UserIdToUpdate: userId,
			PasswordToSet: newPassword,
		}).then((response) => response);
	};

	// Validate Password token
	static validatePasswordToken = async (userId, verificationToken) => {
		return this.sendRequest("ValidatePasswordTokenRequest", {
			UserId: userId,
			VerificationToken: verificationToken,
		}).then((response) => response);
	};

	// Reset Password via email token
	static resetPasswordViaEmail = async (userId, newPassword, verificationToken) => {
		return this.sendRequest("ResetPasswordViaTokenRequest", {
			UserId: userId,
			VerificationToken: verificationToken,
			NewPassword: newPassword,
		}).then((response) => response);
	};
	//#endregion
}
