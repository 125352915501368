import { globalState } from "../store";
import { EntityType } from "./chart-data.tsx";
import { Entity } from "./entity-data.tsx";

export class GroupData extends Entity {
	id;
	active;
	name;
	notes;
	startDate;
	endDate;
	courseIds;
	userIdList;
	userList;
	dateCreated;
	dateLastModified;
	isFilteredVisible;

	constructor(groupParams: {
		id: any;
		active: any;
		name: any;
		notes: any;
		startDate: any;
		endDate: any;
		courseIds: any;
		userList: any;
		dateCreated: any;
		dateLastModified: any;
		isFilteredVisible: any;
	}) {
		super();
		this.constructFromParams(groupParams);
	}

	static constructFromDB = (dbGroup, orgUsers = [], userData) =>
		new GroupData({
			id: dbGroup.Id,
			active: dbGroup.Active,
			name: dbGroup.Name,
			notes: dbGroup.Notes,
			startDate: dbGroup.StartDate,
			endDate: dbGroup.EndDate,
			courseIds: dbGroup.EnrolledCourses.CourseIdList.$values,
			userList: this.getUserList(dbGroup.EnrolledUsers.UserIdList.$values, orgUsers, userData),
			dateCreated: dbGroup.DateCreated,
			dateLastModified: dbGroup.DateLastModified,
			isFilteredVisible: dbGroup.IsFilteredVisible,
		});

	public entityType = EntityType.Group;

	public get label(): string {
		return this.name;
	}

	static getUserList = (userIds, orgUsers = [], userData = globalState.userData) =>
		userIds.reduce((users, userId) => {
			const foundUser = orgUsers?.find((x) => x.id === userId) || (userData.id === userId ? userData : null);
			if (foundUser) {
				users[userId] = foundUser;
			}
			return users;
		}, {});
}
