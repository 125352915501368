export class Validate {
	static hasValue = (value) => typeof value !== "undefined" && value !== null;
	static isNullOrEmpty = (value) =>
		value == null || JSON.stringify(value).trim() === "" || (typeof value === "string" && (value.trim() === "" || value.trim() === "null"));
	static isValidJson = (jsonString) => {
		try {
			JSON.parse(jsonString);
		} catch (e) {
			return false;
		}
		return true;
	};
	static isValidDate = (date) => date && new Date(date).valueOf() > 0;
	static isValidDateRange = (startDate, endDate) => new Date(startDate) <= new Date(endDate);
	static isValidEmailAddress = (emailAddress) => !this.isNullOrEmpty(emailAddress) && /[^\s@]+@[^\s@]+\.[^\s@]/.test(emailAddress);
	static isValidCollectionName = (collectionName) => /^[a-zA-Z0-9_]+$/g.test(collectionName);

	static USERNAME_MIN_LENGTH = 8;
	static USERNAME_MAX_LENGTH = 32;
	static PASSWORD_MIN_LENGTH = 15;
	static USERNAME_BLACKLIST = ["globalsim", "admin", "test", "user", "support", "backup", "system", "root", "server"];
	static PASSWORD_BLACKLIST = ["password"];
}
