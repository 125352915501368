import React, { Component } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Nav from "../../data-structures/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "bootstrap";
import { GSLogo, LOGO_TYPE } from "../../common/logo/logo";
import { globalState } from "../../store";
import { TMSHelpers } from "../../helpers/tms-helpers";
import { TRANSLATIONS } from "../../data-structures/localization";
import { HelperFunctions } from "../../helpers/helper-functions";
import { SocialIcons } from "../../common/social-icons";
import "./header.scss";

export const Header = (props) => {
	const location = useLocation();
	return <HeaderWithRouterWrapper location={location} {...props} />;
};
class HeaderWithRouterWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mainMenuCollapsed: true,
			subMenuCollapsed: true,
		};
	}

	toggleMainMenuCollapsed = () => this.setMainMenuCollapsed(!this.state.mainMenuCollapsed);
	setMainMenuCollapsed = (collapsed) => {
		this.setState({ mainMenuCollapsed: collapsed });
		const collapseInstance = Collapse.getOrCreateInstance(document.querySelector("#main-header-nav"));
		collapsed ? collapseInstance.hide() : collapseInstance.show();
	};

	toggleSubMenuCollapsed = () => this.setSubMenuCollapsed(!this.state.subMenuCollapsed);
	setSubMenuCollapsed = (collapsed) => {
		this.setState({ subMenuCollapsed: collapsed });
		const collapseInstance = Collapse.getOrCreateInstance(document.querySelector("#sub-header-nav"));
		collapsed ? collapseInstance.hide() : collapseInstance.show();
	};

	collapseBothMenus = () => {
		if (!this.state.mainMenuCollapsed) Collapse.getOrCreateInstance(document.querySelector("#main-header-nav")).hide();
		if (!this.state.subMenuCollapsed) Collapse.getOrCreateInstance(document.querySelector("#sub-header-nav")).hide();
		this.setState({ mainMenuCollapsed: true, subMenuCollapsed: true });
	};

	render() {
		return (
			<>
				<button
					className={`close-navbar-toggler ${this.state.mainMenuCollapsed && this.state.subMenuCollapsed ? "collapsed" : ""}`}
					onClick={() => this.collapseBothMenus()}
				/>
				<div className="header-container no-print">
					<nav className="container main-header navbar navbar-expand-lg">
						<div className="container-fluid">
							<GSLogo type={LOGO_TYPE.WHITE} className="navbar-brand" linkTo={Nav.PATHS.HOME} />
							<button className="navbar-toggler" onClick={() => this.toggleMainMenuCollapsed()}>
								<FontAwesomeIcon icon="fa-regular fa-bars" />
							</button>
							<div className="navbar-collapse collapse" id="main-header-nav">
								<div className="navbar-nav">
									<a className="nav-link" href={`${Nav.MAIN_SITE_PATH}/about/`} target="_blank" rel="noreferrer">
										{TRANSLATIONS.NAVLINKS.ABOUT}
									</a>
									<a className="nav-link" href={`${Nav.MAIN_SITE_PATH}/simulators/`} target="_blank" rel="noreferrer">
										{TRANSLATIONS.NAVLINKS.SIMULATORS}
									</a>
									<a className="nav-link" href={`${Nav.MAIN_SITE_PATH}/models/`} target="_blank" rel="noreferrer">
										{TRANSLATIONS.NAVLINKS.MODELS}
									</a>
									<a className="nav-link" href={`${Nav.MAIN_SITE_PATH}/industries/`} target="_blank" rel="noreferrer">
										{TRANSLATIONS.NAVLINKS.INDUSTRIES}
									</a>
									<a className="nav-link" href={`${Nav.MAIN_SITE_PATH}/media/`} target="_blank" rel="noreferrer">
										{TRANSLATIONS.NAVLINKS.MEDIA}
									</a>
									<a className="nav-link" href={`${Nav.MAIN_SITE_PATH}/blog/`} target="_blank" rel="noreferrer">
										{TRANSLATIONS.NAVLINKS.BLOG}
									</a>
								</div>
								<SocialIcons />
								<a href={`${Nav.MAIN_SITE_PATH}/contact/`} target="_blank" rel="noreferrer">
									<button className="btn contact-btn">{TRANSLATIONS.NAVLINKS.CONTACT}</button>
								</a>
							</div>
						</div>
					</nav>

					<nav className="sub-header navbar navbar-expand-lg">
						<div className="container-fluid">
							<button className="navbar-toggler" onClick={() => this.toggleSubMenuCollapsed()}>
								{Nav.getCurrentPathText(this.props.location.pathname) + " "}
								<FontAwesomeIcon
									className="icon-btn"
									icon={`fa-solid ${this.state.subMenuCollapsed ? "fa-chevron-down" : "fa-chevron-up"}`}
								/>
							</button>
							<div className="collapse navbar-collapse" id="sub-header-nav">
								<div className="navbar-nav">
									{(globalState.loggedIn
										? [
												{ data: Nav.ROUTES.DASHBOARD },
												{ data: Nav.ROUTES.WHATS_NEW },
												{ data: Nav.ROUTES.DOWNLOADS },
												{ data: Nav.ROUTES.PERFORMANCE },
												{ data: Nav.ROUTES.GROUP_MANAGEMENT, condition: HelperFunctions.loggedInAsInstructor() },
												{ data: Nav.ROUTES.USER_MANAGEMENT, condition: HelperFunctions.loggedInAsInstructor() },
												{ data: Nav.ROUTES.PROFILE, condition: !globalState.ghostMode },
												{ data: Nav.ROUTES.DEVELOPER, condition: HelperFunctions.loggedInAsDeveloper() },
												{ data: Nav.ROUTES.HELP },
												{
													data: Nav.ROUTES.LOGIN,
													customText: TRANSLATIONS.NAVLINKS.LOG_OUT,
													handleClick: TMSHelpers.handleLogout,
												},
										  ]
										: [
												{
													data: Nav.ROUTES.LOGIN,
													customText: `${Nav.ROUTES.LOGIN.title} / ${Nav.ROUTES.SIGNUP.title}`,
												},
										  ]
									).reduce((navlinks, link, i) => {
										return link.data.active && (link.condition == null || link.condition)
											? navlinks.concat([
													<NavLink
														key={i}
														to={link.data.path}
														className="nav-link"
														target={link.data.externalURL && "_blank"}
														onClick={() => {
															if (link.handleClick) link.handleClick();
															this.collapseBothMenus();
														}}
													>
														{link.customText || link.data.title}
													</NavLink>,
											  ])
											: navlinks;
									}, [])}
								</div>
							</div>
						</div>
					</nav>
				</div>
			</>
		);
	}
}
