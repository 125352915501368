import React, { Component } from "react";
import { TRANSLATIONS } from "../../data-structures/localization";
import OrgDataForm from "../../forms/org-data-form";
import ResetPasswordForm from "../../forms/reset-password-form";
import UserDataForm from "../../forms/user-data-form";

export default class Profile extends Component {
	render() {
		return (
			<div className="profile-container container">
				<h1>{TRANSLATIONS.HEADERS.PROFILE}</h1>
				<OrgDataForm />
				<UserDataForm />
				<ResetPasswordForm requireCurrentPassword />
			</div>
		);
	}
}
