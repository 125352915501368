import React, { Component } from "react";
import { TRANSLATIONS } from "../data-structures/localization";
import { TMSHelpers } from "../helpers/tms-helpers";
import { HelperFunctions } from "../helpers/helper-functions";
import { globalState, onGlobalStateChange } from "../store";
import { Loading } from "../common/loading-icon";
import GSForms from "./form-elements";
import { NavLink } from "react-router-dom";
import Nav from "../data-structures/navigation";

export default class VerifyEmail extends Component {
	constructor(props) {
		super(props);

		const queryParameters = HelperFunctions.getUrlParams();

		this.state = {
			userIdFromURL: queryParameters.get("id"),
			tokenFromURL: queryParameters.get("token"),
			emailVerified: null,

			verificationError: null,
			responseError: null,

			verifyingEmail: false,
			submitting: false,
			saved: false,
		};
	}

	componentDidMount() {
		if (this.state.tokenFromURL) {
			this.handleVerifyEmailAddress();
		}

		const checkForUserData = () => {
			if (globalState.userData) {
				this.setState({
					emailVerified: globalState.userData.emailVerified,
				});
			}
		};

		checkForUserData();
		this.unsubscribe = onGlobalStateChange(checkForUserData);
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	handleVerifyEmailAddress = async () => {
		this.setState({ verifyingEmail: true });
		TMSHelpers.verifyEmailAddress(this.state.userIdFromURL, this.state.tokenFromURL).then((response) => {
			this.setState(
				{
					verifyingEmail: false,
					...(response.Success ? { emailVerified: true } : {}),
					...this.getVerificationErrorState(response),
				},
				() => {
					if (globalState.loggedIn) TMSHelpers.refreshGlobalData();
				}
			);
		});
	};

	getVerificationErrorState = (response) => {
		if (response && response.errorMsg)
			switch (response.key) {
				default:
					return { verificationError: HelperFunctions.getResponseError(response) };
			}
	};

	handleEmailVerificationRequest = async () => {
		this.setState({ submitting: true, saved: false }, () => {
			TMSHelpers.triggerEmailVerificationRequest().then((response) => {
				this.setState({
					submitting: false,
					saved: response.Success,
					...this.getErrorState(response),
				});
			});
		});
	};

	getErrorState = (response) => {
		if (response && response.errorMsg)
			switch (response.key) {
				default:
					return { responseError: HelperFunctions.getResponseError(response) };
			}
	};

	render() {
		return (
			<div>
				<h1>{TRANSLATIONS.HEADERS.VERIFY_EMAIL}</h1>
				<br />
				{(!globalState.loggedIn || globalState.userData) && (
					<>
						{this.state.emailVerified ? (
							<>
								<h3>{TRANSLATIONS.EMAIL.EMAIL_VERIFIED_MESSAGE}</h3>
								<div>
									{globalState.loggedIn
										? TRANSLATIONS.EMAIL.EMAIL_VERIFIED_SUBMESSAGE_LOGGED_IN
										: TRANSLATIONS.EMAIL.EMAIL_VERIFIED_SUBMESSAGE_LOGGED_OUT}
								</div>
							</>
						) : (
							<>
								{this.state.userIdFromURL && this.state.tokenFromURL && (
									<>
										{this.state.verifyingEmail && (
											<>
												<h3>{TRANSLATIONS.EMAIL.VERIFYING_YOUR_EMAIL}</h3>
												<Loading />
											</>
										)}

										{this.state.verificationError && (
											<>
												<h3>{TRANSLATIONS.EMAIL.VERIFICATION_FAILED}</h3>
												<GSForms.ErrorContainer message={this.state.verificationError} />
											</>
										)}

										<hr />
									</>
								)}

								<h4>{TRANSLATIONS.EMAIL.NEW_LINK_MESSAGE}</h4>
								{globalState.loggedIn ? (
									<>
										<h5>{TRANSLATIONS.EMAIL.SEND_VERIFICATION_EMAIL}</h5>

										<GSForms.Button
											buttonText={TRANSLATIONS.EMAIL.RESEND_EMAIL}
											onClick={this.handleEmailVerificationRequest}
											type="button"
										/>
										<GSForms.ErrorContainer message={this.state.responseError} />
										{this.state.submitting && <Loading />}
										{this.state.saved && <GSForms.SavedCheck customMessage={TRANSLATIONS.EMAIL.VERIFICATION_EMAIL_SENT} />}
									</>
								) : (
									<div>{TRANSLATIONS.EMAIL.NEW_LINK_LOGGED_OUT_MESSAGE}</div>
								)}
							</>
						)}

						{globalState.loggedIn ? (
							<NavLink to={Nav.PATHS.DASHBOARD.ROOT}>{TRANSLATIONS.FORMS.BACK_TO_DASHBOARD}</NavLink>
						) : (
							<NavLink to={Nav.PATHS.LOGIN}>{TRANSLATIONS.FORMS.BACK_TO_LOGIN}</NavLink>
						)}
					</>
				)}
			</div>
		);
	}
}
