import React, { Component } from "react";
import { Card, CardList, CardSelectionPanel } from "../../common/cards";
import GSForms from "../../forms/form-elements";
import { Loading } from "../../common/loading-icon";
import { TRANSLATIONS } from "../../data-structures/localization";
import { USER_IMPORT_HEADERS } from "../../data-structures/gs-constants";
import { globalState, onGlobalStateChange } from "../../store";
import { GroupForm } from "../../forms/group-form";
import { CSVLink } from "react-csv";
import { ImportHelpers } from "../../helpers/import-helpers";
import { HelperFunctions } from "../../helpers/helper-functions";
import { TMSHelpers } from "../../helpers/tms-helpers";
import { UserImportExport } from "../../forms/user-import-export";
import "./group-list.scss";

export default class GroupList extends Component {
	//#region Initialization
	constructor(props) {
		super(props);

		this.state = {
			availableGroups: [],

			selectedGroup: null,
			addingNewGroup: false,
			editMode: false,

			groupIdToDelete: null,
			deletingGroup: false,

			importingUsers: false,

			availableUsers: [],
			userIdsToRemoveFromGroup: [],
			usersToAddToGroup: [],
			savingUsers: false,
			savedUsers: false,

			availableCourses: [],
			courseIdsToRemoveFromGroup: [],
			coursesToAddToGroup: [],
			savingCourses: false,
			savedCourses: false,
		};
	}

	componentDidMount() {
		this.setState(this.getUpdatedState());

		this.unsubscribe = onGlobalStateChange(() => {
			this.setState({
				...this.getUpdatedState(true),
				selectedGroup: globalState.groupList?.find((group) => this.state.selectedGroup?.id === group.id),
			});
		});
	}

	componentWillUnmount() {
		this.unsubscribe();
	}
	//#endregion

	getUpdatedState = (refreshData = false) => {
		return {
			...(globalState.groupList &&
				(refreshData || this.state.availableGroups?.length === 0) && { availableGroups: this.getSortedFilteredGroups() }),
			...(globalState.orgData && (refreshData || this.state.availableUsers?.length === 0) && { availableUsers: this.getSortedUsers() }),
			...(globalState.courseCatalog &&
				(refreshData || this.state.availableCourses?.length === 0) && { availableCourses: this.getSortedCourses() }),
		};
	};

	toggleAddGroup = (newState) => this.setState({ addingNewGroup: newState || !this.state.addingNewGroup, selectedGroup: null });

	GroupPanel = () => {
		const unassignedPseudoIsSelected = this.state.selectedGroup?.id === UNASSIGNED_PSEUDO_GROUP.id;

		return this.state.addingNewGroup ? (
			<GroupForm
				addingGroup={this.state.addingNewGroup}
				toggleAddGroup={this.toggleAddGroup}
				handleSubmit={() => this.setState(this.getUpdatedState(true))}
			/>
		) : (
			this.state.selectedGroup && (
				<>
					{!unassignedPseudoIsSelected && (
						<GroupForm
							key={this.state.selectedGroup.id}
							selectedGroup={this.state.selectedGroup}
							addingGroup={this.state.addingNewGroup}
							editMode={this.state.editMode}
							viewOnly={this.props.viewOnly}
							toggleAddGroup={this.toggleAddGroup}
							toggleEditMode={() =>
								this.setState({
									editMode: !this.state.editMode,
								})
							}
							handleSubmit={() => this.setState(this.getUpdatedState(true))}
						/>
					)}

					{this.ClassRoster()}

					{!unassignedPseudoIsSelected && this.CourseList()}
				</>
			)
		);
	};

	//#region Group Cards
	getSortedFilteredGroups = () => {
		const sortedGroups = globalState.groupList.sort((a, b) => {
			const aActive = a.active ? 1 : 0;
			const bActive = b.active ? 1 : 0;
			if (aActive === bActive) return new Date(a.startDate).getTime() > new Date(b.startDate).getTime() ? 1 : -1;
			else return aActive > bActive ? -1 : 1;
		});
		const assignedGroups = (
			!HelperFunctions.loggedInAsAdmin() || this.props.assignedGroupsOnly
				? sortedGroups.filter((g) => g.userList[globalState.userData.id])
				: sortedGroups
		).map((x) => ({ ...x }));

		if (!this.props.assignedGroupsOnly) {
			const unassignedUsers = globalState.orgData.userList
				.filter((x) => x.groups.length === 0)
				.reduce(
					(users, user) => ({
						...users,
						[user.id]: {
							username: user.username,
							completionData: {},
						},
					}),
					{}
				);
			if (Object.entries(unassignedUsers).length > 0) {
				assignedGroups.push({
					...UNASSIGNED_PSEUDO_GROUP,
					name: TRANSLATIONS.GROUP_MANAGEMENT.UNASSIGNED_USERS,
					userList: unassignedUsers,
				});
			}
		}

		return assignedGroups;
	};

	GroupCards = () => (
		<CardList>
			{this.state.availableGroups?.length > 0
				? this.state.availableGroups.map((group) => (
						<div key={group.id}>
							{this.GroupCard(group)}

							{this.state.groupIdToDelete === group.id && (
								<div className="flexbox col-12">
									<GSForms.PermanentActionWarning />

									<GSForms.Button
										buttonText={TRANSLATIONS.FORMS.CANCEL}
										onClick={() => this.setState({ groupIdToDelete: null })}
										type="button"
									/>
									<GSForms.Button
										className="btn-red"
										buttonText={TRANSLATIONS.GROUP_MANAGEMENT.DELETE_GROUP}
										onClick={() => this.handleDeleteGroup(group)}
										type="button"
									/>

									{this.state.deletingGroup && <Loading />}
								</div>
							)}
						</div>
				  ))
				: TRANSLATIONS.GROUP_MANAGEMENT.NO_GROUPS_MESSAGE}
		</CardList>
	);

	GroupCard = (group) => {
		const isUnassignedPseudo = group.id === UNASSIGNED_PSEUDO_GROUP.id;
		return (
			<Card
				className="selectable"
				title={group.name}
				detailsObj={{
					...(!isUnassignedPseudo && {
						[TRANSLATIONS.GROUP_MANAGEMENT.DATE_RANGE]: `${new Date(group.startDate).toLocaleDateString()} - ${new Date(
							group.endDate
						).toLocaleDateString()}`,
					}),
				}}
				viewOnly={this.props.viewOnly}
				isSelected={this.state.selectedGroup?.id === group.id}
				isActive={group.active}
				handleSelect={() =>
					this.setState({
						selectedGroup: group,
						addingNewGroup: false,
						editMode: false,
						userIdsToRemoveFromGroup: [],
						usersToAddToGroup: [],
						courseIdsToRemoveFromGroup: [],
						coursesToAddToGroup: [],
					})
				}
				displayEditButton={!isUnassignedPseudo}
				inEditMode={this.state.editMode}
				handleEdit={() => {
					this.setState({
						selectedGroup: group,
						addingNewGroup: false,
						editMode: true,
					});
				}}
				cancelEdit={() => this.setState({ editMode: false })}
				displayRemoveButton={HelperFunctions.loggedInAsAdmin() && !isUnassignedPseudo}
				handleRemove={() => this.setState({ groupIdToDelete: group.id })}
				cancelRemove={() => this.setState({ groupIdToDelete: null })}
				markedForRemoval={this.state.groupIdToDelete === group.id}
			/>
		);
	};

	handleDeleteGroup = (group) =>
		this.setState(
			{
				deletingGroup: true,
				selectedGroup: this.state.selectedGroup
					? this.state.groupIdToDelete === group.id
						? this.state.availableGroups[0]
						: this.state.selectedGroup
					: null,
			},
			async () =>
				await TMSHelpers.deleteGroupRequest(this.state.groupIdToDelete).then(() => {
					this.setState({
						deletingGroup: false,
						groupIdToDelete: null,
					});
				})
		);
	//#endregion

	//#region Class Roster
	getSortedUsers = () => {
		return globalState.orgData.userList.map((user) => ({ label: user.username, value: user.id, id: user.id, ...user }));
	};

	ClassRoster = () => {
		const unassignedIsSelected = this.state.selectedGroup?.id === UNASSIGNED_PSEUDO_GROUP.id;
		const usersInSelectedGroup = Object.keys(this.state.selectedGroup?.userList)
			.map((uId) => globalState.orgData.userList.find((u) => u.id === uId))
			.filter((x) => x);

		return (
			<div className="class-roster-container">
				<div className="sticky-top">
					<h2 className="no-print">
						{unassignedIsSelected ? TRANSLATIONS.GROUP_MANAGEMENT.UNASSIGNED_USER_LIST : TRANSLATIONS.GROUP_MANAGEMENT.GROUP_ROSTER}
					</h2>
					<h2 className="print-only">
						{unassignedIsSelected
							? TRANSLATIONS.GROUP_MANAGEMENT.UNASSIGNED_USER_LIST
							: TRANSLATIONS.GROUP_MANAGEMENT.GROUP_ROSTER_PRINT_HEADER(this.state.selectedGroup.name)}
					</h2>
					<div className="btn-container">
						<GSForms.Button
							type="button"
							className="no-print"
							buttonText={unassignedIsSelected ? TRANSLATIONS.PRINT : TRANSLATIONS.GROUP_MANAGEMENT.PRINT_GROUP_ROSTER}
							onClick={window.print}
						/>

						<GSForms.Button
							customButton={
								<CSVLink
									className="btn no-print"
									data={ImportHelpers.prepUserListForExport(usersInSelectedGroup)}
									headers={USER_IMPORT_HEADERS}
									target="_blank"
									filename={"unassigned-user-list.csv"}
								>
									{TRANSLATIONS.USER_IMPORT.EXPORT_USERS}
								</CSVLink>
							}
						/>

						{!this.props.viewOnly && !unassignedIsSelected && (
							<GSForms.Button
								type="button"
								className="no-print"
								buttonText={TRANSLATIONS.USER_IMPORT.IMPORT_USERS}
								onClick={() => this.setState({ importingUsers: true })}
							/>
						)}
					</div>

					{!unassignedIsSelected && this.state.importingUsers && (
						<UserImportExport
							exportFileName={`${this.state.selectedGroup.name.toLowerCase().replace(/ /g, "-")}-user-list.csv`}
							groupsToEnrollUsersIn={[this.state.selectedGroup]}
							toggleVisibility={() => this.setState({ importingUsers: false })}
						/>
					)}
				</div>

				{!unassignedIsSelected && !this.props.viewOnly && (
					<GSForms.SelectField
						className="no-print"
						placeholder={`${TRANSLATIONS.USER_MANAGEMENT.ADD_USER}...`}
						fieldValue={null}
						fieldOptions={this.state.availableUsers?.filter(
							(u) => !this.state.selectedGroup.userList[u.id] && !this.state.usersToAddToGroup.find((user) => user.id === u.id)
						)}
						handleChange={(option) => {
							if (!this.state.usersToAddToGroup.find((u) => u.id === option.id)) {
								let newSelectedUsers = [...this.state.usersToAddToGroup, option];
								newSelectedUsers = HelperFunctions.sortByCatalogOrder(newSelectedUsers, this.state.availableUsers);
								this.setState({ usersToAddToGroup: newSelectedUsers });
							}
						}}
					/>
				)}

				<CardList>
					{[
						...this.state.usersToAddToGroup.map((user) => user && this.UserCard(user, true)),
						...usersInSelectedGroup.sort((a, b) => b.role - a.role).map((user) => user && this.UserCard(user)),
					]}
				</CardList>

				{this.state.userIdsToRemoveFromGroup.length + this.state.usersToAddToGroup.length > 0 && (
					<div className="flexbox col-12 no-print">
						<GSForms.Button
							buttonText={TRANSLATIONS.FORMS.DISCARD_CHANGES}
							onClick={() => this.setState({ userIdsToRemoveFromGroup: [], usersToAddToGroup: [] })}
							type="button"
						/>
						<GSForms.Button
							className="btn-red"
							buttonText={TRANSLATIONS.FORMS.SAVE_CHANGES}
							onClick={this.updateClassRoster}
							type="button"
						/>
					</div>
				)}

				{this.state.savingUsers && <Loading />}
				{this.state.savedUsers && <GSForms.SavedCheck toggleVisible={() => this.setState({ savedUsers: false })} />}
			</div>
		);
	};

	UserCard = (user, userToAdd = false) => {
		const markedForRemoval = !userToAdd && this.state.userIdsToRemoveFromGroup.includes(user.id);
		return (
			<Card
				key={user.id}
				className={`${markedForRemoval ? " marked-for-removal" : ""}${userToAdd ? " marked-for-addition no-print" : ""}`}
				title={`${user.firstName} ${user.lastName}`}
				subtitle={HelperFunctions.getRole(user.role).label}
				detailsObj={{
					[TRANSLATIONS.USER_MANAGEMENT.USERNAME]: user.username,
				}}
				viewOnly={this.props.viewOnly}
				isActive={user.active}
				isDisabled={this.state.savingUsers}
				displayRemoveButton={this.state.selectedGroup.id !== UNASSIGNED_PSEUDO_GROUP.id}
				handleRemove={() =>
					userToAdd
						? this.setState({ usersToAddToGroup: this.state.usersToAddToGroup.filter((u) => u.id !== user.id) })
						: this.setState({ userIdsToRemoveFromGroup: [...this.state.userIdsToRemoveFromGroup, user.id] })
				}
				cancelRemove={() => {
					if (!userToAdd) {
						this.setState({
							userIdsToRemoveFromGroup: this.state.userIdsToRemoveFromGroup.filter((u) => u !== user.id),
						});
					}
				}}
				markedForRemoval={markedForRemoval}
				customRemoveIcon="fa-x"
				customCancelIcon="fa-arrow-rotate-left"
			/>
		);
	};

	updateClassRoster = () => {
		this.setState(
			{
				savingUsers: true,
			},
			() => {
				const remainingAddedUserIds = Object.keys(this.state.selectedGroup.userList).filter(
					(uId) => !this.state.userIdsToRemoveFromGroup.includes(uId)
				);
				const newUserIds = this.state.usersToAddToGroup
					.filter((user) => !Object.keys(this.state.selectedGroup.userList).includes(user.id))
					.map((u) => u.id);
				const newUserList = [...remainingAddedUserIds, ...newUserIds];
				TMSHelpers.updateGroupUserList(this.state.selectedGroup.id, newUserList).then((response) => {
					this.setState({
						...this.getUpdatedState(true),
						...(response.Success && { userIdsToRemoveFromGroup: [], usersToAddToGroup: [] }),
						savingUsers: false,
						savedUsers: response.Success,
					});
				});
			}
		);
	};
	//#endregion

	//#region Course List
	getSortedCourses = () => {
		return globalState.courseCatalog.map((course) => ({ label: course.name, value: course.id, id: course.id, ...course }));
	};

	CourseList = () => {
		const coursesInSelectedGroup = this.state.selectedGroup?.courseIds
			.map((id) => globalState.courseCatalog.find((x) => x.id === id))
			.filter((x) => x);

		return (
			<div className="course-list-container">
				<div className="sticky-top">
					<h2 className="no-print">{TRANSLATIONS.GROUP_MANAGEMENT.COURSES}</h2>
					<h2 className="print-only">{TRANSLATIONS.GROUP_MANAGEMENT.COURSE_LIST_PRINT_HEADER(this.state.selectedGroup.name)}</h2>
					<GSForms.Button type="button" className="no-print" buttonText={TRANSLATIONS.PRINT} onClick={window.print} />
				</div>

				{globalState.courseCatalog ? (
					<>
						{!this.props.viewOnly && (
							<GSForms.SelectField
								className="no-print"
								placeholder={`${TRANSLATIONS.GROUP_MANAGEMENT.ADD_COURSE}...`}
								fieldValue={null}
								fieldOptions={this.state.availableCourses?.filter(
									(c) =>
										!this.state.selectedGroup.courseIds.includes(c.id) &&
										!this.state.coursesToAddToGroup.find((course) => course.id === c.id)
								)}
								handleChange={(option) => {
									if (!this.state.coursesToAddToGroup.find((u) => u.id === option.id)) {
										let newSelectedCourses = [...this.state.coursesToAddToGroup, option];
										newSelectedCourses = HelperFunctions.sortByCatalogOrder(newSelectedCourses, this.state.availableCourses);
										this.setState({ coursesToAddToGroup: newSelectedCourses });
									}
								}}
							/>
						)}

						<CardList>
							{[
								...this.state.coursesToAddToGroup.map((course) => this.CourseCard(course, true)),
								...coursesInSelectedGroup?.map((course) => this.CourseCard(course)),
							]}
						</CardList>

						{this.state.coursesToAddToGroup.length + this.state.courseIdsToRemoveFromGroup.length > 0 && (
							<div className="flexbox col-12 no-print">
								<GSForms.Button
									buttonText={TRANSLATIONS.FORMS.DISCARD_CHANGES}
									onClick={() => this.setState({ coursesToAddToGroup: [], courseIdsToRemoveFromGroup: [] })}
									type="button"
								/>
								<GSForms.Button
									className="btn-red"
									buttonText={TRANSLATIONS.FORMS.SAVE_CHANGES}
									onClick={this.updateCourseList}
									type="button"
								/>
							</div>
						)}

						{this.state.savingCourses && <Loading />}
						{this.state.savedCourses && <GSForms.SavedCheck toggleVisible={() => this.setState({ savedCourses: false })} />}
					</>
				) : (
					<Loading />
				)}
			</div>
		);
	};

	CourseCard = (course, courseToAdd = false) => {
		const markedForRemoval = !courseToAdd && this.state.courseIdsToRemoveFromGroup.includes(course.id);
		return (
			<Card
				key={course.id}
				className={`${markedForRemoval ? " marked-for-removal" : ""}${courseToAdd ? " marked-for-addition no-print" : ""}`}
				title={course.name}
				viewOnly={this.props.viewOnly}
				isActive={course.active}
				isDisabled={this.state.savingCourses}
				displayRemoveButton={this.state.selectedGroup.id !== UNASSIGNED_PSEUDO_GROUP.id}
				handleRemove={() =>
					courseToAdd
						? this.setState({ coursesToAddToGroup: this.state.coursesToAddToGroup.filter((c) => c.id !== course.id) })
						: this.setState({ courseIdsToRemoveFromGroup: [...this.state.courseIdsToRemoveFromGroup, course.id] })
				}
				cancelRemove={() => {
					if (!courseToAdd) {
						this.setState({
							courseIdsToRemoveFromGroup: this.state.courseIdsToRemoveFromGroup.filter((c) => c !== course.id),
						});
					}
				}}
				markedForRemoval={markedForRemoval}
				customRemoveIcon="fa-x"
				customCancelIcon="fa-arrow-rotate-left"
			/>
		);
	};

	updateCourseList = () => {
		this.setState(
			{
				savingCourses: true,
			},
			() => {
				const remainingAddedCourseIds = this.state.selectedGroup.courseIds.filter(
					(cId) => !this.state.courseIdsToRemoveFromGroup.includes(cId)
				);
				const newCourseIds = this.state.coursesToAddToGroup
					.filter((course) => !this.state.selectedGroup.courseIds.includes(course.id))
					.map((c) => c.id);
				const newCourseList = [...remainingAddedCourseIds, ...newCourseIds];
				TMSHelpers.updateGroupCourseList(this.state.selectedGroup.id, newCourseList).then((response) => {
					this.setState({
						...this.getUpdatedState(true),
						...(response.Success && { courseIdsToRemoveFromGroup: [], coursesToAddToGroup: [] }),
						savingCourses: false,
						savedCourses: response.Success,
					});
				});
			}
		);
	};
	//#endregion

	render() {
		return globalState.userData && globalState.groupList && globalState.courseCatalog ? (
			<>
				{!this.props.viewOnly && (
					<div className="flexbox justify-end">
						<GSForms.Button
							className="no-print"
							buttonText={TRANSLATIONS.GROUP_MANAGEMENT.ADD_NEW_GROUP}
							onClick={() => this.toggleAddGroup(true)}
						/>
					</div>
				)}

				<CardSelectionPanel
					panelIsOpen={this.state.selectedGroup || this.state.addingNewGroup}
					handleDetailsClose={() => this.setState({ selectedGroup: null, addingNewGroup: false })}
					detailsTitle={this.state.selectedGroup?.name}
				>
					<div>
						<h2>{TRANSLATIONS.GROUP_MANAGEMENT.GROUPS}</h2>
						{this.GroupCards()}
					</div>

					<div>{this.GroupPanel()}</div>
				</CardSelectionPanel>
			</>
		) : (
			<Loading />
		);
	}
}

const UNASSIGNED_PSEUDO_GROUP = {
	id: "unassigned",
	name: null,
	notes: "",
	startDate: null,
	endDate: null,
	courseIds: null,
	userList: null,
	active: true,
	dateCreated: null,
	dateLastModified: null,
	isFilteredVisible: false,
};
